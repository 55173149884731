import { gql, useLazyQuery, useQuery } from '@apollo/client';
export const GET_FIREBASE_NOTIFICATION_COUNT = gql `
  query getFirebaseNotificationCount {
    getFirebaseNotificationCount
  }
`;
export const useGetFirebaseNotificationCountQuery = () => {
    return useQuery(GET_FIREBASE_NOTIFICATION_COUNT, {
        notifyOnNetworkStatusChange: true,
    });
};
export const useGetFirebaseNotificationCountLazyQuery = () => {
    const [getFirebaseNotificationCount, { data }] = useLazyQuery(GET_FIREBASE_NOTIFICATION_COUNT);
    return { getFirebaseNotificationCount, notificationUnReadCount: data };
};
