import { Box, IconButton, Tooltip } from '@mui/material';
import { NxCustomImage } from 'common/components';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useTranslation } from 'react-i18next';
import { ResetIcon, ZoomInIcon, ZoomOutIcon } from 'ui/icons';
import React, { useRef } from 'react';
const NftFullScreenPreview = ({ selectedAsset }) => {
    const imgSrc = (selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.originalUrl) || '';
    const zoomPanRef = useRef(null);
    const { t } = useTranslation();
    return (<TransformWrapper ref={zoomPanRef}>
      {({ zoomIn, zoomOut, resetTransform }) => {
            var _a, _b;
            const isOriginalView = !(zoomPanRef === null || zoomPanRef === void 0 ? void 0 : zoomPanRef.current) || ((_b = (_a = zoomPanRef === null || zoomPanRef === void 0 ? void 0 : zoomPanRef.current) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.scale) === 1;
            return (<>
            <Box className='zoom-controls-buttons'>
              <Tooltip title={<>{t('Zoom In')}</>}>
                <IconButton aria-label='Zoom In' color='primary' className='controls-btn' size='medium' onClick={() => zoomIn()}>
                  <ZoomInIcon fontSize='small'/>
                </IconButton>
              </Tooltip>
              <Tooltip title={<>{t('Zoom Out')}</>}>
                <IconButton aria-label='Zoom Out' color='primary' className={`controls-btn ${isOriginalView ? 'disabled' : ''}`} size='medium' disabled={isOriginalView} onClick={() => zoomOut()}>
                  <ZoomOutIcon fontSize='small'/>
                </IconButton>
              </Tooltip>
              <Tooltip title={<>{t('Fit to Screen')}</>}>
                <IconButton aria-label='Fit to Screen' color='primary' className={`controls-btn ${isOriginalView ? 'disabled' : ''}`} size='medium' disabled={isOriginalView} onClick={() => resetTransform()}>
                  <ResetIcon fontSize='small'/>
                </IconButton>
              </Tooltip>
            </Box>
            <TransformComponent wrapperClass='pan-pinch-wrapper' contentClass='pan-pinch-content'>
              <Box className='vertical-slide-box'>
                <NxCustomImage className='vertical-slide-img' src={imgSrc} alt={(selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.name) || 'NFT'} id='nft-id' onClick={() => {
                    console.log('Image click');
                }}/>
              </Box>
            </TransformComponent>
          </>);
        }}
    </TransformWrapper>);
};
export default NftFullScreenPreview;
