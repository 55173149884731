import { alpha, Box, Card, styled } from '@mui/material';
export const SideBarWrap = styled(Box)(({ theme }) => ({
    '.nx-menu-icon': {
        minWidth: 'inherit',
        marginRight: `${theme.spacing(1.5)}`,
    },
    '.nx-menu-text span': {
        fontSize: '1rem',
        fontWeight: '500',
    },
    '.nx-main-menu': {
        padding: '0',
    },
    '.nx-main-menu-item': {
        borderRadius: '.75rem',
        transition: '0.4s linear 0s',
        '&:not(:last-child)': {
            margin: `0 0 ${theme.spacing(1)}`,
        }
    },
    '.nx-main-menu-item.active, .nx-main-menu-item.active:hover': {
        background: theme.dynamicTheme.sidebarNavigation.link.colorActive,
    },
    '.nx-main-menu-item:hover': {
        backgroundColor: alpha(`${theme.dynamicTheme.sidebarNavigation.link.colorHover}`, .6),
    },
    '#sidebar-button-back-to-category': {
        paddingLeft: `${theme.spacing(0)}`,
    },
    '.nx-drawer-hr': {
        background: theme.dynamicTheme.sidebarNavigation.borderColor,
        margin: `${theme.spacing(0)}`,
    },
}));
export const LeftSideBarCardHead = styled(Box)(({ theme }) => ({
    marginBottom: `${theme.spacing(2)}`,
    '.nx-trade-link': {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    '.nft-book-icon-box': {
        height: '2.5rem',
        width: '2.5rem',
        borderRadius: '50%',
        backgroundColor: theme.dynamicTheme.sidebarNavigation.bookmarkIconBackground.backgroundColor,
        marginRight: `${theme.spacing(1.5)}`,
        '.nft-book-icon path': {
            stroke: theme.dynamicTheme.sidebarNavigation.bookmarkIconBackground.color,
        },
    }
}));
export const LeftSideBarCard = styled(Card)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        margin: 0,
        borderRadius: 0,
        padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
    }
}));
