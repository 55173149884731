var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Checkbox } from '@mui/material';
import MuiAutocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import React from 'react';
import { CheckboxCheckedIcon, CheckboxIcon, EmptyCircleOutlineIcon, SuccessCircleOutlineIcon } from 'ui/icons';
import { useFormContext } from '.';
export const Autocomplete = (_a) => {
    var { formik, label, options, helperText = ' ', autoFocus, required, disabled, multiple, optionClassName, showSelectedIcon = false, placeholder = '', onInputChange } = _a, props = __rest(_a, ["formik", "label", "options", "helperText", "autoFocus", "required", "disabled", "multiple", "optionClassName", "showSelectedIcon", "placeholder", "onInputChange"]);
    const { formId } = useFormContext();
    const id = `${formId ? (formId + '-') : ''}${props.name}`;
    const hasError = formik.touched[props.name] && Boolean(formik.errors[props.name]);
    return (<MuiAutocomplete id={id} disabled={disabled} multiple={multiple} options={options} value={formik.values[props.name]} onInputChange={onInputChange} disableCloseOnSelect={multiple} renderInput={(params) => {
            var _a;
            return (<MuiTextField {...params} label={label} placeholder={placeholder} autoFocus={autoFocus} InputLabelProps={Object.assign(Object.assign({}, params.InputLabelProps), { required: required, shrink: true })} InputProps={Object.assign({}, params.InputProps)} inputProps={Object.assign(Object.assign({}, params.inputProps), { autoComplete: 'off' })} error={hasError} helperText={(_a = formik.errors[props.name]) !== null && _a !== void 0 ? _a : helperText}/>);
        }} renderOption={(props, option, state) => (<li {...props} className={`${props.className} autocomplete-list-item ${optionClassName}`}>
          <div className="option-inner">
            <div className="option-label">{option.label}</div>
            {option.detail && (<div className="option-detail">{option.detail}</div>)}
          </div>
          {showSelectedIcon &&
                <div className="option-label">
              <Checkbox icon={multiple ? <CheckboxIcon fontSize="small"/> : <EmptyCircleOutlineIcon fontSize="small"/>} checkedIcon={multiple ? <CheckboxCheckedIcon fontSize="small"/> : <SuccessCircleOutlineIcon fontSize="small"/>} style={{ marginLeft: 8 }} checked={state.selected}/>
            </div>}
        </li>)} onChange={(e, value) => formik.setFieldValue(props.name, value)} isOptionEqualToValue={(option, value) => {
            return option.value === value.value;
        }}/>);
};
