var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
export const useCopyToClipboard = (resetInterval) => {
    const [isCopied, setCopied] = useState(false);
    useEffect(() => {
        let timeout;
        if (isCopied && resetInterval) {
            timeout = window.setTimeout(() => setCopied(false), resetInterval);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isCopied, resetInterval]);
    const handleCopy = (text) => __awaiter(void 0, void 0, void 0, function* () {
        if (!(navigator === null || navigator === void 0 ? void 0 : navigator.clipboard)) {
            return false;
        }
        try {
            yield navigator.clipboard.writeText(text);
            setCopied(true);
            return text;
        }
        catch (error) {
            setCopied(false);
            return false;
        }
    });
    return { isCopied, handleCopy };
};
