import { DwollaDialog } from 'common/components';
import { useNotificationContext } from '../index';
import { useAppContext } from '../Application/AppProvider';
import { useGetDwollaBankLazyQuery, useGetDwollaCustomerBalanceLazyQuery, useGetDwollaCustomerLazyQuery } from 'graphql-client/dwolla';
import Cookies from 'js-cookie';
import { useNavigate, useSearchParams, createSearchParams, useLocation } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { DwollaCustomerStatus, } from 'types/graphql.models';
import * as DWOLLA_ACTIONS from './DwollaActions';
import { DwollaTypes, DwollaCurrentStateTypes, DwollaSetupWalletSteps } from './dwolla-types';
import { NotificationSubtype } from '../Notification/notification-types';
import { useDeferredPromise } from 'helpers/hooks';
import { LinkTokenReinitiate } from './LinkTokenReinitiate';
const initialState = {
    defaultDrawerContentState: DwollaCurrentStateTypes.FETCHING_CUSTOMER_DATA,
    isWalletDrawerOpen: false,
    currentDrawerContentState: DwollaCurrentStateTypes.FETCHING_CUSTOMER_DATA,
    isDwollaDialogOpen: false,
    currentDwollaDialogContentState: DwollaCurrentStateTypes.CREATE_WALLET,
    walletSetupCurrentStep: DwollaSetupWalletSteps.WELCOME,
    recentlyAddedBank: null,
    dwollaData: {
        addFundAmount: 0,
        bankList: null,
        dwollaCustomerBalance: { balance: 0 },
        withdrawAmount: 0,
        dueAmount: 0
    },
    dwollaCustomerData: null,
    previousDialogState: []
};
const contextInitialState = {
    state: initialState,
    dispatch: () => null,
    getBankData: () => null,
    openWalletSetup: () => new Promise((resolve) => resolve({})),
    getCustomerBalance: () => null,
};
export const DwollaContext = React.createContext(contextInitialState);
const reducer = (state, action) => {
    switch (action.type) {
        case DwollaTypes.SWITCH_WALLET_DRAWER_OPEN_STATE:
            return Object.assign(Object.assign({}, state), { isWalletDrawerOpen: (action.payload === false || action.payload) ? action.payload : !state.isWalletDrawerOpen, currentDrawerContentState: state.defaultDrawerContentState, dwollaData: Object.assign(Object.assign({}, state.dwollaData), { addFundAmount: 0, withdrawAmount: 0 }) });
        case DwollaTypes.SWITCH_CURRENT_DRAWER_CONTENT_STATE:
            return Object.assign(Object.assign({}, state), { currentDrawerContentState: action.payload });
        case DwollaTypes.SWITCH_DEFAULT_DRAWER_CONTENT_STATE:
            return Object.assign(Object.assign({}, state), { defaultDrawerContentState: action.payload });
        case DwollaTypes.SWITCH_DWOLLA_DIALOG_OPEN_STATE:
            return Object.assign(Object.assign({}, state), { recentlyAddedBank: (action.payload ? state.recentlyAddedBank : null), previousDialogState: [], walletSetupCurrentStep: DwollaSetupWalletSteps.WELCOME, isDwollaDialogOpen: (action.payload === false || action.payload) ? action.payload : !state.isDwollaDialogOpen });
        case DwollaTypes.SWITCH_CURRENT_DWOLLA_DIALOG_CONTENT_STATE:
            return Object.assign(Object.assign({}, state), { currentDwollaDialogContentState: action.payload });
        case DwollaTypes.UPDATE_DWOLLA_CREATE_WALLET_STEP:
            return Object.assign(Object.assign({}, state), { walletSetupCurrentStep: action.payload });
        case DwollaTypes.UPDATE_ADD_FUND_AMOUNT:
            return Object.assign(Object.assign({}, state), { dwollaData: Object.assign(Object.assign({}, state.dwollaData), { addFundAmount: action.payload }) });
        case DwollaTypes.UPDATE_WITHDRAW_FUND_AMOUNT:
            return Object.assign(Object.assign({}, state), { dwollaData: Object.assign(Object.assign({}, state.dwollaData), { withdrawAmount: action.payload }) });
        case DwollaTypes.UPDATE_BANK_LIST:
            return Object.assign(Object.assign({}, state), { dwollaData: Object.assign(Object.assign({}, state.dwollaData), { bankList: action.payload }) });
        case DwollaTypes.GET_DWOLLA_CUSTOMER_BALANCE:
            return Object.assign(Object.assign({}, state), { dwollaData: Object.assign(Object.assign({}, state.dwollaData), { dwollaCustomerBalance: action.payload }) });
        case DwollaTypes.UPDATE_DWOLLA_CUSTOMER_DATA:
            return Object.assign(Object.assign({}, state), { dwollaCustomerData: action.payload });
        case DwollaTypes.UPDATE_RECENTLY_ADDED_BANK:
            return Object.assign(Object.assign({}, state), { previousDialogState: [], recentlyAddedBank: action.payload });
        case DwollaTypes.UPDATE_DIALOG_PREV_STATE:
            return Object.assign(Object.assign({}, state), { previousDialogState: action.payload });
        case DwollaTypes.UPDATE_DUE_AMOUNT:
            return Object.assign(Object.assign({}, state), { dwollaData: Object.assign(Object.assign({}, state.dwollaData), { dueAmount: action.payload }) });
        default:
            return state;
    }
};
export const useDwollaContext = () => {
    return useContext(DwollaContext);
};
// TODO: Refactor internal functions to move it outside
export const DwollaProvider = ({ children, dwollaCustomer }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { updateDwollaCustomerData, isLoggedIn } = useAppContext();
    const { defer, deferRef } = useDeferredPromise();
    const { notification } = useNotificationContext();
    // const router = useRouter();
    // const queryObj = (router?.query || {});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const queryObj = Object.fromEntries([...searchParams]);
    const { getDwollaBank, bankData } = useGetDwollaBankLazyQuery();
    const { getDwollaCustomerDetails, data: dwollaCustomerData } = useGetDwollaCustomerLazyQuery();
    const linkToken = Cookies.get('linkToken');
    const { getDwollaCustomerBalance, customerBalance } = useGetDwollaCustomerBalanceLazyQuery();
    const walletStatusNotification = [
        NotificationSubtype.walletDeactivated,
        NotificationSubtype.walletDocumentFailed,
        NotificationSubtype.walletMoreDetailsRequired,
        NotificationSubtype.walletReActivated,
        NotificationSubtype.walletSuspended,
        NotificationSubtype.walletVerified
    ];
    useEffect(() => {
        var _a;
        if (state.isWalletDrawerOpen && ((_a = state === null || state === void 0 ? void 0 : state.dwollaCustomerData) === null || _a === void 0 ? void 0 : _a.status) === DwollaCustomerStatus.Verified) {
            getDwollaCustomerBalance();
        }
        else if (!state.isDwollaDialogOpen && (queryObj.openwallet || queryObj.accupdate)) {
            const filteredQuery = Object.assign({}, queryObj);
            delete filteredQuery.openwallet;
            delete filteredQuery.accupdate;
            navigate({
                pathname: '/',
                search: `?${createSearchParams(Object.assign({}, filteredQuery))}`
            });
        }
        // eslint-disable-next-line
    }, [state.isWalletDrawerOpen]);
    useEffect(() => {
        dispatch(DWOLLA_ACTIONS.toggleDrawer());
    }, [location]);
    useEffect(() => {
        var _a, _b, _c;
        if (state.currentDrawerContentState === DwollaCurrentStateTypes.WITHDRAW_FUND_SUCCESS) {
            getDwollaCustomerBalance();
        }
        if (dwollaCustomer &&
            state.isWalletDrawerOpen &&
            ((_a = state === null || state === void 0 ? void 0 : state.dwollaCustomerData) === null || _a === void 0 ? void 0 : _a.status) === DwollaCustomerStatus.Verified &&
            (state === null || state === void 0 ? void 0 : state.currentDrawerContentState) !== DwollaCurrentStateTypes.MANAGE_FUNDING_SOURCE &&
            !((_c = (_b = state === null || state === void 0 ? void 0 : state.dwollaData) === null || _b === void 0 ? void 0 : _b.bankList) === null || _c === void 0 ? void 0 : _c.length)) {
            getBankData();
        }
        // eslint-disable-next-line
    }, [state.currentDrawerContentState, state.isWalletDrawerOpen]);
    useEffect(() => {
        if (isLoggedIn) {
            getDwollaCustomerDetails();
        }
    }, []);
    useEffect(() => {
        if (dwollaCustomerData === null || dwollaCustomerData === void 0 ? void 0 : dwollaCustomerData.getDwollaCustomerByUserId) {
            updateDwollaCustomerData(dwollaCustomerData === null || dwollaCustomerData === void 0 ? void 0 : dwollaCustomerData.getDwollaCustomerByUserId);
        }
        // eslint-disable-next-line
    }, [dwollaCustomerData]);
    useEffect(() => {
        if (notification === null || notification === void 0 ? void 0 : notification.subType) {
            if ((notification === null || notification === void 0 ? void 0 : notification.subType) === NotificationSubtype.fundAddedCompleted ||
                (notification === null || notification === void 0 ? void 0 : notification.subType) === NotificationSubtype.fundWithdrawCompleted) {
                getDwollaCustomerBalance();
            }
            if (walletStatusNotification.indexOf(notification === null || notification === void 0 ? void 0 : notification.subType) != -1) {
                getDwollaCustomerDetails();
            }
        }
        // eslint-disable-next-line
    }, [notification]);
    useEffect(() => {
        if (bankData === null || bankData === void 0 ? void 0 : bankData.getDwollaBanksByDwollaCustomerUrl) {
            dispatch(DWOLLA_ACTIONS.setBankList(bankData.getDwollaBanksByDwollaCustomerUrl));
        }
    }, [bankData]);
    useEffect(() => {
        if (customerBalance === null || customerBalance === void 0 ? void 0 : customerBalance.getDwollaWalletBalance) {
            dispatch(DWOLLA_ACTIONS.setCustomerBalance(customerBalance.getDwollaWalletBalance));
        }
        else {
            dispatch(DWOLLA_ACTIONS.setCustomerBalance({ balance: 0 }));
        }
    }, [customerBalance]);
    const getContentPayloadBasedOnStatus = (status) => {
        switch (status) {
            case DwollaCustomerStatus.Document:
                return DwollaCurrentStateTypes.UPLOAD_DOCUMENT;
            case DwollaCustomerStatus.Retry:
                return DwollaCurrentStateTypes.UPGRADE_CUSTOMER;
            case DwollaCustomerStatus.Suspended:
                return DwollaCurrentStateTypes.SUSPENDED_USER;
            default:
                return DwollaCurrentStateTypes.CREATE_WALLET;
        }
    };
    useEffect(() => {
        if (dwollaCustomer) {
            if (dwollaCustomer.url && dwollaCustomer.status === DwollaCustomerStatus.Verified) {
                dispatch(DWOLLA_ACTIONS.setCustomerBalance({ balance: 0 }));
                dispatch(DWOLLA_ACTIONS.setBankList([]));
                getDwollaCustomerBalance();
            }
            dispatch(DWOLLA_ACTIONS.setCustomerData(dwollaCustomer));
            if (dwollaCustomer.status === DwollaCustomerStatus.Verified) {
                if (state.walletSetupCurrentStep === DwollaSetupWalletSteps.CREATE_WALLET_VERIFICATION_IN_PROGRESS ||
                    state.walletSetupCurrentStep === DwollaSetupWalletSteps.CREATE_WALLET_FORM) {
                    dispatch(DWOLLA_ACTIONS.setSetupWalletStep(DwollaSetupWalletSteps.CREATE_WALLET_SUCCESS));
                }
                if (state.currentDwollaDialogContentState === DwollaCurrentStateTypes.UPGRADE_CUSTOMER ||
                    state.currentDwollaDialogContentState === DwollaCurrentStateTypes.UPLOAD_DOCUMENT) {
                    dispatch(DWOLLA_ACTIONS.setDialogContent(DwollaCurrentStateTypes.CREATE_WALLET));
                    dispatch(DWOLLA_ACTIONS.setSetupWalletStep(DwollaSetupWalletSteps.CREATE_WALLET_SUCCESS));
                }
                dispatch(DWOLLA_ACTIONS.setDrawerContent(DwollaCurrentStateTypes.WALLET_OVERVIEW));
                dispatch(DWOLLA_ACTIONS.setDefaultDrawerContent(DwollaCurrentStateTypes.WALLET_OVERVIEW));
            }
            else {
                dispatch(DWOLLA_ACTIONS.setDrawerContent(DwollaCurrentStateTypes.CREATE_WALLET));
                dispatch(DWOLLA_ACTIONS.setDefaultDrawerContent(DwollaCurrentStateTypes.CREATE_WALLET));
                dispatch(DWOLLA_ACTIONS.setDialogContent(getContentPayloadBasedOnStatus(dwollaCustomer.status)));
            }
        }
        else {
            dispatch(DWOLLA_ACTIONS.setDrawerContent(DwollaCurrentStateTypes.CREATE_WALLET));
            dispatch(DWOLLA_ACTIONS.setDefaultDrawerContent(DwollaCurrentStateTypes.CREATE_WALLET));
            dispatch(DWOLLA_ACTIONS.setCustomerData(null));
        }
    }, [dwollaCustomer === null || dwollaCustomer === void 0 ? void 0 : dwollaCustomer.url, dwollaCustomer === null || dwollaCustomer === void 0 ? void 0 : dwollaCustomer.status]);
    const openWalletSetup = (openWalletDrawer = false, openDialog = false) => {
        // Create a deffered promise object
        const defPromiseObj = defer();
        if (state.dwollaCustomerData) {
            const { dwollaCustomerData: { status } } = state;
            if (openDialog) {
                dispatch(DWOLLA_ACTIONS.toggleDialog(true));
            }
            else {
                dispatch(DWOLLA_ACTIONS.toggleDrawer(!state.isWalletDrawerOpen));
            }
            if (status === DwollaCustomerStatus.Document) {
                dispatch(DWOLLA_ACTIONS.setSetupWalletStep(DwollaCurrentStateTypes.UPLOAD_DOCUMENT));
            }
            else if (status === DwollaCustomerStatus.Retry || status === DwollaCustomerStatus.Unverified) {
                dispatch(DWOLLA_ACTIONS.setSetupWalletStep(DwollaCurrentStateTypes.UPGRADE_CUSTOMER));
            }
            else if (status === DwollaCustomerStatus.Suspended) {
                dispatch(DWOLLA_ACTIONS.setDialogContent(DwollaCurrentStateTypes.SUSPENDED_USER));
            }
        }
        else {
            if (openWalletDrawer) {
                dispatch(DWOLLA_ACTIONS.toggleDrawer(true));
            }
            else {
                dispatch(DWOLLA_ACTIONS.toggleDialog(true));
                dispatch(DWOLLA_ACTIONS.setDialogContent(DwollaCurrentStateTypes.CREATE_WALLET));
                dispatch(DWOLLA_ACTIONS.setSetupWalletStep(DwollaSetupWalletSteps.WELCOME));
            }
        }
        // return a deffered promise to handle response
        return defPromiseObj.promise;
    };
    const closeDialog = (reason) => {
        dispatch(DWOLLA_ACTIONS.toggleDialog(false));
        // Resolve promise with reason once user closes the Dialog
        deferRef === null || deferRef === void 0 ? void 0 : deferRef.resolve({
            modalClosed: true,
            modalType: state.currentDwollaDialogContentState,
            reason
        });
    };
    useEffect(() => {
        if (isLoggedIn && searchParams.get('openwallet')) {
            openWalletSetup(true);
        }
        // eslint-disable-next-line
    }, [isLoggedIn, searchParams.get('openwallet')]);
    const getBankData = () => {
        if (dwollaCustomer === null || dwollaCustomer === void 0 ? void 0 : dwollaCustomer.url) {
            getDwollaBank({
                variables: { ListBanksArguments: { dwollaCustomerUrl: dwollaCustomer.url } },
            });
        }
    };
    return (<DwollaContext.Provider value={{ state, dispatch, openWalletSetup, getBankData, getCustomerBalance: getDwollaCustomerBalance }}>
      {(linkToken && queryObj.oauth_state_id && (dwollaCustomer === null || dwollaCustomer === void 0 ? void 0 : dwollaCustomer.url)) && (<LinkTokenReinitiate url={dwollaCustomer === null || dwollaCustomer === void 0 ? void 0 : dwollaCustomer.url} linkToken={linkToken}/>)}
      {children}
      <DwollaDialog getBankData={getBankData} state={state} dispatch={dispatch} closeDialog={closeDialog}/>
    </DwollaContext.Provider>);
};
