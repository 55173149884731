import { gql, useLazyQuery, useQuery } from '@apollo/client';
export const GET_USER_DETAILS_BY_ID = gql `
  query getUserDetailsById($id: Int!, $userId: Int!) {
    getUserDetailsById(
      id: $id,
    ){
      id
      username
      firstname
      lastname
      userInstitutions {
        College {
          institution {
            name
          }
        }
      }
      preferences {
        id
        name
      }
      marketplaceFullAccess
      dwollaCustomer {
        status
      }
      isPeopleBookmarked
      backgroundVideoData
      backgroundImage
      backgroundImageThumbnail
      profileImage
      email
      isFollowing(userId: $userId)
    }
  }
`;
export const useGetUserDetailsByIdQuery = ({ id }) => {
    return useQuery(GET_USER_DETAILS_BY_ID, {
        variables: { id },
        notifyOnNetworkStatusChange: true,
    });
};
export const useGetUserDetailsByIdLazyQuery = () => {
    const [getUserDetailsById, { loading, data, error }] = useLazyQuery(GET_USER_DETAILS_BY_ID, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
    });
    return { getUserDetailsById, query: GET_USER_DETAILS_BY_ID, loading, data, error };
};
