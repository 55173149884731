import { Box, DialogContent, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'common/components';
import { usePostNftContext } from 'providers';
import { setIsConfirmDialog } from 'providers/PostNft/PostNftActions';
import { useNavigate } from 'react-router-dom';
export const ConfirmUnsaveDialog = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { dispatch, state: { isConfirmUnsaveWork } } = usePostNftContext();
    const handleConfirm = () => {
        handleClose();
        navigate('/');
    };
    const handleClose = () => {
        dispatch(setIsConfirmDialog(false));
    };
    return (<Dialog open={isConfirmUnsaveWork} className="Unsaved-changes dialog-md" maxWidth="md" variant="secondary" title="" leftButtonText={t('No')} rightButtonText={t('Yes')} leftButtonAction={handleClose} onClose={handleClose} rightButtonAction={handleConfirm}>
      <DialogContent className="nx-dialog-content">
        <Box paddingX={6}>
          <Typography variant="h6" sx={{ color: (theme) => theme.colors.white }}>
            {t('Are you sure you want to Cancel NFT creation?')}
          </Typography>
        </Box>
      </DialogContent>
      <Box paddingX={2}>
        <Divider />
      </Box>
    </Dialog>);
};
