import { Box, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'common/components';
import { NxFilter } from 'common/components';
import NxShowFilterButton from 'common/components/Drawer/NxFilter/NxShowFilterButton';
import { FilterDetailsBox } from './FilterSection.styled';
const commonPageId = 'filter-details';
const FilterButtonBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
}));
export const FilterSection = ({ searchString, totalCount, title, hideSearchCount, showSearchCountTitle, loading = false, seeAllLink }) => {
    const s = ((totalCount || 0) > 1 ? 's' : '');
    const { t } = useTranslation();
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const toggleDrawer = () => {
        setOpenFilterDrawer(true);
    };
    const onCloseDrawer = () => {
        setOpenFilterDrawer(false);
    };
    return (<FilterDetailsBox>
      <Box>
        <FilterButtonBox>
          <Box display="flex">
            {title &&
            <Loader loading={loading}>
                <Typography variant="h3" className="dashboard-main-head">
                  {title}{' '}
                  {showSearchCountTitle &&
                    <Typography variant="caption" fontWeight="500" className="filter-main-head">
                      {t(`result${s}`, { num: (totalCount || 0) })}
                    </Typography>}
                </Typography>
              </Loader>}
            {!hideSearchCount &&
            <Loader loading={loading}>
                <Typography variant="h6" fontWeight="500" className="filter-main-head" ml={title ? 1 : 0}>
                  {t(searchString ? `num result${s} for` : `result${s}`, { num: (totalCount || 0) })}{' '}
                  {searchString && (<Typography variant="caption" className="nx-search-value">
                      {searchString}
                    </Typography>)}
                </Typography>
              </Loader>}
          </Box>
          <NxShowFilterButton commonPageId={commonPageId} onClick={toggleDrawer} seeAllLink={seeAllLink}/>
        </FilterButtonBox>
        <Box className="nx-filter-btn-box">
          <NxFilter drawerOpen={openFilterDrawer} onClose={() => onCloseDrawer()}/>
        </Box>
      </Box>
    </FilterDetailsBox>);
};
