var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { Box, Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLoader, useToast } from 'providers';
import { NxCustomImage } from 'common/components';
import { DialogHead, EditDialog, ThumbnailCardContent } from './EditThumbnailDialog.styled';
import { CheckIcon, PlusIcon } from 'ui/icons';
import { revokeAssetBlobUrls } from 'screens/createnft/utils/assetProcessUtils';
import { getDeviceVideoThumbs, getGalleryVideoThumbs } from 'screens/createnft/utils/getVideoThumbs';
import { GalleryListWrapper } from '../../MultiAsset/components/NXGalleryDrawer/NxGalleryDrawer.styled';
const commonPageId = 'edit-video-thumb-dialog';
export const EditVideoThumbDialog = (props) => {
    const { isOpen, assetImgData, onClose, onEditImage } = props;
    const { t } = useTranslation();
    const { openToast } = useToast();
    const { openLoader, closeLoader } = useLoader();
    const [selectedAsset, setSelectedAsset] = useState(-1);
    const [videoThumbs, setVideoThumbs] = useState([]);
    const getVideoThumbs = () => __awaiter(void 0, void 0, void 0, function* () {
        openLoader();
        try {
            if (assetImgData === null || assetImgData === void 0 ? void 0 : assetImgData.playbackId) {
                const videoThumbs = yield getGalleryVideoThumbs(((assetImgData === null || assetImgData === void 0 ? void 0 : assetImgData.playbackId) || ''), 3);
                setVideoThumbs(videoThumbs);
            }
            else {
                const videoThumbs = yield getDeviceVideoThumbs(((assetImgData === null || assetImgData === void 0 ? void 0 : assetImgData.originalUrl) || ''), 3);
                setVideoThumbs(videoThumbs);
            }
        }
        catch (err) {
            openToast(t('Oops! Something went wrong!'), { type: 'error' });
        }
        closeLoader();
    });
    // Revoke other URLs (Not selected)
    const revokeThumbUrls = () => {
        videoThumbs.forEach((thumb, i) => {
            if (i !== selectedAsset)
                revokeAssetBlobUrls(thumb);
        });
    };
    useEffect(() => {
        if (isOpen) {
            getVideoThumbs();
        }
        else if (videoThumbs.length) {
            revokeThumbUrls();
            setVideoThumbs([]);
            setSelectedAsset(-1);
        }
    }, [isOpen]);
    const handleSaveThumbnail = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedAsset > -1) {
            onEditImage(videoThumbs[selectedAsset]);
        }
        else {
            openToast(t('Please select thumbnail!'), { type: 'error' });
        }
    });
    const handleAssetSelect = (index) => () => {
        setSelectedAsset(index);
    };
    return (<EditDialog className={`${commonPageId} dialog-md`} open={!!isOpen} onClose={onClose} maxWidth="md" color="primary" hideCloseButton>
      <DialogContent className="dialog-content">
        <DialogHead variant="h5" id={`${commonPageId}-main-head`} fontWeight="500">
          {t('Choose thumbnail')}
        </DialogHead>
        <ThumbnailCardContent>
          <GalleryListWrapper>
            <Grid container spacing={3}>
              {videoThumbs.map((thumbData, i) => {
            const isActive = (selectedAsset === i);
            return (<Grid item xs key={i}>
                    <Box className={`gallery-img-box ${(isActive ? 'active' : '')}`}>
                      <NxCustomImage src={((thumbData === null || thumbData === void 0 ? void 0 : thumbData.croppedCompImg) || '')} className="gallery-img" alt="create-nft-img" layout="fill" id={`${commonPageId}-content-${i}`} onClick={handleAssetSelect(i)}/>

                      {/* Show checked icon for selected asset */}
                      {isActive && <CheckIcon className="icon-active"/>}

                      {/* Show plus icon on hover of asset */}
                      <PlusIcon className="icon-hover"/>
                    </Box>
                  </Grid>);
        })}
            </Grid>
          </GalleryListWrapper>
          <Typography className="edit-thumb-helper">
            {t('This is how your NFT will appear in listings. The original won\'t change.')}
          </Typography>
        </ThumbnailCardContent>
      </DialogContent>
      <DialogActions>
        <Button fullWidth id={`${commonPageId}-cancel-button`} type="button" variant="outlined" color="secondary" size="medium" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button fullWidth disabled={(selectedAsset < 0)} onClick={handleSaveThumbnail}>
          {t('Save')}
        </Button>
      </DialogActions>
    </EditDialog>);
};
