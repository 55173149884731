var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { generateVideoThumbnailViaUrl, getVideoDurationFromVideoFile } from '@rajesh896/video-thumbnails-generator';
import { getMuxThumbnailUrl } from 'helpers/utils';
import { cropImage } from './getCroppedImg';
// Function to get video thumbs from Device files
export const getDeviceVideoThumbs = (url, thumbLength) => __awaiter(void 0, void 0, void 0, function* () {
    // Return blank ary if URL not found
    if (!url)
        return [];
    const thumbAry = [];
    try {
        // Get video duration to generate random thumbs 
        const duration = yield getVideoDurationFromVideoFile(url);
        // Generate random thumbs and crop the thumbnail with default Crop Data
        for (let i = 1; i <= (thumbLength || 1); i += 1) {
            try {
                const videoThumb = yield generateVideoThumbnailViaUrl(url, Math.random() * duration);
                const res = yield cropImage({ imgSrc: videoThumb });
                if (res)
                    thumbAry.push(res);
            }
            catch (err) {
                console.log('ERROR while processing INDIVIDUAL Device video thumb:', err);
            }
        }
    }
    catch (err) {
        console.log('ERROR while fetching Device Video Thumbs:', err);
    }
    return thumbAry;
});
// Function to get video thumbs from NxGallery files
export const getGalleryVideoThumbs = (playbackId, thumbLength) => __awaiter(void 0, void 0, void 0, function* () {
    // Return blank ary if playbackId not found
    if (!playbackId)
        return [];
    const thumbAry = [];
    try {
        for (let i = 1; i <= (thumbLength || 1); i += 1) {
            try {
                // MUX thumb URL from Video
                const nxVideoThumb = `${getMuxThumbnailUrl(playbackId)}?time=${Math.round(Math.random() * 10)}`;
                // Convert MUX thumb url into Blob
                const thumbBlob = yield fetch(nxVideoThumb).then(r => r.blob());
                const thumbUrl = URL.createObjectURL(thumbBlob);
                // Crop the MUX thumb
                const res = yield cropImage({ imgSrc: thumbUrl });
                if (res)
                    thumbAry.push(res);
            }
            catch (err) {
                console.log('ERROR while processing INDIVIDUAL NXGallery video thumb:', err);
            }
        }
    }
    catch (err) {
        console.log('ERROR while fetching NXGallery Video Thumbs:', err);
    }
    return thumbAry;
});
