var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import AppleLogin from 'react-apple-login';
import { NXLVL_URL, APPLE_SERVICE_KEY } from 'helpers/constants';
import React from 'react';
export const AppleLoginSignUp = (_a) => {
    var { handleAppleClicked } = _a, rest = __rest(_a, ["handleAppleClicked"]);
    return (<AppleLogin {...rest} clientId={(APPLE_SERVICE_KEY || '')} redirectURI={(NXLVL_URL || '')} usePopup={true} callback={handleAppleClicked} scope="name email" responseMode="query"/>);
};
