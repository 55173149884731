var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Compressor from 'compressorjs';
export const imgPreviewCompressed = (image) => {
    return new Promise((resolve, reject) => {
        new Compressor(image, {
            quality: 0.2,
            // Compression for PNG and WEBP files
            // Thumb will be generated in JPEG
            // convertTypes: ['image/png', 'image/webp'],
            // convertSize: 1000000,
            // Prevent default file format conversion by adding below params
            convertTypes: [],
            convertSize: Infinity,
            success: (compressedResult) => __awaiter(void 0, void 0, void 0, function* () {
                const blob = compressedResult;
                if (!blob) {
                    return null;
                }
                const previewUrl = URL.createObjectURL(blob);
                resolve({ previewUrl, blob });
            }),
            error: (error) => {
                reject(error);
            }
        });
    });
};
