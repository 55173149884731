import React, { useRef } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { NftCardMedia } from 'common/components';
import { NFTImageBox } from 'screens/createnft/components/MultiAsset/MultiAsset.styled';
import { PenFillIcon, PlayShadowIcon, StarFillIcon } from 'ui/icons';
import { NftType } from 'types';
import { useTranslation } from 'react-i18next';
import { PreviewEditBtn, PreviewListBox } from './NftAssetPreview.styled';
import { NftPreviewMode } from './NftAssetPreview.types';
export const NftAssetPreviewList = (props) => {
    var _a;
    const { previewMode, assetList, isEditable, isHideBG, selectedAssetId, onThumbClick, onEditClick } = props;
    const { t } = useTranslation();
    const imgBoxRef = useRef(null);
    const isLargeScreen = useMediaQuery(useTheme().breakpoints.up('xl'));
    const isDndEnabled = (previewMode === NftPreviewMode.CreateNft && isHideBG);
    const imgBoxHeight = (((_a = imgBoxRef === null || imgBoxRef === void 0 ? void 0 : imgBoxRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) || 0);
    const assetLength = ((assetList === null || assetList === void 0 ? void 0 : assetList.length) || 0);
    const handleThumbClick = (asset) => () => {
        onThumbClick(asset);
    };
    return (<PreviewListBox style={{ height: isDndEnabled ? (Math.round(imgBoxHeight) + 15) * assetLength : 'auto' }} isPreviewScreen={isHideBG} className={(isHideBG ? 'preview-img-wrap' : '')}>
			{isEditable && (<PreviewEditBtn color="primary" size="xsmall" fullWidth startIcon={<PenFillIcon />} onClick={onEditClick}>
					{t('Edit')}
				</PreviewEditBtn>)}
			<Box className="preview-img-wrap-list">
				{assetList === null || assetList === void 0 ? void 0 : assetList.map((assetObj, index) => {
            const asset = parseAssetValues(assetObj, previewMode);
            const id = ((asset === null || asset === void 0 ? void 0 : asset.id) || index);
            const isActive = (id === selectedAssetId);
            return (<NFTImageBox key={id} imageWidth={isLargeScreen ? '4.125' : '5.625'} activeCursorPointer className={`${(isActive ? 'active' : '')} ${(isDndEnabled ? 'preview-img' : '')}`} top={isDndEnabled ? (index * (Math.round(imgBoxHeight) + 15)) : 0} zIndex={(assetLength - index)} ref={(index === 0 ? imgBoxRef : undefined)} onClick={handleThumbClick(Object.assign(Object.assign({ id }, asset), { isDefault: !!asset.isDefault }))}>
							<NftCardMedia id={id === null || id === void 0 ? void 0 : id.toString()} image={asset === null || asset === void 0 ? void 0 : asset.thumbImg} size="xsmall" multiple/>

							{(asset === null || asset === void 0 ? void 0 : asset.isDefault) && (<StarFillIcon className="star-icon"/>)}

							{((asset === null || asset === void 0 ? void 0 : asset.type) === NftType.Video) && (<PlayShadowIcon className="video-play-icon"/>)}
						</NFTImageBox>);
        })}
			</Box>
		</PreviewListBox>);
};
export const parseAssetValues = (obj, type) => {
    if (type === NftPreviewMode.CreateNft) {
        const assetObj = obj;
        console.log('assetObj ===', assetObj);
        return {
            id: assetObj === null || assetObj === void 0 ? void 0 : assetObj.id,
            thumbImg: assetObj === null || assetObj === void 0 ? void 0 : assetObj.croppedCompImg,
            isDefault: !!(assetObj === null || assetObj === void 0 ? void 0 : assetObj.isDefault),
            originalUrl: assetObj === null || assetObj === void 0 ? void 0 : assetObj.originalUrl,
            videoData: assetObj === null || assetObj === void 0 ? void 0 : assetObj.nftDataInput,
            type: assetObj === null || assetObj === void 0 ? void 0 : assetObj.type
        };
    }
    if (type === NftPreviewMode.PDP) {
        const assetObj = obj;
        return {
            id: assetObj === null || assetObj === void 0 ? void 0 : assetObj.id,
            thumbImg: (assetObj === null || assetObj === void 0 ? void 0 : assetObj.imageUrl) || '',
            isDefault: !!(assetObj === null || assetObj === void 0 ? void 0 : assetObj.isDefault),
            originalUrl: assetObj === null || assetObj === void 0 ? void 0 : assetObj.imageUrl,
            videoData: (assetObj === null || assetObj === void 0 ? void 0 : assetObj.videoData) || '',
            type: (assetObj === null || assetObj === void 0 ? void 0 : assetObj.imageUrl) ? NftType.Image : NftType.Video
        }; // Parse data for the PDP asset list
    }
    return {};
};
