import { gql, useMutation } from '@apollo/client';
export const MARK_FIREBASE_NOTIFICATION_ALL_READ = gql `
  mutation markAllFirebaseNotificationAsRead {
    markAllFirebaseNotificationAsRead
  }
`;
export const useMarkAllFirebaseNotificationAsRead = () => {
    const [markAllReadFirebaseNotification] = useMutation(MARK_FIREBASE_NOTIFICATION_ALL_READ, {
        notifyOnNetworkStatusChange: true,
    });
    const markAllReadFirebaseNotificationMutation = ({ variables, query }) => markAllReadFirebaseNotification({
        variables: variables,
        update: (cache) => {
            var _a, _b;
            const data = cache.readQuery({ query, variables: variables });
            const newData = JSON.parse(JSON.stringify(data));
            (_b = (_a = newData === null || newData === void 0 ? void 0 : newData.getFirebaseNotifications) === null || _a === void 0 ? void 0 : _a.pageEdges) === null || _b === void 0 ? void 0 : _b.forEach((notification) => {
                if (notification === null || notification === void 0 ? void 0 : notification.node)
                    notification.node.isRead = true;
            });
            cache.writeQuery({ query, variables: variables, data: newData });
        }
    });
    return { markAllReadFirebaseNotificationMutation };
};
