import { DateTimeFormat, formatDate } from 'helpers/utils/formatDate';
export var MixpanelEvents;
(function (MixpanelEvents) {
    MixpanelEvents["Signup"] = "Signup";
    MixpanelEvents["Signup_Email_Verified"] = "Signup Email Verified";
    MixpanelEvents["Login"] = "Login";
    MixpanelEvents["Login_Forgot_Password"] = "Login Forgot Password";
    MixpanelEvents["Create_NFT"] = "Create NFT";
    MixpanelEvents["Share_NFT_Social_Media"] = "Share NFT on Social Media";
})(MixpanelEvents || (MixpanelEvents = {}));
export const MIXPANEL_TOKEN = (process.env.REACT_APP_MIXPANEL_TOKEN || '');
// Register common properties: https://developer.mixpanel.com/docs/javascript#super-properties
export const mpRegisterProperties = (properties) => {
    var _a;
    try {
        if (window === null || window === void 0 ? void 0 : window.mixpanel)
            (_a = window === null || window === void 0 ? void 0 : window.mixpanel) === null || _a === void 0 ? void 0 : _a.register(properties);
    }
    catch (e) {
        console.log('Mixpanel - Register props error:');
    }
};
// Set unique identity: https://developer.mixpanel.com/docs/javascript#identify
export const mpSetIdentity = (id) => {
    var _a;
    try {
        if (window === null || window === void 0 ? void 0 : window.mixpanel)
            (_a = window === null || window === void 0 ? void 0 : window.mixpanel) === null || _a === void 0 ? void 0 : _a.identify(id);
    }
    catch (e) {
        console.log('Mixpanel - Register props error:');
    }
};
// Reset super properties and identity_id: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelreset
export const mpReset = () => {
    var _a;
    try {
        if (window === null || window === void 0 ? void 0 : window.mixpanel)
            (_a = window === null || window === void 0 ? void 0 : window.mixpanel) === null || _a === void 0 ? void 0 : _a.reset();
    }
    catch (e) {
        console.log('Mixpanel - Reset error:');
    }
};
// Send events with name and properties: https://developer.mixpanel.com/docs/javascript#sending-events
export const mpTrack = (eventName, properties) => {
    var _a;
    try {
        if (window === null || window === void 0 ? void 0 : window.mixpanel)
            (_a = window === null || window === void 0 ? void 0 : window.mixpanel) === null || _a === void 0 ? void 0 : _a.track(eventName, properties);
    }
    catch (e) {
        console.log('Mixpanel - Track event error:');
    }
};
export const mpTrackEvent = (eventName, properties) => {
    var _a, _b;
    switch (eventName) {
        case MixpanelEvents.Login: {
            const loginDate = formatDate(new Date(), { format: DateTimeFormat.MIXPANEL });
            const signupDate = ((properties === null || properties === void 0 ? void 0 : properties.createdAt) ?
                formatDate(new Date(properties === null || properties === void 0 ? void 0 : properties.createdAt), { format: DateTimeFormat.MIXPANEL }) : '');
            // Set common properties that will be sent in all Events
            mpRegisterProperties({
                'ID': properties === null || properties === void 0 ? void 0 : properties.id,
                'First Name': ((properties === null || properties === void 0 ? void 0 : properties.firstname) || (properties === null || properties === void 0 ? void 0 : properties.username)),
                'Last Name': ((properties === null || properties === void 0 ? void 0 : properties.lastname) || (properties === null || properties === void 0 ? void 0 : properties.username)),
                'User Name': (properties === null || properties === void 0 ? void 0 : properties.username),
                'Login Type': ((properties === null || properties === void 0 ? void 0 : properties.provider) || 'Email'),
                'Last Login Date': loginDate,
                'Signup Date': signupDate,
                'List of Interests': ((properties === null || properties === void 0 ? void 0 : properties.preferences) ?
                    (_b = (_a = properties === null || properties === void 0 ? void 0 : properties.preferences) === null || _a === void 0 ? void 0 : _a.map(({ name }) => name)) === null || _b === void 0 ? void 0 : _b.toString() : ''),
            });
            // Set User Identity
            if (properties === null || properties === void 0 ? void 0 : properties.id)
                mpSetIdentity((properties === null || properties === void 0 ? void 0 : properties.id) || '');
            // Track Login Event
            mpTrack(MixpanelEvents.Login, { Date: loginDate });
            break;
        }
        default: {
            mpTrack(eventName, properties);
        }
    }
};
