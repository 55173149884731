import { colors } from 'ui/foundations';
import { themeDefault } from '../foundations';
const MuiTooltip = {
    styleOverrides: {
        popper: {
            '.MuiTooltip-tooltip': {
                backgroundColor: colors.neutral700,
                padding: `${themeDefault.spacing(1.375)}`,
                borderRadius: '0.313rem',
            }
        },
    },
    defaultProps: {}
};
export default MuiTooltip;
