import { colors } from 'ui/foundations';
const MuiAlert = {
    styleOverrides: {
        root: {
            '&.MuiAlert-root': {
                color: colors.white,
                alignItems: 'center',
            },
            '.MuiAlertTitle-root': {
                fontSize: 16,
            },
            '.MuiAlert-message': {
                fontSize: 14,
                textAlign: 'left',
            },
            '.MuiSvgIcon-root': {
                color: colors.white,
            },
            '.MuiButton-root': {
                display: 'flex',
            }
        }
    },
    variants: [
        {
            props: {
                severity: 'success'
            },
            style: {
                backgroundColor: colors.success500,
            }
        },
        {
            props: {
                severity: 'error'
            },
            style: {
                backgroundColor: colors.error500,
            }
        },
        {
            props: {
                severity: 'warning'
            },
            style: {
                backgroundColor: colors.warning500,
            }
        },
        {
            props: {
                severity: 'info'
            },
            style: {
                backgroundColor: colors.info500,
            }
        },
    ],
};
export default MuiAlert;
