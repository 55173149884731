var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { GET_CATEGORIES } from 'graphql-client/sidebar.graphql';
import { useLazyQuery } from '@apollo/client';
/**
 * Function to find category object for selected category id
 * it will also find parent category if the selected category is sub-category (activity)
 */
const findCategoryById = (catList, catId) => {
    var _a, _b;
    let parentCategory = null;
    let selectedCategory = null;
    const len = (catList === null || catList === void 0 ? void 0 : catList.length) || 0;
    for (let i = 0; i < len; i += 1) {
        // TODO: Getting common id in parent and child activities, will change in future
        // If catId is a parent category then parent will be selected category
        // if (catList[i]?.id === Number(catId)) {
        //   selectedCategory = catList[i];
        //   break;
        // }
        // If catId is not parent category then search in activities
        const activities = ((_a = catList[i]) === null || _a === void 0 ? void 0 : _a.activities) || [];
        if ((activities === null || activities === void 0 ? void 0 : activities.length) > 0) {
            for (let ci = 0; ci < (activities === null || activities === void 0 ? void 0 : activities.length); ci += 1) {
                if (((_b = activities[ci]) === null || _b === void 0 ? void 0 : _b.id) === Number(catId)) {
                    selectedCategory = activities[ci];
                    parentCategory = catList[i];
                    break;
                }
            }
        }
    }
    return { parentCategory, selectedCategory };
};
const dashFilters = ['catId'];
// Remove falsy values from object
const getPureFilter = (obj) => {
    Object.keys(obj).forEach(key => {
        if (!obj[key]) {
            delete obj[key];
        }
    });
    return obj;
};
const pickBy = (obj, filterBy) => {
    const pickedObj = {};
    filterBy.forEach((key) => {
        if (key && obj[key]) {
            pickedObj[key] = obj[key];
        }
    });
    return pickedObj;
};
// Common hook for Dashboard category filter
export const useCategoryFilter = (type) => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();
    const [selectedCat, setSelectedCat] = useState({});
    const [getCategories] = useLazyQuery(GET_CATEGORIES, { nextFetchPolicy: 'cache-first' });
    const filter = pickBy(Object.fromEntries([...queryParams]), dashFilters);
    const { parentCategory, selectedCategory } = selectedCat;
    const getCats = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const res = yield getCategories();
        if ((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.getCategory) {
            const categoryDetails = findCategoryById((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.getCategory, filter === null || filter === void 0 ? void 0 : filter.catId);
            setSelectedCat(categoryDetails);
        }
    });
    useEffect(() => {
        if (type === 'use-category') {
            if (filter === null || filter === void 0 ? void 0 : filter.catId) {
                getCats();
            }
            else if (parentCategory || selectedCategory) {
                setSelectedCat({ parentCategory: null, selectedCategory: null });
            }
        }
        // eslint-disable-next-line
    }, [filter === null || filter === void 0 ? void 0 : filter.catId, type]);
    const handleFilterChange = (fType = '', fValue) => {
        if (fType) {
            const flts = getPureFilter(Object.assign(Object.assign({}, filter), { [fType]: fValue }));
            navigate({
                pathname: '/',
                search: `?${createSearchParams(flts)}`,
            });
        }
    };
    return { filter, handleFilterChange, parentCategory, selectedCategory };
};
