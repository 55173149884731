import { Loader } from 'common/components';
import { Box, Typography } from '@mui/material';
import { useDashboardNftQuery } from 'graphql-client/dashboard';
import { Link } from 'react-router-dom';
import { useToast } from '@nxlvl/web-providers';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NxMainBody, NxMainHead } from '../../DashboardScreen.styled';
import { SectionType } from 'types';
import { NxlvlSponsor } from '../NxlvlSponsor/NxlvlSponsor';
import Center3dSlider from './Center3dSlider/Center3dSlider';
import Center3dSliderLoading from './Center3dSlider/Center3dSliderLoading';
import { TrendingBox } from './Trending.styled';
import { PAGE_ROUTES } from 'helpers/constants';
const commonPageId = 'dashboard-trending';
export const Trending = ({ catId }) => {
    const { t } = useTranslation();
    const { openToast } = useToast();
    const queryParams = {
        cursor: 0,
        pageNo: 0,
        take: 20,
        activity: catId ? [Number(catId)] : [],
        type: SectionType.Trending
    };
    console.log('[process.env.TEST_ENV]', process.env.TEST_ENV);
    const { loading, error, data: { getDashboardNFTs = {} } = {}, } = useDashboardNftQuery(queryParams);
    const trendingNfts = ((getDashboardNFTs === null || getDashboardNFTs === void 0 ? void 0 : getDashboardNFTs.pageEdges) || []);
    useEffect(() => {
        error && openToast && openToast(error === null || error === void 0 ? void 0 : error.message, { type: 'error' });
    }, [error]);
    if (!loading && trendingNfts.length === 0) {
        return null;
    }
    return (<TrendingBox>
      <NxMainHead display="flex" alignItems="center" justifyContent="space-between">
        <Loader loading={loading}>
          <Typography variant="h5" className="dashboard-main-head">
            {t('Trending')}
          </Typography>
          <button onClick={() => openToast('hello', {
            type: 'success'
        })}>Open toast</button>
        </Loader>
        <Loader loading={loading}>
          <Link to={catId ? `${PAGE_ROUTES.DASHBOARD_TRENDING}?activityIds=${catId}` : PAGE_ROUTES.DASHBOARD_TRENDING}>
            <a id={`${commonPageId}-see-all`} className="dashboard-see-all">
              {t('See all')}
            </a>
          </Link>
        </Loader>
      </NxMainHead>
      <NxMainBody>
        <Box className="nx-main-slider">
          {loading ?
            <Center3dSliderLoading id={commonPageId}/>
            :
                <Center3dSlider id={commonPageId} nfts={trendingNfts} queryParams={{ dashboardArgs: queryParams }}/>}
        </Box>
        <NxlvlSponsor />
      </NxMainBody>
    </TrendingBox>);
};
