import { default as MuiIconButton } from '@mui/material/IconButton';
import { Alert, Box, Snackbar, styled } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import { ExclamationCircleOutlineIcon, InfoOutlineIcon, SuccessCircleOutlineIcon, XOutlineIcon, WarningIcon, } from 'ui/icons';
const ToastContext = createContext({});
export const useToast = () => useContext(ToastContext);
const SnackbarToast = styled(Snackbar)(() => ({
    minWidth: '60%',
    '&.MuiSnackbar-root': {
        backgroundColor: 'transparent',
        padding: 0,
    },
    '.MuiAlert-message': {
        fontSize: '.875rem',
    },
    '.extra-action-box': {
        display: 'flex',
        alignItems: 'center',
    },
}));
const icons = {
    success: <SuccessCircleOutlineIcon />,
    info: <InfoOutlineIcon />,
    warning: <WarningIcon />,
    error: <ExclamationCircleOutlineIcon />,
};
export const ToastProvider = ({ children }) => {
    const [isActive, setIsActive] = useState(false);
    const [message, setMessage] = useState('');
    const [options, setOptions] = useState({ type: 'error', disableAutoClose: false });
    const openToast = (message = 'Something went wrong...', options) => {
        setMessage(message);
        if (options) {
            setOptions(options);
        }
        else {
            setOptions({ type: 'error', disableAutoClose: false });
        }
        setIsActive(true);
    };
    const closeToast = () => {
        setIsActive(false);
        setMessage('');
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        closeToast();
    };
    const toastType = (options === null || options === void 0 ? void 0 : options.type) || 'error';
    return (<ToastContext.Provider value={{
            openToast,
            closeToast,
        }}>
      {children}

      <SnackbarToast open={isActive} autoHideDuration={(options === null || options === void 0 ? void 0 : options.disableAutoClose) ? null : 5000} onClose={handleClose} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}>
        <Alert action={<Box className='extra-action-box'>
              {(options === null || options === void 0 ? void 0 : options.extraActions) || null}
              <MuiIconButton id='toast-close-button' size='small' aria-label='close' color='inherit' onClick={() => {
                closeToast();
            }}>
                <XOutlineIcon fontSize='small'/>
              </MuiIconButton>
            </Box>} severity={toastType} sx={{ width: '100%' }} icon={icons[toastType]}>
          {message}
        </Alert>
      </SnackbarToast>
    </ToastContext.Provider>);
};
