import { Box, Link, Stack, styled, Tabs } from '@mui/material';
export const NXGalleryDrawerBox = styled(Box)(({ theme }) => ({
    '.selected-title-wrap': {
        backgroundColor: theme.colors.black,
        padding: theme.spacing(0.5, 2),
        margin: theme.spacing(0, -2),
        display: 'flex',
        justifyContent: 'space-between',
        '.selected-title': {
            fontSize: '0.75rem',
            fontWeight: '500',
            lineHeight: '1.5',
            color: theme.colors.neutral300,
        },
        '.select-title-right': {
            color: theme.colors.white,
        }
    },
}));
export const NXGalleryDrawerHeaderBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.black,
    padding: theme.spacing(0.5, 2),
    margin: theme.spacing(0, -2),
    display: 'flex',
    justifyContent: 'space-between',
    '.selected-title': {
        fontSize: '0.75rem',
        fontWeight: '500',
        lineHeight: '1.5',
        color: theme.colors.neutral300,
    },
    '.select-title-right': {
        color: theme.colors.white,
    }
}));
export const FilterButtonStack = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    margin: `${theme.spacing(2.5)} 0`,
    '.filter-btn': {
        marginRight: theme.spacing(1),
    },
}));
export const GalleryListWrapper = styled(Box)(({ theme }) => ({
    'paddingTop': '0',
    '.gallery-img-box': {
        position: 'relative',
        borderRadius: '0.5rem',
        height: '12.333rem',
        border: '2px solid transparent',
        // below height is given based on 9:12 ratio of all breakpoints
        [theme.breakpoints.down('xl')]: {
            height: '12.333rem',
        },
        [theme.breakpoints.down('lg')]: {
            height: '12.333rem',
        },
        [theme.breakpoints.down('md')]: {
            height: '12.333rem',
        },
        [theme.breakpoints.down('sm')]: {
            height: '12.333rem',
        },
        '.icon-active': {
            position: 'absolute',
            top: '0.5rem',
            fontSize: '1rem',
            right: '0.5rem',
            zIndex: '1',
            backgroundColor: theme.colors.blue,
            borderRadius: '4px',
            width: '1.125rem',
            height: '1.125rem',
            display: 'none',
            cursor: 'pointer',
            strokeWidth: '0.125rem',
            stroke: theme.colors.white
        },
        '.icon-hover': {
            position: 'absolute',
            top: '0.5rem',
            fontSize: '1rem',
            right: '0.5rem',
            zIndex: '1',
            backgroundColor: theme.colors.white,
            borderRadius: '0.25rem',
            width: '1.125rem',
            height: '1.125rem',
            display: 'none',
            cursor: 'pointer',
            strokeWidth: '0.125rem',
            stroke: theme.colors.neutral500
        },
        '&:hover': {
            borderColor: theme.colors.blue,
            '.icon-hover': {
                display: 'block',
            }
        },
        '&.active': {
            borderColor: theme.colors.blue,
            '.icon-active': {
                display: 'block',
            },
            '&:hover': {
                '.icon-hover': {
                    display: 'none',
                }
            },
        },
        '&.disable': {
            borderColor: 'transparent',
            filter: 'grayscale(100%)',
            '&:hover': {
                '.icon-hover': {
                    display: 'none',
                }
            },
            '.gallery-img': {
                cursor: 'default',
            },
            '.icon-active': {
                display: 'none',
            },
        },
    },
    '.gallery-img': {
        width: '100% !important',
        height: '100% !important',
        objectFit: 'cover',
        borderRadius: '0.5rem',
        cursor: 'pointer'
    },
    '.create-text': {
        fontSize: '.75rem',
        marginTop: theme.spacing(1),
        minHeight: '2rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
    }
}));
export const EmptyStateWrapper = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    maxWidth: '28.125rem',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 0)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    '.upload-desktop-box': {
        cursor: 'pointer'
    },
    '.upload-icon-box': {
        margin: `${theme.spacing(5)} 0 ${theme.spacing(5)}`,
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing(2)} 0 ${theme.spacing(4)}`,
        },
    },
    '.upload-img-icon': {
        fontSize: '3rem',
        marginBottom: '0.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem',
        },
        'path': {
            stroke: theme.dynamicTheme.mainSection.text1.color,
        },
    },
    '.empty-text': {
        color: theme.dynamicTheme.mainSection.text1.color,
        fontSize: '0.875rem',
        'span': {
            color: theme.dynamicTheme.mainSection.text.color,
            textTransform: 'uppercase',
        },
        '&.text-white': {
            color: theme.colors.white
        }
    }
}));
export const DesktopEmptyStateWrapper = styled(Box)(({ theme }) => ({
    borderRadius: '1rem',
    padding: '1rem',
    backgroundColor: theme.colors.neutral925,
    marginBottom: '2rem',
    '.qr-code-icon': {
        margin: `${theme.spacing(3)} 0 ${theme.spacing(4)}`,
    },
    '.refresh-btn': {
        marginTop: theme.spacing(1)
    },
    '.qr-code-box': {
        boxShadow: 'none',
        paddingLeft: '0',
        paddingRight: '0',
        background: 'transparent',
    }
}));
export const MobileEmptyStateWrapper = styled(Box)(({ theme }) => ({
    '.add-photos-box': {
        padding: '1.5rem 1rem',
        backgroundColor: theme.colors.neutral925,
        boxShadow: 'none',
        '.logo-icon': {
            width: '.875rem',
            transform: 'translateY(0.5rem)',
            margin: '0 0.1rem',
            'path': {
                fill: theme.colors.blue,
            },
        }
    },
    '.empty-link': {
        '&:hover': {
            '.logo-icon': {
                'path': {
                    fill: theme.colors.white,
                },
            }
        }
    },
    '.app-download-box': {
        padding: '1.5rem 1rem',
        backgroundColor: theme.colors.neutral925,
        boxShadow: 'none',
        border: `.25rem solid ${theme.colors.neutral925}`
    },
    '.refresh-btn': {
        marginTop: theme.spacing(1)
    },
    '.upload-photos-box': {
        padding: '1.5rem 1rem',
        backgroundColor: theme.colors.neutral925,
        boxShadow: 'none',
    }
}));
export const QRCodeBox = styled(Box)(({ theme }) => ({
    margin: `0 auto ${theme.spacing(2)}`,
    padding: `${theme.spacing(1)}`,
    width: '144px',
    backgroundColor: theme.colors.white,
    display: 'flex',
    alignTtems: 'center',
    justifyContent: 'center',
}));
export const RefreshLink = styled(Link)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.refresh-icon': {
        marginRight: '0.5rem',
    }
});
export const MediaTabsWrap = styled(Box)({
    'position': 'relative',
    '.media-refresh': {
        'position': 'absolute',
        'right': '0rem',
        'top': '0.8rem',
        'marginRight': '0 !important',
    }
});
export const MediaTabs = styled(Tabs)({
    'position': 'relative',
    '.step-2-tab': {
        'border': '0 !important',
    },
    '.media-refresh': {
        'position': 'absolute',
        'right': '0rem',
        'top': '0.8rem'
    }
});
export const MediaSelectWrap = styled(Box)({
    display: 'flex',
    flexFlow: 'column',
    height: 'calc(100vh - 6.8rem)',
    '.tabContent': {
        flexGrow: '1',
    },
    '.tabFooter': {
        paddingTop: '1rem'
    }
});
