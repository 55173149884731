import { gql, useLazyQuery } from '@apollo/client';
export const SEARCH_VERIFIED_USERS = gql `
  query GetDwollaVerifiedUser($searchString: String) {
    getDwollaVerifiedUser(searchString: $searchString) {
      user {
        id
        username
        firstname
        lastname
        profileImage
      }
    }
  }
`;
export const useSearchVerfiedUsers = () => {
    const [searchVerifiedUsers, { loading, data }] = useLazyQuery(SEARCH_VERIFIED_USERS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
    });
    return { searchVerifiedUsers, loading, data };
};
