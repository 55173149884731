import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { useCategoryFilter } from 'helpers/hooks';
import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AllNftsIcon, ChevronDownDoubleOutlineIcon, ChevronUpDoubleOutlineIcon, WalletIcon, PeopleIcon } from 'ui/icons';
import { SideBarWrap } from '../../SideBar.styled';
import { CustomSvgIcon } from '../CustomSvgIcon';
import { MyNFTMenuList } from '../MyNFTMenuList/MyNFTMenuList';
import PeopleBookmarkCard from '../PeopleBookmarkCard';
import TransitionHistoryMenuCard from '../TransitionHistoryMenuCard';
import { MenuListResponsiveBox, SelectedMenuBox } from './ResponsiveSideBarMenuCard.styled';
const SideBarMenuCard = React.lazy(() => import('../SideBarMenuCard/SideBarMenuCard'));
export const ResponsiveSideBarMenuCard = ({ isMyNft, isBookmarkPeople, isTransactions, isSidebarMenu }) => {
    const [open, setOpen] = useState(false);
    const { category = '' } = useParams();
    const handleClick = () => {
        setOpen((open) => !open);
    };
    const { selectedCategory } = useCategoryFilter('use-category');
    return (<MenuListResponsiveBox className={`${isMyNft ? 'my-nft-menu-list' : ''}`}>
			<SelectedMenuBox onClick={handleClick}>
				{isMyNft &&
            <Typography className="text-uppercase">{(category || 'listed')}</Typography>}
				{isSidebarMenu &&
            <Box display="flex" alignItems="center">
						{selectedCategory ?
                    <>
								<CustomSvgIcon className="svg-stroke-color" src={(selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.iconURL) || ''}/>
								<Typography variant="h4" className="text-uppercase" fontWeight="500" sx={{ ml: 1 }}>{selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.name}</Typography>
							</>
                    :
                        <>
								<AllNftsIcon className="stroke-color"/>
								<Typography variant="h4" className="text-uppercase" fontWeight="500" sx={{ ml: 1 }}>{('All categories')}</Typography>
							</>}
					</Box>}
				{isBookmarkPeople &&
            <Box display="flex" alignItems="center">
						{selectedCategory ?
                    <>
								<CustomSvgIcon className="svg-stroke-color" src={(selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.iconURL) || ''}/>
								<Typography variant="h4" className="text-uppercase" fontWeight="500" sx={{ ml: 1 }}>{selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.name}</Typography>
							</>
                    :
                        <>
								<PeopleIcon className="stroke-color"/>
								<Typography variant="h4" className="text-uppercase" fontWeight="500" sx={{ ml: 1 }}>{('People')}</Typography>
							</>}
					</Box>}
				{isTransactions &&
            <Box display="flex" alignItems="center">
						{selectedCategory ?
                    <>
								<CustomSvgIcon className="svg-stroke-color" src={(selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.iconURL) || ''}/>
								<Typography variant="h4" className="text-uppercase" fontWeight="500" sx={{ ml: 1 }}>{selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.name}</Typography>
							</>
                    :
                        <>
								<WalletIcon className="stroke-color"/>
								<Typography variant="h4" className="text-uppercase" fontWeight="500" sx={{ ml: 1 }}>{('Wallet')}</Typography>
							</>}
					</Box>}
				<IconButton>
					{(open) ? (<ChevronUpDoubleOutlineIcon className="stroke-color"/>) : (<ChevronDownDoubleOutlineIcon className="stroke-color"/>)}
				</IconButton>
			</SelectedMenuBox>
			<Collapse in={open} timeout="auto">
				<Box>
					{isMyNft && (<MyNFTMenuList responsiveOnClick={handleClick}/>)}
					{isSidebarMenu && (<SideBarWrap>
							<SideBarMenuCard fullHeight={true} onCategoryFilterChange={handleClick}/>
						</SideBarWrap>)}
					{isBookmarkPeople && (<PeopleBookmarkCard />)}
					{isTransactions && (<TransitionHistoryMenuCard />)}
				</Box>

			</Collapse>
		</MenuListResponsiveBox>);
};
