export const NOTIFICATION_TYPE_CONSTANTS = {
    NFT_TRANFSER_FAILED: 'nftTransferFailed',
    NFT_PURCHASED: 'nftPurchased',
    PAYMENT_DEBITED: 'paymentDebited',
    NFT_SOLD: 'nftSold',
    PAYMENT_CREDITED: 'paymentCredited',
    NFT_MINTED: 'nftMinted',
    NFT_LISTED: 'nftListed',
    NFT_LIST_FAILED: 'nftListFailed',
    DWOLLA_PAYMENT_FAILED: 'dwollaPaymentFailed',
    WALLET_VERIFIED: 'walletVerified',
    WALLET_SUSPENDED: 'walletSuspended',
    WALLET_DEACTIVATED: 'walletDeactivated',
    WALLET_REACTIVATED: 'walletReActivated',
    WALLET_MORE_DETAILED_REQUIRED: 'walletMoreDetailsRequired',
    WALLET_DOCUMENT_FAILED: 'walletDocumentFailed',
    BANK_ADDED: 'bankAdded',
    BANK_VERIFIED: 'bankVerified',
    BANK_UNDEVRIFIED: 'bankUnverified',
    FUND_ADDED_COMPLETED: 'fundAddedCompleted',
    FUND_WITHDRAW_COMPLETED: 'fundWithdrawCompleted',
    FUND_ADDED_FAILED: 'fundAddedFailed',
    FUND_WITHDRAW_FAILED: 'fundWithdrawFailed',
    ROYALTY_RECEIVED: 'royaltyReceived'
};
export const NOTIFICATIONS_SUBTYPE = [
    'walletVerified',
    'walletSuspended',
    'fundAddedCompleted',
    'fundAddedFailed',
    'fundWithdrawCompleted',
    'fundWithdrawFailed',
    'walletDocumentFailed',
    'walletReActivated',
    'walletMoreDetailsRequired',
    'nftSold',
    'nftMinted',
    'nftListed',
    'nftListFailed',
    'nftPurchased',
    'nftTransferFailed'
];
export const DWOLLA_NOTIFICATIONS = {
    InValidWebhook: 'We can not consume job with invalid data.',
    WalletActivatedTitle: 'Wallet Activated',
    WalletActivateMessage: 'Congrats! Your nXlvl wallet is activated. You can now buy, list, and sell in the nXmarketplace.',
    WalletSuspendTitle: 'Wallet Suspended',
    WalletSuspendMessage: 'Oops! Your wallet activation is suspended, please contact us.',
    WalletDeactivatedTitle: 'Wallet Deactivated',
    WalletDeactivatedMessage: 'Oops! Your wallet activation is deactivated, please contact us.',
    WalletReActivatedTitle: 'Wallet Re-activated',
    WalletReActivatedMessage: 'Your wallet re-activated.',
    WalletReVerificationTitle: 'Wallet need more info',
    WalletReVerificationMessage: 'Oops! We need more info to verify your account and activate your wallet.',
    WalletDocumentNeededTitle: 'Wallet need more info',
    WalletDocumentNeededMessage: 'Oops! We need more info to verify your account and activate your wallet.',
    WalletDocumentRejectedTitle: 'Wallet document rejected',
    WalletDocumentRejectedMessage: 'Document rejected to activate wallet. Please upload valid identity document.',
    BankAddedTitle: 'Bank account added to wallet',
    BankAddedMessage: 'New bank account added to your wallet.',
    BankVerifiedTitle: 'Bank account verified',
    BankVerifiedMessage: 'Added bank account verified successfully by micro-deposits method.',
    BankUnVerifiedTitle: 'Bank account not verified',
    BankUnVerifiedMessage: 'Unfortunately, Added bank account does not verified by micro-deposits method.',
    FundAddedTitle: 'Funds Added Success',
    FundAddedMessage1: 'Success! You added ',
    FundAddedMessage2: ' to your wallet.',
    FundWithdrawTitle: 'Funds Withdraw Success',
    FundWithdrawMessage1: 'Success! You have transferred ',
    FundWithdrawMessage2: ' from your wallet to your bank account.',
    FundAddedFailedTitle: 'Funds Added Failed',
    FundAddedFailedMessage1: 'Uh-oh! Your deposit of ',
    FundAddedFailedMessage2: ' has failed, try again.',
    FundWithdrawFailedTitle: 'Funds Withdraw Failed',
    FundWithdrawFailedMessage1: 'Uh-oh! Your withdraw request of ',
    FundWithdrawFailedMessage2: ' has failed, try again.',
    NftPurchaseSuccessMessage1: 'Congrats! You now own ',
    NftPurchaseSuccessMessage2: ' of ',
    NftPurchaseSuccessMessage3: ' Player Trading Card NFT.',
    NftPurchaseFailedMessage1: 'Oops! Your purchase of ',
    NftPurchaseFailedMessage2: ' Player Trading Card NFT failed; try again',
    NftMintingSuccess1: 'Congrats! Your ',
    NftMintingSuccess2: ' Player Trading Card NFT is minted',
    NftListingSuccess1: 'Congrats! Your ',
    NftListingSuccess2: ' Player Trading Card NFT has been listed.',
    NftListingFailed1: 'Oops! Your ',
    NftListingFailed2: ' NFT was not listed. Please try again',
    NftListingFailed3: ' , try again',
    NftSoldNotification1: 'ChaChing! ',
    NftSoldNotification2: ' bought ',
    NftSoldNotification3: ' of your ',
    NftSoldNotification4: ' Player Trading Card NFT.',
    NftSoldNotification5: ' We added ',
    NftSoldNotification6: ' to your wallet.',
    PaymentCredited1: 'Success! ',
    PaymentCredited2: ' has been credited from your wallet',
    PaymentDebited1: 'Success! ',
    PaymentDebited2: ' has been debited from your wallet',
};
export const SENDER_IMAGE = [
    NOTIFICATION_TYPE_CONSTANTS.NFT_LIST_FAILED,
    NOTIFICATION_TYPE_CONSTANTS.NFT_LISTED,
    NOTIFICATION_TYPE_CONSTANTS.NFT_MINTED
];
export const RECEIVER_IMAGE = [
    NOTIFICATION_TYPE_CONSTANTS.NFT_PURCHASED,
    NOTIFICATION_TYPE_CONSTANTS.NFT_TRANFSER_FAILED,
    NOTIFICATION_TYPE_CONSTANTS.NFT_SOLD,
    NOTIFICATION_TYPE_CONSTANTS.DWOLLA_PAYMENT_FAILED
];
export const DWOLLA_FAILURE_TYPE = [
    NOTIFICATION_TYPE_CONSTANTS.WALLET_DOCUMENT_FAILED,
    NOTIFICATION_TYPE_CONSTANTS.WALLET_REACTIVATED,
    NOTIFICATION_TYPE_CONSTANTS.WALLET_MORE_DETAILED_REQUIRED
];
export const TRANSACTION_FAILURE_TYPE = [
    NOTIFICATION_TYPE_CONSTANTS.NFT_TRANFSER_FAILED,
    NOTIFICATION_TYPE_CONSTANTS.DWOLLA_PAYMENT_FAILED
];
export const WALLET_FAILURE = [
    NOTIFICATION_TYPE_CONSTANTS.WALLET_SUSPENDED,
    NOTIFICATION_TYPE_CONSTANTS.WALLET_MORE_DETAILED_REQUIRED
];
export const PURCHASE_NFT_REDIRECTION = [
    NOTIFICATION_TYPE_CONSTANTS.NFT_PURCHASED,
];
export const MY_ORDER_REDIRECTION = [
    NOTIFICATION_TYPE_CONSTANTS.NFT_TRANFSER_FAILED,
    NOTIFICATION_TYPE_CONSTANTS.DWOLLA_PAYMENT_FAILED,
    NOTIFICATION_TYPE_CONSTANTS.NFT_SOLD
];
export const MY_DRAFT_REDIRECTION = [
    NOTIFICATION_TYPE_CONSTANTS.NFT_LIST_FAILED
];
export const OPEN_DWOLLA_WALLET = [
    NOTIFICATION_TYPE_CONSTANTS.WALLET_VERIFIED,
    NOTIFICATION_TYPE_CONSTANTS.WALLET_SUSPENDED,
    NOTIFICATION_TYPE_CONSTANTS.WALLET_MORE_DETAILED_REQUIRED
];
export const PDP_REDIRECTION = [
    NOTIFICATION_TYPE_CONSTANTS.NFT_LISTED,
    NOTIFICATION_TYPE_CONSTANTS.NFT_MINTED
];
export const TRANSACTION_REDIRECTION = [
    NOTIFICATION_TYPE_CONSTANTS.FUND_ADDED_FAILED,
    NOTIFICATION_TYPE_CONSTANTS.FUND_WITHDRAW_COMPLETED,
    NOTIFICATION_TYPE_CONSTANTS.FUND_WITHDRAW_FAILED,
    NOTIFICATION_TYPE_CONSTANTS.PAYMENT_DEBITED,
    NOTIFICATION_TYPE_CONSTANTS.PAYMENT_CREDITED,
    NOTIFICATION_TYPE_CONSTANTS.FUND_ADDED_COMPLETED,
    NOTIFICATION_TYPE_CONSTANTS.ROYALTY_RECEIVED
];
export const AVATAR_DWOLLA_WALLET_REDIRECTION = [
    NOTIFICATION_TYPE_CONSTANTS.WALLET_VERIFIED,
    NOTIFICATION_TYPE_CONSTANTS.WALLET_SUSPENDED,
];
