import React from 'react';
import { Box, styled } from '@mui/material';
const ShadowBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&.notification-bar': {
        'div:first-child': {
            opacity: '.7',
            width: '96%',
        },
        'div:last-child': {
            opacity: '.5',
            width: '94%',
        },
        '&.success': {
            'div': {
                backgroundColor: theme.colors.success500,
            }
        },
        '&.error': {
            'div': {
                backgroundColor: theme.colors.error500,
            }
        },
        '&.warning': {
            'div': {
                backgroundColor: theme.colors.warning500,
            }
        },
        '&.default': {
            'div': {
                backgroundColor: theme.colors.blue,
            }
        },
    },
}));
const SHADOW_LAYER_HEIGHT = 6;
const ShadowLayer = styled(Box)(({ theme, layerWidth, layerOpacity }) => ({
    width: layerWidth,
    height: SHADOW_LAYER_HEIGHT,
    opacity: layerOpacity,
    backgroundColor: theme.colors.white,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
}));
export const NftCardMediaShadow = ({ visible, className = '' }) => {
    return ((visible) ? (<ShadowBox className={`${className}`}>
        <ShadowLayer layerWidth="89%" layerOpacity={0.4}/>
        <ShadowLayer layerWidth="82%" layerOpacity={0.2}/>
      </ShadowBox>) : (<Box height={2 * SHADOW_LAYER_HEIGHT}/>));
};
