import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { ChevronDownFillIcon } from 'ui/icons';
import { DropdownBox } from './SortDropdown.styled';
const Icon = () => {
    return <ChevronDownFillIcon className="nx-dropdown-icon stroke-color MuiSelect-icon"/>;
};
export const SortDropdown = ({ options, value, onChange, disable = false }) => {
    return (<DropdownBox className={`${disable === true ? 'disable-text' : ''}`}>
      <FormControl variant="standard">
        <Select value={value} MenuProps={{
            classes: {
                root: 'nx-dropdown-box'
            }
        }} inputProps={{
            name: 'age',
            id: 'uncontrolled-native',
        }} IconComponent={Icon} onChange={(event) => { onChange(event.target.value); }} className="nx-dropdown-select">
          {options.map(op => <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>)}
        </Select>
      </FormControl>
    </DropdownBox>);
};
