import React from 'react';
import { Loader } from '../Loader';
export const CircularContentLoader = ({ size }) => {
    const getCircularContentLoaderBySize = (size) => {
        switch (size) {
            case 'xlarge':
                return <Loader loading width={48} height={48} variant="circular"/>;
            case 'large':
                return <Loader loading width={40} height={40} variant="circular"/>;
            case 'medium':
                return <Loader loading width={30} height={30} variant="circular"/>;
            case 'small':
                return <Loader loading width={18} height={18} variant="circular"/>;
            default:
                return <Loader loading width={15} height={15} variant="circular"/>;
        }
    };
    return (getCircularContentLoaderBySize(size));
};
