import { ResponsiveSideBarMenuCard } from 'common/Layout';
import { useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { SectionType } from 'types';
import { CategoryFilter, ViewAllDashboardSection } from '../components';
import React from 'react';
export const DashboardSectionScreen = () => {
    const params = useParams();
    const theme = useTheme();
    const isTab = useMediaQuery(theme.breakpoints.down('lg'));
    const sectionNameArray = params.section.split('-').map(current => {
        return current[0].toUpperCase() + current.substring(1);
    });
    const sectionType = sectionNameArray.join('');
    return (<>
      {/* ***************** This component is developed static and dynamic work is pending ****************** */}
      {/* <FilterSection /> */}
      {isTab ? (<ResponsiveSideBarMenuCard isSidebarMenu/>) : (<CategoryFilter />)}
      {sectionType === SectionType.ForYou && <ViewAllDashboardSection sectionType={sectionType} sectionTitle="For You"/>}
      {sectionType === SectionType.LatestDrop && <ViewAllDashboardSection sectionType={sectionType} sectionTitle="Latest Drops"/>}
      {sectionType === SectionType.TopPick && <ViewAllDashboardSection sectionType={sectionType} sectionTitle="Top Picks"/>}
      {sectionType === SectionType.TopSales && <ViewAllDashboardSection sectionType={sectionType} sectionTitle="Top Sales"/>}
      {sectionType === SectionType.Trending && <ViewAllDashboardSection sectionType={sectionType} sectionTitle="Trending"/>}
    </>);
};
// DashboardSectionScreen.getLayout = NextPageLayout;
DashboardSectionScreen.layoutOptions = {
    pageTitle: 'View All'
};
