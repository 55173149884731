import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { NftAssetPreview, NftAssetPreviewList, NftPreviewMode, parseAssetValues, } from 'common/components';
import { NFTImageBox } from 'screens/createnft/components/MultiAsset/MultiAsset.styled';
import FullScreenButton from '../FullScreenButton/FullScreenButton';
export const AssetOverview = ({ nftAssetList, currentStep, handleEditAssets, previewMode, isEditable }) => {
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [isFullScreen, setFullScreen] = useState(false);
    useEffect(() => {
        // Check whether the selected asset is thier in the list
        const isAssetRemoved = (selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.id) && !(nftAssetList === null || nftAssetList === void 0 ? void 0 : nftAssetList.some((asset) => asset.id === (selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.id)));
        if (previewMode === NftPreviewMode.PDP &&
            (nftAssetList === null || nftAssetList === void 0 ? void 0 : nftAssetList.length) &&
            nftAssetList[0] &&
            (!selectedAsset || isAssetRemoved)) {
            const defaultAsset = nftAssetList === null || nftAssetList === void 0 ? void 0 : nftAssetList.find((asset) => asset.isDefault);
            setSelectedAsset(parseAssetValues(defaultAsset || nftAssetList[0], previewMode));
            return;
        }
        else if ((nftAssetList === null || nftAssetList === void 0 ? void 0 : nftAssetList.length) && nftAssetList[0] && (!selectedAsset || isAssetRemoved)) {
            setSelectedAsset(parseAssetValues(nftAssetList[0], previewMode));
            return;
        }
        // Remove selected if there is no Asset in list
        if (!(nftAssetList === null || nftAssetList === void 0 ? void 0 : nftAssetList.length) && selectedAsset) {
            setSelectedAsset(null);
            return;
        }
    }, [nftAssetList === null || nftAssetList === void 0 ? void 0 : nftAssetList.length]);
    return (<Grid container spacing={3}>
      <Grid item xs={12} lg={3} xl={2}>
        <NftAssetPreviewList previewMode={previewMode} assetList={nftAssetList} isEditable={isEditable} isHideBG={!isEditable} selectedAssetId={selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.id} onEditClick={handleEditAssets} onThumbClick={(asset) => {
            setSelectedAsset(asset);
        }}/>
      </Grid>
      <Grid item lg={7} xl={8} display="flex" justifyContent="center">
        <NFTImageBox imageWidth="20.125">
          <NftAssetPreview onAssetClick={() => { setFullScreen(!isFullScreen); }} selectedAsset={selectedAsset}/>
        </NFTImageBox>
      </Grid>
      <Grid item lg={2} xl={2}>
        <FullScreenButton isFullScreen={isFullScreen} setFullScreen={setFullScreen} selectedAsset={selectedAsset}/>
      </Grid>
    </Grid>);
};
