import { Box, Card, styled, Typography } from '@mui/material';
import isPropValid from '@emotion/is-prop-valid';
export const SmallText = styled(Typography)(({ theme }) => ({
    color: theme.dynamicTheme.mainSection.text1.color,
    fontSize: '0.75rem',
}));
export const NxlvlWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
}));
export const NxlvlLabel = styled(Typography)(({ theme }) => ({
    color: theme.dynamicTheme.mainSection.text1.color,
    textTransform: 'uppercase',
    paddingRight: theme.spacing(1),
}));
export const NxlvlText = styled(Typography)({
    flex: 3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.break-word': {
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces',
    }
});
export const NFTAmountBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '27%',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexDirection: 'row-reverse',
        '.transaction-date': {
            fontSize: '0.625rem'
        }
    }
}));
export const TypographyWhite60 = styled(Typography)(() => ({
    color: 'rgba(255, 255, 255, .6)',
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
    letterSpacing: .12
}));
export const NftTitle = styled(Typography)(() => ({
    fontSize: '0.875rem',
    fontWeight: 600,
    minHeight: '2.625rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
}));
export const TermsBox = styled(Box)(({ theme }) => ({
    '.MuiTypography-root': {
        fontSize: '.75rem!important',
        padding: `${theme.spacing(0.5)} 0`,
    },
    '.MuiCheckbox-root': {
        padding: `0 ${theme.spacing(1)} 0`,
    },
    '.MuiFormControlLabel-root': {
        alignItems: 'flex-start',
    },
    '.MuiFormHelperText-root': {
        color: `${theme.colors.error500}!important`,
        fontSize: '0.875rem',
    }
}));
export const ContentPageWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    padding: `${theme.spacing(6)} ${theme.spacing(5)}`,
    '.MuiTypography-root': {
        color: theme.colors.black,
    },
    'a:hover': {
        color: `${theme.colors.blue}!important`,
    },
    '.main-title': {
        fontSize: '2.5rem',
        textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    }
}));
export const ContentPageTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));
export const ContentPageTypography = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    position: 'relative',
    '&.dots': {
        paddingLeft: theme.spacing(2),
        '&:before': {
            position: 'absolute',
            content: '""',
            width: '.5rem',
            height: '.5rem',
            backgroundColor: theme.colors.black,
            borderRadius: '50%',
            left: 0,
            top: '0.375rem',
        }
    }
}));
export const ContentPageFooterLogo = styled(Box)(({ theme }) => ({
    padding: `${theme.spacing(5)} 0`,
    textAlign: 'center',
    '.foot-logo-icon': {
        fontSize: '4rem',
        'path': {
            fill: theme.colors.neutral700,
        }
    }
}));
export const NftRoyaltyCardWrapper = styled(Box)(({ theme, disabledMargin }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: disabledMargin ? 0 : theme.spacing(1),
    '.close-btn': {
        padding: 0,
    },
}));
export const NftRoyaltyBlackCard = styled(Card, { shouldForwardProp: isPropValid })(({ theme, activeMR, disabledMB, activeBorder }) => ({
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    backgroundColor: theme.colors.black,
    boxShadow: 'none',
    marginBottom: disabledMB ? 0 : theme.spacing(1),
    marginRight: activeMR ? theme.spacing(1) : 0,
    flex: 1,
    border: activeBorder ? `0.063rem solid ${theme.colors.neutral700}` : 'none',
}));
