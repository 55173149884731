import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { AuthDialogProvider, QRDialogProvider, QRDialog, MultipleNotificationProvider, DwollaProvider } from 'providers';
import { getJwtExpirationDate } from 'helpers/utils';
import { attachCustomDataGleap, removeCustomDataGleap } from 'lib/gleap';
import { SnackbarProvider } from 'notistack';
import { MAX_SNACK_NOTIFICATION } from 'helpers/constants';
import { mpReset } from 'lib/mixpanel';
const AppContext = createContext({});
const setUserObj = () => {
    const userCookie = Cookie.get('user');
    if (userCookie) {
        return JSON.parse(atob(userCookie));
    }
    else {
        return null;
    }
};
export const AppWrapper = ({ children, isPrivateRoute }) => {
    const [token, setToken] = useState((Cookie.get('token') || undefined));
    const [currentUser, setCurrentUser] = useState(() => setUserObj());
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        refreshContext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Cookie.get('token'), Cookie.get('user')]);
    const updateDwollaCustomerData = (dwollaCustomer) => {
        const tokenExpDate = getJwtExpirationDate(Cookie.get('token'));
        const refreshTokenExpDate = getJwtExpirationDate(Cookie.get('refresh-token'));
        Cookie.set('user', btoa(JSON.stringify(Object.assign(Object.assign({}, currentUser), { dwollaCustomer }))), { expires: refreshTokenExpDate || tokenExpDate });
        refreshContext();
    };
    const refreshContext = () => {
        const tokenCookie = Cookie.get('token');
        const userCookie = Cookie.get('user');
        if (tokenCookie) {
            setToken(tokenCookie);
        }
        else {
            setToken(undefined);
        }
        const userObj = setUserObj();
        setCurrentUser(userObj);
        // Gleap actions to set/remove custom data (Logged-in user's Id and Email)
        if (userObj) {
            attachCustomDataGleap({ userId: userObj.id, userEmail: userObj.email });
        }
        else {
            // Reset Gleap Data
            removeCustomDataGleap('userId');
            removeCustomDataGleap('userEmail');
            // Reset Mixpanel if user is not logged-in
            mpReset();
        }
        setIsLoggedIn(Boolean(tokenCookie && userCookie));
    };
    return (<AppContext.Provider value={{
            isLoggedIn,
            token,
            currentUser,
            refreshContext,
            updateDwollaCustomerData
        }}>
      <SnackbarProvider maxSnack={MAX_SNACK_NOTIFICATION}>
        <MultipleNotificationProvider>
          <DwollaProvider dwollaCustomer={currentUser === null || currentUser === void 0 ? void 0 : currentUser.dwollaCustomer}>
            <QRDialogProvider>
              <AuthDialogProvider isPrivateRoute={isPrivateRoute}>
                {children}
              </AuthDialogProvider>
              <QRDialog />
            </QRDialogProvider>
          </DwollaProvider>
        </MultipleNotificationProvider>
      </SnackbarProvider>
    </AppContext.Provider>);
};
export const useAppContext = () => {
    return useContext(AppContext);
};
