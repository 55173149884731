import { createSearchParams, useNavigate } from 'react-router-dom';
export const useNavigateParams = () => {
    const navigate = useNavigate();
    return (pathname, params, replace = false) => {
        console.log('params', params);
        const path = {
            pathname,
            search: createSearchParams(JSON.parse(JSON.stringify(params))).toString(),
            replace,
        };
        navigate(path);
    };
};
