import { Card, CardContent, styled, Typography } from '@mui/material';
import { LogoIcon } from 'ui/icons';
import React from 'react';
const EmptyStateWrapper = styled(Card)(({ theme }) => ({
    marginTop: theme.spacing(4),
    '.MuiTypography-root': {
        color: theme.dynamicTheme.mainSection.disableText.color,
    },
    '.MuiCardContent-root': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '12rem',
        justifyContent: 'space-evenly'
    },
    '.logo-icon': {
        marginBottom: theme.spacing(4),
        transform: 'scale(3)',
        '& path': {
            fill: theme.dynamicTheme.mainSection.disableText.color,
        },
    },
    '.text-logo-icon': {
        transform: 'scale(.8)',
        '& path': {
            fill: theme.dynamicTheme.mainSection.disableText.color,
        },
    }
}));
export const EmptyNftListState = () => {
    return (<EmptyStateWrapper>
      <CardContent>
        <LogoIcon className="logo-icon"/>
        <Typography variant="h6" display="flex" alignItems="center"> No any nX<LogoIcon className="text-logo-icon"/>nfts found to display</Typography>
      </CardContent>
    </EmptyStateWrapper>);
};
