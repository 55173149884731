import React from 'react';
import { styled } from '@mui/material';
import { NFT_IMG_RATIO } from 'helpers/constants';
const AspectWrapper = styled('div')((props) => ({
    position: 'relative',
    '--aspect-ratio': props.ratio,
    '& > :first-of-type': {
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
    },
    '& > img': {
        height: 'auto',
    },
    '&::before': {
        content: '""',
        display: 'block',
        width: '100%',
    },
    '@supports not (aspect-ratio: 1/1)': {
        '&::before': {
            height: '0',
            paddingBottom: 'calc(100% / (var(--aspect-ratio)))',
        },
    },
    '@supports (aspect-ratio: 1/1)': {
        '&::before': {
            aspectRatio: 'calc(var(--aspect-ratio))',
        },
    }
}));
export const AspectRatio = ({ ratio = NFT_IMG_RATIO, children }) => {
    return (<AspectWrapper ratio={ratio}>
      {children}
    </AspectWrapper>);
};
