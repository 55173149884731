import { colors, themeDefault } from 'ui/foundations';
const MuiCard = {
    styleOverrides: {
        root: {
            borderColor: colors.neutral450,
            marginTop: themeDefault.spacing(2),
            marginBottom: themeDefault.spacing(2),
            '&.thin': {
                marginTop: themeDefault.spacing(1),
                marginBottom: themeDefault.spacing(1),
            }
        },
    },
};
export default MuiCard;
