import React from 'react';
import { Dialog, SliderArrowLeft, SliderArrowRight } from 'common/components';
import Slider from 'react-slick';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ImgMaximizeIcon } from 'ui/icons';
import { useTranslation } from 'react-i18next';
import NftFullScreenPreview from '../NftFullScreenPreview/NftFullScreenPreview';
import MaximizeImgBox from './FullScreenButton.styled';
import { VIDEO_ELEMENT_ID } from 'helpers/constants';
import { NftType } from 'types';
const FullScreenButton = ({ selectedAsset, isFullScreen, setFullScreen }) => {
    var _a;
    const { t } = useTranslation();
    const videoElementId = (_a = selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.videoData) === null || _a === void 0 ? void 0 : _a.playbackId;
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        // TODO: If img length > 3 then infite will be true otherwise it will be false
        infinite: false,
        arrows: true,
        nextArrow: <SliderArrowRight />,
        prevArrow: <SliderArrowLeft />,
        centerMode: true,
    };
    const handleFullScreenVideo = () => {
        const el = document.getElementById(VIDEO_ELEMENT_ID);
        if (!el)
            return;
        if (el.requestFullscreen) {
            el.requestFullscreen();
        }
        else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
        }
        else if (el.webkitRequestFullscreen) {
            el.webkitRequestFullscreen();
        }
        else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
        }
        else if (el.webkitEnterFullscreen) {
            el.webkitEnterFullscreen();
        }
    };
    const toggleFullScreen = () => {
        if (videoElementId || ((selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.type) === NftType.Video && (selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.originalUrl))) {
            handleFullScreenVideo();
        }
        else {
            setFullScreen(!isFullScreen);
        }
    };
    return (<>
      <Tooltip title={<>{t('View Full Screen')}</>}>
        <span>
          <IconButton disabled={!selectedAsset} className="img-max-btn" onClick={toggleFullScreen}>
            <ImgMaximizeIcon className="stroke-color"/>
          </IconButton>
        </span>
      </Tooltip>

      <Dialog variant="default" open={isFullScreen} onClose={toggleFullScreen} maxWidth="xl" hideDialogAction={true}>
        <MaximizeImgBox>
          <Box className="fullscreen-slide-wrapper">
            <Slider {...settings}>
              <NftFullScreenPreview selectedAsset={selectedAsset}/>
            </Slider>
          </Box>
        </MaximizeImgBox>
      </Dialog>
    </>);
};
export default FullScreenButton;
