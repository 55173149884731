import { gql, useLazyQuery, useQuery } from '@apollo/client';
export const GET_DWOLLA_CUSTOMER_DETAILS_BY_ID = gql `
  query getDwollaCustomerByUserId{
    getDwollaCustomerByUserId {
      id
      userId
      url
      status
  }
}
`;
export const GET_DWOLLA_CUSTOMER_DATA = gql `
  query getDwollaCustomer {
    getDwollaCustomer {
      id
      firstName
      lastName
      email
      address1
      address2
      city
      state
      postalCode
      dateOfBirth
      ssn
      phone
    }
  }
`;
export const useGetDwollaCustomerQuery = () => {
    return useQuery(GET_DWOLLA_CUSTOMER_DETAILS_BY_ID, {
        notifyOnNetworkStatusChange: true,
    });
};
export const useGetDwollaCustomerLazyQuery = () => {
    const [getDwollaCustomerDetails, { data }] = useLazyQuery(GET_DWOLLA_CUSTOMER_DETAILS_BY_ID, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });
    return { getDwollaCustomerDetails, data };
};
export const useGetDwollaCustomerRetryDataQuery = () => {
    return useQuery(GET_DWOLLA_CUSTOMER_DATA, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });
};
