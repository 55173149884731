import { Box, styled } from '@mui/material';
export const PresentedByBox = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    '.presented-text': {
        textTransform: 'uppercase',
        margin: `0 0 ${theme.spacing(1.25)}`,
        fontWeight: '500',
        lineHeight: 1.3,
    },
    '.brand-logo': {
        fontSize: '6rem',
        height: '2rem',
    },
}));
