import { getNftImgUrls } from 'helpers/utils';
import { centerCrop, makeAspectCrop } from 'react-image-crop';
import { NftType } from 'types';
// Load image from URL and return image element for Cropping in Canvas
export const loadImage = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve(img);
        };
        img.onerror = (err) => {
            reject(err);
        };
        img.src = src;
    });
};
// Get centered crop data based on the IMG width and height
export const centerAspectCrop = (width, height, aspect) => {
    return centerCrop(makeAspectCrop({
        unit: '%',
        height: 99,
    }, aspect, width, height), width, height);
};
// Convert canvas into Blob
export const canvasToBlob = (canvas) => {
    return new Promise((resolve) => {
        canvas.toBlob(resolve);
    });
};
// Revoke asset's all Blob URLs to clear memory
export const revokeAssetBlobUrls = (asset) => {
    if (asset === null || asset === void 0 ? void 0 : asset.originalUrl)
        URL.revokeObjectURL(asset === null || asset === void 0 ? void 0 : asset.originalUrl);
    if (asset === null || asset === void 0 ? void 0 : asset.croppedImg)
        URL.revokeObjectURL(asset === null || asset === void 0 ? void 0 : asset.croppedImg);
    if (asset === null || asset === void 0 ? void 0 : asset.croppedCompImg)
        URL.revokeObjectURL(asset === null || asset === void 0 ? void 0 : asset.croppedCompImg);
};
// Convert Blob into File
export const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, { lastModified: Date.now(), type: theBlob.type });
};
// Function to get Parsed Asset List for the API mutation
export const parseAssetListForAPI = (assetList) => {
    if (Array.isArray(assetList) && assetList.length > 0) {
        const parsedAssetList = assetList.map((asset, index) => (Object.assign({ 
            // For New and Existing assets
            isDefault: asset.isDefault, order: (index + 1), type: asset.type, nftDataInput: asset.nftDataInput, 
            // For existing uploaded assets
            isPublished: asset.isPublished, imageUrl: asset.imageUrl }, (!!asset.passthroughId && { passthroughId: asset.passthroughId }) // For video assets
        )));
        return parsedAssetList;
    }
    return [];
};
// Function to devoce API Asset List into our Asset List Format
export const decodeAssetListFromAPI = (apiAssetAry) => {
    if (Array.isArray(apiAssetAry) && apiAssetAry.length) {
        // Sort the list with order number
        const sortedAssetList = apiAssetAry.sort((a, b) => ((a === null || a === void 0 ? void 0 : a.order) || 0) - ((b === null || b === void 0 ? void 0 : b.order) || 0));
        const assetList = sortedAssetList.map((asset, index) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            const { originalImg, thumbnailImg } = getNftImgUrls((asset === null || asset === void 0 ? void 0 : asset.imageUrl) || '');
            // Video data
            const isVideo = !!((_a = asset === null || asset === void 0 ? void 0 : asset.videoData) === null || _a === void 0 ? void 0 : _a.playbackId);
            const videoThumb = (isVideo ? (_b = asset === null || asset === void 0 ? void 0 : asset.videoData) === null || _b === void 0 ? void 0 : _b.videoThumbnail : '');
            return {
                id: ((asset === null || asset === void 0 ? void 0 : asset.id) || (Date.now() + index)),
                isAlreadyUploaded: !!(asset === null || asset === void 0 ? void 0 : asset.id),
                order: ((asset === null || asset === void 0 ? void 0 : asset.order) || 1),
                isDefault: !!(asset === null || asset === void 0 ? void 0 : asset.isDefault),
                isPublished: !!(asset === null || asset === void 0 ? void 0 : asset.isPublished),
                imageUrl: ((asset === null || asset === void 0 ? void 0 : asset.imageUrl) || ''),
                passthroughId: ((asset === null || asset === void 0 ? void 0 : asset.passthroughId) || ''),
                originalUrl: (originalImg || ''),
                croppedImg: ((asset === null || asset === void 0 ? void 0 : asset.imageUrl) || ''),
                croppedCompImg: (videoThumb || thumbnailImg || ''),
                type: (isVideo ? NftType.Video : NftType.Image),
                nftDataInput: Object.assign(Object.assign({ assetId: (_c = asset === null || asset === void 0 ? void 0 : asset.videoData) === null || _c === void 0 ? void 0 : _c.assetId, playbackId: (_d = asset === null || asset === void 0 ? void 0 : asset.videoData) === null || _d === void 0 ? void 0 : _d.playbackId, videoThumbnail: (_e = asset === null || asset === void 0 ? void 0 : asset.videoData) === null || _e === void 0 ? void 0 : _e.videoThumbnail, contentId: (_f = asset === null || asset === void 0 ? void 0 : asset.videoData) === null || _f === void 0 ? void 0 : _f.contentId }, (((_g = asset === null || asset === void 0 ? void 0 : asset.videoData) === null || _g === void 0 ? void 0 : _g.duration) && { duration: (_h = asset === null || asset === void 0 ? void 0 : asset.videoData) === null || _h === void 0 ? void 0 : _h.duration })), (((_j = asset === null || asset === void 0 ? void 0 : asset.videoData) === null || _j === void 0 ? void 0 : _j.passthrough) && { passthrough: (_k = asset === null || asset === void 0 ? void 0 : asset.videoData) === null || _k === void 0 ? void 0 : _k.passthrough })),
            };
        });
        return assetList;
    }
    return [];
};
