import React from 'react';
import { Button, Card, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CreateNFTIcon, LogoIcon, MyNftsNewIcon } from 'ui/icons';
import { DiscoverCardBox, DiscoverCardLoaderBox } from './DiscoverCard.styled';
import { Link, Loader } from 'common/components';
import { useAppContext, useAuthDialog } from 'providers';
import { PAGE_ROUTES } from 'helpers/constants';
import { useNavigate } from 'react-router-dom';
const commonPageId = 'sidebar-discover';
const DiscoverCardLoader = () => {
    return (<DiscoverCardLoaderBox>
      <Stack spacing={1.8} marginBottom="37px">
        <Loader loading variant="rectangular"/>
        <Loader loading variant="rectangular"/>
        <Loader loading width="50%" variant="rectangular"/>
      </Stack>
      <Stack spacing={2}>
        <Loader loading variant="rectangular" height={56}/>
        <Loader loading variant="rectangular" height={56}/>
      </Stack>
    </DiscoverCardLoaderBox>);
};
export const DiscoverCard = ({ hideMyNftBtn = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoggedIn, currentUser } = useAppContext();
    const { openAuthDialog } = useAuthDialog();
    const hasAppAccess = ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.marketplaceFullAccess) || false);
    const handleCreateNft = () => {
        if (!isLoggedIn) {
            openAuthDialog().then((res) => {
                res.success && navigate(PAGE_ROUTES.CREATE_NFT);
            });
            return;
        }
        navigate(PAGE_ROUTES.CREATE_NFT);
    };
    if (isLoggedIn && !hasAppAccess) {
        return null;
    }
    return (<DiscoverCardBox>
      <Card>
        <Typography variant="h3" className="nx-user-head">
          {t('Discover, Collect & Sell your favorite Talent, Moments and rare NFTs')}
        </Typography>
        <Button id={`${commonPageId}-button-discover-btn`} className="nx-discover-btn" fullWidth startIcon={<CreateNFTIcon className="create-nft-btn-icon"/>} onClick={handleCreateNft}>
          {t('Create')} NX<LogoIcon className="nx-logo-icon"/>NFT
        </Button>
        {!hideMyNftBtn && (<Link id={`${commonPageId}-link-my-nft`} href={PAGE_ROUTES.MY_NFT__LISTED} passHref>
            <Button id={`${commonPageId}-button-my-nft`} variant="outlined" color="secondary" className="nx-discover-btn" fullWidth startIcon={<MyNftsNewIcon className="nx-discover-btn-icon stroke-color"/>}>
              {t('My NXNFTs')}
            </Button>
          </Link>)}
      </Card>
    </DiscoverCardBox>);
    return <DiscoverCardLoader />;
};
