import { createContext, useContext, useReducer } from 'react';
import { ConfirmUnsaveDialog } from 'screens/createnft/components/common';
import { NftEditionTier, NftType } from 'types';
import { PostNftActionTypes, PostNftStepTypes } from './PostNft.types';
const initialState = {
    nftAssetList: [],
    createNftData: {
        isAnyEditionListed: false,
        data: {
            seriesNumber: 0,
            name: '',
            description: '',
            activityIds: [],
            price: 0,
            royaltyFee: 0,
            size: 0,
            tier: NftEditionTier.Legendary,
            coRoyaltyData: [],
            nftFirstSaleShare: [],
            type: NftType.Image,
            nftDataInput: {}
        },
        serverData: undefined,
    },
    currentStep: PostNftStepTypes.NFT_ASSETS_SELECTION,
    isConfirmUnsaveWork: false,
};
const contextInitialState = {
    state: initialState,
    dispatch: () => null,
};
export const PostNftContext = createContext(contextInitialState);
export const usePostNftContext = () => {
    return useContext(PostNftContext);
};
const reducer = (state, action) => {
    switch (action.type) {
        case PostNftActionTypes.SET_NFT_ASSETS:
            return Object.assign(Object.assign({}, state), { nftAssetList: action.payload });
        case PostNftActionTypes.SET_NFT_DETAILS:
            return Object.assign(Object.assign({}, state), { createNftData: action.payload });
        case PostNftActionTypes.SET_CURRENT_STEP:
            return Object.assign(Object.assign({}, state), { currentStep: action.payload });
        case PostNftActionTypes.SET_IS_CONFRIM_WORK_DIALOG:
            return Object.assign(Object.assign({}, state), { isConfirmUnsaveWork: action.payload });
        default:
            return state;
    }
};
export const PostNftProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (<PostNftContext.Provider value={{ state, dispatch }}>
      {children}

      <ConfirmUnsaveDialog />
    </PostNftContext.Provider>);
};
