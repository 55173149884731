import { gql, useMutation } from '@apollo/client';
export const UPDATE_DWOLLA_CUSTOMER_STATUS = gql `
  mutation updateDwollaCustomerStatus(
    $status: DwollaCustomerStatus!
  ) {
    updateDwollaCustomerStatus(
      status: $status
    ) {
      id
      userId
      status
      url
    }
  }
`;
export const useUpdateDwollaCustomerStatus = () => {
    const [updateDwollaCustomerStatus] = useMutation(UPDATE_DWOLLA_CUSTOMER_STATUS, {
        notifyOnNetworkStatusChange: true,
    });
    return { updateDwollaCustomerStatus };
};
