import { gql, useLazyQuery, useQuery } from '@apollo/client';
export const SEARCH_NFT = gql `
    query searchNFT($searchNFTArgs: SearchNFTArgs!) {
      searchNFT(searchNFTArgs: $searchNFTArgs) {
        pageEdges{
          node{
            id
            name
            tier
            userId
            user {
              profileImage
              dwollaCustomer {
                status
              }
            }
            firstname
            lastname
            imageURL
            isNFTBookmarked
            isLiked
            likeCount
            playbackId
            editions{
              id
              tier
            }
            activity{
              id
              name
            }
            createdAt
          }
        }
        pageInfo{
          hasNextPage
          pageNo
          totalCount
        }
      }
    }
`;
export const useSearchNFTQuery = (searchNFTArgs) => {
    return Object.assign(Object.assign({}, useQuery(SEARCH_NFT, {
        variables: { searchNFTArgs },
        notifyOnNetworkStatusChange: true,
    })), { query: SEARCH_NFT });
};
export const useLazySearchNFTQuery = () => {
    return useLazyQuery(SEARCH_NFT, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });
};
