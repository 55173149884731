import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext, useAuthDialog } from 'providers';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
// Stack, Grid
import { PeopleIcon, InstitutionsIcon } from 'ui/icons';
import { PAGE_ROUTES } from 'helpers/constants';
import { LeftSideBarCard } from '../SideBar.styled';
import { useNavigate } from 'react-router-dom';
// import { Loader } from '@/components/common';
const commonPageId = 'bookmark-card';
{ /* Section comments for content loader */ }
// const PeopleCardLoader = () => {
//   return (
//     <Box>
//       <Stack spacing={4}>
//         {([1, 2, 3, 4]).map((i) => (
//           <div key={i}>
//             <Grid container alignItems="center" spacing={1}>
//               <Grid item xs="auto" >
//                 <Loader loading width={32} height={32} variant="circular" />
//               </Grid>
//               <Grid item xs={5}>
//                 <Loader loading height={12} variant="rectangular" />
//               </Grid>
//             </Grid>
//           </div>
//         ))}
//       </Stack>
//     </Box>
//   );
// }
const PeopleBookmarkCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoggedIn } = useAppContext();
    const { openAuthDialog } = useAuthDialog();
    const handleBookmark = () => {
        if (!isLoggedIn) {
            openAuthDialog().then((res) => {
                res.success && navigate(PAGE_ROUTES.USERS__BOOKMARKED_PEOPLE);
            });
            return;
        }
        navigate(PAGE_ROUTES.USERS__BOOKMARKED_PEOPLE);
    };
    return (<LeftSideBarCard>

      {/* Section comments for content loader */}
      {/* <PeopleCardLoader></PeopleCardLoader> */}

      <List className="nx-main-menu">
        <ListItem id={`${commonPageId}-button-institutions`} button className="nx-main-menu-item">
          <Box display="flex">
            <ListItemIcon className="nx-menu-icon svg-stroke-color"><InstitutionsIcon /></ListItemIcon>
            <ListItemText className="nx-menu-text">{t('Institutions')}</ListItemText>
          </Box>
        </ListItem>
        <ListItem id={`${commonPageId}-button-people`} button className="nx-main-menu-item active" onClick={handleBookmark}>
          <Box display="flex">
            <ListItemIcon className="nx-menu-icon svg-stroke-color"><PeopleIcon /></ListItemIcon>
            <ListItemText className="nx-menu-text">{t('People')}</ListItemText>
          </Box>
        </ListItem>
      </List>
    </LeftSideBarCard>);
};
export default PeopleBookmarkCard;
