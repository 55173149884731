import React from 'react';
import { MuxVideoPlayer, NxCustomImage } from 'common/components';
import { NftType } from 'types';
import { NftAssetPreviewBox } from './NftAssetPreview.styled';
import { VIDEO_ELEMENT_ID } from 'helpers/constants';
export const NftAssetPreview = (props) => {
    const { imageAltTxt, selectedAsset, onAssetClick } = props;
    const renderPreview = () => {
        if ((selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.type) === NftType.Video) {
            const videoData = (selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.videoData) || {};
            if (videoData === null || videoData === void 0 ? void 0 : videoData.playbackId) {
                return (<MuxVideoPlayer className="vertical-slide-img video" playbackId={(videoData === null || videoData === void 0 ? void 0 : videoData.playbackId) || ''} style={{ maxWidth: '100%' }} id={VIDEO_ELEMENT_ID}/>);
            }
            if (selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.originalUrl) {
                return (<video className="nft-video" id={VIDEO_ELEMENT_ID} src={selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.originalUrl} autoPlay controls/>);
            }
        }
        return (<NxCustomImage className="vertical-slide-img" src={selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.originalUrl} alt={imageAltTxt || 'NFT'} id="nft-id" onClick={selectedAsset ? onAssetClick : undefined}/>);
    };
    return <NftAssetPreviewBox>{renderPreview()}</NftAssetPreviewBox>;
};
