var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { imgPreviewCompressed } from 'common/components';
import { canvasPreview } from 'common/components/ImageBased/ImageCrop/canvasPreview';
import { NFT_IMG_RATIO } from 'helpers/constants';
import { centerAspectCrop, loadImage, canvasToBlob } from './assetProcessUtils';
const isExternalURL = (url) => (new URL(url).origin !== location.origin);
const getLocalImgURL = (url) => __awaiter(void 0, void 0, void 0, function* () {
    if (isExternalURL(url)) {
        try {
            const imgBlob = yield fetch(url).then(r => r.blob());
            const imgUrl = URL.createObjectURL(imgBlob);
            return { blob: imgBlob, url: imgUrl };
        }
        catch (err) {
            return { url: '' };
        }
    }
    return { url };
});
// Function to Crop and Compress Image with file URL
export const cropImage = ({ imgSrc, crop, isCompressed }) => __awaiter(void 0, void 0, void 0, function* () {
    const { url: originalImgUrl, blob: originalImgBlob } = yield getLocalImgURL(imgSrc);
    // Loaded image element from img URL
    const imageEle = yield loadImage(originalImgUrl);
    // Create a canvas for Cropping
    const canvas = document.createElement('canvas');
    // Return null if No Canvas Found
    if (!canvas)
        return null;
    const cropData = (crop || centerAspectCrop(imageEle.naturalWidth, imageEle.naturalHeight, NFT_IMG_RATIO));
    // Crop the image! Default crop will be centered align!
    canvasPreview(imageEle, canvas, cropData, 1, 0);
    // Convert canvas into Blob & Get Blob URL
    const croppedImgBlob = yield canvasToBlob(canvas);
    if (!croppedImgBlob) {
        return null;
    }
    // const croppedImg = await convertFileToBase64(croppedImgBlob);
    const croppedImgUrl = URL.createObjectURL(croppedImgBlob);
    const cropImgData = {
        croppedImg: croppedImgUrl,
        croppedImgBlob,
        croppedCompImg: croppedImgUrl,
        croppedCompImgBlob: croppedImgBlob,
        cropData
    };
    if (isCompressed) {
        const compressRes = yield imgPreviewCompressed(croppedImgBlob);
        if (compressRes) {
            cropImgData.croppedCompImg = compressRes === null || compressRes === void 0 ? void 0 : compressRes.previewUrl;
            cropImgData.croppedCompImgBlob = compressRes === null || compressRes === void 0 ? void 0 : compressRes.blob;
        }
    }
    // Add original asset's blob and blob URL (If user edit the Published asset)
    if (originalImgUrl && originalImgBlob) {
        cropImgData.originalUrl = originalImgUrl;
        cropImgData.originalUrlBlob = originalImgBlob;
    }
    return cropImgData;
});
