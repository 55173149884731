import { Box, styled } from '@mui/material';
export const BasicSliderBox = styled(Box)(({ theme, hideShadow }) => ({
    '.slick-slider': {
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(-2),
        },
        '&:last-child': {
            paddingRight: 0
        },
        '.slick-list': {
            padding: '0 !important',
            ':before, :after': !hideShadow ? {
                position: 'absolute',
                content: '""',
                width: '6.2rem',
                height: '100%',
                right: '0',
                top: '0',
                backgroundImage: 'linear-gradient(to left, #000 25%, rgba(0, 0, 0, 0) 60%)',
                zIndex: '9',
                [theme.breakpoints.down('md')]: {
                    width: '11.2rem',
                    background: 'linear-gradient(0.25turn, rgba(0, 0, 0, 0), rgba(0, 0, 0, 100))',
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                },
            } : {
                display: 'none'
            },
            ':after': !hideShadow ? {
                right: 'auto',
                left: '-0.313rem',
                transform: 'rotate(-180deg)',
                [theme.breakpoints.down('md')]: {
                    display: 'none'
                },
            } : {
                display: 'none'
            },
        },
        '.slick-track': {
            margin: 'inherit'
        }
    },
    '.nx-slider-box': {
        paddingRight: theme.spacing(3)
    },
}));
