import React from 'react';
import { Box, CardContent, Divider, Grid, styled, } from '@mui/material';
import { NftCardBoxLoader } from '../NftCard.styled';
import { Loader } from 'common/components';
const NftCardContent = styled(CardContent)(() => ({
    padding: 0,
    '&.size-large': {
        width: '100%'
    }
}));
export const NftCardContentLoader = ({ size }) => {
    return (<NftCardContent>
      {size === 'small' ?
            <Box className="nft-card-content-box">
          <Loader loading width="50%"/>
          <Loader loading height={12}/>
          <Loader loading/>
          <Divider className="nft-card-divider"/>
          <Grid container spacing={0.7} alignItems="center">
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
          </Grid>
          <Divider className="nft-card-divider"/>
          {/*
                  <Loader loading />
                  <Loader loading />
                */}
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
            <Grid item xs={2}>
              <Loader loading/>
            </Grid>
            <Grid item xs>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Loader loading variant="circular" height={24} width={24}/>
                </Grid>
                <Grid item>
                  <Loader loading height={24} width={17}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
            :
                <Box className="nft-card-content-box">
          <Loader loading height={12} width="50%"/>
          <Loader loading/>
          <Divider className="nft-card-divider"/>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
          </Grid>
          <Divider className="nft-card-divider"/>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Loader loading variant="circular" height={24} width={24}/>
            </Grid>
            <Grid item xs={2}>
              <Loader loading/>
            </Grid>
            <Grid item xs>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Loader loading variant="circular" height={24} width={24}/>
                </Grid>
                <Grid item>
                  <Loader loading height={24} width={20}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>}
    </NftCardContent>);
};
export const NftCardLoader = ({ size, hideContent = false }) => {
    return (<NftCardBoxLoader>
      <div className={`card-loader card-loader--${size}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
          <circle cx="32" cy="32" r="29" stroke="url(#8m07bbwana)" strokeWidth="6"/>
          <defs>
            <linearGradient id="8m07bbwana" x1="-14.4" y1="-12.4" x2="5.934" y2="57.118" gradientUnits="userSpaceOnUse">
              <stop stopColor="#345BFF"/>
              <stop offset=".99" stopColor="#141826"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      {!hideContent && <NftCardContentLoader size={size}/>}
    </NftCardBoxLoader>);
};
