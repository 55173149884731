import { gql, useQuery } from '@apollo/client';
export const GET_FEATURED_CREATORS = gql `
	query getFeaturedCreators(
		$cursor: Int!,
		$take: Int!,
		$userId: Int!
	){
		getFeaturedCreators(cursor: $cursor, take: $take) {
			pageEdges {
				node {
					id
					user {
						id
						firstname
						lastname
						profileImage
						backgroundImage
						backgroundVideoData
						isFollowing(userId: $userId)
						dwollaCustomer {
							status
						}
					}
				}
			}
			pageInfo {
				hasNextPage
				totalCount
			}
		}
	}
`;
export const useFeaturedCreatorsQuery = (variables) => {
    return useQuery(GET_FEATURED_CREATORS, {
        variables: variables,
        notifyOnNetworkStatusChange: true,
    });
};
