import React from 'react';
import { Box, styled } from '@mui/material';
import { getMuxGifURL } from 'helpers/utils';
import { AspectRatio, NxCustomImage } from 'common/components';
const CardMediaBox = styled(Box)(() => ({
    '.MuiCardMedia-root': {
        borderRadius: '0.5rem',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
}));
const BorderBox = styled(Box)(({ theme, disableBorder }) => ({
    height: '100%',
    borderRadius: '0.625rem',
    '&.regular-border': {
        border: disableBorder ? '' : '1px solid rgba(255,255,255, .4 )'
    },
    '&.gradient-border': {
        padding: `${theme.spacing(1)}`,
        '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 0,
            borderRadius: 'inherit',
            background: theme.colors.tierLegendary
        }
    },
    '.card-media-img': {
        backgroundColor: theme.colors.neutral25,
        position: 'relative',
        zIndex: 1
    }
}));
export const NftCardMedia = ({ id, image, video, size = 'medium', multiple, disableBorder }) => {
    const isVideo = !!((video === null || video === void 0 ? void 0 : video.videoThumbnail) || (video === null || video === void 0 ? void 0 : video.playbackId));
    const imgSrc = isVideo ? (video.videoThumbnail || getMuxGifURL(video.playbackId)) : image;
    return (<CardMediaBox className={`size-${size}`}>
      <AspectRatio>
        <BorderBox className={multiple ? 'regular-border' : 'gradient-border'} disableBorder={disableBorder}>
          <NxCustomImage src={(imgSrc || '')} alt="NFT" id={id}/>
        </BorderBox>
      </AspectRatio>
    </CardMediaBox>);
};
