import { gql, useQuery } from '@apollo/client';
export const SEARCH_CREATORS = gql `
  query searchCreator(
    $searchString: String,
    $activity: [Int!]
    $pageNo: Int,
    $take: Int,
    $userId: Int!
  ) {
    searchCreator(
      searchCreatorArgs: {
        searchString: $searchString
        activity: $activity
        pageNo: $pageNo
        take: $take
      }
    ) {
      pageInfo {
        totalCount
        pageNo
        hasNextPage
      }
      pageEdges {
        node {
          id
          firstname
          lastname
          profileThumbnail
          backgroundImageThumbnail
          user {
            isFollowing(userId: $userId)
            backgroundVideoData
            dwollaCustomer {
              status
            }
          }
        }
      }
    }
  }
`;
export const useSearchCreators = (variables) => {
    return Object.assign(Object.assign({}, useQuery(SEARCH_CREATORS, {
        variables: variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
    })), { query: SEARCH_CREATORS });
};
