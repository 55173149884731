import { IconButton, styled } from '@mui/material';
export const SliderArrowButton = styled(IconButton)(({ theme }) => ({
    '&.slick-prev': {
        [theme.breakpoints.down('xl')]: {
            left: '-0.75rem'
        },
        [theme.breakpoints.down('md')]: {
            left: 'inherit',
            right: '4.375rem'
        },
    },
    '&.slick-next': {
        [theme.breakpoints.down('xl')]: {
            right: '-0.75rem'
        },
        [theme.breakpoints.down('md')]: {
            right: '0'
        },
    },
    '&.slick-arrow': {
        width: '2.5rem',
        height: '2.5rem',
        background: `${theme.dynamicTheme.mainSection.bookmarkIconBackground.backgroundColor} !important`,
        color: `${theme.dynamicTheme.mainSection.bookmarkIconBackground.color} !important`,
        borderRadius: '50%',
        display: 'flex !important',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        zIndex: '1',
        '&.slider-icon': {
            width: ' 100%',
            height: ' 100%',
            transform: ' scale(.8)',
        },
        '&:before': {
            display: 'none',
        }
    },
    '&:hover, &:focus, &:active': {
        background: `${theme.dynamicTheme.mainSection.bookmarkIconBackground.backgroundColor} !important`,
        color: `${theme.dynamicTheme.mainSection.bookmarkIconBackground.color} !important`,
    },
    '&.slick-disabled': {
        opacity: '0.4',
        cursor: 'default'
    },
}));
