import React, { useState } from 'react';
import { NftCard } from 'common/components';
import { useMediaQuery, useTheme } from '@mui/material';
import { getPdpUrl } from 'helpers/utils';
import { useNavigate } from 'react-router-dom';
export const NftSlideCard = React.memo(function (StackedCarouselSlideProps) {
    var _a, _b, _c, _d, _e;
    const theme = useTheme();
    const isTab = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const router = useRouter();
    const navigate = useNavigate();
    const [isTapped, setIsTapped] = useState(true);
    const { id, data, dataIndex, isCenterSlide, swipeTo, slideIndex, query, queryParams, loading, maxVisibleSlide } = StackedCarouselSlideProps;
    const onNftSelected = (nftEdition, type) => () => {
        if (!isCenterSlide || !(nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.id) || slideIndex !== 0)
            return;
        if ((type === 'click' && !isMobile) || (type === 'touch' && isTab && isTapped)) {
            navigate(getPdpUrl(nftEdition));
        }
    };
    // Created a custom Tap event for the IOS specific click issue
    // Issue Ref Link: https://therecruit.atlassian.net/browse/NXM-3824
    const handleTouchEvent = (type) => () => {
        if (type === 'start' && !isTapped) {
            setIsTapped(true);
        }
        if (type === 'move' && isTapped) {
            setIsTapped(false);
        }
    };
    return (<div className="inner-card" draggable={false} onTouchStart={handleTouchEvent('start')} onTouchMove={handleTouchEvent('move')} onTouchEnd={onNftSelected((_a = data === null || data === void 0 ? void 0 : data[dataIndex]) === null || _a === void 0 ? void 0 : _a.node, 'touch')} onClick={() => {
            if (!isCenterSlide) {
                const stepValue = Math.sqrt(slideIndex * slideIndex);
                const maxVisibleStep = Math.floor(maxVisibleSlide / 2);
                if (maxVisibleStep >= stepValue) {
                    // the card is visible
                    swipeTo(slideIndex);
                }
            }
        }}>
      <div className="description">
        <NftCard id={`${id}-card-${(_c = (_b = data === null || data === void 0 ? void 0 : data[dataIndex]) === null || _b === void 0 ? void 0 : _b.node) === null || _c === void 0 ? void 0 : _c.id}`} data={(_d = data === null || data === void 0 ? void 0 : data[dataIndex]) === null || _d === void 0 ? void 0 : _d.node} query={query} queryParams={queryParams} size={(isMobile ? 'small' : (isTab ? 'medium' : 'large'))} isLoading={loading} hideContent={(!isCenterSlide || isTab)} disableClick={!isCenterSlide} onSelect={onNftSelected((_e = data === null || data === void 0 ? void 0 : data[dataIndex]) === null || _e === void 0 ? void 0 : _e.node, 'click')}/>
      </div>
    </div>);
});
NftSlideCard.displayName = 'trending-3d-slider';
