import { createContext, useContext, useState } from 'react';
import React from 'react';
const QRDialogContext = createContext({});
export const useQRDialog = () => useContext(QRDialogContext);
export const QRDialogProvider = ({ children }) => {
    const [title, setTitle] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [userName, setUserName] = useState('');
    const openQRDialog = (dialogTitle = '', username = '') => {
        setUserName(username);
        setIsOpen(true);
        dialogTitle && setTitle(dialogTitle);
    };
    const closeQRDialog = () => {
        setIsOpen(false);
        title && setTitle('');
    };
    return (<QRDialogContext.Provider value={{ title, isOpen, closeQRDialog, openQRDialog, userName }}>
      {children}
    </QRDialogContext.Provider>);
};
