import { colors } from 'ui/foundations';
const MuiFormLabel = {
    styleOverrides: {
        root: {
            color: colors.white,
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: '1rem',
            transform: 'none',
            '&.Mui-focused': {
                color: colors.white,
            },
            '&.Mui-error': {
                color: colors.white,
            },
        },
        asterisk: {
            '&.Mui-error': {
                color: colors.white,
            },
        }
    },
    variants: [],
    defaultProps: {}
};
export default MuiFormLabel;
