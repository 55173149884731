import { alpha, Box, Card, styled } from '@mui/material';
export const TopTradersCardBox = styled(Box)(({ theme }) => ({
    '& .nx-traders-list-item': {
        cursor: 'pointer',
        padding: `${theme.spacing(1)}`,
        '&:hover': {
            backgroundColor: alpha(`${theme.dynamicTheme.sidebarNavigation.link.colorHover}`, .6),
            borderRadius: '0.5rem',
        },
        '& .nx-trade-info': {
            '.nx-trader-id': {
                color: theme.colors.neutral500,
                fontSize: '0.75rem',
                marginTop: `${theme.spacing(-0.375)}`,
            },
            '.nx-trader-name a': {
                color: `${theme.colors.white} !important`
            }
        },
    },
}));
export const TopTraderLoaderBox = styled(Card)(() => ({
    paddingTop: '1rem',
    paddingBottom: '1rem',
    width: '100%',
    height: '16.5rem',
}));
