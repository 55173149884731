import { createContext, useContext, useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { useDeferredPromise } from 'helpers/hooks';
import { PAGE_ROUTES } from 'helpers/constants';
import { AuthDialog } from './AuthDialog';
import { dynamicPathReplacer } from 'helpers/utils';
import { useLocation, useSearchParams } from 'react-router-dom';
import React from 'react';
// For static, Add isPrivateRoute: true in the layoutOptions of that Page
// Add only private dynamic routes in below array
const privateRoutes = [PAGE_ROUTES.USERS__BOOKMARKED_PEOPLE];
const AuthDialogContext = createContext({});
export const useAuthDialog = () => useContext(AuthDialogContext);
export const AuthDialogProvider = ({ children, isPrivateRoute: layoutPrivateRoute }) => {
    const [mode, setMode] = useState('LOGIN');
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState({});
    const { defer, deferRef } = useDeferredPromise();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const queryObj = Object.fromEntries([...searchParams]);
    const openAuthDialog = (options) => {
        if (options)
            setOptions(options);
        setIsOpen(true);
        return defer().promise;
    };
    const closeAuthDialog = (user) => {
        setOptions({});
        setIsOpen(false);
        deferRef === null || deferRef === void 0 ? void 0 : deferRef.resolve({
            success: true,
            user: user
        });
    };
    useEffect(() => {
        const isLoggedin = Boolean(Cookie.get('token') && Cookie.get('user'));
        if (!isLoggedin) {
            let isPrivateRoute = (layoutPrivateRoute === true);
            if (!isPrivateRoute && layoutPrivateRoute === 'dynamic-route') {
                // Check if current path matches in the privateRoutes array
                // This is just for DYNAMIC PRIVATE ROUTE
                const exactPath = dynamicPathReplacer(location.pathname, (queryObj || {}));
                isPrivateRoute = privateRoutes.some((path) => exactPath.includes(path));
            }
            if (isPrivateRoute) {
                openAuthDialog({ redirectToDashboard: true, isInitialModal: true });
            }
            else if (isOpen) {
                closeAuthDialog();
            }
        }
        return () => {
            if (isOpen)
                closeAuthDialog();
        };
    }, [location.pathname]);
    const rejectAuthDialog = () => {
        setOptions({});
        setIsOpen(false);
        deferRef === null || deferRef === void 0 ? void 0 : deferRef.resolve({ success: false });
    };
    return (<AuthDialogContext.Provider value={{
            isOpen,
            options,
            mode,
            closeAuthDialog,
            rejectAuthDialog,
            openAuthDialog,
            setMode
        }}>
      {children}
      <AuthDialog />
    </AuthDialogContext.Provider>);
};
