import { t } from 'i18next';
import { formatDate } from './formatDate';
export const timeSince = (date, dateOptions = {}) => {
    const current = new Date().getTime();
    const tnxDate = new Date(date).getTime();
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const timeDiff = current - tnxDate;
    if (timeDiff < msPerHour) {
        const minsAgo = Math.round(timeDiff / msPerMinute);
        return (minsAgo <= 1) ? t('1 min ago') : t('mins ago', { minsAgo: minsAgo });
    }
    if (timeDiff < msPerDay) {
        const hoursAgo = Math.round(timeDiff / msPerHour);
        return (hoursAgo <= 1) ? t('1 hour ago') : t('hours ago', { hoursAgo: hoursAgo });
    }
    if (timeDiff < msPerMonth) {
        const daysAgo = Math.round(timeDiff / msPerDay);
        if (daysAgo <= 7) {
            return (daysAgo <= 1) ? t('1 day ago') : t('days ago', { daysAgo: daysAgo });
        }
    }
    return formatDate(date, dateOptions);
};
