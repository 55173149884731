import { gql, useMutation } from '@apollo/client';
export const LOGIN = gql `
  mutation login(
    $loginInput:LoginInput
  ) {
    login(
      loginInput: $loginInput
    ) {
      accessToken
      userId
      refreshToken
    }
  }
`;
export const AUTO_LOGIN = gql `
  mutation autoLogin(
    $uniqueKey: String!
  ) {
    autoLogin(
      uniqueKey: $uniqueKey
    ) {
      accessToken
      userId
      refreshToken
    }
  }
`;
export const useLoginMutation = () => {
    const [loginMutation] = useMutation(LOGIN, {
        notifyOnNetworkStatusChange: true,
    });
    const [autoLogin] = useMutation(AUTO_LOGIN, {
        notifyOnNetworkStatusChange: true,
    });
    const doAutoLogin = (variables) => {
        return autoLogin({
            variables: Object.assign({}, variables)
        });
    };
    return { loginMutation, doAutoLogin };
};
