var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { NftCard } from 'common/components';
import { IconButton, styled, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ChevronLeftOutlineIcon, ChevronRightOutlineIcon } from 'ui/icons';
const MultipleItemSlideBox = styled(Box)(({ theme, hasMore }) => ({
    '.slick-slider': {
        ':before': hasMore ? {
            position: 'absolute',
            content: '""',
            right: '0',
            top: '0',
            zIndex: 1,
            width: '9.875rem',
            height: 'calc(100% - 12rem)',
            background: 'linear-gradient(0.25turn, rgba(0, 0, 0, 0), rgba(0, 0, 0, 100))',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            }
        } : {},
        '.slick-slide': {
            paddingRight: theme.spacing(2),
        },
        '.slick-list': {
            padding: '0 !important',
            '.slick-track': {
                margin: '0'
            }
        },
    },
}));
const ArrowButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    position: 'absolute',
    top: 290,
    zIndex: 2,
    '&:hover': {
        backgroundColor: theme.colors.neutral075,
    },
    '&.Mui-disabled': {
        backgroundColor: theme.colors.white,
    }
}));
const NextArrow = (_a) => {
    var props = __rest(_a, []);
    const { onClick, currentSlide, slideCount } = props;
    const isDisabled = currentSlide === slideCount - 3;
    return <ArrowButton onClick={onClick} sx={{ right: 16 }} disableRipple disabled={isDisabled}>
    <ChevronRightOutlineIcon />
  </ArrowButton>;
};
const PrevArrow = (_a) => {
    var props = __rest(_a, []);
    const { onClick, currentSlide } = props;
    const isDisabled = currentSlide === 0;
    return <ArrowButton onClick={onClick} sx={{ right: 85 }} disableRipple disabled={isDisabled}>
    <ChevronLeftOutlineIcon />
  </ArrowButton>;
};
export const MultipleItemSlider = ({ id, topPicksNfts, query, queryParams, isLoading = false }) => {
    // This will use in future
    // const [activeIndex, setActiveIndex] = useState<number>(0);
    const theme = useTheme();
    const isTab = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const slidesToShow = isMobile ? 1 : isTab ? 2 : 3;
    const settings = {
        infinite: false,
        slidesToShow: slidesToShow,
        speed: 500,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        swipe: isMobile,
        arrows: !isMobile,
        waitForAnimate: true,
        centerMode: isMobile ? true : false,
        // This will use in future
        // afterChange: (index) => {
        //   setActiveIndex(index);
        // },
        // variableWidth: true
    };
    return (<MultipleItemSlideBox hasMore={!!(topPicksNfts && (topPicksNfts || []).length > slidesToShow)}>
      <Slider {...settings}>
        {(topPicksNfts || []).map((topPickNft, i) => {
            var _a;
            return (<Box key={i}>
              <NftCard id={`${id}-card-${(_a = topPickNft === null || topPickNft === void 0 ? void 0 : topPickNft.node) === null || _a === void 0 ? void 0 : _a.id}`} isLoading={isLoading} data={topPickNft === null || topPickNft === void 0 ? void 0 : topPickNft.node} query={query} queryParams={queryParams} size={isMobile ? 'medium' : isTab ? 'medium' : 'large'}/>
            </Box>);
        })}
      </Slider>
    </MultipleItemSlideBox>);
};
