import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { FullSizeLoader } from './FullSizeLoader';
const LoaderContext = createContext({});
export const useLoader = () => useContext(LoaderContext);
export const FullSizeLoaderProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const openLoader = useCallback(() => { setIsLoading(true); }, []);
    const closeLoader = useCallback(() => { setIsLoading(false); }, []);
    useEffect(() => {
        // Router.events.on('routeChangeStart', openLoader);
        // Router.events.on('routeChangeComplete', closeLoader);
        // Router.events.on('routeChangeError', closeLoader);
    }, []);
    return (<LoaderContext.Provider value={{ isLoading, openLoader, closeLoader }}>
      {children}
      {isLoading && <FullSizeLoader />}
    </LoaderContext.Provider>);
};
