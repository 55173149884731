import { Link } from '@mui/material';
import { OrderTnxType } from 'types';
import { DwollaTnxStatus, DwollaTnxType } from 'types/graphql.models';
const getAddFundTxnText = (status) => {
    switch (status) {
        case DwollaTnxStatus.Completed:
            return 'Money added to wallet';
        case DwollaTnxStatus.Processing:
            return 'Add fund request sent.';
        default:
            return 'Transaction failed to adding funds';
    }
};
const getPaidTxnText = ({ qty, nftName, nftId, status }) => {
    const editionText = qty > 1 ? 'editions' : 'edition';
    switch (status) {
        case DwollaTnxStatus.Completed:
            return <>Purchased {qty} {editionText} of <Link href={`/pdp/${nftId}`}>{nftName}</Link></>;
        case DwollaTnxStatus.Processing:
            return <>Processed to purchase {qty} {editionText} of <Link href={`/pdp/${nftId}`}>{nftName}</Link></>;
        default:
            return <>Failed to purchase {qty} {editionText} of <Link href={`/pdp/${nftId}`}>{nftName}</Link></>;
    }
};
const getReceivedTxnText = ({ qty, nftName, nftId }) => {
    const editionText = qty > 1 ? 'editions' : 'edition';
    return <>Sold {qty} {editionText} of <Link href={`/pdp/${nftId}`}>{nftName}</Link></>;
};
const getWithdrawFundTxnText = (name, status) => {
    switch (status) {
        case DwollaTnxStatus.Completed:
            return `Withdraw to your ${name}`;
        case DwollaTnxStatus.Processing:
            return 'Withdraw request sent.';
        default:
            return `Failed to withdraw in your ${name}`;
    }
};
const getRoyaltyCreditTxnText = ({ qty, nftName, nftId }) => {
    const editionText = `edition${(qty > 1 ? 's' : '')}`;
    return (<>
      Royalty received for {qty} {editionText} of <Link href={`/pdp/${nftId}`}>{nftName}</Link>
    </>);
};
export const getTextByTransactionStatusAndType = (status, type, transactionData) => {
    if (type === DwollaTnxType.FundAdded) {
        return getAddFundTxnText(status);
    }
    else if (type === DwollaTnxType.Purchased) {
        const { qty, nftName, nftId } = transactionData;
        return getPaidTxnText({ qty, nftName, nftId, status });
    }
    else if (type === DwollaTnxType.Sold) {
        const { qty, nftName, nftId } = transactionData;
        return getReceivedTxnText({ qty, nftName, nftId });
    }
    else if (type === DwollaTnxType.FundWithdrawn) {
        const { name } = transactionData;
        return getWithdrawFundTxnText(name, status);
    }
    else if (type === OrderTnxType.CreatorRoyalty) {
        const { qty, nftName, nftId } = transactionData;
        return getRoyaltyCreditTxnText({ qty, nftName, nftId });
    }
};
