import { Box, styled, Typography } from '@mui/material';
export const SnackbarTittle = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    'svg': {
        width: 24,
        height: 24,
        marginRight: 8,
    },
    '.close-btn': {
        padding: 0,
        'svg': {
            marginRight: 0,
            color: theme.colors.white,
        },
    }
}));
export const SnackbarContent = styled(Box)(({ theme }) => ({
    margin: `${theme.spacing(.5)} ${theme.spacing(0)} ${theme.spacing(.5)} ${theme.spacing(4)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
        display: 'block',
    }
}));
export const SnackbarAction = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.MuiButton-root': {
        height: '2rem',
        padding: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1.5),
    }
}));
export const SnackbarText = styled(Typography)(({ maxHeight, lineClamp }) => ({
    maxHeight: maxHeight,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: lineClamp,
    WebkitBoxOrient: 'vertical',
}));
export const MultipleNotificationContentBox = styled(Box)(({ theme }) => ({
    '.multiple-notification-box': {
        padding: 16,
        width: '100%',
        margin: '0 !important',
        '.snackbar-action': {
            justifyContent: 'flex-end',
            '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                ':not(:last-child)': {
                    marginRight: 8,
                }
            }
        },
    },
    '.notification-success, .notification-bar-success div, .clear-text-success, .clear-icon-success': {
        backgroundColor: theme.colors.success500,
        '.snackbar-action': {
            '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                backgroundColor: theme.colors.successButton,
            }
        }
    },
    '.notification-error, .notification-bar-error div, .clear-text-error, .clear-icon-error': {
        backgroundColor: theme.colors.error500,
        '.snackbar-action': {
            '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                backgroundColor: theme.colors.errorButton,
            }
        }
    },
    '.notification-warning, .notification-bar-warning div, .clear-text-warning, .clear-icon-warning': {
        backgroundColor: theme.colors.warning500,
        '.snackbar-action': {
            '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                backgroundColor: theme.colors.warningButton,
            }
        }
    },
    '.notification-default, .notification-bar-default div, .clear-text-default, .clear-icon-default': {
        backgroundColor: theme.colors.blue,
        '.snackbar-action': {
            '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                backgroundColor: theme.colors.infoButton,
            }
        }
    },
}));
export const CloseAllNotificationButton = styled(Box)(({ theme }) => ({
    marginBottom: 8,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-end',
    '.close-div': {
        cursor: 'pointer',
        minHeight: 24,
        '.clear-icon': {
            width: 24,
            height: 24,
            transition: 'linear 0.4s',
            borderRadius: '50%',
            position: 'absolute',
            right: 0,
        },
        '.clear-text': {
            padding: `${theme.spacing(.26)} ${theme.spacing(1.5)}`,
            transition: 'linear 0.4s',
            borderRadius: 12,
            position: 'absolute',
            right: -75,
        },
        '&:hover': {
            '.clear-icon': {
                right: -25,
            },
            '.clear-text': {
                right: 0,
            },
        },
    },
}));
