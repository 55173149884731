import { Box, Button, Card, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { forwardRef, useEffect, useState } from 'react';
import { ExclamationCircleOutlineIcon, InfoOutlineIcon, SuccessCircleOutlineIcon, WarningIcon, XOutlineIcon } from 'ui/icons';
import { PAGE_ROUTES } from 'helpers/constants';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { NftCardMediaShadow } from 'common/components';
import { NotificationSubtype } from '../notification-types';
import { SnackbarAction, SnackbarTittle, SnackbarContent, SnackbarText, MultipleNotificationContentBox, CloseAllNotificationButton } from '../Notification.styled';
import { useTranslation } from 'react-i18next';
let notificationCounts = 0;
const MultipleNotificationContent = forwardRef((props, ref) => {
    const { id, nftId, title, body, variant, subType } = props;
    const [showShadow, setShowShadow] = useState(notificationCounts > 1);
    const { closeSnackbar, } = useSnackbar();
    // const router = useRouter();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const queryObj = Object.fromEntries([...searchParams]);
    // const queryObj = (router?.query || {});
    const { t } = useTranslation();
    useEffect(() => {
        notificationCounts += 1;
        setShowShadow(notificationCounts > 1);
        return () => {
            if (notificationCounts > 0) {
                notificationCounts -= 1;
                setShowShadow(notificationCounts > 1);
            }
        };
    }, []);
    const handleAllClose = () => {
        closeSnackbar();
    };
    const handleClose = () => {
        closeSnackbar(id);
    };
    const handleViewClick = () => {
        switch (subType) {
            case NotificationSubtype.nftTransferFailed:
            case NotificationSubtype.nftListFailed:
                navigate(PAGE_ROUTES.MY_NFT__DRAFT);
                break;
            case NotificationSubtype.nftPurchased:
                // TODO: Add Purchase success drawer once notification center merged, can we redirect to my-nft/purchased?
                // navigate(`${PAGE_ROUTES.PDP}/${notification.nftId}`);
                navigate(PAGE_ROUTES.MY_NFT__PURCHASED);
                break;
            case NotificationSubtype.nftSold:
                navigate(PAGE_ROUTES.MY_NFT__SOLD);
                break;
            case NotificationSubtype.nftMinted:
            case NotificationSubtype.nftListed:
                navigate(`${PAGE_ROUTES.PDP}/${nftId}`);
                break;
            case NotificationSubtype.bankAdded:
            case NotificationSubtype.bankVerified:
            case NotificationSubtype.bankUnverified:
                navigate(`${PAGE_ROUTES.TRANSACTIONS__WALLET}?accupdate=true`);
                break;
            case NotificationSubtype.fundAddedCompleted:
            case NotificationSubtype.fundWithdrawCompleted:
            case NotificationSubtype.fundAddedFailed:
            case NotificationSubtype.fundWithdrawFailed:
            case NotificationSubtype.paymentDebited:
            case NotificationSubtype.paymentCredited:
            case NotificationSubtype.dwollaPaymentFailed:
            case NotificationSubtype.royaltyReceived:
                navigate(PAGE_ROUTES.TRANSACTIONS__WALLET);
                break;
            case NotificationSubtype.walletVerified:
            case NotificationSubtype.walletSuspended:
            case NotificationSubtype.walletDeactivated:
            case NotificationSubtype.walletReActivated:
            case NotificationSubtype.walletMoreDetailsRequired:
            case NotificationSubtype.walletDocumentFailed:
                navigate({
                    pathname: '/',
                    search: `?${createSearchParams(Object.assign(Object.assign({}, queryObj), { openwallet: 'true' }))}`
                });
                break;
        }
        closeSnackbar(id);
    };
    const getSnackbarIcon = (variant) => {
        let returnSnackbarIcon = null;
        switch (variant) {
            case 'success':
                returnSnackbarIcon = <SuccessCircleOutlineIcon />;
                break;
            case 'warning':
                returnSnackbarIcon = <WarningIcon />;
                break;
            case 'error':
                returnSnackbarIcon = <ExclamationCircleOutlineIcon />;
                break;
            default:
                returnSnackbarIcon = <InfoOutlineIcon />;
                break;
        }
        return returnSnackbarIcon;
    };
    return (<>
      <MultipleNotificationContentBox ref={ref}>
        <CloseAllNotificationButton onClick={handleAllClose}>
          <Box className="close-div">
            <Box className={`clear-icon clear-icon-${variant}`}><XOutlineIcon fontSize="small"/></Box>
            <Typography variant="h6" fontWeight="500" fontSize={12} className={`clear-text clear-text-${variant}`}>Clear all</Typography>
          </Box>
        </CloseAllNotificationButton>
        <Card className={`multiple-notification-box notification-${variant}`}>
          <SnackbarTittle>
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center">{getSnackbarIcon(variant)}</Box>
              <SnackbarText maxHeight={25} lineClamp={1} variant="h4" fontWeight="500">{title}</SnackbarText>
            </Box>
          </SnackbarTittle>
          <SnackbarContent>
            <SnackbarText maxHeight={68} lineClamp={3} variant="h6" fontWeight="500" pr={2}>{body}</SnackbarText>
            <SnackbarAction className="snackbar-action">
              <Button id="close-button" color="primary" size="small" onClick={handleClose}>
                {t('Close')}
              </Button>
              <Button id="view-button" color="primary" size="small" onClick={handleViewClick}>
                {
        // tslint-disable-next-line
        t('view')}
              </Button>
            </SnackbarAction>
          </SnackbarContent>

        </Card>
      </MultipleNotificationContentBox>
      <NftCardMediaShadow visible={showShadow} className={`notification-bar ${variant}`}/>
    </>);
});
MultipleNotificationContent.displayName = 'Multiple-Notification';
export default MultipleNotificationContent;
