import { IconButton, Typography } from '@mui/material';
import { Avatar } from 'common/components';
import { useTranslation } from 'react-i18next';
import { NftRoyaltyBlackCard } from 'styles/global.styled';
import { DwollaCustomerStatus } from 'types';
import { XOutlineIcon } from 'ui/icons';
import { NftSplitUserWrapper, RoyaltyTag } from './NftSplitUserCard.styled';
import React from 'react';
export const NftSplitUserCard = (props) => {
    var _a;
    const { user, royaltyFee, isRemoveVisible, ownerTag, showRoyaltyTag, activeMarginRight, disabledMarginBottom, activeBorder, onRemoveUser } = props;
    const { t } = useTranslation();
    const renderRoyalty = () => {
        if (royaltyFee) {
            if (showRoyaltyTag)
                return (<RoyaltyTag reSaleDataTag={showRoyaltyTag}>{royaltyFee}</RoyaltyTag>);
            return (<Typography variant="h6" fontWeight="500" fontSize={12}>{royaltyFee}</Typography>);
        }
        return null;
    };
    return (<NftRoyaltyBlackCard disabledMB={disabledMarginBottom} activeMR={activeMarginRight} activeBorder={activeBorder}>
			<NftSplitUserWrapper>
				<Avatar size="xsmall" verifiedUser={(((_a = user === null || user === void 0 ? void 0 : user.dwollaCustomer) === null || _a === void 0 ? void 0 : _a.status) === DwollaCustomerStatus.Verified ? 'normal' : null)} src={((user === null || user === void 0 ? void 0 : user.profileImage) || '')} name={`${((user === null || user === void 0 ? void 0 : user.firstname) || '')} ${((user === null || user === void 0 ? void 0 : user.lastname) || '')}`}/>

				{isRemoveVisible && (<IconButton onClick={onRemoveUser} className="cancel-button">
						<XOutlineIcon className="fill-color"/>
					</IconButton>)}

				{ownerTag && (<RoyaltyTag>{t('Owner')}</RoyaltyTag>)}

				{renderRoyalty()}
			</NftSplitUserWrapper>
		</NftRoyaltyBlackCard>);
};
