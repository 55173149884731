import { DwollaTypes } from 'providers/Dwolla/dwolla-types';
export const toggleDrawer = (open = false) => {
    return {
        type: DwollaTypes.SWITCH_WALLET_DRAWER_OPEN_STATE,
        payload: open
    };
};
export const toggleDialog = (open = false) => {
    return {
        type: DwollaTypes.SWITCH_DWOLLA_DIALOG_OPEN_STATE,
        payload: open
    };
};
export const setDrawerContent = (currentDrawerContentState) => {
    return {
        type: DwollaTypes.SWITCH_CURRENT_DRAWER_CONTENT_STATE,
        payload: currentDrawerContentState
    };
};
export const setDefaultDrawerContent = (defaultDrawerContentState) => {
    return {
        type: DwollaTypes.SWITCH_DEFAULT_DRAWER_CONTENT_STATE,
        payload: defaultDrawerContentState
    };
};
export const setDialogContent = (currentDialogContentState) => {
    return {
        type: DwollaTypes.SWITCH_CURRENT_DWOLLA_DIALOG_CONTENT_STATE,
        payload: currentDialogContentState
    };
};
export const setSetupWalletStep = (step) => {
    return {
        type: DwollaTypes.UPDATE_DWOLLA_CREATE_WALLET_STEP,
        payload: step
    };
};
export const setAddFundAmount = (amount) => {
    return {
        type: DwollaTypes.UPDATE_ADD_FUND_AMOUNT,
        payload: amount
    };
};
export const setWithdrawFundAmount = (amount) => {
    return {
        type: DwollaTypes.UPDATE_WITHDRAW_FUND_AMOUNT,
        payload: amount
    };
};
export const setBankList = (list) => {
    return {
        type: DwollaTypes.UPDATE_BANK_LIST,
        payload: list
    };
};
export const setCustomerBalance = (balance) => {
    return {
        type: DwollaTypes.GET_DWOLLA_CUSTOMER_BALANCE,
        payload: balance
    };
};
export const setCustomerData = (data) => {
    return {
        type: DwollaTypes.UPDATE_DWOLLA_CUSTOMER_DATA,
        payload: data
    };
};
export const setDialogPrevState = (data) => {
    return {
        type: DwollaTypes.UPDATE_DIALOG_PREV_STATE,
        payload: data
    };
};
export const setRecentlyAddedBank = (recentlyAddedBank) => {
    return {
        type: DwollaTypes.UPDATE_RECENTLY_ADDED_BANK,
        payload: recentlyAddedBank
    };
};
export const setDueAmount = (amount) => {
    return {
        type: DwollaTypes.UPDATE_DUE_AMOUNT,
        payload: amount
    };
};
