import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'ui/icons';
import React from 'react';
export const NoImgBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: '0.5rem',
    padding: theme.spacing(1),
    height: '100%',
    '&.creator-preview': {
        background: 'linear-gradient(180deg, rgba(52,91,255,1) 0%, rgba(20,24,38,1) 100%)',
        '.logo-icon': {
            transform: 'scale(6)',
            opacity: '.05',
        }
    },
    '&.no-image-preview': {
        backgroundColor: theme.colors.blueDark,
    },
    '.no-img-text': {
        color: theme.colors.neutral550,
        textTransform: 'uppercase',
        fontWeight: '600',
        fontSize: '.75rem',
        textAlign: 'center',
        width: '50%',
    }
}));
export const NoImagePreview = ({ text, variant = 'default' }) => {
    const { t } = useTranslation();
    if (variant === 'creator') {
        return (<NoImgBox className="creator-preview">
        <LogoIcon className="logo-icon"/>
      </NoImgBox>);
    }
    return (<NoImgBox className="no-image-preview">
      <Typography variant="body2" className="no-img-text">
        {(text || t('No Preview Available'))}
      </Typography>
    </NoImgBox>);
};
