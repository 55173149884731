import { gql, useMutation } from '@apollo/client';
const VERIFY_CONFIRM_EMAIL = gql `
  mutation confirmEmail(
    $emailConfirmToken: String!
    ) {
    confirmEmail(emailConfirmToken: $emailConfirmToken) {
      uniqueKey
    }
  }
`;
export const useConfirmEmailToken = () => {
    const [confirmEmailToken] = useMutation(VERIFY_CONFIRM_EMAIL, {
        notifyOnNetworkStatusChange: true,
    });
    const verifyConfirmEmail = (variables) => {
        return confirmEmailToken({
            variables: Object.assign({}, variables)
        });
    };
    return { verifyConfirmEmail };
};
