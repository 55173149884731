/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export var ActivityType;
(function (ActivityType) {
    ActivityType["Listed"] = "Listed";
    ActivityType["Minted"] = "Minted";
    ActivityType["Purchase"] = "Purchase";
    ActivityType["SecondarySaleListed"] = "SecondarySaleListed";
    ActivityType["SecondarySaleRemoved"] = "SecondarySaleRemoved";
    ActivityType["SecondarySaleUpdated"] = "SecondarySaleUpdated";
})(ActivityType || (ActivityType = {}));
export var AttributeValuePicker;
(function (AttributeValuePicker) {
    AttributeValuePicker["Count"] = "Count";
    AttributeValuePicker["Distance"] = "Distance";
    AttributeValuePicker["List"] = "List";
    AttributeValuePicker["Percent"] = "Percent";
    AttributeValuePicker["Speed"] = "Speed";
    AttributeValuePicker["Time"] = "Time";
    AttributeValuePicker["Weight"] = "Weight";
})(AttributeValuePicker || (AttributeValuePicker = {}));
export var AttributeValueType;
(function (AttributeValueType) {
    AttributeValueType["Input"] = "Input";
    AttributeValueType["List"] = "List";
    AttributeValueType["Picker"] = "Picker";
})(AttributeValueType || (AttributeValueType = {}));
export var BlockType;
(function (BlockType) {
    BlockType["Block"] = "Block";
    BlockType["Report"] = "Report";
})(BlockType || (BlockType = {}));
export var BookmarkType;
(function (BookmarkType) {
    BookmarkType["Edition"] = "Edition";
    BookmarkType["Institution"] = "Institution";
    BookmarkType["Item"] = "Item";
    BookmarkType["Nft"] = "Nft";
    BookmarkType["User"] = "User";
})(BookmarkType || (BookmarkType = {}));
export var CategoryDomain;
(function (CategoryDomain) {
    CategoryDomain["Amazon"] = "Amazon";
    CategoryDomain["AmazonMusic"] = "AmazonMusic";
    CategoryDomain["AppleMusic"] = "AppleMusic";
    CategoryDomain["ApplePay"] = "ApplePay";
    CategoryDomain["ApplePodcasts"] = "ApplePodcasts";
    CategoryDomain["Audiomack"] = "Audiomack";
    CategoryDomain["Bandcamp"] = "Bandcamp";
    CategoryDomain["Cameo"] = "Cameo";
    CategoryDomain["CashApp"] = "CashApp";
    CategoryDomain["Clubhouse"] = "Clubhouse";
    CategoryDomain["DailyMotion"] = "DailyMotion";
    CategoryDomain["Deezer"] = "Deezer";
    CategoryDomain["Discord"] = "Discord";
    CategoryDomain["Etsy"] = "Etsy";
    CategoryDomain["Facebook"] = "Facebook";
    CategoryDomain["FacebookWatch"] = "FacebookWatch";
    CategoryDomain["FansMeetIdols"] = "FansMeetIdols";
    CategoryDomain["GooglePay"] = "GooglePay";
    CategoryDomain["Hudl"] = "Hudl";
    CategoryDomain["Instagram"] = "Instagram";
    CategoryDomain["LinkedIn"] = "LinkedIn";
    CategoryDomain["Mixcloud"] = "Mixcloud";
    CategoryDomain["Myspace"] = "Myspace";
    CategoryDomain["Opendorse"] = "Opendorse";
    CategoryDomain["Pandora"] = "Pandora";
    CategoryDomain["Patreon"] = "Patreon";
    CategoryDomain["Paypal"] = "Paypal";
    CategoryDomain["Pinterest"] = "Pinterest";
    CategoryDomain["PlayerFm"] = "PlayerFM";
    CategoryDomain["Podcast"] = "Podcast";
    CategoryDomain["Reddit"] = "Reddit";
    CategoryDomain["Shopify"] = "Shopify";
    CategoryDomain["Snapchat"] = "Snapchat";
    CategoryDomain["Soundcloud"] = "Soundcloud";
    CategoryDomain["Spotify"] = "Spotify";
    CategoryDomain["SquareCash"] = "SquareCash";
    CategoryDomain["Stitcher"] = "Stitcher";
    CategoryDomain["Strava"] = "Strava";
    CategoryDomain["Tidal"] = "Tidal";
    CategoryDomain["TikTok"] = "TikTok";
    CategoryDomain["Twitch"] = "Twitch";
    CategoryDomain["Twitter"] = "Twitter";
    CategoryDomain["Vsco"] = "VSCO";
    CategoryDomain["Venmo"] = "Venmo";
    CategoryDomain["Vevo"] = "Vevo";
    CategoryDomain["VideosHub"] = "VideosHub";
    CategoryDomain["Vimeo"] = "Vimeo";
    CategoryDomain["VimeoMusic"] = "VimeoMusic";
    CategoryDomain["YouTube"] = "YouTube";
    CategoryDomain["YouTubeMusic"] = "YouTubeMusic";
    CategoryDomain["Youtube"] = "Youtube";
    CategoryDomain["Zelle"] = "Zelle";
    CategoryDomain["ITunes"] = "iTunes";
    CategoryDomain["On3"] = "on3";
})(CategoryDomain || (CategoryDomain = {}));
export var CategoryType;
(function (CategoryType) {
    CategoryType["Ads"] = "Ads";
    CategoryType["CollectMoney"] = "CollectMoney";
    CategoryType["Endorsements"] = "Endorsements";
    CategoryType["EverythingElse"] = "EverythingElse";
    CategoryType["GrowYourFollowing"] = "GrowYourFollowing";
    CategoryType["MusicChannel"] = "MusicChannel";
    CategoryType["MyNews"] = "MyNews";
    CategoryType["MyWebsite"] = "MyWebsite";
    CategoryType["NxlvlVideos"] = "NxlvlVideos";
    CategoryType["Podcasts"] = "Podcasts";
    CategoryType["SocialNetwork"] = "SocialNetwork";
    CategoryType["Store"] = "Store";
    CategoryType["UniqueProduct"] = "UniqueProduct";
    CategoryType["VideoLinks"] = "VideoLinks";
})(CategoryType || (CategoryType = {}));
export var ConnectionStatus;
(function (ConnectionStatus) {
    ConnectionStatus["Approved"] = "Approved";
    ConnectionStatus["Pending"] = "Pending";
    ConnectionStatus["Rejected"] = "Rejected";
})(ConnectionStatus || (ConnectionStatus = {}));
export var ContentImageExtensions;
(function (ContentImageExtensions) {
    ContentImageExtensions["Gif"] = "gif";
    ContentImageExtensions["Jpeg"] = "jpeg";
    ContentImageExtensions["Jpg"] = "jpg";
    ContentImageExtensions["Png"] = "png";
})(ContentImageExtensions || (ContentImageExtensions = {}));
export var ContentType;
(function (ContentType) {
    ContentType["Comment"] = "Comment";
    ContentType["Content"] = "Content";
    ContentType["UserBio"] = "UserBio";
})(ContentType || (ContentType = {}));
export var DbUnitType;
(function (DbUnitType) {
    DbUnitType["G"] = "g";
    DbUnitType["MeterPerHour"] = "meterPerHour";
    DbUnitType["Mm"] = "mm";
    DbUnitType["Ms"] = "ms";
})(DbUnitType || (DbUnitType = {}));
export var DwollaCustomerOperation;
(function (DwollaCustomerOperation) {
    DwollaCustomerOperation["Insert"] = "Insert";
    DwollaCustomerOperation["Update"] = "Update";
})(DwollaCustomerOperation || (DwollaCustomerOperation = {}));
export var DwollaCustomerStatus;
(function (DwollaCustomerStatus) {
    DwollaCustomerStatus["Deactivated"] = "deactivated";
    DwollaCustomerStatus["Document"] = "document";
    DwollaCustomerStatus["Retry"] = "retry";
    DwollaCustomerStatus["ReverifyingDetails"] = "reverifyingDetails";
    DwollaCustomerStatus["ReviewingDocument"] = "reviewingDocument";
    DwollaCustomerStatus["Suspended"] = "suspended";
    DwollaCustomerStatus["Unverified"] = "unverified";
    DwollaCustomerStatus["Verified"] = "verified";
})(DwollaCustomerStatus || (DwollaCustomerStatus = {}));
export var DwollaTnxDateFilters;
(function (DwollaTnxDateFilters) {
    DwollaTnxDateFilters["CurrentYear"] = "CurrentYear";
    DwollaTnxDateFilters["LastSixMonths"] = "LastSixMonths";
    DwollaTnxDateFilters["LastThirtyDays"] = "LastThirtyDays";
    DwollaTnxDateFilters["LastThreeMonths"] = "LastThreeMonths";
})(DwollaTnxDateFilters || (DwollaTnxDateFilters = {}));
export var DwollaTnxStatus;
(function (DwollaTnxStatus) {
    DwollaTnxStatus["Cancelled"] = "Cancelled";
    DwollaTnxStatus["Completed"] = "Completed";
    DwollaTnxStatus["Failed"] = "Failed";
    DwollaTnxStatus["Processing"] = "Processing";
})(DwollaTnxStatus || (DwollaTnxStatus = {}));
export var DwollaTnxType;
(function (DwollaTnxType) {
    DwollaTnxType["FundAdded"] = "FundAdded";
    DwollaTnxType["FundWithdrawn"] = "FundWithdrawn";
    DwollaTnxType["Purchased"] = "Purchased";
    DwollaTnxType["Sold"] = "Sold";
})(DwollaTnxType || (DwollaTnxType = {}));
export var EventFilter;
(function (EventFilter) {
    EventFilter["All"] = "All";
    EventFilter["Invites"] = "Invites";
    EventFilter["MyEvents"] = "MyEvents";
    EventFilter["PastEvents"] = "PastEvents";
})(EventFilter || (EventFilter = {}));
export var EventResponse;
(function (EventResponse) {
    EventResponse["Going"] = "Going";
    EventResponse["Maybe"] = "Maybe";
    EventResponse["NotInterested"] = "NotInterested";
})(EventResponse || (EventResponse = {}));
export var EventResponseFilter;
(function (EventResponseFilter) {
    EventResponseFilter["Going"] = "Going";
    EventResponseFilter["Invited"] = "Invited";
    EventResponseFilter["Maybe"] = "Maybe";
    EventResponseFilter["NotInterested"] = "NotInterested";
})(EventResponseFilter || (EventResponseFilter = {}));
export var EventStatus;
(function (EventStatus) {
    EventStatus["Active"] = "Active";
    EventStatus["Past"] = "Past";
})(EventStatus || (EventStatus = {}));
export var EventType;
(function (EventType) {
    EventType["InPerson"] = "InPerson";
    EventType["Online"] = "Online";
})(EventType || (EventType = {}));
export var FirebaseNotificationType;
(function (FirebaseNotificationType) {
    FirebaseNotificationType["Dwolla"] = "Dwolla";
    FirebaseNotificationType["ListedNft"] = "ListedNFT";
    FirebaseNotificationType["MintedNft"] = "MintedNFT";
    FirebaseNotificationType["TransferNft"] = "TransferNFT";
})(FirebaseNotificationType || (FirebaseNotificationType = {}));
export var FlagContentReason;
(function (FlagContentReason) {
    FlagContentReason["BullyingAndHarassment"] = "Bullying_and_Harassment";
    FlagContentReason["Misinformation"] = "Misinformation";
    FlagContentReason["ObjectionableContent"] = "Objectionable_Content";
    FlagContentReason["Other"] = "Other";
    FlagContentReason["PrivacyViolations"] = "Privacy_Violations";
    FlagContentReason["ViolenceAndCriminalBehavior"] = "Violence_and_Criminal_Behavior";
})(FlagContentReason || (FlagContentReason = {}));
export var Gender;
(function (Gender) {
    Gender["Female"] = "Female";
    Gender["Male"] = "Male";
    Gender["Other"] = "Other";
    Gender["PreferNotToSay"] = "Prefer_Not_To_Say";
})(Gender || (Gender = {}));
export var GradeSchool;
(function (GradeSchool) {
    GradeSchool["Eight"] = "Eight";
    GradeSchool["Five"] = "Five";
    GradeSchool["Four"] = "Four";
    GradeSchool["Freshman"] = "Freshman";
    GradeSchool["GraduateStudent"] = "Graduate_Student";
    GradeSchool["Junior"] = "Junior";
    GradeSchool["One"] = "One";
    GradeSchool["PreferNotToSay"] = "Prefer_Not_To_Say";
    GradeSchool["Senior"] = "Senior";
    GradeSchool["Seven"] = "Seven";
    GradeSchool["Six"] = "Six";
    GradeSchool["Sophomore"] = "Sophomore";
    GradeSchool["Three"] = "Three";
    GradeSchool["Two"] = "Two";
})(GradeSchool || (GradeSchool = {}));
export var Hand;
(function (Hand) {
    Hand["Ambidextrous"] = "Ambidextrous";
    Hand["Left"] = "Left";
    Hand["Right"] = "Right";
})(Hand || (Hand = {}));
export var Level;
(function (Level) {
    Level["Coach"] = "Coach";
    Level["College"] = "College";
    Level["HighSchool"] = "HighSchool";
    Level["MiddleSchool"] = "MiddleSchool";
    Level["PreferNotToSay"] = "Prefer_Not_To_Say";
    Level["Professional"] = "Professional";
})(Level || (Level = {}));
export var MarketplaceType;
(function (MarketplaceType) {
    MarketplaceType["Primary"] = "Primary";
    MarketplaceType["Secondary"] = "Secondary";
})(MarketplaceType || (MarketplaceType = {}));
export var MediaType;
(function (MediaType) {
    MediaType["Event"] = "Event";
    MediaType["Image"] = "Image";
    MediaType["Nft"] = "NFT";
    MediaType["News"] = "News";
    MediaType["Text"] = "Text";
    MediaType["Video"] = "Video";
})(MediaType || (MediaType = {}));
export var NftEditionStatus;
(function (NftEditionStatus) {
    NftEditionStatus["Bookmarked"] = "Bookmarked";
    NftEditionStatus["Created"] = "Created";
    NftEditionStatus["Draft"] = "Draft";
    NftEditionStatus["Listed"] = "Listed";
    NftEditionStatus["Purchased"] = "Purchased";
    NftEditionStatus["Sold"] = "Sold";
})(NftEditionStatus || (NftEditionStatus = {}));
export var NftEditionTier;
(function (NftEditionTier) {
    NftEditionTier["Common"] = "Common";
    NftEditionTier["Elite"] = "Elite";
    NftEditionTier["Epic"] = "Epic";
    NftEditionTier["Legendary"] = "Legendary";
    NftEditionTier["Rare"] = "Rare";
})(NftEditionTier || (NftEditionTier = {}));
export var NftSortBy;
(function (NftSortBy) {
    NftSortBy["MostLiked"] = "MostLiked";
    NftSortBy["MostViewed"] = "MostViewed";
    NftSortBy["Newest"] = "Newest";
    NftSortBy["Oldest"] = "Oldest";
    NftSortBy["PriceHighToLow"] = "PriceHighToLow";
    NftSortBy["PriceLowToHigh"] = "PriceLowToHigh";
})(NftSortBy || (NftSortBy = {}));
export var NftStatus;
(function (NftStatus) {
    NftStatus["Draft"] = "Draft";
    NftStatus["Listed"] = "Listed";
    NftStatus["Sold"] = "Sold";
})(NftStatus || (NftStatus = {}));
export var NftType;
(function (NftType) {
    NftType["Image"] = "Image";
    NftType["Video"] = "Video";
})(NftType || (NftType = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["NewFollower"] = "NewFollower";
    NotificationType["ReceiveCommentLike"] = "ReceiveCommentLike";
    NotificationType["ReceiveCommentMention"] = "ReceiveCommentMention";
    NotificationType["ReceiveConnectionApproval"] = "ReceiveConnectionApproval";
    NotificationType["ReceiveConnectionRequest"] = "ReceiveConnectionRequest";
    NotificationType["ReceiveContentComment"] = "ReceiveContentComment";
    NotificationType["ReceiveContentLike"] = "ReceiveContentLike";
    NotificationType["ReceiveContentMention"] = "ReceiveContentMention";
    NotificationType["ReceiveContentRepost"] = "ReceiveContentRepost";
    NotificationType["ReceiveContentTag"] = "ReceiveContentTag";
    NotificationType["ReceiveEventDelete"] = "ReceiveEventDelete";
    NotificationType["ReceiveEventInvitation"] = "ReceiveEventInvitation";
    NotificationType["ReceiveEventResponse"] = "ReceiveEventResponse";
    NotificationType["ReceiveEventUpdate"] = "ReceiveEventUpdate";
    NotificationType["ReceiveManuallyRemoveContentNotification"] = "ReceiveManuallyRemoveContentNotification";
    NotificationType["ReceiveMessage"] = "ReceiveMessage";
    NotificationType["ReceiveRemoveContentNotification"] = "ReceiveRemoveContentNotification";
    NotificationType["ReceiveVideoProcessed"] = "ReceiveVideoProcessed";
    NotificationType["ReceiveViewProfile"] = "ReceiveViewProfile";
})(NotificationType || (NotificationType = {}));
export var OrderDate;
(function (OrderDate) {
    OrderDate["CurrentYear"] = "CurrentYear";
    OrderDate["LastSixMonths"] = "LastSixMonths";
    OrderDate["LastThirtyDays"] = "LastThirtyDays";
    OrderDate["LastThreeMonths"] = "LastThreeMonths";
})(OrderDate || (OrderDate = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["Completed"] = "Completed";
    OrderStatus["Failed"] = "Failed";
    OrderStatus["Processing"] = "Processing";
})(OrderStatus || (OrderStatus = {}));
export var OrderType;
(function (OrderType) {
    OrderType["Purchased"] = "Purchased";
    OrderType["Sold"] = "Sold";
})(OrderType || (OrderType = {}));
export var PaymentType;
(function (PaymentType) {
    PaymentType["CreditCard"] = "CreditCard";
    PaymentType["Dwolla"] = "Dwolla";
})(PaymentType || (PaymentType = {}));
export var PickerType;
(function (PickerType) {
    PickerType["Distance"] = "Distance";
    PickerType["Percent"] = "Percent";
    PickerType["Speed"] = "Speed";
    PickerType["Time"] = "Time";
    PickerType["Weight"] = "Weight";
})(PickerType || (PickerType = {}));
export var PostSortByOptions;
(function (PostSortByOptions) {
    PostSortByOptions["MostCommented"] = "MostCommented";
    PostSortByOptions["MostLiked"] = "MostLiked";
    PostSortByOptions["Relevance"] = "Relevance";
})(PostSortByOptions || (PostSortByOptions = {}));
export var PreviewSettings;
(function (PreviewSettings) {
    PreviewSettings["Accordion"] = "Accordion";
    PreviewSettings["Grid"] = "Grid";
    PreviewSettings["Tile"] = "Tile";
})(PreviewSettings || (PreviewSettings = {}));
export var Reactions;
(function (Reactions) {
    Reactions["Emphasis"] = "Emphasis";
    Reactions["Haha"] = "Haha";
    Reactions["HappyFace"] = "Happy_Face";
    Reactions["Heart"] = "Heart";
    Reactions["Question"] = "Question";
    Reactions["ThumbsUp"] = "Thumbs_Up";
})(Reactions || (Reactions = {}));
export var ReportNftReason;
(function (ReportNftReason) {
    ReportNftReason["BullyingAndHarassment"] = "Bullying_and_Harassment";
    ReportNftReason["Misinformation"] = "Misinformation";
    ReportNftReason["ObjectionableContent"] = "Objectionable_Content";
    ReportNftReason["Other"] = "Other";
    ReportNftReason["PrivacyViolations"] = "Privacy_Violations";
    ReportNftReason["ViolenceAndCriminalBehavior"] = "Violence_and_Criminal_Behavior";
})(ReportNftReason || (ReportNftReason = {}));
export var ReportUserReason;
(function (ReportUserReason) {
    ReportUserReason["FakeProfile"] = "Fake_profile";
    ReportUserReason["InappropriateBehavior"] = "Inappropriate_behavior";
    ReportUserReason["InappropriateProfilePicture"] = "Inappropriate_profile_picture";
    ReportUserReason["InappropriateUsernameBio"] = "Inappropriate_username_bio";
})(ReportUserReason || (ReportUserReason = {}));
export var SearchByDate;
(function (SearchByDate) {
    SearchByDate["Newest"] = "Newest";
    SearchByDate["Oldest"] = "Oldest";
})(SearchByDate || (SearchByDate = {}));
export var SearchContentDatePosted;
(function (SearchContentDatePosted) {
    SearchContentDatePosted["AllTime"] = "AllTime";
    SearchContentDatePosted["LastSixMonths"] = "LastSixMonths";
    SearchContentDatePosted["LastThreeMonths"] = "LastThreeMonths";
    SearchContentDatePosted["LastWeek"] = "LastWeek";
    SearchContentDatePosted["ThisMonth"] = "ThisMonth";
    SearchContentDatePosted["Today"] = "Today";
    SearchContentDatePosted["Yesterday"] = "Yesterday";
})(SearchContentDatePosted || (SearchContentDatePosted = {}));
export var SearchPostDate;
(function (SearchPostDate) {
    SearchPostDate["Newest"] = "Newest";
    SearchPostDate["Oldest"] = "Oldest";
})(SearchPostDate || (SearchPostDate = {}));
export var SearchUserSortBy;
(function (SearchUserSortBy) {
    SearchUserSortBy["MostFollowers"] = "MostFollowers";
    SearchUserSortBy["Relevance"] = "Relevance";
})(SearchUserSortBy || (SearchUserSortBy = {}));
export var SearchUserUserCreated;
(function (SearchUserUserCreated) {
    SearchUserUserCreated["AllTime"] = "AllTime";
    SearchUserUserCreated["LastSixMonths"] = "LastSixMonths";
    SearchUserUserCreated["LastThreeMonths"] = "LastThreeMonths";
    SearchUserUserCreated["ThisMonth"] = "ThisMonth";
    SearchUserUserCreated["Today"] = "Today";
    SearchUserUserCreated["Yesterday"] = "Yesterday";
})(SearchUserUserCreated || (SearchUserUserCreated = {}));
export var SecondaryItemSortBy;
(function (SecondaryItemSortBy) {
    SecondaryItemSortBy["EditionHighToLow"] = "EditionHighToLow";
    SecondaryItemSortBy["EditionLowToHigh"] = "EditionLowToHigh";
    SecondaryItemSortBy["PriceHighToLow"] = "PriceHighToLow";
    SecondaryItemSortBy["PriceLowToHigh"] = "PriceLowToHigh";
})(SecondaryItemSortBy || (SecondaryItemSortBy = {}));
export var SelectionType;
(function (SelectionType) {
    SelectionType["Input"] = "Input";
    SelectionType["List"] = "List";
    SelectionType["Picker"] = "Picker";
})(SelectionType || (SelectionType = {}));
export var SignUpOrLogoutType;
(function (SignUpOrLogoutType) {
    SignUpOrLogoutType["CoreApp"] = "CoreApp";
    SignUpOrLogoutType["Marketplace"] = "Marketplace";
    SignUpOrLogoutType["Nil"] = "NIL";
})(SignUpOrLogoutType || (SignUpOrLogoutType = {}));
export var SocialProvider;
(function (SocialProvider) {
    SocialProvider["Apple"] = "Apple";
    SocialProvider["Facebook"] = "Facebook";
    SocialProvider["Google"] = "Google";
})(SocialProvider || (SocialProvider = {}));
export var Status;
(function (Status) {
    Status["All"] = "All";
    Status["Connected"] = "Connected";
    Status["NonConnected"] = "NonConnected";
})(Status || (Status = {}));
export var UserImageExtensions;
(function (UserImageExtensions) {
    UserImageExtensions["Jpeg"] = "jpeg";
    UserImageExtensions["Jpg"] = "jpg";
    UserImageExtensions["Png"] = "png";
})(UserImageExtensions || (UserImageExtensions = {}));
export var UserImageExtensionsContent;
(function (UserImageExtensionsContent) {
    UserImageExtensionsContent["Jpeg"] = "jpeg";
    UserImageExtensionsContent["Jpg"] = "jpg";
    UserImageExtensionsContent["Png"] = "png";
})(UserImageExtensionsContent || (UserImageExtensionsContent = {}));
export var Visibility;
(function (Visibility) {
    Visibility["Connections"] = "Connections";
    Visibility["Followers"] = "Followers";
    Visibility["OnlyMe"] = "OnlyMe";
    Visibility["Public"] = "Public";
})(Visibility || (Visibility = {}));
export var SectionType;
(function (SectionType) {
    SectionType["ForYou"] = "ForYou";
    SectionType["LatestDrop"] = "LatestDrop";
    SectionType["TopPick"] = "TopPick";
    SectionType["TopSales"] = "TopSales";
    SectionType["Trending"] = "Trending";
})(SectionType || (SectionType = {}));
export var SortByActivityType;
(function (SortByActivityType) {
    SortByActivityType["Newest"] = "Newest";
    SortByActivityType["Oldest"] = "Oldest";
})(SortByActivityType || (SortByActivityType = {}));
export var SortByType;
(function (SortByType) {
    SortByType["NameAsc"] = "NameAsc";
    SortByType["NameDesc"] = "NameDesc";
    SortByType["Newest"] = "Newest";
    SortByType["Oldest"] = "Oldest";
})(SortByType || (SortByType = {}));
export const RefreshTokenDocument = gql `
    mutation refreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    accessToken
    userId
    refreshToken
  }
}
    `;
/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RefreshTokenDocument, options);
}
export const CategoriesDocument = gql `
    query categories {
  getCategory(ids: 2) {
    id
    name
    iconURL
    activities {
      id
      name
      iconURL
    }
  }
}
    `;
/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CategoriesDocument, options);
}
export function useCategoriesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CategoriesDocument, options);
}
export const BookmarkedUsersDocument = gql `
    query bookmarkedUsers($cursor: Int!, $take: Int!, $userId: Int!) {
  getBookmarkedUsers(userId: $userId, cursor: $cursor, take: $take) {
    pageEdges {
      node {
        id
        user {
          id
          firstname
          lastname
          username
          profileImage
          dwollaCustomer {
            status
          }
        }
      }
    }
    pageInfo {
      totalCount
      hasNextPage
    }
  }
}
    `;
/**
 * __useBookmarkedUsersQuery__
 *
 * To run a query within a React component, call `useBookmarkedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarkedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarkedUsersQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBookmarkedUsersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(BookmarkedUsersDocument, options);
}
export function useBookmarkedUsersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(BookmarkedUsersDocument, options);
}
