import { Box, styled } from '@mui/material';
export const AdvertiseCardBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '100%',
    '.add-card': {
        position: 'relative',
        backgroundColor: 'transparent',
        marginBottom: '0',
        padding: '0',
        height: '100%'
    },
    '.nx-advertise-img-box': {
        height: '100%',
        paddingBottom: theme.spacing(3),
    },
    '.nx-advertise-img': {
        width: '100%',
        borderRadius: '.75rem',
        height: '100%',
        maxHeight: '100%',
        objectFit: 'cover',
    },
    '.add-card-content': {
        display: 'none',
    },
    '.add-details-card': {
        '.nx-advertise-img-box': {
            height: 'calc(100% - 12.5rem)',
            paddingBottom: 0,
        },
        '.add-news-head': {
            lineHeight: 1.3,
            paddingTop: theme.spacing(2.5),
        },
        '.add-card-content': {
            display: 'block',
            paddingLeft: '0',
            paddingRight: '0'
        },
    },
    '.add-card-divider': {
        margin: `${theme.spacing(1)} 0`
    },
}));
