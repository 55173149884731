const randomColor = (name) => {
    const colors = ['#baa036', '#7e90d6', '#77ab3c', '#f7804d'];
    return (colors[(name || '').length % colors.length] || '#bdbdbd');
};
export const avatarInitials = (name, singleLetter) => {
    var _a, _b, _c;
    const fullName = ((name === null || name === void 0 ? void 0 : name.trim()) || '');
    if (fullName) {
        const fullNameAry = (_a = fullName === null || fullName === void 0 ? void 0 : fullName.split(' ')) === null || _a === void 0 ? void 0 : _a.filter((str) => !!str);
        return {
            sx: {
                bgcolor: randomColor(fullName),
            },
            children: (`${(((_b = fullNameAry === null || fullNameAry === void 0 ? void 0 : fullNameAry[0]) === null || _b === void 0 ? void 0 : _b[0]) || '')}${(singleLetter ? '' : (((_c = fullNameAry === null || fullNameAry === void 0 ? void 0 : fullNameAry[1]) === null || _c === void 0 ? void 0 : _c[0]) || ''))}` || '').toUpperCase(),
        };
    }
    return '';
};
