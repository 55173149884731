import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ExternalOutlineIcon, NxConnectMeIcon } from 'ui/icons';
import { NXConnectAdvertiseBox, NXCardContent } from './NXConnectAdvertiseCard.styled';
import { AspectRatio, NxCustomImage } from 'common/components';
import { STATIC_IMG_URL } from 'helpers/constants';
export const NXConnectAdvertiseCard = ({ variant }) => {
    return (<NXConnectAdvertiseBox className={`variant-${variant}`}>
      <Box className="nx-advertise-img-box">
        <AspectRatio>
          <NxCustomImage priority={false} src={`${STATIC_IMG_URL}/sidebar-bg.webp`} alt="nike logo" id="nike-logo" className="nx-advertise-img"/>
        </AspectRatio>
      </Box>
      <NXCardContent>
        <Typography variant="h2" className="add-news-head"> <NxConnectMeIcon className="nx-connect-icon"/></Typography>
        <Button variant="outlined" size="xsmall" color="secondary" className="view-btn">View all my links <ExternalOutlineIcon /></Button>
        <Typography variant="h6" fontWeight="500" className="nx-user-id"><span>@michael.Oher</span></Typography>
      </NXCardContent>
    </NXConnectAdvertiseBox>);
};
