import React from 'react';
import { GA_TRACKING_ID } from 'lib/gtag';
import { Helmet } from 'react-helmet';
export const GoogleAnalyticScript = () => {
    return (<Helmet>
      <script src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}/>
      <script id='gtag-init' dangerouslySetInnerHTML={{
            __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${GA_TRACKING_ID}', {
                        page_path: window.location.pathname,
                    });
                `,
        }}/>
    </Helmet>);
};
