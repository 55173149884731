import { Box, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
const ProgressBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '50%',
    paddingLeft: theme.spacing(1.5)
}));
const ProgressBar = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'error',
})(({ theme, fulfilled, backgroundColor }) => ({
    height: '2px',
    width: '25%',
    marginRight: '4px',
    backgroundColor: fulfilled ? backgroundColor : theme.colors.neutral100,
    '&:last-child': {
        marginRight: 0
    }
}));
export const PasswordStrengthIndicator = ({ validation }) => {
    const theme = useTheme();
    const strengths = [{
            name: 'Very Weak',
            color: theme.colors.neutral100,
            fulfilledAnswers: 0
        }, {
            name: 'Weak',
            color: theme.colors.error500,
            fulfilledAnswers: 2
        }, {
            name: 'So So',
            color: theme.colors.warning500,
            fulfilledAnswers: 3
        }, {
            name: 'Good',
            color: theme.colors.info500,
            fulfilledAnswers: 4
        }, {
            name: 'Great',
            color: theme.colors.success500,
            fulfilledAnswers: 5
        }];
    const fulfilledCount = validation.reduce((p, c) => p + (c.valid ? 1 : 0), 0);
    const strength = strengths.filter(s => s.fulfilledAnswers <= fulfilledCount).reverse()[0];
    const bars = [1, 2, 3, 4];
    return (<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography sx={{ textTransform: 'uppercase', color: strength.color }}>{strength.name}</Typography>
      <ProgressBox>
        {bars.map((bar, index) => <ProgressBar key={`bar-${bar}`} fulfilled={fulfilledCount > index} backgroundColor={strength.color}/>)}
      </ProgressBox>
    </Box>);
};
