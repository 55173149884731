var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useToast } from '@nxlvl/web-providers';
import { usePlaidLink } from 'react-plaid-link';
import { useExchangePublicToken } from 'graphql-client/dwolla/useGetDwollaBankQuery';
import { getDwollaCustomerId } from 'helpers/utils';
import { PAGE_ROUTES } from 'helpers/constants';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import React from 'react';
export const LinkTokenReinitiate = ({ linkToken, url }) => {
    const { openToast } = useToast();
    // const router = useRouter();
    const navigate = useNavigate();
    const { exchangePublicToken } = useExchangePublicToken();
    const handlePlaidLinkSuccess = (publicToken, { accounts }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        if (publicToken && accounts && url) {
            const { data } = yield exchangePublicToken({
                variables: {
                    exchangeTokenArguments: {
                        accountId: (_a = accounts === null || accounts === void 0 ? void 0 : accounts[0]) === null || _a === void 0 ? void 0 : _a.id,
                        clientId: getDwollaCustomerId(url),
                        fundingSourceName: (_b = accounts === null || accounts === void 0 ? void 0 : accounts[0]) === null || _b === void 0 ? void 0 : _b.name,
                        token: publicToken
                    }
                },
            });
            Cookies.remove('linkToken');
            navigate(PAGE_ROUTES.DASHBOARD);
            if ((_c = data === null || data === void 0 ? void 0 : data.exchangePublicToken) === null || _c === void 0 ? void 0 : _c.status) {
                openToast('Bank added successfully', { type: 'success' });
            }
            else {
                openToast((_d = data === null || data === void 0 ? void 0 : data.exchangePublicToken) === null || _d === void 0 ? void 0 : _d.message, { type: 'error' });
            }
        }
        else {
            Cookies.remove('linkToken');
            navigate(PAGE_ROUTES.DASHBOARD);
            openToast('Faild to add account', { type: 'error' });
        }
    });
    const { open: openPlaidLink, ready: isPlaidLinkReady } = usePlaidLink({
        onSuccess: handlePlaidLinkSuccess,
        token: linkToken,
        receivedRedirectUri: window.location.href,
        onExit: () => {
            navigate(PAGE_ROUTES.DASHBOARD);
            Cookies.remove('linkToken');
        }
    });
    if (isPlaidLinkReady) {
        openPlaidLink();
    }
    return (<></>);
};
