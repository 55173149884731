import { Box, styled } from '@mui/material';
export const NFTImageBox = styled(Box)(({ imageWidth, theme, disabledMB, activeCursorPointer }) => ({
    width: `${imageWidth}` === 'auto' ? `${imageWidth}` : `${imageWidth}rem`,
    marginBottom: disabledMB ? 0 : theme.spacing(2),
    position: 'relative',
    cursor: activeCursorPointer ? 'pointer' : 'default',
    transition: 'top 0.5s linear',
    '.regular-border': {
        borderColor: `${theme.colors.white} !important`,
        borderWidth: '0.125rem !important',
    },
    '.nft-video': {
        width: '100%',
        border: `0.125rem solid ${theme.colors.white} !important`,
        borderRadius: '0.75rem',
    },
    '&.preview-img': {
        position: 'absolute',
        width: '100%',
        left: '0',
    },
    [theme.breakpoints.down('lg')]: {
        marginRight: theme.spacing(2),
    },
}));
