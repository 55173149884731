import React, { useEffect } from 'react';
import { List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MyNFTMenuListBox } from './MyNFTMenuList.styled';
import { useSidebarCountQuery } from 'graphql-client/myNFT/useSidebarCount';
import { useAppContext } from 'providers';
import { PAGE_ROUTES } from 'helpers/constants';
import { useNavigate, useParams } from 'react-router-dom';
const commonPageId = 'my-nft-menu';
const defaultValues = {
    bookmarkCount: 0,
    createdCount: 0,
    draftCount: 0,
    listedCount: 0,
    purchaseCount: 0,
    soldCount: 0,
};
export const MyNFTMenuList = ({ responsiveOnClick }) => {
    const { t } = useTranslation();
    const { userId = 0, category = '' } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const { isLoggedIn } = useAppContext();
    const activeCategory = category;
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { data: { getNFTStatistics } = {
        getNFTStatistics: Object.assign({}, defaultValues)
    }, refetch, } = useSidebarCountQuery({ userId: (Number(userId) || null) });
    useEffect(() => {
        // refetch counts when user loggedin on My NFT through login modal
        if ((location.pathname.startsWith(PAGE_ROUTES.MY_NFT) || location.pathname.startsWith(PAGE_ROUTES.NFT)) && isLoggedIn) {
            refetch();
        }
    }, [isLoggedIn, category]);
    const privateCategories1 = [
        {
            key: 'Sold',
            label: t('Sold'),
            number: (getNFTStatistics === null || getNFTStatistics === void 0 ? void 0 : getNFTStatistics.soldCount) || '0',
        },
        {
            key: 'Purchased',
            label: t('Purchased'),
            number: (getNFTStatistics === null || getNFTStatistics === void 0 ? void 0 : getNFTStatistics.purchaseCount) || '0',
        },
    ];
    const privateCategories2 = !userId ? [
        {
            key: 'Draft',
            label: t('Drafts'),
            number: (getNFTStatistics === null || getNFTStatistics === void 0 ? void 0 : getNFTStatistics.draftCount) || '0',
        },
        {
            key: 'Bookmarked',
            label: t('Bookmarked'),
            number: (getNFTStatistics === null || getNFTStatistics === void 0 ? void 0 : getNFTStatistics.bookmarkCount) || '0',
        },
    ] : [];
    const CATEGORIES = [
        {
            key: 'Listed',
            label: t('Listed'),
            number: (getNFTStatistics === null || getNFTStatistics === void 0 ? void 0 : getNFTStatistics.listedCount) || '0',
        },
        ...privateCategories1,
        {
            key: 'Created',
            label: t('Created'),
            number: (getNFTStatistics === null || getNFTStatistics === void 0 ? void 0 : getNFTStatistics.createdCount) || '0',
        },
        ...privateCategories2
    ];
    // const categoryKeys = CATEGORIES.map(e => e.key.toLowerCase());
    const handleCategorySelect = (category) => () => {
        if (responsiveOnClick && isMobile) {
            responsiveOnClick();
        }
        if (userId) {
            navigate(`/nft/${userId}/${category.toLocaleLowerCase()}`);
        }
        else {
            navigate(`/my-nft/${category.toLocaleLowerCase()}`);
        }
    };
    // if (userId && !(categoryKeys).includes((activeCategory))) {
    //   router.push('/404');
    // }
    return (<MyNFTMenuListBox>
      <List className="nx-main-menu">
        {CATEGORIES.map((category, i) => {
            return (<ListItem id={`${commonPageId}-category-${category.key}`} key={i} button className={`nx-main-menu-item ${category.key.toLocaleLowerCase() === activeCategory ? 'active' : ''}`} onClick={handleCategorySelect(category.key)}>
              <ListItemText className="nx-menu-text">{category.label}<span className="badge-number">{category.number}</span></ListItemText>
            </ListItem>);
        })}
      </List>
    </MyNFTMenuListBox>);
};
