import { gql, useMutation } from '@apollo/client';
const CHANGE_PASSWORD = gql `
mutation changePassword(
  $id:Int!,
  $password:String!
) {
  changePassword (
   id: $id,
   password: $password
  )
}`;
export const useChangePassword = () => {
    const [changePasswordMutation] = useMutation(CHANGE_PASSWORD, {
        notifyOnNetworkStatusChange: true,
    });
    const changePassword = (variables) => {
        return changePasswordMutation({
            variables
        });
    };
    return { changePassword };
};
