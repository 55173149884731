import { useLocation } from 'react-router-dom';
const options = [
    {
        path: '/dashboard',
        options: {
            hideSidebar: false,
            fullContainer: false,
            fullContainerForMobile: false,
            myNftSidebar: false,
            hideSubheader: false,
            title: 'Dashboard',
        },
    },
    {
        path: '/search',
        options: {
            hideSidebar: false,
            fullContainer: false,
            fullContainerForMobile: false,
            myNftSidebar: false,
            hideSubheader: false,
            title: 'Dashboard',
        },
    },
    {
        path: '/my-notification',
        options: {
            hideSidebar: false,
            fullContainer: false,
            fullContainerForMobile: false,
            myNftSidebar: false,
            hideSubheader: true,
            title: 'My Notification',
        },
    },
    {
        path: '/nft',
        options: {
            hideSidebar: false,
            fullContainer: false,
            hideSubheader: true,
            myNftSidebar: true,
            fullContainerForMobile: true,
            title: 'NFT',
        },
    },
    {
        path: '/my-nft',
        options: {
            hideSidebar: false,
            fullContainer: false,
            hideSubheader: true,
            myNftSidebar: true,
            fullContainerForMobile: true,
            isPrivateRoute: true,
            title: 'My NFT',
        },
    },
    {
        path: '/create-nft',
        options: {
            hideSidebar: true,
            fullContainer: true,
            hideSubheader: true,
            fullContainerForMobile: true,
            isPrivateRoute: true,
            title: 'Create NFT',
        },
    },
    {
        path: '/edit-nft',
        options: {
            hideSidebar: true,
            fullContainer: true,
            hideSubheader: true,
            fullContainerForMobile: true,
            isPrivateRoute: true,
            title: 'Edit NFT',
        },
    },
    {
        path: '/relist-nft',
        options: {
            hideSidebar: true,
            fullContainer: true,
            hideSubheader: true,
            fullContainerForMobile: true,
            isPrivateRoute: true,
            title: 'Relist NFT',
        },
    },
];
const defaultOptions = {
    hideSidebar: false,
    fullContainer: false,
    fullContainerForMobile: false,
    myNftSidebar: false,
    hideSubheader: true,
    title: '',
};
export const useLayoutOptions = () => {
    const location = useLocation();
    const option = options.filter((el) => location.pathname.includes(el.path));
    return option.length > 0 ? option[0].options : defaultOptions;
};
