import { Box, styled } from '@mui/material';
import { colors } from 'ui/foundations';
// variables for normal drag handle and bar size.
// Override in your scss file by setting these variables FIRST, then including this file.
const dragHandleWidthNum = 14;
const dragHandleHeightNum = 14;
const dragBarSizeNum = 6;
const dragHandleWidth = `${dragHandleWidthNum}px`;
const dragHandleHeight = `${dragHandleHeightNum}px`;
const dragBarSize = `${dragBarSizeNum}px`;
// Mobile handle/bar sizes.  Override as above.
const dragHandleMobileWidth = '24px';
const dragHandleMobileHeight = '24px';
// Handle color/border.
const dragHandleBackgroundColour = 'transparent';
const dragHandleBorder = '2px solid rgba(255, 255, 255, 1)';
const dragHandleActiveBorderColor = 'blue';
const dragHandleActiveBgColor = `${colors.info500}`;
const halfDragHandleHeight = `${dragHandleWidthNum / 2}px`;
const halfDragHandleWidth = `${dragHandleHeightNum / 2}px`;
const halfDragBarSize = `${dragBarSizeNum / 2}px`;
export const ImageCropBox = styled(Box)(({ theme }) => ({
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    '.ReactCrop': {
        position: 'relative',
        display: 'inline-block',
        cursor: 'crosshair',
        overflow: 'hidden',
        maxWidth: '100%',
        height: '100%',
        '& *, & *::before, & *::after': {
            boxSizing: 'border-box'
        },
        '&--disabled, &--locked': {
            cursor: 'inherit'
        },
        '&__child-wrapper': {
            maxHeight: 'inherit',
            height: '100%',
            '& > img, & > video': {
                display: 'block',
                height: '100%',
                // maxWidth: 'inherit',
                // maxHeight: '100%',
                touchAction: 'none',
            },
        },
        '&__crop-selection': {
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translate3d(0, 0, 0)',
            cursor: 'move',
            boxShadow: '0 0 0 9999em rgba(20, 24, 38, 0.6)',
            touchAction: 'none',
            '.ReactCrop--disabled &': {
                cursor: 'inherit'
            },
            '.ReactCrop--circular-crop &': {
                borderRadius: '50%',
                boxShadow: '0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5)'
            },
            border: '1px solid rgba(255, 255, 255, 0.6)',
            '&:focus': {
                outline: 'none',
                borderColor: dragHandleActiveBorderColor,
                borderStyle: 'solid'
            }
        },
        '&--invisible-crop &__crop-selection': {
            display: 'none'
        },
        '&__rule-of-thirds-vt::before, &__rule-of-thirds-vt::after, &__rule-of-thirds-hz::before, &__rule-of-thirds-hz::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            backgroundColor: 'rgba(255, 255, 255, 0.4)'
        },
        '&__rule-of-thirds-vt': {
            '&::before, &::after': {
                width: '1px',
                height: '100%'
            },
            '&::before': {
                left: 'calc(100% / 3)',
            },
            '&::after': {
                left: 'calc(100% / 3 * 2)',
            }
        },
        '&__rule-of-thirds-hz': {
            '&::before, &::after': {
                width: '100%',
                height: '1px'
            },
            '&::before': {
                top: 'calc(100% / 3)'
            },
            '&::after': {
                top: 'calc(100% / 3 * 2)'
            }
        },
        '&__drag-handle': {
            position: 'absolute',
            '&::after': {
                position: 'absolute',
                content: '""',
                display: 'block',
                width: dragHandleWidth,
                height: dragHandleHeight,
                backgroundColor: dragHandleBackgroundColour,
                border: dragHandleBorder,
                // This stops the borders disappearing when keyboard
                // nudging.
                outline: '1px solid transparent'
            },
            '&:focus': {
                '&::after': {
                    borderColor: dragHandleActiveBorderColor,
                    background: dragHandleActiveBgColor,
                }
            }
        },
        '.ord-nw': {
            top: 0,
            left: 0,
            marginTop: '-3px',
            marginLeft: '-3px',
            // marginTop: `-${halfDragHandleHeight}`,
            // marginLeft: `-${halfDragHandleWidth}`,
            cursor: 'nw-resize',
            '&:after': {
                top: 0,
                left: 0,
                borderBottomWidth: 0,
                borderRightWidth: 0,
            }
        },
        '.ord-n': {
            top: 0,
            left: '50%',
            marginTop: `-${halfDragHandleHeight}`,
            marginLeft: `-${halfDragHandleWidth}`,
            cursor: 'n-resize',
            '&::after': {
                top: 0
            }
        },
        '.ord-ne': {
            top: 0,
            right: 0,
            marginTop: '-3px',
            marginRight: '-3px',
            // marginTop: `-${halfDragHandleHeight}`,
            // marginRight: `-${halfDragHandleWidth}`,
            cursor: 'ne-resize',
            '&::after': {
                top: 0,
                right: 0,
                borderBottomWidth: 0,
                borderLeftWidth: 0,
            }
        },
        '.ord-e': {
            top: '50%',
            right: 0,
            marginTop: `-${halfDragHandleHeight}`,
            marginRight: `-${halfDragHandleWidth}`,
            cursor: 'e-resize',
            '&::after': {
                right: 0
            }
        },
        '.ord-se': {
            bottom: 0,
            right: 0,
            marginBottom: '-3px',
            marginRight: '-3px',
            // marginBottom: `-${halfDragHandleHeight}`,
            // marginRight: `-${halfDragHandleWidth}`,
            cursor: 'se-resize',
            '&::after': {
                bottom: 0,
                right: 0,
                borderTopWidth: 0,
                borderLeftWidth: 0,
            }
        },
        '.ord-s': {
            bottom: 0,
            left: '50%',
            marginBottom: `-${halfDragHandleHeight}`,
            marginLeft: `-${halfDragHandleWidth}`,
            cursor: 's-resize',
            '&::after': {
                bottom: 0,
            }
        },
        '.ord-sw': {
            bottom: 0,
            left: 0,
            marginBottom: '-3px',
            marginLeft: '-3px',
            // marginBottom: `-${halfDragHandleHeight}`,
            // marginLeft: `-${halfDragHandleWidth}`,
            cursor: 'sw-resize',
            '&::after': {
                bottom: 0,
                left: 0,
                borderTopWidth: 0,
                borderRightWidth: 0,
            }
        },
        '.ord-w': {
            top: '50%',
            left: 0,
            marginTop: `-${halfDragHandleHeight}`,
            marginLeft: `-${halfDragHandleWidth}`,
            cursor: 'w-resize',
            '&::after': {
                left: 0,
            }
        },
        // Use the same specificity as the ords above but just
        // come after.
        '&__disabled &__drag-handle': {
            cursor: 'inherit'
        },
        '&__drag-bar': {
            position: 'absolute',
            '&.ord-n': {
                top: 0,
                left: 0,
                width: '100%',
                height: dragBarSize,
                marginTop: `-${halfDragBarSize}`,
            },
            '&.ord-e': {
                right: 0,
                top: 0,
                width: dragBarSize,
                height: '100%',
                marginRight: `-${halfDragBarSize}`,
            },
            '&.ord-s': {
                bottom: 0,
                left: 0,
                width: '100%',
                height: dragBarSize,
                marginBottom: `-${halfDragBarSize}`,
            },
            '&.ord-w': {
                top: 0,
                left: 0,
                width: dragBarSize,
                height: '100%',
                marginLeft: `-${halfDragBarSize}`,
            }
        },
        '&--new-crop .ReactCrop__drag-bar, &--new-crop .ReactCrop__-handle, &--fixed-aspect .ReactCrop__drag-bar': {
            display: 'none'
        },
        '&--fixed-aspect .ReactCrop__drag-handle.ord-n, &--fixed-aspect .ReactCrop__drag-handle.ord-e, &--fixed-aspect .ReactCrop__drag-handle.ord-s, &--fixed-aspect .ReactCrop__drag-handle.ord-w': {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            '.ord-n, .ord-e, .ord-s, .ord-w': {
                display: 'none'
            },
            '&__drag-handle': {
                width: dragHandleMobileWidth,
                height: dragHandleMobileHeight,
            }
        }
    }
}));
