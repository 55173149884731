import { styled, Box } from '@mui/material';
import { colors } from 'ui/foundations';
export const UserCardBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    zIndex: '1',
    [theme.breakpoints.down('lg')]: {
        padding: `${theme.spacing(2.5)} 0`,
    },
}));
export const PublicUserBox = styled(Box)(({ theme }) => ({
    '.nxUser-box-wrapper': {
        padding: `${theme.spacing(10)} 0 0`,
    },
    '.nx-user-box': {
        '.nx-avatar-box': {
            display: 'block',
            '.badge-icon': {
                width: '1.1rem',
            }
        },
        '.nx-avatar-img': {
            marginRight: `${theme.spacing(1.37)}`,
        },
        '.nx-user-info': {
            margin: theme.spacing(0),
        },
        '.nx-avatar-id': {
            fontSize: '0.875rem',
            margin: `${theme.spacing(.5)} 0 0`,
            whiteSpace: 'break-spaces',
            wordBreak: 'break-all',
        },
        '.nx-avatar-name': {
            fontSize: '2rem',
            fontWeight: 'bold',
            color: theme.dynamicTheme.mainSection.text.color,
            whiteSpace: 'break-spaces',
            wordBreak: 'break-all',
        },
    },
    '.my-profile-link': {
        fontSize: '0.875rem',
        color: `${theme.dynamicTheme.mainSection.link.color} !important`,
        position: 'relative',
        zIndex: '1',
        cursor: 'pointer',
        '&:hover': {
            color: `${theme.dynamicTheme.mainSection.link.colorHover} !important`,
        }
    },
    '.nx-avatar-school-name, .nx-avatar-interest': {
        color: theme.dynamicTheme.mainSection.text.color,
        lineHeight: '1.64',
        letterSpacing: '0.004rem',
        fontSize: '0.875rem',
    },
    '.nx-avatar-interest': {
        color: colors.neutral200,
    },
    '.sponsor-text': {
        display: 'block',
        lineHeight: '1.27',
        fontSize: '0.688rem',
        marginTop: theme.spacing(.5),
        marginBottom: theme.spacing(1),
        textAlign: 'center',
    },
    '.nx-sub-heading': {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        marginTop: theme.spacing(.5),
        'span': {
            margin: `0 ${theme.spacing(1)}`,
        }
    },
    '.bg-nx-logo': {
        position: 'absolute',
        opacity: '0.05',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '.nx-logo-icon': {
            transform: ' scale(14)',
        }
    }
}));
export const MoreMenuBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    right: '0',
    zIndex: '1',
    '.more-btn, .more-btn:hover': {
        display: 'block',
        padding: '0',
        marginBottom: theme.spacing(1),
        backgroundColor: theme.dynamicTheme.mainSection.listing.backgroundColor,
        width: '2rem',
        height: '2rem',
        border: `0.063rem solid ${theme.dynamicTheme.mainSection.listing.backgroundColor}`,
        '.stroke-color': {
            fontSize: '1.25rem',
        },
        '.dots-btn-icon, .follow-btn-icon': {
            'path': {
                fill: theme.dynamicTheme.mainSection.text.color,
            }
        },
        '.follow-btn-icon': {
            fontSize: '1rem',
            transform: 'translateY(-0.1rem)',
        },
        '&.active': {
            '.book-btn-icon': {
                'path': {
                    fill: theme.colors.blue,
                    stroke: theme.colors.blue,
                }
            },
            '.follow-btn-icon': {
                'path': {
                    fill: theme.colors.blue,
                    stroke: theme.colors.blue,
                }
            }
        }
    },
}));
