import React from 'react';
import { Card, styled, Box } from '@mui/material';
import { getThumbnailFromImageUrl } from 'helpers/utils';
import { NftEditionTier } from 'types';
import { NftCardLoader, NftCardContent, NftCardContentDraft, NftCardContentStacked, NftCardMedia, NftCardMediaShadow } from './components';
import { getPdpUrl } from 'helpers/utils';
import { Link } from 'react-router-dom';
const NftItemCard = styled(Card)(() => ({
    padding: 0,
    marginBottom: 0,
    backgroundColor: 'transparent',
    borderRadius: 10,
    cursor: 'pointer',
    width: '100%',
}));
export const NftCard = ({ id, data, size = 'small', query, queryParams, hideContent, isLoading, disableClick, variant = 'stacked', showListForSaleButton = false, showActionsOnBookmarkTab = false, onEdit, onDelete, isDraftMode }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    if (isLoading || !data) {
        return <NftCardLoader size={size}/>;
    }
    const redirectOnEdit = (variant) => {
        if (variant === 'draft' && onEdit) {
            onEdit();
        }
    };
    return (<Link to={getPdpUrl(data, variant, disableClick, isDraftMode)} target="_blank" rel="noreferrer">
      <a>
        <NftItemCard id={`${id}-nft-item-card`} className={`nft-item-card size-${size}`}>
          {(data.__typename === 'NFTEditionEdge') &&
            <>
              <Box onClick={() => redirectOnEdit(variant)}>
                <NftCardMedia id={`${id}-media`} image={getThumbnailFromImageUrl((_b = (_a = data === null || data === void 0 ? void 0 : data.node) === null || _a === void 0 ? void 0 : _a.nft) === null || _b === void 0 ? void 0 : _b.imageUrl)} video={(_d = (_c = data === null || data === void 0 ? void 0 : data.node) === null || _c === void 0 ? void 0 : _c.nft) === null || _d === void 0 ? void 0 : _d.videoData} size={size} multiple={(variant !== 'stacked' && !(((_e = data === null || data === void 0 ? void 0 : data.node) === null || _e === void 0 ? void 0 : _e.tier) === 'Legendary')) ||
                    (variant === 'stacked' && !((_g = (_f = data === null || data === void 0 ? void 0 : data.node) === null || _f === void 0 ? void 0 : _f.associatedTiers) === null || _g === void 0 ? void 0 : _g.find(t => t === NftEditionTier.Legendary)))}/>
              </Box>

              {(variant === 'stacked') &&
                    <NftCardMediaShadow visible={(variant === 'stacked' && !!((_j = (_h = data === null || data === void 0 ? void 0 : data.node) === null || _h === void 0 ? void 0 : _h.associatedTiers) === null || _j === void 0 ? void 0 : _j.find(t => t !== NftEditionTier.Legendary))) ||
                            (variant !== 'stacked' && !(((_k = data === null || data === void 0 ? void 0 : data.node) === null || _k === void 0 ? void 0 : _k.tier) === 'Legendary'))}/>}
            </>}
          {(data.__typename === 'DashboardNFT') &&
            <>
              <NftCardMedia id={`${id}-media`} image={getThumbnailFromImageUrl(data === null || data === void 0 ? void 0 : data.imageUrl)} video={data === null || data === void 0 ? void 0 : data.videoData} size={size} multiple={!((_l = data.associatedTiers) === null || _l === void 0 ? void 0 : _l.find(t => t === NftEditionTier.Legendary))}/>
              <NftCardMediaShadow visible={!!((_m = data.associatedTiers) === null || _m === void 0 ? void 0 : _m.find(t => t !== NftEditionTier.Legendary))}/>
            </>}


          {(!hideContent && variant === 'draft' && data.__typename === 'NFTEditionEdge') &&
            <NftCardContentDraft data={(_o = data.node) === null || _o === void 0 ? void 0 : _o.nft} edition={data.node} id={`${id}-details`} query={query} queryParams={queryParams} size={size} onDelete={onDelete} onEdit={onEdit}/>}

          {(!hideContent && variant !== 'draft' && data.__typename === 'NFTEditionEdge') &&
            <NftCardContent data={(_p = data.node) === null || _p === void 0 ? void 0 : _p.nft} edition={data.node} id={`${id}-details`} query={query} queryParams={queryParams} size={size} variant={variant} showListForSaleButton={showListForSaleButton} showActionsOnBookmarkTab={showActionsOnBookmarkTab}/>}

          {(!hideContent && variant === 'stacked' && data.__typename === 'DashboardNFT') &&
            <NftCardContentStacked data={data} id={`${id}-details`} query={query} queryParams={queryParams} size={size}/>}
        </NftItemCard>
      </a>
    </Link>);
};
