/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import Router from './routes/index';
import * as Sentry from '@sentry/react';
Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.PUBLIC_ENV,
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', process.env.GRAPH_QL_URL || ''],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const router = createBrowserRouter(createRoutesFromElements(Router()));
const render = () => {
    const root = ReactDOMClient.createRoot(document.getElementById('root'));
    root.render(<React.StrictMode>
      <RouterProvider router={router}/>
    </React.StrictMode>);
};
render();
