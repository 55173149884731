import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BasicSliderBox } from './BasicSlider.styled';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { NftCard } from 'common/components';
import { SliderArrowLeft, SliderArrowRight } from '../SliderArrow/SliderArrow';
export const BasicSlider = ({ data, loading, query, queryParams, hideShadow = false }) => {
    const theme = useTheme();
    const isMaxScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const settings = {
        infinite: false,
        slidesToShow: (isMobile ? 1.5 : (isTablet ? 2 : (isMaxScreen ? 3 : 4))),
        speed: 500,
        swipeToSlide: true,
        nextArrow: <SliderArrowRight />,
        prevArrow: <SliderArrowLeft />,
        swipe: isMobile,
        arrows: !isMobile,
        waitForAnimate: true,
    };
    const nftList = (data === null || data === void 0 ? void 0 : data.length) > 0 ? data : [null, null, null, null, null];
    return (<BasicSliderBox hideShadow={hideShadow}>
      <Grid spacing={2} container maxWidth="xl">
        <Grid item xs={12} lg={12}>
          <Slider {...settings}>
            {nftList.map((nEdition, index) => {
            var _a;
            return (<Box key={((_a = nEdition === null || nEdition === void 0 ? void 0 : nEdition.node) === null || _a === void 0 ? void 0 : _a.id) || index} className="nx-slider-box">
                  <NftCard id="nft-card-styleguide" size={isMobile ? 'medium' : 'large'} isLoading={loading} data={nEdition} showListForSaleButton query={query} queryParams={queryParams} variant="stacked"/>
                </Box>);
        })}
          </Slider>
        </Grid>
      </Grid>
    </BasicSliderBox>);
};
