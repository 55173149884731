import { Stack, Box } from '@mui/material';
import { Loader } from 'common/components';
import { SidebarDivider, MyNFTMenuCardLoaderBox } from './MyNFTMenuCard.styled';
export const MyNFTMenuCardLoader = () => {
    return (<MyNFTMenuCardLoaderBox>
      <Stack spacing={1.8} className="">
        <Loader loading width={40} height={40} variant="circular"/>
        <Loader loading width="70%" variant="rectangular"/>
        <Loader loading width="80%" height={40} variant="rectangular"/>
        <Loader loading width="80%" height={40} variant="rectangular"/>
        <Box className="loader-profile">
          <Stack spacing={1} width="79%">
            <Loader loading variant="rectangular"/>
            <Loader loading variant="rectangular"/>
            <Loader loading variant="rectangular"/>
          </Stack>
          <Box className="profile-loader-logo">
            <Loader loading width={50} height={55} variant="rectangular"/>
          </Box>
        </Box>
        <Loader loading width="40%" variant="rectangular"/>
      </Stack>
      <SidebarDivider />
      <Stack spacing={2}>
        <Loader loading variant="rectangular" height={40}/>
        <Loader loading variant="rectangular" height={40}/>
        <Loader loading variant="rectangular" height={40}/>
        <Loader loading variant="rectangular" height={40}/>
        <Loader loading variant="rectangular" height={40}/>
        <Loader loading variant="rectangular" height={40}/>
      </Stack>
    </MyNFTMenuCardLoaderBox>);
};
