var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { IMaskInput } from 'react-imask';
export const PriceMaskInput = React.forwardRef(function PriceFieldMaskInput(props, ref) {
    const { onChange } = props, other = __rest(props, ["onChange"]);
    return (<IMaskInput {...other} inputRef={ref} mask="num" blocks={{
            num: {
                mask: Number,
                thousandsSeparator: ',',
                scale: 2,
                radix: '.',
                mapToRadix: ['.'],
                signed: false,
                normalizeZeros: false,
                padFractionalZeros: true,
            },
        }} onAccept={(value) => onChange({ target: { name: props.name, value: (value || '') } })} unmask/>);
});
