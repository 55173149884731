export const getNftImgUrls = (imageUrl = '') => {
    let originalImgSrc = imageUrl;
    let thumbnailImgSrc = imageUrl;
    // Get image's path, name and extension
    const fileIndex = imageUrl.lastIndexOf('/');
    const path = imageUrl.substring(0, fileIndex);
    const fileName = imageUrl.substring(fileIndex + 1);
    const lastDotIndex = fileName.lastIndexOf('.');
    const extension = fileName.substring(lastDotIndex + 1);
    const fileNameNoExt = fileName.substring(0, lastDotIndex);
    if (path && fileNameNoExt && extension) {
        originalImgSrc = `${path}/${fileNameNoExt}_original.${extension}`;
        thumbnailImgSrc = `${path}/${fileNameNoExt}_thumbnail.${extension}`;
    }
    return {
        originalImg: originalImgSrc,
        thumbnailImg: thumbnailImgSrc,
        fileMeta: {
            extension,
            imgFileName: fileNameNoExt
        },
    };
};
export const getThumbnailFromImageUrl = (imageURL = '') => {
    if (!imageURL)
        return '';
    const addAtIndex = imageURL.lastIndexOf('.');
    return imageURL.substring(0, addAtIndex) + '_thumbnail' + imageURL.substring(addAtIndex);
};
