import jwtDecode from 'jwt-decode';
export const getJwtExpirationDate = (token) => {
    if (!token) {
        return undefined;
    }
    const payload = jwtDecode(token);
    if (!payload.exp) {
        return undefined;
    }
    const date = new Date(0);
    date.setUTCSeconds(payload.exp);
    return date;
};
