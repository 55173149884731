import { Box, Button, styled, FormHelperText } from '@mui/material';
import { Dialog } from 'common/components/Dialog';
export const NFTActivityWrapper = styled(Box)(({ theme }) => ({
    '.optional-text': {
        fontSize: '0.625rem',
    },
    '.activity-btn': {
        margin: `${theme.spacing(1)} 0 0`
    }
}));
export const NFTActivityButton = styled(Button)(({ theme, isReadable }) => ({
    margin: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
    color: theme.colors.black,
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    fontSize: '12px',
    fontWeight: '600',
    cursor: isReadable ? 'auto' : '',
    'svg.MuiSvgIcon-root': {
        width: '1rem',
        height: '1rem',
        borderRadius: '50%',
        fontSize: '12px',
        backgroundColor: theme.colors.neutral400,
        color: theme.colors.white,
    },
    '&.active': {
        color: theme.colors.white,
        fontWeight: '500',
        'svg.MuiSvgIcon-root': {
            backgroundColor: 'transparent',
            marginRight: `${theme.spacing(1)}`,
        }
    },
    '&:hover': {
        backgroundColor: isReadable ? `${theme.colors.white}` : '',
    }
}));
export const NFTButtonListingBox = styled('div')(({ theme }) => ({
    width: 'max-content',
    '.MuiButton-root': {
        justifyContent: 'center',
        color: theme.colors.blue,
        fontSize: '14px',
    },
}));
export const NFTSearchActivity = styled(Box)(({ theme }) => ({
    padding: `${theme.spacing(1)} ${theme.spacing(2)} 0`,
    '& input': {
        height: '2rem',
    },
    '.nx-search-text-input > div': {
        margin: `${theme.spacing(0)}`,
    },
    '.nx-search-icon-box': {
        '.nx-search-icon': {
            transform: 'scale(1.3)',
            color: theme.colors.black,
        },
    },
}));
export const NftActivityDialog = styled(Dialog)(({ theme }) => ({
    '.activity-row-chips': {
        marginTop: `${theme.spacing(2)}`,
        paddingBottom: `${theme.spacing(1)}`,
    },
    '.MuiDialogContent-root': {
        paddingTop: '0 !important',
    },
    '.MuiDivider-root': {
        marginTop: '0.5rem !important',
    }
}));
export const NFTHelperText = styled(FormHelperText)({
    fontSize: '.75rem',
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'uppercase'
});
