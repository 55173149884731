import React from 'react';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { LoaderCircular } from 'common/components';
const LoaderBox = styled(Box)(({ theme }) => ({
    position: 'fixed',
    width: '100%',
    height: '100vh',
    background: theme.colors.black80,
    left: '0',
    top: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1301', // In header we set zIndex as 1100 
}));
export const FullSizeLoader = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTab = useMediaQuery(theme.breakpoints.down('lg'));
    return (<LoaderBox>
      <LoaderCircular size={isMobile ? 'small' : isTab ? 'medium' : 'xlarge'}/>
    </LoaderBox>);
};
