import { gql, useMutation } from '@apollo/client';
const LOGOUT = gql `
  mutation Logout($deviceId: String, $logOutVia: SignUpORLogoutType) {
    logout(deviceId: $deviceId, logOutVia: $logOutVia)
  }
`;
export const useLogoutMutation = () => {
    const [logoutMutation] = useMutation(LOGOUT, {
        notifyOnNetworkStatusChange: true,
    });
    return { logoutMutation };
};
