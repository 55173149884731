export var PostNftStepTypes;
(function (PostNftStepTypes) {
    PostNftStepTypes[PostNftStepTypes["NFT_ASSETS_SELECTION"] = 0] = "NFT_ASSETS_SELECTION";
    PostNftStepTypes[PostNftStepTypes["ENTER_NFT_DETAILS"] = 1] = "ENTER_NFT_DETAILS";
    PostNftStepTypes[PostNftStepTypes["PREVIEW_NFT_DETAILS"] = 2] = "PREVIEW_NFT_DETAILS";
    PostNftStepTypes[PostNftStepTypes["CREATE_NFT_SUCCESS"] = 3] = "CREATE_NFT_SUCCESS";
})(PostNftStepTypes || (PostNftStepTypes = {}));
export var PostNftActionTypes;
(function (PostNftActionTypes) {
    PostNftActionTypes[PostNftActionTypes["SET_NFT_ASSETS"] = 0] = "SET_NFT_ASSETS";
    PostNftActionTypes[PostNftActionTypes["SET_NFT_DETAILS"] = 1] = "SET_NFT_DETAILS";
    PostNftActionTypes[PostNftActionTypes["SET_CURRENT_STEP"] = 2] = "SET_CURRENT_STEP";
    PostNftActionTypes[PostNftActionTypes["SET_IS_CONFRIM_WORK_DIALOG"] = 3] = "SET_IS_CONFRIM_WORK_DIALOG";
})(PostNftActionTypes || (PostNftActionTypes = {}));
export var PostNftActions;
(function (PostNftActions) {
    PostNftActions[PostNftActions["SET_NFT_ASSET_LIST"] = 0] = "SET_NFT_ASSET_LIST";
})(PostNftActions || (PostNftActions = {}));
