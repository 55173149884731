import React from 'react';
import { ResponsiveContainer, StackedCarousel } from 'react-stacked-center-carousel';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Loader, NftCardContentStacked } from 'common/components';
import { GET_DASHBOARD_NFT } from 'graphql-client/dashboard';
import { ChevronLeftOutlineIcon, ChevronRightOutlineIcon } from 'ui/icons';
import { Center3dSliderBox } from './Center3dSlider.styled';
import { NftSlideCard } from './NftSlideCard';
const Center3dSliderLoading = ({ id }) => {
    const ref = React.useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTab = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const loading = true;
    const maxVisibleSlide = 5;
    const CustomNftSlideCard = (props) => {
        return <NftSlideCard id={id} query={GET_DASHBOARD_NFT} queryParams={{}} loading={loading} maxVisibleSlide={maxVisibleSlide} {...props}/>;
    };
    return (<>
      <Center3dSliderBox>
        <div className="card">
          <ResponsiveContainer carouselRef={ref} render={(width, carouselRef) => {
            return (<StackedCarousel ref={carouselRef} slideComponent={CustomNftSlideCard} slideWidth={isMobile ? 160 : isTab ? 260 : 334} carouselWidth={width} data={[null, null, null, null, null]} maxVisibleSlide={maxVisibleSlide} disableSwipe={isDesktop ? true : false} customScales={isTab ? [1, 0.85, 0.7, 0] : [1, 0.85, 0.7, 0.55]} transitionTime={450}/>);
        }}/>

          {/* This => disable <= class use for arrow disable */}
          <div className="card-button left" onClick={() => { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.goBack(); }}>
            {loading ?
            <Loader variant="circular" width={32} height={32}/>
            :
                <ChevronLeftOutlineIcon />}
          </div>
          <div className="card-button right" onClick={() => { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.goNext(); }}>
            {loading ?
            <Loader variant="circular" width={32} height={32}/>
            :
                <ChevronRightOutlineIcon />}
          </div>

        </div>
      </Center3dSliderBox>

      {isTab &&
            <Box display="flex" justifyContent="center">
          <Box sx={{ width: '80%' }}>
            <NftCardContentStacked id={`${id}-card-loading`} data={null} query={GET_DASHBOARD_NFT} 
            // queryParams={queryParams}
            size="large" isLoading={loading}/>
          </Box>
        </Box>}
    </>);
};
export default Center3dSliderLoading;
