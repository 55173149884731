import { Box, Card, styled } from '@mui/material';
export const NxlvlCardBox = styled(Box)(({ theme }) => ({
    '.left-sidebar-card': {
        backgroundImage: 'linear-gradient(160deg, #345bff -22%, #141826 74%)',
        marginTop: `${theme.spacing(1)}`,
    },
    '& .nx-what-nx-box-logo': {
        fontSize: '4.375rem',
        margin: `0 auto ${theme.spacing(1)}`,
    },
    '.nx-nxlvl-head': {
        fontSize: '1.25rem',
    },
    '.nx-nxlvl-body': {
        fontSize: '1rem',
        margin: `${theme.spacing(1)} 0 ${theme.spacing(2)}`,
    },
    '.nx-nxlvl-btn': {
        margin: `0 ${theme.spacing(.625)}`,
        minWidth: '6.25rem',
    },
}));
export const NxlvlCardLoaderBox = styled(Card)(({ theme }) => ({
    borderRadius: '0.625rem',
    border: `2px solid ${theme.dynamicTheme.mainSection.alertBackgroundColor}`,
    background: 'transparent',
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
    width: '100%',
    height: '15.32rem',
}));
