import { gql, useLazyQuery } from '@apollo/client';
export const GET_DWOLLA_CUSTOMER_BALANCE = gql `
  query getDwollaWalletBalance {
  getDwollaWalletBalance {
    balance
  }
}
`;
export const useGetDwollaCustomerBalanceLazyQuery = () => {
    const [getDwollaCustomerBalance, { data }] = useLazyQuery(GET_DWOLLA_CUSTOMER_BALANCE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });
    return { getDwollaCustomerBalance, customerBalance: data };
};
