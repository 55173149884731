import { Box, styled } from '@mui/material';
const MaximizeImgBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100% - 2.5rem)',
    '.slick-slide': {
        textAlign: 'center'
    },
    '.fullscreen-slide-wrapper': {
        position: 'relative',
        width: '100%',
        '.pan-pinch-wrapper': {
            width: '100%',
            margin: '0 auto',
            '.pan-pinch-content': {
                width: '100%',
                margin: '0 auto',
                cursor: 'grab',
                alignItems: 'center'
            }
        },
        '.zoom-controls-buttons': {
            '.controls-btn': {
                'svg': {
                    color: theme.colors.white,
                    fontSize: '1.125rem',
                },
                '&.disabled': {
                    opacity: 0.5
                }
            }
        }
    },
    '.vertical-slide-box': {
        position: 'relative',
        margin: `0 auto ${theme.spacing(4)}`,
        minHeight: 'calc(100vh - 7rem)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '50rem',
        'span': {
            position: 'relative !important',
            width: '100% !important',
            'img': {
                position: 'relative !important',
            }
        },
        [theme.breakpoints.down('xl')]: {
            maxWidth: '35rem',
        },
    },
    '.vertical-slide-img': {
        width: '100% !important',
        height: '100% !important',
        objectFit: 'cover',
        borderRadius: '0.75rem',
        border: `0.125rem solid ${theme.colors.white} !important`,
        minWidth: '16rem!important',
        [theme.breakpoints.down('lg')]: {
            border: `0.063rem solid ${theme.colors.neutral550} !important`,
        },
        '&.video': {
            objectFit: 'contain',
        }
    },
    //====== Section comment for slider arrows ======//
    // '.slick-list': {
    //   ':before, :after': {
    //     position: 'absolute',
    //     content: '""',
    //     width: '13rem',
    //     height: '100%',
    //     right: 0,
    //     top: '0',
    //     backgroundImage: 'linear-gradient(to left, rgba(0, 0, 0, 1) 00%, rgba(0, 0, 0, 0) 100%)',
    //     zIndex: '9',
    //     [theme.breakpoints.down('md')]: {
    //       width: '7rem',
    //     },
    //     [theme.breakpoints.down('sm')]: {
    //       width: '3.5rem',
    //     },
    //   },
    //   ':after': {
    //     right: 'auto',
    //     left: '-0.313rem',
    //     transform: 'rotate(-180deg)',
    //   },
    // },
    '.slick-prev': {
        left: '1.5rem',
    },
    '.slick-next': {
        right: '1.5rem',
    },
    '.slick-arrow': {
        [theme.breakpoints.down('md')]: {
            display: 'none !important'
        },
    }
}));
export default MaximizeImgBox;
