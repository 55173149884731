import { styled, Card } from '@mui/material';
export const SelectBankBox = styled(Card)(({ theme, isdisabled }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 16,
    marginTop: 16,
    marginBottom: 24,
    border: `0.063rem solid ${isdisabled ? theme.colors.neutral300 : theme.colors.blue}`,
    backgroundColor: `${theme.colors.neutral925}!important`,
    cursor: 'pointer',
    '.wallet-overview-icon': {
        fontSize: 18,
        marginTop: 2,
        marginRight: 6,
        'path': {
            fill: isdisabled ? theme.colors.neutral300 : theme.colors.blue,
        }
    },
}));
