import { FormControl, IconButton, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChevronDownOutlineIcon, SearchOutlineIcon } from 'ui/icons';
import { TextField } from '../..';
import { DropdownListBox, SearchDropdownBox, SearchFieldBox } from './SearchDropdown.styled';
import React from 'react';
const Icon = () => {
    return <ChevronDownOutlineIcon className="nx-search-dropdown-icon stroke-color"/>;
};
const commonPageId = 'search-dropdown';
export const qTypes = ['All', 'NFTs', 'Institutions', 'Users'];
export const SearchDropdown = ({ name, formik, searchType, onTypeChange, isValidHashtag }) => {
    const { t } = useTranslation();
    return (<SearchDropdownBox>
      <SearchFieldBox>
        <TextField id={`${commonPageId}-input-global-search`} formik={formik} name={name} margin="none" placeholder="Search NFTs, Institutions and Users" InputProps={{
            startAdornment: (<IconButton className="nx-search-icon-box" edge="end" type="submit">
                <SearchOutlineIcon className="nx-search-icon stroke-color"/>
              </IconButton>)
        }} className="nx-search-text-input"/>
      </SearchFieldBox>
      <DropdownListBox>
        <FormControl fullWidth>
          <Select value={searchType} MenuProps={{
            classes: {
                root: 'nx-dropdown-box'
            }
        }} inputProps={{
            name: 'age',
            id: 'uncontrolled-native',
        }} variant="standard" IconComponent={Icon} onChange={(event) => { onTypeChange(event.target.value); }}>
            {qTypes.map((q) => (<MenuItem key={q} value={q} disabled={(isValidHashtag && q !== 'NFTs')}>{q}</MenuItem>))}
          </Select>
        </FormControl>
      </DropdownListBox>
    </SearchDropdownBox>);
};
