import React from 'react';
import { Loader } from 'common/components';
import { Box, Card, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'ui/icons';
import { BuildBrandCardBox, BuildBrandLoader } from './BuildBrandCard.styled';
const BuildBrandCardLoader = () => {
    return (<BuildBrandLoader>
      <Stack spacing={1}>
        <Loader loading height={21} variant="rectangular"/>
        <Loader loading height={13} variant="rectangular"/>
      </Stack>
    </BuildBrandLoader>);
};
export const BuildBrandCard = ({ transparent, fullWidth, size = 'small', labels }) => {
    const { t } = useTranslation();
    return (<BuildBrandCardBox sx={fullWidth ? { width: '100%' } : {}} className={`size-${size}`}>
      <Card className="left-sidebar-card" sx={transparent ? { backgroundColor: 'transparent' } : {}}>
        <Typography variant="h2" align="center" className="nx-build-heading">
          {t('build your')} <span className="text-blue">br<LogoIcon className="nx-brand-logo-icon"/>ND</span>
        </Typography>
        <Box>
          {size === 'large' && <Box className="gradient-border"></Box>}
          {labels &&
            <Typography variant="h5" align="center" className="nx-build-sub-heading" fontWeight="500">
              <LogoIcon className="nx-logo-icon"/><span>{labels.join(' • ')}</span><LogoIcon className="nx-logo-icon"/>
            </Typography>}
          {size === 'large' && <Box className="gradient-border"></Box>}
        </Box>
      </Card>
    </BuildBrandCardBox>);
    return <BuildBrandCardLoader />;
};
