import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useFormContext } from '.';
import React from 'react';
export const SwitchField = ({ formik, name, label }) => {
    const { formId } = useFormContext();
    const id = `${formId ? (formId + '-') : ''}${name}`;
    const handleChange = (event) => {
        formik.setFieldValue(name, event.target.checked);
    };
    return (<FormGroup>
      <FormControlLabel control={<Switch id={id} checked={formik.values[name]} onChange={handleChange}/>} id={`${id}-label`} label={label || ''} sx={{ marginLeft: 0 }}/>
    </FormGroup>);
};
