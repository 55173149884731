import { Box, styled } from '@mui/material';
export const NxlvlSponsorBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    right: '0',
    bottom: '0',
    [theme.breakpoints.down('md')]: {
        position: 'relative',
        textAlign: 'center',
    }
}));
