import { Box, Avatar, IconButton, styled, Typography, Link, Tooltip } from '@mui/material';
import React from 'react';
import { useAppContext } from 'providers';
import { SOCIAL_APP_LINK } from 'helpers/constants';
import { avatarInitials } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { VerifiedGoldenMarkIcon, VerifiedMarkIcon } from 'ui/icons';
const commonPageId = 'user-avtar';
const AvatarBox = styled(Box)(({ theme }) => ({
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '.nx-avatar-name': {
        fontSize: '1rem',
        marginLeft: theme.spacing(1)
    },
    '&.size-medium': {
        '.MuiAvatar-root': {
            width: '3rem',
            height: '3rem',
        },
    },
    '&.size-large': {
        '.MuiAvatar-root': {
            width: '4rem',
            height: '4rem',
            fontSize: '3rem',
            fontWeight: '600',
        },
    },
    '.see-profile-text': {
        fontSize: '0.75rem',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(-0.3),
        display: 'flex'
    }
}));
const UserBadge = styled(IconButton)(({ theme }) => ({
    width: 'max-content',
    padding: '1px',
    borderRadius: '50%',
    position: 'relative',
    '.nx-avatar-img': {
        margin: '0!important',
    },
    '.badge-icon': {
        position: 'absolute',
        right: 4,
        bottom: -8,
        width: 14,
    },
    '&.normal-badge': {
        backgroundColor: theme.colors.blue,
    },
    '&.golden-badge': {
        background: theme.colors.tierLegendary,
    }
}));
export const UserAvatar = ({ hideName, size, singleLetter, showProfileLink, hideVerified = false }) => {
    var _a, _b, _c, _d, _e;
    const appContext = useAppContext();
    const { t } = useTranslation();
    const userName = (((_a = appContext === null || appContext === void 0 ? void 0 : appContext.currentUser) === null || _a === void 0 ? void 0 : _a.firstname) || '') + ' ' + (((_b = appContext === null || appContext === void 0 ? void 0 : appContext.currentUser) === null || _b === void 0 ? void 0 : _b.lastname) || '');
    const profileImg = (((_c = appContext === null || appContext === void 0 ? void 0 : appContext.currentUser) === null || _c === void 0 ? void 0 : _c.profileImage) || '');
    const verifiedUser = ((((_e = (_d = appContext === null || appContext === void 0 ? void 0 : appContext.currentUser) === null || _d === void 0 ? void 0 : _d.dwollaCustomer) === null || _e === void 0 ? void 0 : _e.status) === 'verified' && !hideVerified) ? 'normal' : null);
    return (<AvatarBox className={`nx-avatar-box size-${size}`}>
      <UserBadge className={`${verifiedUser}-badge`} size="small" color="inherit">
        <Box>
          <Tooltip title={<>{userName}</>}>
            <Avatar {...avatarInitials(userName, singleLetter)} src={profileImg} alt={userName}/>
          </Tooltip>
        </Box>
        {(verifiedUser) && ((verifiedUser === 'normal') ?
            <Tooltip title={<>{t('Verified')}</>}>
              <div>
                <VerifiedMarkIcon className="badge-icon"/>
              </div>
            </Tooltip> :
            <Tooltip title={<>{t('Verified')}</>}>
              <div>
                <VerifiedGoldenMarkIcon className="badge-icon"/>
              </div>
            </Tooltip>)}
      </UserBadge>
      <Box className="nx-trade-info">
        {!hideName && <Typography className="nx-avatar-name" fontWeight="500">{userName}</Typography>}
        {showProfileLink && <Link href={SOCIAL_APP_LINK} className="see-profile-text" id={`${commonPageId}-your-profile`}>{t('See your profile')}</Link>}
      </Box>
    </AvatarBox>);
};
