import { Box, Container, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useAuthDialog } from 'providers';
import { SubHeader } from './SubHeader/SubHeader';
import { useLayoutOptions } from 'helpers/hooks/useLayoutOptions';
import { Helmet } from 'react-helmet';
import { STATIC_IMG_URL } from 'helpers/constants';
const SideBar = React.lazy(() => import('common/Layout/SideBar/SideBar'));
const MyNftSidebar = React.lazy(() => import('common/Layout/SideBar/components/MyNftSidebar'));
const MainBox = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up('lg')]: {
        minHeight: 'calc(100vh - 50px)',
    },
}));
export const Layout = ({ children }) => {
    const layoutOptions = useLayoutOptions();
    const theme = useTheme();
    const { isOpen, options } = useAuthDialog();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isSidebarVisible = !layoutOptions.hideSidebar;
    const isInitLoginOpened = isOpen && !!(options === null || options === void 0 ? void 0 : options.isInitialModal);
    const disableGuttersContainer = layoutOptions.fullContainer || (layoutOptions.fullContainerForMobile && isMobile);
    return (<>
    <Helmet>
      <title>{layoutOptions.title || 'nXlvl'}</title>
      <link rel="icon" type="image/png" href={`${STATIC_IMG_URL}/nxlvl_logo.webp`}/>
      </Helmet>
      <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
      {/* //TODO: use lazyload if it works better than async  */}
      <script src="https://cdn.dwolla.com/v2.1.8/dwolla-web.js" async={true}></script>
      {/* <HeaderMain
          drawerOpen={nxDrawerOpen}
          onOpenDrawer={toggleNxDrawer(true)}
          onCloseDrawer={toggleNxDrawer(false)}
        /> */}

      {!isInitLoginOpened && (<MainBox>
          <Box mt={!layoutOptions.hideSidebar ? 3 : 0}>
            <Container disableGutters={disableGuttersContainer} maxWidth={layoutOptions.fullContainer ? false : 'xl'}>
              {layoutOptions.fullContainer ? (children || null) : (<Grid container spacing={2}>
                  {isSidebarVisible && !isMobile && (<Grid item xs={12} lg={3} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                      {layoutOptions.myNftSidebar ? <MyNftSidebar /> : <SideBar />}
                    </Grid>)}
                  <Grid item xs={12} lg={isSidebarVisible ? 9 : 12}>
                    {!layoutOptions.hideSubheader && <SubHeader />}
                    {children}
                  </Grid>
                </Grid>)}
            </Container>
          </Box>
        </MainBox>)}
      {/* {
          (!layoutOptions.hideFooter) &&
          <>
            <Footer />
            {isMobile &&
              <HeaderBottomNavigation openMenu={toggleNxDrawer(true)} />
            }
          </>
        } */}
    </>);
};
