import { styled, DialogContent, Box, Typography } from '@mui/material';
export const DowllaDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: `0 ${theme.spacing(5)}!important`,
    position: 'relative',
    minHeight: 380,
    '.logo-icon': {
        position: 'absolute',
        width: '60%',
        height: 'auto',
        left: '0',
        top: '0px',
        opacity: '.1',
        margin: '0 auto',
        right: '0',
        'path': {
            fill: theme.colors.blue,
        }
    },
    '.dwolla-screen-box': {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacing(5)} 0`,
        minHeight: 360,
        position: 'relative',
        zIndex: 1,
        margin: '0 auto',
        textAlign: 'center',
        '.bg-icon-box': {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
        },
        '.logo-icon-text': {
            fontSize: 90,
            marginBottom: 20,
        },
        [theme.breakpoints.up('sm')]: {
            width: 450,
        }
    },
    '.blue-icon': {
        'path': {
            fill: theme.colors.blue,
        }
    },
    '.blue-stroke-icon': {
        'path': {
            stroke: theme.colors.blue,
        }
    },
    '.link-text': {
        fontSize: 16,
        marginTop: 20,
    },
    '.dwolla-form-box': {
        '.MuiInput-input.MuiInputBase-input': {
            height: 32,
            color: theme.colors.black,
            fontWeight: 'bold',
        },
        '.MuiSelect-select.MuiSelect-standard': {
            height: 'auto',
            padding: 16,
        }
    },
    '.small-text': {
        fontSize: 12,
        fontWeight: 500,
        color: theme.colors.neutral300,
    },
    '.dropInContainer.MuiBox-root': {
        position: 'relative',
        zIndex: '1',
    },
    '.dotted-arrow-box': {
        padding: `0 ${theme.spacing(2)}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '.arrow-svg': {
            display: 'block',
            height: 'auto',
        }
    }
}));
export const TooltipBox = styled(Box)({
    position: 'relative',
    '.faq-icon': {
        position: 'absolute',
        right: 0,
        top: 12,
        cursor: 'pointer',
    }
});
export const DwollaAlertBox = styled(Box)({
    width: '27.5rem',
    margin: '2rem auto 0',
    position: 'relative',
    zIndex: 2,
});
export const DwollaNumberFormat = styled(Typography)({
    '.price-value': {
        paddingLeft: 8,
    }
});
