var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ChevronLeftOutlineIcon, ChevronRightOutlineIcon } from 'ui/icons';
import { SliderArrowButton } from './SliderArrow.styled';
export const SliderArrowWrapper = (_a) => {
    var { currentSlide, slideCount, children } = _a, props = __rest(_a, ["currentSlide", "slideCount", "children"]);
    return (<div {...props}>{children}</div>);
};
export const SliderArrowLeft = (_a) => {
    var { currentSlide, slideCount, children } = _a, props = __rest(_a, ["currentSlide", "slideCount", "children"]);
    return (<SliderArrowButton disableRipple {...props}>
    {(children || <ChevronLeftOutlineIcon className="slider-icon"/>)}
  </SliderArrowButton>);
};
export const SliderArrowRight = (_a) => {
    var { currentSlide, slideCount, children } = _a, props = __rest(_a, ["currentSlide", "slideCount", "children"]);
    return (<SliderArrowButton disableRipple {...props}>
    {(children || <ChevronRightOutlineIcon className="slider-icon"/>)}
  </SliderArrowButton>);
};
