import { Card, styled } from '@mui/material';
export const EmptyStateLoaderBox = styled(Card)(({ theme }) => ({
    marginTop: theme.spacing(2),
    textAlign: 'center',
    padding: `${theme.spacing(7)} 0`,
    '.emptystate-section': {
        width: '30%',
        margin: '0 auto',
        '.MuiSkeleton-root': {
            margin: '0 auto',
            marginBottom: theme.spacing(1.5),
        },
        '.empty-profile': {
            margin: '0 auto',
            marginBottom: theme.spacing(3),
        }
    },
}));
