import { gql, useMutation } from '@apollo/client';
export const REGISTER_FIREBASE_TOKEN = gql `
  mutation registerFirebaseToken(
    $token: String!
    $userId: Int!
    $deviceId: String
  ) {
    registerFirebaseToken(
      token: $token
      userId: $userId
      deviceId: $deviceId
    ) {
      deviceId
      token
    }
  }
`;
export const useRegisterFirebaseToken = () => {
    const [registerFirebaseToken] = useMutation(REGISTER_FIREBASE_TOKEN, {
        notifyOnNetworkStatusChange: true,
    });
    return { registerFirebaseToken };
};
