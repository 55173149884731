import { Box, styled } from '@mui/material';
export const SearchWrapper = styled(Box)(({ theme }) => ({
    '.nx-search-text-input': {
        '& input': {
            fontSize: '1rem ',
        },
        'input[type="text"]::placeholder': {
            opacity: '1',
            fontWeight: '600',
        },
        '& > div': {
            fontWeight: 'bold',
            margin: `${theme.spacing(0)}`,
            borderRadius: '0.625rem',
            paddingRight: theme.spacing(1)
        },
    },
    '.nx-search-icon-box, .nx-search-icon-box:hover': {
        backgroundColor: theme.dynamicTheme.mainSection.chipPrimary.backgroundColor,
        marginRight: `${theme.spacing(0)}`,
        borderRadius: '.625rem',
        '.nx-search-icon': {
            transform: 'scale(1.2)',
        },
    },
}));
