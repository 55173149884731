import { colors } from 'ui/foundations';
const MuiSlider = {
    styleOverrides: {
        root: {
            width: '82%',
            color: colors.blue,
            borderRadius: '3.125rem',
            '.MuiSlider-rail': {
                backgroundColor: colors.white,
            },
            '.MuiSlider-track': {
                backgroundColor: colors.blue,
                border: 'none',
            },
            '.MuiSlider-thumb': {
                width: '1rem',
                height: '1rem',
            },
        }
    },
};
export default MuiSlider;
