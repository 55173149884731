import { gql, useMutation } from '@apollo/client';
// { bookmarkType: User, userId: 4 }
export const DELETE_BOOKMARK_PERSON = gql `
    mutation deleteBMpeople (
      $bookmarkArgs: bookmarkArgument!
    ) {
      deleteBookmark(bookmarkArgs: $bookmarkArgs){
        message
      }
    }
`;
export const useDeleteBookmarkPersonMutation = () => {
    const [deleteBookmarkMutation, options] = useMutation(DELETE_BOOKMARK_PERSON, {
        notifyOnNetworkStatusChange: true,
    });
    const deleteBookmarkPersonMutation = ({ userId, bookmarkType, query, queryParams }) => deleteBookmarkMutation({
        variables: { bookmarkArgs: { userId, bookmarkType } },
        update: (cache) => {
            var _a;
            const data = cache.readQuery({ query, variables: queryParams });
            const newData = JSON.parse(JSON.stringify(data));
            if (((_a = newData === null || newData === void 0 ? void 0 : newData.getUserDetailsById) === null || _a === void 0 ? void 0 : _a.isPeopleBookmarked) === true) {
                newData.getUserDetailsById.isPeopleBookmarked = false;
            }
            cache.writeQuery({ query, variables: queryParams, data: newData });
        }
    });
    return { deleteBookmarkPersonMutation, options };
};
