var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Dialog as MuiDialog, DialogActions, DialogTitle, IconButton, styled, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { WarningIcon, XOutlineIcon, SuccessCircleOutlineIcon, ExclamationCircleOutlineIcon, InfoOutlineIcon } from 'ui/icons';
import { useTranslation } from 'react-i18next';
const HeaderActionButton = styled(Button)({
    '&:focus': {
        backgroundColor: 'transparent !important'
    }
});
const getNotificationIcon = (color) => {
    let returnNotificationIcon = null;
    switch (color) {
        case 'success':
            returnNotificationIcon = <SuccessCircleOutlineIcon />;
            break;
        case 'warning':
            returnNotificationIcon = <WarningIcon />;
            break;
        case 'error':
            returnNotificationIcon = <ExclamationCircleOutlineIcon />;
            break;
        default:
            returnNotificationIcon = <InfoOutlineIcon />;
            break;
    }
    return returnNotificationIcon;
};
export const Dialog = (_a) => {
    var _b;
    var { children, title, className, hideCloseButton, open = false, variant = 'primary', leftButtonText, rightButtonText, onClose, leftButtonAction, rightButtonAction, hideDialogAction, titleContent, rightButtonColor } = _a, rest = __rest(_a, ["children", "title", "className", "hideCloseButton", "open", "variant", "leftButtonText", "rightButtonText", "onClose", "leftButtonAction", "rightButtonAction", "hideDialogAction", "titleContent", "rightButtonColor"]);
    const notification = (_b = variant.split('notification-')) === null || _b === void 0 ? void 0 : _b[1];
    const color = notification !== null && notification !== void 0 ? notification : variant;
    const rightActionButtonColor = rightButtonColor !== null && rightButtonColor !== void 0 ? rightButtonColor : color;
    const { t } = useTranslation();
    if (!open)
        return null;
    return (<MuiDialog className={className} fullWidth maxWidth="md" onClose={(e, reason) => reason !== 'backdropClick' && onClose && onClose(reason)} open={open} scroll="body" aria-labelledby="modal-dialog-title" color={color} PaperProps={{ className: `${notification ? 'notification-modal' : ''}` }} {...rest}>
      <DialogTitle id="modal-dialog-title" className={`dialog-title ${notification ? 'notification-modal-title' : ''}`}>
        {/* Primary Modal Title */}
        {(variant === 'primary') &&
            <>
            {leftButtonAction && <HeaderActionButton id={`${leftButtonText}-button`} variant="text" onClick={leftButtonAction}>{leftButtonText}</HeaderActionButton>}
            <Typography variant="h5" component="span" fontWeight="500" className="dialog-title">{(titleContent || title)}</Typography>
            {rightButtonAction && <HeaderActionButton id={`${rightButtonText}-button`} variant="text" onClick={rightButtonAction}>{rightButtonText}</HeaderActionButton>}
          </>}

        {/* Notification Title */}
        {(notification) && getNotificationIcon(color)}
        {(title && variant !== 'primary') && title}
        {((variant === 'default' || variant === 'primary' || variant === 'secondary') && !hideCloseButton) &&
            <Tooltip title={<>{t('Close')}</>}>
            <IconButton id="modal-dialog-close-button" aria-label="close" color="primary" className="close-btn" size="medium" onClick={() => onClose && onClose('CLOSE')} sx={{ position: 'absolute', right: 8, top: 8 }}>
              <XOutlineIcon fontSize="small"/>
            </IconButton>
          </Tooltip>}
      </DialogTitle>
      {children}

      {/* Modal Footer Buttons*/}
      {((variant === 'secondary' || notification) && !hideDialogAction) && (<DialogActions>
          {leftButtonAction && <Button id={`${leftButtonText}-button`} fullWidth variant={`${(color === 'secondary') ? 'outlined' : 'contained'}`} color={`${(color === 'secondary') ? 'inherit' : 'primary'}`} size="small" onClick={leftButtonAction}>
            {leftButtonText}
          </Button>}
          {rightButtonAction && <Button id={`${rightButtonText}-button`} fullWidth variant={`${(color === 'secondary') ? 'outlined' : 'contained'}`} color={`${(rightActionButtonColor === 'secondary') ? 'error' : 'primary'}`} size="small" onClick={rightButtonAction}>{rightButtonText}
          </Button>}
        </DialogActions>)}
    </MuiDialog>);
};
