var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef, useState, useEffect } from 'react';
import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useToast } from '@nxlvl/web-providers';
import { ImageCrop } from 'common/components';
import { DialogHead, EditDialog, ThumbnailCardContent } from './EditThumbnailDialog.styled';
import { cropImage } from 'screens/createnft/utils/getCroppedImg';
const commonPageId = 'edit-thumbnail-dialog';
export const EditThumbnailDialog = (props) => {
    const { isOpen, assetImgData, onClose, onEditImage } = props;
    const { t } = useTranslation();
    const { openToast } = useToast();
    const imgRef = useRef(null);
    const [isCompressing, setIsCompressing] = useState(false);
    const [isLandscapeImage, setIsLandscapeImage] = useState(false);
    const [thumbnailCrop, setThumbnailCrop] = useState(assetImgData.crop);
    // Check land-scape image
    useEffect(() => {
        var _a, _b;
        const imageHeight = (((_a = imgRef === null || imgRef === void 0 ? void 0 : imgRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight) || 0);
        const imageWidth = (((_b = imgRef === null || imgRef === void 0 ? void 0 : imgRef.current) === null || _b === void 0 ? void 0 : _b.offsetWidth) || 0);
        const landscape = !!imageHeight && !!imageWidth && (imageWidth > imageHeight);
        setIsLandscapeImage(landscape);
    }, [imgRef.current]);
    const handleSaveThumbnail = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsCompressing(true);
        if (imgRef.current && thumbnailCrop) {
            try {
                const res = yield cropImage({
                    imgSrc: ((assetImgData === null || assetImgData === void 0 ? void 0 : assetImgData.originalUrl) || ''),
                    crop: thumbnailCrop,
                    isCompressed: true
                });
                if (res) {
                    onEditImage(res);
                }
            }
            catch (err) {
                setIsCompressing(false);
                openToast(t('Please resize to reduce image size or replace with another image'), { type: 'error' });
                return;
            }
        }
        else {
            openToast(t('Oops! Something went wrong!'), { type: 'error' });
        }
        setIsCompressing(false);
    });
    return (<EditDialog className={`${commonPageId} dialog-md`} open={!!isOpen} onClose={onClose} maxWidth="md" color="primary" hideCloseButton>
      <DialogContent className="dialog-content">
        <DialogHead variant="h5" id={`${commonPageId}-main-head`} fontWeight="500">
          {t('Set thumbnail')}
        </DialogHead>
        <ThumbnailCardContent>
          <Box className={`img-crop-box ${(isLandscapeImage ? 'landscape-crop-box' : 'portrait-crop-box')}`}>
            <ImageCrop id={`${commonPageId}-thumbnail-crop`} imgSrc={((assetImgData === null || assetImgData === void 0 ? void 0 : assetImgData.originalUrl) || '')} imgRef={imgRef} percentCrop={assetImgData === null || assetImgData === void 0 ? void 0 : assetImgData.crop} onCompletedCrop={setThumbnailCrop} isLandscape={isLandscapeImage}/>
          </Box>
          <Typography className="edit-thumb-helper">
            {t('This is how your NFT will appear in listings. The original won\'t change.')}
          </Typography>
        </ThumbnailCardContent>
      </DialogContent>
      <DialogActions>
        <Button fullWidth id={`${commonPageId}-cancel-button`} type="button" variant="outlined" color="secondary" size="medium" onClick={onClose} disabled={isCompressing}>
          {t('Cancel')}
        </Button>
        <Button fullWidth onClick={handleSaveThumbnail} disabled={(!thumbnailCrop || isCompressing)}>
          {t('Save')}
        </Button>
      </DialogActions>
    </EditDialog>);
};
