import { PostNftActionTypes } from './PostNft.types';
export const setCurrentStep = (payload) => {
    return {
        type: PostNftActionTypes.SET_CURRENT_STEP,
        payload: payload
    };
};
export const setNFTAssets = (nftAssetList) => {
    return {
        type: PostNftActionTypes.SET_NFT_ASSETS,
        payload: nftAssetList
    };
};
export const setNftDetails = (PostNftState) => {
    return {
        type: PostNftActionTypes.SET_NFT_DETAILS,
        payload: PostNftState
    };
};
export const setIsConfirmDialog = (payload) => {
    return {
        type: PostNftActionTypes.SET_IS_CONFRIM_WORK_DIALOG,
        payload
    };
};
