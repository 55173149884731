import { Card, styled, CardContent } from '@mui/material';
import { STATIC_IMG_URL } from 'helpers/constants';
export const NXConnectAdvertiseBox = styled(Card)(({ theme }) => ({
    padding: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '&.variant-horizontal': {
        backgroundImage: `url(${STATIC_IMG_URL}/nx-connect-bg.webp)`,
        height: '10.375rem',
        display: 'flex',
        alignItems: 'center',
        '.nx-advertise-img-box': {
            alignSelf: 'stretch',
            width: '7rem',
            '.nx-advertise-img': {
                borderRadius: '0 0 0.5rem 0.5rem',
            }
        },
        '.nx-connect-icon': {
            [theme.breakpoints.up('lg')]: {
                width: '13.75rem',
            }
        }
    },
    '&.variant-vertical': {
        backgroundImage: `url(${STATIC_IMG_URL}/nx-connect-vertical-bg.webp)`,
        height: 'calc(100% - 0.5rem)',
        backgroundSize: 'contain',
        backgroundPosition: 'bottom',
        '.nx-advertise-img-box': {
            '.nx-advertise-img': {
                height: '100%',
            }
        },
    }
}));
export const NXCardContent = styled(CardContent)(({ theme }) => ({
    textAlign: 'center',
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(1),
    padding: theme.spacing(2.5, 1),
    '.view-btn': {
        margin: `${theme.spacing(1)} 0`,
        textTransform: 'uppercase',
        fontSize: '0.625rem',
        [theme.breakpoints.up('lg')]: {
            fontSize: '0.563rem',
        },
        [theme.breakpoints.up('xl')]: {
            letterSpacing: 0.6,
            fontSize: '0.625rem',
        },
        'svg': {
            fontSize: '0.625rem',
            marginLeft: theme.spacing(1.25),
        }
    },
    '.nx-user-id': {
        fontSize: '0.75rem',
        [theme.breakpoints.up('xl')]: {
            fontSize: '0.875rem',
        },
        'span': {
            borderBottom: `0.063rem solid ${theme.colors.white}`,
        }
    },
    '.add-news-head': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '.nx-connect-icon': {
        width: '7.5rem',
        [theme.breakpoints.up('xl')]: {
            width: '9rem',
        },
    }
}));
