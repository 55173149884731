import { gql, useMutation } from '@apollo/client';
const DWOLLA_ADD_FUND = gql `
  mutation addFundToWallet(
	  	$fundingSourceId: String!,
      $amount: Float!,
	) {
    addFundToWallet(
  		AddFundToWalletArguments: {
    		fundingSourceId: $fundingSourceId, amount: $amount
      }
    	) {
      message,
			status
    }
}`;
export const useAddFundDwolla = () => {
    const [addFundDwollaMutation] = useMutation(DWOLLA_ADD_FUND, {
        notifyOnNetworkStatusChange: true,
    });
    const addFundDwollaWallet = (variables) => {
        return addFundDwollaMutation({
            variables
        });
    };
    return { addFundDwollaWallet };
};
