import { Box, Card, styled } from '@mui/material';
import { STATIC_IMG_URL } from 'helpers/constants';
export const BuildBrandCardBox = styled(Box)(({ theme }) => ({
    '.left-sidebar-card': {
        backgroundImage: `url(${STATIC_IMG_URL}/nx-connect-bg.webp)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    },
    '.nx-brand-logo-icon': {
        width: '1.525rem',
        height: '100%',
        verticalAlign: 'middle',
        [theme.breakpoints.down('xl')]: {
            width: '1.225rem',
        },
        'path': {
            fill: `${theme.colors.blue}`,
        }
    },
    '.nx-logo-icon': {
        width: '0.875rem',
    },
    '.nx-build-heading': {
        fontSize: '1.875rem',
        textTransform: 'uppercase',
        margin: `0 0 ${theme.spacing(1)}`,
        [theme.breakpoints.down('xl')]: {
            fontSize: '1.575rem',
        },
    },
    '.nx-build-sub-heading': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        'span': {
            margin: `0 ${theme.spacing(1)}`,
            textTransform: 'uppercase',
            [theme.breakpoints.down('xl')]: {
                fontSize: '.875rem'
            },
        }
    },
    '&.size-large': {
        '.MuiCard-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '18.75rem',
            backgroundImage: `url(${STATIC_IMG_URL}/login-bg.webp)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }
    },
    '.gradient-border': {
        height: '2px',
        background: 'radial-gradient(50% 50% at 50% 50%, #345BFF 0%, rgba(52, 91, 255, 0) 100%)',
        margin: '.5rem 0'
    }
}));
export const BuildBrandLoader = styled(Card)(() => ({
    paddingBottom: '2rem',
    paddingTop: '2rem',
    width: '100%',
    height: '6.69rem',
}));
