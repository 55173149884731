import React, { useEffect, useRef, useState } from 'react';
import { ResponsiveContainer, StackedCarousel } from 'react-stacked-center-carousel';
import { NftCardContentStacked } from 'common/components';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { GET_DASHBOARD_NFT } from 'graphql-client/dashboard';
import { ChevronLeftOutlineIcon, ChevronRightOutlineIcon } from 'ui/icons';
import { Center3dSliderBox } from './Center3dSlider.styled';
import { NftSlideCard } from './NftSlideCard';
const getCurrentSlides = (length) => {
    if (length < 3)
        return 1;
    if (length < 5)
        return 3;
    return 5;
};
const Center3dSlider = ({ id, nfts, queryParams }) => {
    var _a;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTab = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const ref = useRef(null);
    const [centerSlide, setCenterSlide] = useState(null);
    // Carousel related data
    const carouselData = (nfts || []);
    const currentVisibleSlides = getCurrentSlides(carouselData.length);
    const customScalesLength = ((currentVisibleSlides + 3) / 2);
    const customScales = (isTab ? [1, 0.85, 0.7, 0, 0, 0] : [1, 0.85, 0.7, 0.55, 0, 0]).splice(0, customScalesLength);
    const handleActiveSlide = (index) => {
        if (isTab && carouselData.length > 0) {
            setCenterSlide(carouselData[index]);
        }
    };
    useEffect(() => {
        handleActiveSlide(0);
    }, [carouselData]);
    const CustomNftSlideCard = (props) => {
        return (<NftSlideCard id={id} query={GET_DASHBOARD_NFT} queryParams={queryParams} loading={false} maxVisibleSlide={currentVisibleSlides} {...props}/>);
    };
    return (<>
      <Center3dSliderBox>
        <div className="card">
          <ResponsiveContainer carouselRef={ref} render={(width, carouselRef) => {
            return (<StackedCarousel key={currentVisibleSlides} ref={carouselRef} slideComponent={CustomNftSlideCard} slideWidth={isMobile ? 160 : isTab ? 260 : 334} carouselWidth={width} data={carouselData} maxVisibleSlide={currentVisibleSlides} currentVisibleSlide={currentVisibleSlides} disableSwipe={isDesktop} customScales={customScales} transitionTime={450} onActiveSlideChange={(index) => handleActiveSlide(index)}/>);
        }}/>

          {/* This => disable <= class use for arrow disable */}
          <div className="card-button left" onClick={() => { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.goBack(); }}>
            <ChevronLeftOutlineIcon />
          </div>
          <div className="card-button right" onClick={() => { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.goNext(); }}>
            <ChevronRightOutlineIcon />
          </div>

        </div>
      </Center3dSliderBox>

      {(isTab && !!centerSlide) && (<Box display="flex" justifyContent="center">
          <Box sx={{ width: '80%' }}>
            <NftCardContentStacked id={`${id}-card-${(_a = centerSlide === null || centerSlide === void 0 ? void 0 : centerSlide.node) === null || _a === void 0 ? void 0 : _a.id}`} data={centerSlide === null || centerSlide === void 0 ? void 0 : centerSlide.node} query={GET_DASHBOARD_NFT} queryParams={queryParams} size="large" isLoading={false}/>
          </Box>
        </Box>)}
    </>);
};
export default Center3dSlider;
