import { gql, useMutation } from '@apollo/client';
export const MARK_FIREBASE_NOTIFICATION_DISABLE = gql `
  mutation markNotificationAsDisabled(
    $notificationId: Int!
  ) {
    markNotificationAsDisabled(notificationId: $notificationId)
}
`;
export const useMarkNotificationDisable = () => {
    const [markReadFirebaseNotificationDisable, options] = useMutation(MARK_FIREBASE_NOTIFICATION_DISABLE, {
        notifyOnNetworkStatusChange: true,
    });
    const markFirebaseNotificationDisableMutation = (params) => markReadFirebaseNotificationDisable({
        variables: params
    });
    return { markFirebaseNotificationDisableMutation, options };
};
