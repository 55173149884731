import { gql, useLazyQuery, useQuery, useMutation } from '@apollo/client';
export const GET_DWOLLA_CUSTOMER_BANK_DETAILS = gql `
    query getDwollaBanksByDwollaCustomerUrl(
          $ListBanksArguments: ListBanksArguments!
    ) {
        getDwollaBanksByDwollaCustomerUrl(
          ListBanksArguments: $ListBanksArguments
        ){
            id
            name
            bankAccountType
            status
        }
    }
`;
export const GET_LINK_TOKEN = gql `
    query getLinkToken {
      getLinkToken {
        message
        status
        linkToken
      }
    }
`;
export const EXCHANGE_PUBLIC_TOKEN = gql `
    mutation exchangePublicToken(
      $exchangeTokenArguments: exchangeTokenArguments!
    ) {
      exchangePublicToken(
        exchangeTokenArguments: $exchangeTokenArguments
      ) {
        message
        status
        bankName
        bankType
        fundingSourceId
      }
    }
`;
export const useGetDwollaBankLazyQuery = () => {
    const [getDwollaBank, { data, error }] = useLazyQuery(GET_DWOLLA_CUSTOMER_BANK_DETAILS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });
    return { getDwollaBank, bankData: data, error };
};
export const useGetLinkToken = () => {
    return useQuery(GET_LINK_TOKEN, {
        notifyOnNetworkStatusChange: true,
    });
};
export const useExchangePublicToken = () => {
    const [exchangePublicToken] = useMutation(EXCHANGE_PUBLIC_TOKEN, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });
    return { exchangePublicToken };
};
