import { ResponsiveSideBarMenuCard } from 'common/Layout';
import { useMediaQuery, useTheme } from '@mui/material';
import { CategoryFilter, ViewAllExplore } from '../components';
import React from 'react';
export const DashboardExploreScreen = () => {
    const theme = useTheme();
    const isTab = useMediaQuery(theme.breakpoints.down('lg'));
    return (<>
      {/* ***************** This component is developed static and dynamic work is pending ****************** */}
      {/* <FilterSection /> */}
      {isTab ?
            <ResponsiveSideBarMenuCard isSidebarMenu/>
            :
                <CategoryFilter />}
      <ViewAllExplore />
    </>);
};
// DashboardExploreScreen.getLayout = NextPageLayout;
DashboardExploreScreen.layoutOptions = {
    pageTitle: 'Explore All'
};
