var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Box, Button, CardContent, Divider, IconButton, styled, Typography, Tooltip, } from '@mui/material';
import { useAddNftBookmarkMutation, useAddNftLikeMutation, useDeleteNftBookmarkMutation, useDeleteNftLikeMutation, } from 'graphql-client/dashboard';
import { shortNumberFormat } from 'helpers/utils';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { BookmarkType } from 'types';
import { BookmarkOutlineIcon, HeartOutlineIcon } from 'ui/icons';
import { useAuthDialog } from 'providers';
import { useAppContext, useToast } from '@nxlvl/web-providers';
import { NftCardContentLoader } from './NftCardLoader';
import { NftCardTierBox } from './NftCardTierBox';
import { Avatar } from 'common/components';
import { useTranslation } from 'react-i18next';
import { PAGE_ROUTES, API_FAILURE_MESSAGE } from 'helpers/constants';
import { NftTitle, TypographyWhite60 } from 'styles/global.styled';
const NftCardContent = styled(CardContent)(({ theme }) => ({
    padding: `${theme.spacing(1)} 0 !important`,
    '&.size-large': {
        width: '100%',
    },
}));
const FavoriteBox = styled(Box)(({ theme, active, activeFill }) => ({
    display: 'flex',
    alignItems: 'center',
    '.MuiIconButton-root': {
        padding: 0,
    },
    '.MuiSvgIcon-root': {
        fontSize: '1.5rem',
        color: active && activeFill ? theme.dynamicTheme.mainSection.link.color : theme.colors.white,
        fill: active && activeFill ? theme.dynamicTheme.mainSection.link.color : 'none',
    },
    '.MuiTypography-root': {
        marginLeft: theme.spacing(1),
        color: 'rgba(255, 255, 255, .6)',
        fontSize: '0.75rem',
    },
}));
const FooterBox = styled(Box)(({ theme }) => ({
    '.nft-card-user': {
        marginLeft: `${theme.spacing(0.25)}`,
        marginRight: `${theme.spacing(0.25)}`,
    },
    '.nx-avatar-box': {
        display: 'block',
    },
}));
export const NftCardContentStacked = ({ id, data, size = 'small', query, queryParams, isLoading, showListForSaleButton = false, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { currentUser } = useAppContext();
    const { openAuthDialog } = useAuthDialog();
    const { t } = useTranslation();
    const { addNftLikeMutation, options: likeNftOption } = useAddNftLikeMutation();
    const { deleteNftLikeMutation, options: unLikeNftOption } = useDeleteNftLikeMutation();
    const { addNftBookmarkMutation } = useAddNftBookmarkMutation();
    const { deleteNftBookmarkMutation } = useDeleteNftBookmarkMutation();
    const { openToast } = useToast();
    const navigate = useNavigate();
    const details = {
        userName: ((_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.firstname) + ' ' + ((_b = data === null || data === void 0 ? void 0 : data.user) === null || _b === void 0 ? void 0 : _b.lastname),
        verified: ((_d = (_c = data === null || data === void 0 ? void 0 : data.user) === null || _c === void 0 ? void 0 : _c.dwollaCustomer) === null || _d === void 0 ? void 0 : _d.status) === 'verified' ? 'normal' : null,
        title: data === null || data === void 0 ? void 0 : data.name,
    };
    const currentUserIsCreator = ((_e = data === null || data === void 0 ? void 0 : data.user) === null || _e === void 0 ? void 0 : _e.id) === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id);
    const liked = (data === null || data === void 0 ? void 0 : data.isLiked) || false;
    const bookmarked = (data === null || data === void 0 ? void 0 : data.isNFTBookmarked) || false;
    const isListed = true;
    const isAllowActions = isListed; // || (variant === 'stacked' && edition?.autoGenerated);
    const isNftLiked = !(likeNftOption === null || likeNftOption === void 0 ? void 0 : likeNftOption.loading) && !(unLikeNftOption === null || unLikeNftOption === void 0 ? void 0 : unLikeNftOption.loading);
    const handleLikeNft = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.stopPropagation();
        const like = () => __awaiter(void 0, void 0, void 0, function* () {
            if (query && isNftLiked) {
                if (!liked) {
                    yield addNftLikeMutation({ nftId: Number(data === null || data === void 0 ? void 0 : data.id), query, queryParams });
                }
                else {
                    yield deleteNftLikeMutation({ nftId: Number(data === null || data === void 0 ? void 0 : data.id), query, queryParams });
                }
            }
        });
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)) {
            openAuthDialog().then((res) => {
                res.success && like();
            });
        }
        else {
            like();
        }
    });
    const handleBookmarkNft = (e) => {
        e.stopPropagation();
        const bookmark = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if (query) {
                const bookmarkParams = {
                    nftId: (data === null || data === void 0 ? void 0 : data.id) || 0,
                    query,
                    queryParams,
                    bookmarkType: BookmarkType.Nft,
                };
                if (!bookmarked) {
                    const addBookmarkRes = yield addNftBookmarkMutation(bookmarkParams);
                    if ((_b = (_a = addBookmarkRes === null || addBookmarkRes === void 0 ? void 0 : addBookmarkRes.data) === null || _a === void 0 ? void 0 : _a.createBookmark) === null || _b === void 0 ? void 0 : _b.message) {
                        openToast(((_d = (_c = addBookmarkRes === null || addBookmarkRes === void 0 ? void 0 : addBookmarkRes.data) === null || _c === void 0 ? void 0 : _c.createBookmark) === null || _d === void 0 ? void 0 : _d.message) || '', { type: 'success' });
                    }
                    else {
                        openToast(API_FAILURE_MESSAGE || '', { type: 'error' });
                    }
                }
                else {
                    const deleteBookMarkRes = yield deleteNftBookmarkMutation(bookmarkParams);
                    if ((_f = (_e = deleteBookMarkRes === null || deleteBookMarkRes === void 0 ? void 0 : deleteBookMarkRes.data) === null || _e === void 0 ? void 0 : _e.deleteBookmark) === null || _f === void 0 ? void 0 : _f.message) {
                        openToast(((_h = (_g = deleteBookMarkRes === null || deleteBookMarkRes === void 0 ? void 0 : deleteBookMarkRes.data) === null || _g === void 0 ? void 0 : _g.deleteBookmark) === null || _h === void 0 ? void 0 : _h.message) || '', { type: 'success' });
                    }
                    else {
                        openToast(API_FAILURE_MESSAGE || '', { type: 'error' });
                    }
                }
            }
        });
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)) {
            openAuthDialog().then((res) => {
                res.success && bookmark();
            });
        }
        else {
            bookmark();
        }
    };
    const handleListForSale = (e) => {
        e.stopPropagation();
        navigate(`/relist-nft/${data === null || data === void 0 ? void 0 : data.id}?legendary=true`);
    };
    const redirectOnProfile = (e) => {
        var _a, _b;
        e.preventDefault();
        e.stopPropagation();
        if (((_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.id) === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)) {
            navigate(PAGE_ROUTES.MY_NFT__LISTED);
        }
        else {
            navigate(`${PAGE_ROUTES.NFT}/${((_b = data === null || data === void 0 ? void 0 : data.user) === null || _b === void 0 ? void 0 : _b.id) || 0}/listed`);
        }
    };
    const redirectOnPDP = (e, tier) => {
        e.preventDefault();
        e.stopPropagation();
        if (tier) {
            navigate(`${PAGE_ROUTES.PDP}/${data === null || data === void 0 ? void 0 : data.id}/${tier === null || tier === void 0 ? void 0 : tier.toLowerCase()}`);
        }
    };
    if (isLoading) {
        return <NftCardContentLoader size={size}/>;
    }
    return (<NftCardContent className={`size-${size}`}>
      <TypographyWhite60 noWrap>{details === null || details === void 0 ? void 0 : details.userName}</TypographyWhite60>
      <NftTitle>{details === null || details === void 0 ? void 0 : details.title}</NftTitle>
      <Divider className='thin'/>
      <Box display='flex'>
        {((data === null || data === void 0 ? void 0 : data.associatedTiers) || []).map((tier, i) => {
            return (<Box key={`${i}-${tier}`} onClick={(e) => redirectOnPDP(e, tier)}>
              <NftCardTierBox showContent={false} tier={tier} series={null}/>
            </Box>);
        })}
      </Box>
      <Divider className='thin'/>

      <FooterBox className={`size-${size} ${showListForSaleButton ? 'active-sale-btn' : ''}`}>
        {isAllowActions ? (<>
            <Box display='flex' justifyContent='space-between'>
              <FavoriteBox active={liked} activeFill>
                <Tooltip onClick={(e) => handleLikeNft(e)} title={<>{t(!(data === null || data === void 0 ? void 0 : data.isLiked) ? 'Like NFT' : 'Unlike NFT')}</>}>
                  <IconButton id={`${id}-favorite-button`}>
                    <HeartOutlineIcon />
                  </IconButton>
                </Tooltip>

                <Typography variant='body2' fontWeight='bold' paddingLeft={0} paddingRight={0} className='nft-favorite-text'>
                  {shortNumberFormat((data === null || data === void 0 ? void 0 : data.likeCount) || 0)}
                </Typography>
              </FavoriteBox>

              <FavoriteBox active={bookmarked} activeFill>
                {((_f = data === null || data === void 0 ? void 0 : data.user) === null || _f === void 0 ? void 0 : _f.id) && (<Tooltip title={<>{details === null || details === void 0 ? void 0 : details.userName}</>}>
                    <IconButton size='small' color='inherit' className='nft-card-user' onClick={redirectOnProfile} disableRipple>
                      <Box>
                        <Avatar src={((_g = data === null || data === void 0 ? void 0 : data.user) === null || _g === void 0 ? void 0 : _g.profileImage) || ''} id={`${id}-user-avatar`} name={(details === null || details === void 0 ? void 0 : details.userName) || ''} hideName alt={details === null || details === void 0 ? void 0 : details.userName} verifiedUser={details === null || details === void 0 ? void 0 : details.verified} size='xsmall' className='cursor-pointer'/>
                      </Box>
                    </IconButton>
                  </Tooltip>)}
                <Tooltip onClick={(e) => handleBookmarkNft(e)} title={<>{!(data === null || data === void 0 ? void 0 : data.isNFTBookmarked) ? 'Bookmark NFT' : 'Remove Bookmark'}</>}>
                  <IconButton id={`${id}-bookmark-icon`} size='small' color='inherit'>
                    <BookmarkOutlineIcon />
                  </IconButton>
                </Tooltip>
              </FavoriteBox>
            </Box>
          </>) : (<>
            {showListForSaleButton && (<Button color='primary' size='xsmall' fullWidth disabled={!currentUserIsCreator} onClick={handleListForSale}>
                {currentUserIsCreator ? <>List for sale</> : <>Not yet Listed</>}
              </Button>)}
          </>)}
      </FooterBox>
    </NftCardContent>);
};
