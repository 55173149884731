import { gql, useLazyQuery, useQuery } from '@apollo/client';
export const GET_APPLE_USER_DATA = gql `
  query GetTempAppleUser($id: String!) {
    getTempAppleUser(id: $id) {
      id
      user
    }
  }
`;
export const useGetTempAppleUserQuery = (variables) => {
    return Object.assign(Object.assign({}, useQuery(GET_APPLE_USER_DATA, {
        notifyOnNetworkStatusChange: true,
        variables
    })), { query: GET_APPLE_USER_DATA });
};
export const useGetTempAppleUserLazyQuery = () => {
    const [getTempAppleUser, { loading, data, error }] = useLazyQuery(GET_APPLE_USER_DATA, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
    });
    return { getTempAppleUser, query: GET_APPLE_USER_DATA, loading, data, error };
};
