export const mapElasticNftToDashboardNft = (elasticNft) => {
    var _a, _b, _c;
    if (!elasticNft) {
        return elasticNft;
    }
    const result = {
        __typename: 'DashboardNFT',
        id: (elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.id) || 0,
        name: elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.name,
        associatedTiers: elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.tier,
        userId: elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.userId,
        imageUrl: elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.imageURL,
        isLiked: elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.isLiked,
        likeCount: elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.likeCount,
        isNFTBookmarked: elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.isNFTBookmarked,
        videoData: {
            playbackId: elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.playbackId
        },
        user: {
            id: (elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.userId) || 0,
            firstname: (elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.firstname) || '',
            lastname: (elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.lastname) || '',
            username: (elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.username) || '',
            profileImage: ((elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.profileImage) || ((_a = elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.user) === null || _a === void 0 ? void 0 : _a.profileImage) || ''),
            dwollaCustomer: {
                status: (((_c = (_b = elasticNft === null || elasticNft === void 0 ? void 0 : elasticNft.user) === null || _b === void 0 ? void 0 : _b.dwollaCustomer) === null || _c === void 0 ? void 0 : _c.status) || '')
            }
        },
    };
    return result;
};
