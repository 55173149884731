import { DwollaTnxType } from 'types/graphql.models';
export const getTransactionTypeText = (type) => {
    switch (type) {
        case DwollaTnxType.FundAdded:
            return 'Money Added';
        case DwollaTnxType.Purchased:
            return 'Paid';
        case DwollaTnxType.Sold:
            return 'Received';
        case DwollaTnxType.FundWithdrawn:
            return 'Withdraw';
    }
};
