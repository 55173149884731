var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
export const CustomScrollbar = (props) => {
    const { className = '' } = props, rest = __rest(props, ["className"]);
    return (<Scrollbars className={`custom-scrollbar ${className}`} autoHide renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal"/>} renderThumbVertical={(props) => <div {...props} className="thumb-vertical"/>} {...rest}>
      {props.children}
    </Scrollbars>);
};
