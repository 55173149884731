import { styled, Box, Chip } from '@mui/material';
export const NxTierChipStyle = styled(Chip)(({ theme }) => ({
    margin: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
    '.logo-icon': {
        fontSize: '.875rem',
        transform: 'translateY(0.125rem)',
    },
}));
export const FilterResultBox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));
export const FilterBox = styled(Box)(({ theme }) => ({
    paddingBottom: theme.spacing(3),
    '&.tier-box': {
        paddingTop: theme.spacing(1),
    }
}));
export const PriceRangeBox = styled(Box)(({ theme }) => ({
    padding: `${theme.spacing(3)} 0 0`,
}));
export const PriceRangeTextBoxWrapper = styled(Box)(({ theme }) => ({
    '.MuiInputBase-root': {
        marginTop: '0!important',
    },
    '.MuiInputLabel-root, .MuiFormHelperText-root': {
        display: 'none',
    },
    '.error-text': {
        color: `${theme.colors.error500}!important`
    },
    '.price-range-text-value': {
        color: theme.colors.neutral600,
        fontSize: '0.75rem',
        fontWeight: 600,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiInputBase-input': {
        paddingLeft: '0!important',
        textAlign: 'right',
        fontWeight: 700,
    }
}));
