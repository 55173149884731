var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';
import { getMuxStreamUrl } from 'helpers/utils';
export const MuxVideoPlayer = (props) => {
    const { playbackId, autoPlay = true, controls = true } = props, rest = __rest(props, ["playbackId", "autoPlay", "controls"]);
    const videoRef = useRef(null);
    const src = getMuxStreamUrl(playbackId);
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let hls;
        if (videoRef === null || videoRef === void 0 ? void 0 : videoRef.current) {
            const video = videoRef === null || videoRef === void 0 ? void 0 : videoRef.current;
            if (video.canPlayType('application/vnd.apple.mpegurl')) {
                // Some browsers (safari and ie edge) support HLS natively
                video.src = src;
            }
            else if (Hls.isSupported()) {
                // This will run in all other modern browsers
                hls = new Hls();
                hls.loadSource(src);
                hls.attachMedia(video);
            }
            else {
                console.error('This is a legacy browser that doesn\'t support MSE');
            }
        }
        return () => {
            if (hls)
                hls === null || hls === void 0 ? void 0 : hls.destroy();
        };
    }, [videoRef, src]);
    return (<video ref={videoRef} {...rest} autoPlay={autoPlay} controls={controls}/>);
};
