var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { IconButton } from '@mui/material';
import { SearchOutlineIcon } from 'ui/icons';
import { TextField } from '../..';
import { SearchWrapper } from './SearchBox.styled';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
const commonPageId = 'search-box';
export const SearchBox = () => {
    const formik = useFormik({
        initialValues: {
            search: '',
        },
        validationSchema: Yup.object({
            search: Yup.string(),
        }),
        onSubmit: () => __awaiter(void 0, void 0, void 0, function* () {
            // Implement onSubmit() functionality
        }),
    });
    return (<SearchWrapper>
      <TextField id={`${commonPageId}-book-people`} formik={formik} variant="standard" name="search" margin="none" placeholder="Search Bookmarked People " InputProps={{
            endAdornment: (<IconButton className="nx-search-icon-box" edge="end" type="submit">
              <SearchOutlineIcon className="nx-search-icon stroke-color"/>
            </IconButton>)
        }} className="nx-search-text-input"/>
    </SearchWrapper>);
};
