export const colors = {
    black: '#000',
    blackPrimary: '#252A3C',
    black80: 'rgba(0, 0, 0, 0.8)',
    white: '#fff',
    blue: '#345BFF',
    blueDark: '#141826',
    blueDark60: 'rgba(20, 24, 38, 0.6)',
    neutral025: '#F5F6FA',
    neutral050: '#E9EAF2',
    neutral075: '#DADCE5',
    neutral100: '#CACDD9',
    neutral200: '#B0B3BF',
    neutral300: '#A1A4B2',
    neutral400: '#9094A6',
    neutral450: '#828699',
    neutral500: '#666B80',
    neutral550: '#565C73',
    neutral600: '#474D66',
    neutral700: '#3C4159',
    neutral800: '#31364D',
    neutral900: '#262B40',
    neutral925: '#1F2333',
    error050: '#FFEDF6',
    error500: '#CA126B',
    errorButton: '#EA2182',
    success050: '#EAFDF7',
    success500: '#25C690',
    successButton: '#2FDCA2',
    warning050: '#FFF9E3',
    warning500: '#FFC400',
    warningButton: '#FFD751',
    info050: '#EDF0FF',
    info500: '#345BFF',
    infoButton: '#3479FF',
    tierLegendary: 'linear-gradient(151deg, #d3b056 12%, #6b5323 25%, #846c2e 38%, #d3b056 51%, #846c2e 59%)',
    tierEpic: '#363640',
    tierElite: '#757680',
    tierRare: '#9e5743',
};
