import { styled, Box } from '@mui/material';
export const MenuListResponsiveBox = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    position: 'fixed',
    width: '100%',
    left: '0',
    top: '3.625rem',
    zIndex: 102,
    backgroundColor: theme.dynamicTheme.mainSection.backgroundColor,
    borderTop: `0.063rem solid ${theme.dynamicTheme.mainSection.backgroundColor}`,
    '.text-uppercase': {
        textTransform: 'capitalize',
    },
    '.nx-menu-text span': {
        fontSize: '1rem',
        fontWeight: '500',
    },
    '.nx-main-menu-item': {
        borderRadius: '.75rem',
    },
    '.nx-main-menu-item.active, .nx-main-menu-item.active:hover': {
        background: theme.dynamicTheme.sidebarNavigation.link.colorActive,
    },
    '&.my-nft-menu-list': {
        '.nx-main-menu-item': {
            borderRadius: 0,
        },
    }
}));
export const SelectedMenuBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(.5)} ${theme.spacing(2)}`,
    backgroundColor: theme.dynamicTheme.mainNavigation.backgroundColor,
}));
