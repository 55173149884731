import { useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { NftEditionStatus, NftSortBy } from '../../graphql-client/types/graphql.models';
const checkIsDraftMode = (nftStatus) => {
    return ((nftStatus === null || nftStatus === void 0 ? void 0 : nftStatus.toLocaleLowerCase()) === NftEditionStatus.Draft.toLocaleLowerCase());
};
const readFiltersFromQuery = (queryObj) => {
    const catIdQry = queryObj['catId'] || undefined;
    const tiers = queryObj['tier'] ? queryObj['tier'].split(',') : [];
    const activityIdsQry = queryObj['activityIds'] ? queryObj['activityIds'].toLowerCase().split(',') : [];
    const price = queryObj['price'] || undefined;
    const catId = catIdQry ? [Number(catIdQry)] : [];
    const activityIds = activityIdsQry.map(aStr => Number(aStr));
    const forSale = queryObj['isForSale'] === 'true' ? true : false;
    const filters = {
        tiers: tiers,
        searchString: queryObj === null || queryObj === void 0 ? void 0 : queryObj.query,
        orderBy: queryObj === null || queryObj === void 0 ? void 0 : queryObj.sortBy,
        availableForSale: queryObj === null || queryObj === void 0 ? void 0 : queryObj.availableForSale,
        activityIds: [...catId, ...activityIds],
        price: price,
        isForSale: forSale
    };
    if (price) {
        const priceRange = price.split('-');
        const minPrice = parseFloat(priceRange[0] || '0');
        const maxPrice = parseFloat(priceRange[1] || '0');
        if (minPrice && maxPrice && (minPrice >= 3 && maxPrice <= 50000) && (minPrice <= maxPrice)) {
            filters.priceRange = { min: minPrice, max: maxPrice };
        }
    }
    return filters;
};
const countAppliedFilter = (globalFilters, isDraftMode) => {
    const sortFilterApplied = globalFilters.orderBy;
    const priceFilterApplied = globalFilters.priceRange || null;
    const marketFilterApplied = globalFilters.isForSale;
    const tiersFilterApplied = globalFilters.tiers ? globalFilters.tiers : [];
    const activitiesFilterApplied = globalFilters.activityIds ? globalFilters.activityIds : [];
    const isPriceFilterApplied = !!(!isDraftMode && priceFilterApplied);
    const isSortFilterApplied = !!(sortFilterApplied && (!isDraftMode || (isDraftMode && (sortFilterApplied === NftSortBy.Oldest || sortFilterApplied === NftSortBy.Newest))));
    const isMarketFilterApplied = !!(!isDraftMode && marketFilterApplied);
    const filterCount = Number(isPriceFilterApplied) + Number(isMarketFilterApplied) + Number(isSortFilterApplied) + Number((tiersFilterApplied === null || tiersFilterApplied === void 0 ? void 0 : tiersFilterApplied.length) > 0) + Number((activitiesFilterApplied === null || activitiesFilterApplied === void 0 ? void 0 : activitiesFilterApplied.length) > 0);
    return filterCount;
};
export const useGlobalFilters = () => {
    const { category = '' } = useParams();
    const [searchParams] = useSearchParams();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryObjData = [];
    for (const entry of searchParams.entries()) {
        const [param, value] = entry;
        queryObjData.push([param, value]);
    }
    const queryObj = Object.fromEntries([...queryObjData]);
    const [filters, setFilters] = useState(readFiltersFromQuery(queryObj));
    const [isDraftMode, setIsDraftMode] = useState(checkIsDraftMode(category));
    const [filterCount, setFilterCount] = useState(0);
    useEffect(() => {
        const newFilters = readFiltersFromQuery(queryObj);
        if (JSON.stringify(newFilters) != JSON.stringify(filters)) {
            setFilters(Object.assign({}, newFilters));
        }
        setIsDraftMode(checkIsDraftMode(category));
        // eslint-disable-next-line
    }, [queryObj, category]);
    useEffect(() => {
        setFilterCount(countAppliedFilter(filters, isDraftMode));
    }, [filters, isDraftMode]);
    return { globalFilters: filters, isDraftMode, filterCount };
};
