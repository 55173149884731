import { useAppContext } from 'providers';
import { useGetUserDetailsByIdLazyQuery } from 'graphql-client/auth';
import { getMuxGifURL } from 'helpers/utils';
import { NxCustomImage } from 'common/components';
import React, { useEffect, useState } from 'react';
import { MyNFTMenuList } from '../MyNFTMenuList/MyNFTMenuList';
import { UserCard } from '../UserCard/UserCard';
import { BackgroundImageBox, MyNFTMenuCardBox, SidebarDivider } from './MyNFTMenuCard.styled';
import { MyNFTMenuCardLoader } from './MyNFTMenuCardLoader';
import { PAGE_ROUTES } from 'helpers/constants';
import { useLocation, useParams } from 'react-router-dom';
export const MyNFTMenuCard = () => {
    var _a, _b;
    const { getUserDetailsById, query, data: updatedData, loading } = useGetUserDetailsByIdLazyQuery();
    const [userData, setUserData] = useState(null);
    const location = useLocation();
    const { currentUser } = useAppContext();
    let { userId = 0 } = useParams();
    const isMyNft = location.pathname.startsWith(PAGE_ROUTES.MY_NFT);
    userId = (userId || (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) || 0);
    const queryParams = { id: Number(userId), userId: ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) || 0) };
    useEffect(() => {
        if (userId) {
            getUserDetailsById({
                variables: Object.assign({}, queryParams),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, currentUser === null || currentUser === void 0 ? void 0 : currentUser.id]);
    useEffect(() => {
        if (updatedData === null || updatedData === void 0 ? void 0 : updatedData.getUserDetailsById)
            setUserData(updatedData === null || updatedData === void 0 ? void 0 : updatedData.getUserDetailsById);
    }, [updatedData === null || updatedData === void 0 ? void 0 : updatedData.getUserDetailsById]);
    const videoImgSrc = (userData === null || userData === void 0 ? void 0 : userData.backgroundVideoData) ? ((_a = userData === null || userData === void 0 ? void 0 : userData.backgroundVideoData) === null || _a === void 0 ? void 0 : _a.videoThumbnail) || getMuxGifURL((_b = userData === null || userData === void 0 ? void 0 : userData.backgroundVideoData) === null || _b === void 0 ? void 0 : _b.playbackId) : '';
    const bgImg = (userData === null || userData === void 0 ? void 0 : userData.backgroundImage) || (userData === null || userData === void 0 ? void 0 : userData.backgroundImageThumbnail);
    const imgSrc = videoImgSrc || bgImg;
    if (loading || !userData) {
        return (<MyNFTMenuCardLoader />);
    }
    return (<MyNFTMenuCardBox>
      <BackgroundImageBox>
        {(imgSrc) &&
            <NxCustomImage className="bg-img" src={imgSrc} alt="Background"/>}
      </BackgroundImageBox>
      <UserCard userData={userData} isMyNft={isMyNft} query={query} queryParams={queryParams}/>
      <SidebarDivider />
      <MyNFTMenuList /* userData={userData}  */ />
    </MyNFTMenuCardBox>);
};
