import { Grid } from '@mui/material';
import React from 'react';
export const AdCardListing = ({ index, children, adCardComponent, adBannerComponent, adCardFrequency = 4, adBannerFrequency = 20 }) => {
    /* After every adCardFrequency item will be a sponsored Card and after every adBannerFrequency item we will have NXLVL banner and at last we will have brand banner */
    const shoudShowAdCard = ((index + 1) % adCardFrequency === 0);
    const showAdBanner = ((index + 1) % adBannerFrequency === 0);
    const showAdCard = (shoudShowAdCard && !showAdBanner);
    return (<>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        {children}
      </Grid>
      {(showAdCard) && (<Grid item xs={6} sm={4} md={3} lg={2}>
          {adCardComponent}
        </Grid>)}
      {(showAdBanner) && (<Grid item xs={12} sm={12} md={12} lg={12}>
          {adBannerComponent}
        </Grid>)}
    </>);
};
