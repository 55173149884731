import React from 'react';
import { Box, styled, Typography, useTheme, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NftEditionTier } from 'types';
import { LogoIcon } from 'ui/icons/index';
import { TierCommonIcon, TierEliteIcon, TierEpicIcon, TierLegendaryIcon, TierRareIcon } from 'ui/icons';
export var Tier;
(function (Tier) {
    Tier["Legendary"] = "Legendary";
    Tier["Epic"] = "Epic";
    Tier["Elite"] = "Elite";
    Tier["Rare"] = "Rare";
    Tier["Common"] = "Common";
})(Tier || (Tier = {}));
const TierBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});
const IconBox = styled(Box)(({ theme, bgColor }) => ({
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: bgColor,
    borderRadius: '50%',
    marginRight: theme.spacing(.5),
    '.MuiSvgIcon-root': {
        fontSize: '1.5rem',
        color: theme.colors.white,
    },
    '&.tier-icon-medium': {
        width: '2rem',
        height: '2rem',
        '.MuiSvgIcon-root': {
            fontSize: '2rem'
        },
    },
    '&.tier-icon-large': {
        width: '2.5rem',
        height: '2.5rem',
        '.MuiSvgIcon-root': {
            fontSize: '2.5rem'
        },
    }
}));
const TierName = styled(Typography)(({ theme }) => ({
    display: 'flex',
    textTransform: 'uppercase',
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    fontSize: '0.75rem',
    '&.tier-name-medium': {
        fontSize: '1rem',
    },
    '&.tier-name-large': {
        fontSize: '1.25rem',
    }
}));
const SeriesName = styled(Typography)(({
    display: 'flex',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: '0.75rem',
    color: 'rgba(255, 255, 255, .6)',
}));
const LegendaryTierPrefix = styled('span')(({
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
}));
export const NftCardTierBox = ({ tier, series, showContent = true, size = 'small', hideSeriesData }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isLegendary = tier === NftEditionTier.Legendary;
    const isEpic = tier === NftEditionTier.Epic;
    const isElite = tier === NftEditionTier.Elite;
    const isRare = tier === NftEditionTier.Rare;
    const isCommon = tier === NftEditionTier.Common;
    return (<TierBox className={`tier-size-${size}`}>
      {isLegendary &&
            <Tooltip title={<>{t('Legendary')}</>}>
          <IconBox className={`tier-icon-${size}`} bgColor={theme.colors.tierLegendary}>
            <TierLegendaryIcon />
          </IconBox>
        </Tooltip>}
      {isEpic &&
            <Tooltip title={<>{t('Epic')}</>}>
          <IconBox className={`tier-icon-${size}`} bgColor={theme.colors.tierEpic}>
            <TierEpicIcon />
          </IconBox>
        </Tooltip>}
      {isElite &&
            <Tooltip title={<>{t('Elite')}</>}>
          <IconBox className={`tier-icon-${size}`} bgColor={theme.colors.tierElite}>
            <TierEliteIcon />
          </IconBox>
        </Tooltip>}
      {isRare &&
            <Tooltip title={<>{t('Rare')}</>}>
          <IconBox className={`tier-icon-${size}`} bgColor={theme.colors.tierRare}>
            <TierRareIcon />
          </IconBox>
        </Tooltip>}
      {isCommon &&
            <Tooltip title={<>{t('Common')}</>}>
          <IconBox className={`tier-icon-${size}`} bgColor={theme.colors.black}>
            <TierCommonIcon />
          </IconBox>
        </Tooltip>}
      {(showContent) && (<>
          <TierName className={`tier-name-${size}`}>{isLegendary && <LegendaryTierPrefix>nX<LogoIcon fontSize="inherit"/></LegendaryTierPrefix>}{tier}</TierName>
          {(!isLegendary && !hideSeriesData) && <SeriesName>Series {series}</SeriesName>}
        </>)}
    </TierBox>);
};
