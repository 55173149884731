import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NxlvlCardBox, NxlvlCardLoaderBox } from './NxlvlCard.styled';
import { FooterLogo } from 'ui/shared/logo';
import { Loader } from 'common/components';
import React from 'react';
const commonPageId = 'sidebar-about-nxlvl';
const NxlvlCardLoader = () => {
    return (<NxlvlCardLoaderBox>
            <Stack spacing={2.5}>
                <Stack spacing={1}>
                    <Box display="flex" justifyContent="center">
                        <Loader loading width={56} height={56}/>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Loader loading width="65%" height={15} variant="rectangular"/>
                    </Box>
                </Stack>
                <Stack spacing={1}>
                    <Box>
                        <Loader loading height={15}/>
                    </Box>
                    <Box>
                        <Loader loading height={15}/>
                    </Box>
                </Stack>
                <Stack spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Loader loading height={32} variant="rectangular"/>
                        </Grid>
                        <Grid item xs={6}>
                            <Loader loading height={32} variant="rectangular"/>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </NxlvlCardLoaderBox>);
};
export const NxlvlCard = () => {
    const { t } = useTranslation();
    return (<NxlvlCardBox>
            <Card className="left-sidebar-card">
                <Box textAlign="center">
                    <FooterLogo className="nx-what-nx-box-logo fill-color"/>
                </Box>
                <Typography variant="h4" align="center" className="nx-nxlvl-head">
                    What&apos;s an <span className="text-blue">NXlvl NFT</span>
                </Typography>
                <Typography variant="body2" align="center" className="nx-nxlvl-body">
                    {t('A quick guide to owning a piece of your favorite athlete or performer')}
                </Typography>
                <Box className="nx-flex-box" display="flex" justifyContent="center">
                    <Button id={`${commonPageId}-button-video`} size="xsmall" className="nx-nxlvl-btn">
                        {t('Watch video')}
                    </Button>
                    <Button id={`${commonPageId}-button-faq`} variant="outlined" color="primary" size="xsmall" className="nx-nxlvl-btn">
                        {t('FAQ')}
                    </Button>
                </Box>
            </Card>
        </NxlvlCardBox>);
    return <NxlvlCardLoader />;
};
