import { Dialog } from 'common/components/Dialog';
import { Typography, styled, CardContent } from '@mui/material';
export const EditDialog = styled(Dialog)(({ theme }) => ({
    '.dialog-content': {
        textAlign: 'center',
        paddingTop: `${theme.spacing(2)}!important`,
    },
    '.dialog-title': {
        padding: '0',
    },
    '.close-btn': {
        left: '.5rem',
        right: 'auto',
    },
    '.replace-image-container': {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: `${theme.spacing(2)}!important`,
    },
    '.MuiCardContent-root': {
        '&:last-child': {
            paddingBottom: `${theme.spacing(1)}!important`,
        }
    },
    '.edit-thumb-helper': {
        color: theme.colors.neutral300,
        maxWidth: '25rem',
        fontSize: 14,
        textAlign: 'center',
        margin: '1rem auto'
    }
}));
export const DialogHead = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    color: `${theme.colors.white}!important`,
}));
export const ThumbnailCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: `0 ${theme.spacing(-1)}`,
    '.img-crop-box': {
        backgroundColor: theme.colors.neutral800,
        display: 'block',
        width: 'auto',
    },
}));
