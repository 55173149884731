import React from 'react';
import { Loader } from '../Loader';
export const ButtonContentLoader = ({ size }) => {
    const getButtonContentLoaderBySize = (size) => {
        switch (size) {
            case 'large':
                return <Loader loading height={60} variant="rectangular"/>;
            case 'medium':
                return <Loader loading height={40} variant="rectangular"/>;
            case 'small':
                return <Loader loading height={25} variant="rectangular"/>;
            default:
                return <Loader loading height={10} variant="rectangular"/>;
        }
    };
    return (getButtonContentLoaderBySize(size));
};
