import { NftEditionTier } from 'types';
export const SOCIAL_APP_LINK = 'https://share.nxlvl.app/share-profile/';
export const NFT_LISTING_FEE = 1;
export const CURRENCY_PREFIX_FOR_PRICE = 'USD $';
export const CURRENCY_PREFIX_NAME = 'USD';
export const CURRENCY_PREFIX_SYMBOL = '$';
export const POLYGON_URL = 'https://mumbai.polygonscan.com/tx';
export const DWOLLA_STATUS = {
    VERIFIED: 'verified',
    UNVERIFIED: 'unverified',
    RETRY: 'retry',
    DOCUMENT: 'document',
    DEACTIVATED: 'deactivated',
    SUSPENDED: 'suspended'
};
export const MAX_NUMBER_DWOLLA_BANK = 5;
export const NFT_IMG_RATIO = (9 / 18);
export const IS_PROD = (process.env.NODE_ENV === 'production');
export const STATIC_IMG_URL = (process.env.NEXT_PUBLIC_STATIC_IMG_URL || 'https://datatrdev.blob.core.windows.net/marketplace-static-images');
export const US_STATES = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MP', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UM', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];
export const DEFAULT_IMAGE = `${STATIC_IMG_URL}/login-bg.webp`;
export const PLACE_HOLDER_IMAGE = `${STATIC_IMG_URL}/create-nft-background.webp`;
export const MAX_SNACK_NOTIFICATION = 10;
export const NFT_TIER_NAME = [NftEditionTier.Legendary, NftEditionTier.Epic, NftEditionTier.Elite, NftEditionTier.Rare, NftEditionTier.Common];
export const PRODUCTION_ENABLED = true;
export const API_FAILURE_MESSAGE = 'Oops! Something went wrong!';
export const NXLVL_URL = process.env.REACT_APP_APPLE_REDIRECTED_URL;
export const APPLE_SERVICE_KEY = process.env.REACT_APP_APPLE_SERVICE_KEY;
export const DEFAULT_FEE_PERCENTAGE = 5;
export const MIN_NFT_PRICE = 4;
export const HELP_CENTER_ZOHO_LINK = 'https://nxlvl.zohodesk.com/portal/en/kb/nxlvl';
export const VIDEO_ELEMENT_ID = 'full-view-mux-video';
export const appVersion = '0.30.6';
