/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo, useEffect } from 'react';
import { FilterButton } from 'common/components/Search/FilterSection/FilterSection.styled';
import { FilterResultBox } from 'common/components/Drawer/NxFilter/NxFilter.styled';
import { useTranslation } from 'react-i18next';
import { XOutlineIcon } from 'ui/icons';
import { InputType } from './NxCustomFilter.types';
// import { DrawerProps } from 'common/components/Drawer';
const commonPageId = 'filter-drawer';
// const Drawer = dynamic<DrawerProps>(
//   () => import('common/components/Drawer').then((module) => module.Drawer),
// );
// Function to return objects value based on key with types
const getObjVal = (key, obj) => {
    return obj[key];
};
const NxCustomFilter = (props) => {
    const { fltConfig = {}, drawerOpen, selFilter, onApply } = props;
    const { t } = useTranslation();
    // Internal drawer componet state for filters
    const [filters, setFilters] = useState({});
    // Set initial values to internal drawer state (When drawer opens)
    useEffect(() => {
        if (drawerOpen)
            setFilters(JSON.parse(JSON.stringify(selFilter)));
        // eslint-disable-next-line
    }, [drawerOpen]);
    // Parse applied filter values, That will be display on Top of the listing with parsed values
    const parseValues = (key) => {
        var _a, _b, _c, _d, _e, _f;
        if (((_b = (_a = fltConfig[key]) === null || _a === void 0 ? void 0 : _a.inputData) === null || _b === void 0 ? void 0 : _b.type) === InputType.ChipSelect) {
            const gtValue = getObjVal(key, selFilter);
            const fltConfigObj = fltConfig[key];
            const isMulti = (((_c = fltConfigObj === null || fltConfigObj === void 0 ? void 0 : fltConfigObj.inputData) === null || _c === void 0 ? void 0 : _c.selection) === 'multiple');
            const labelValue = (((_d = fltConfigObj === null || fltConfigObj === void 0 ? void 0 : fltConfigObj.inputData) === null || _d === void 0 ? void 0 : _d.labelValue) || '');
            // Additional data along with value
            const fObj = {};
            if (labelValue) {
                (_e = fltConfigObj === null || fltConfigObj === void 0 ? void 0 : fltConfigObj.options) === null || _e === void 0 ? void 0 : _e.forEach((opt) => {
                    if (fObj && opt.value) {
                        fObj[opt === null || opt === void 0 ? void 0 : opt.value] = Object.assign({}, opt);
                    }
                });
            }
            if (isMulti && Array.isArray(gtValue)) {
                return { value: gtValue.map((v) => { var _a; return (Object.assign({ value: v }, (labelValue && { label: (_a = fObj === null || fObj === void 0 ? void 0 : fObj[v]) === null || _a === void 0 ? void 0 : _a[labelValue], inputData: fltConfigObj === null || fltConfigObj === void 0 ? void 0 : fltConfigObj.inputData }))); }) };
            }
            else if (typeof gtValue === 'string') {
                return Object.assign({ value: gtValue }, (labelValue && { label: (_f = fObj === null || fObj === void 0 ? void 0 : fObj[gtValue]) === null || _f === void 0 ? void 0 : _f[labelValue], inputData: fltConfigObj === null || fltConfigObj === void 0 ? void 0 : fltConfigObj.inputData }));
            }
        }
    };
    // Applied filter will be displayed on Top of Listing
    const appliedFilters = useMemo(() => {
        // Parsed filter object
        const fObj = Object.keys(selFilter).filter((key) => !!getObjVal(key, selFilter)).reduce((obj, key) => {
            return Object.assign(obj, { [key]: parseValues(key) });
        }, {});
        return fObj;
        // eslint-disable-next-line
    }, [selFilter]);
    const hasFilterApplied = (Object.keys(appliedFilters).length > 0);
    const handleFilterChange = (key, value = '', iData) => () => {
        if ((iData === null || iData === void 0 ? void 0 : iData.type) === InputType.ChipSelect) {
            const exValue = getObjVal(key, filters);
            const newValue = (value || '');
            let newFlt = {};
            if ((iData === null || iData === void 0 ? void 0 : iData.selection) === 'multiple') {
                const ary = (Array.isArray(exValue) ? exValue : []);
                const nIndex = ary.indexOf(newValue);
                if (nIndex > -1) {
                    ary.splice(nIndex, 1);
                }
                else {
                    ary.push(newValue);
                }
                if (ary.length > 0)
                    newFlt = { [key]: ary };
            }
            else {
                const isActive = (exValue === newValue);
                if (!isActive)
                    newFlt = { [key]: newValue };
            }
            // Delete value from filter object
            if (!newFlt[key] && filters[key])
                delete filters[key];
            setFilters((pre) => (Object.assign(Object.assign({}, pre), newFlt)));
        }
        else {
            setFilters((flt) => (Object.assign(Object.assign({}, flt), { [key]: value })));
        }
    };
    // Apply filter: Store filter object in outer state
    const applyFilter = (fObj) => {
        onApply(Object.assign({}, fObj));
    };
    // Function to handle remove filter from Top of listing buttons
    const handleRemoveFilter = ({ key, addData, iData }) => () => {
        if ((iData === null || iData === void 0 ? void 0 : iData.type) === InputType.ChipSelect) {
            if ((iData === null || iData === void 0 ? void 0 : iData.selection) === 'multiple') {
                const exValue = getObjVal(key, selFilter);
                const ary = (Array.isArray(exValue) ? [...exValue] : []);
                const nIndex = ary.indexOf(addData === null || addData === void 0 ? void 0 : addData.value);
                if (nIndex > -1)
                    ary.splice(nIndex, 1);
                if (ary.length > 0) {
                    applyFilter(Object.assign(Object.assign({}, selFilter), { [key]: ary }));
                }
                else if (selFilter[key]) {
                    delete selFilter[key];
                    applyFilter(selFilter);
                }
            }
            else if (selFilter[key]) {
                delete selFilter[key];
                applyFilter(selFilter);
            }
        }
        else if (selFilter[key]) {
            delete selFilter[key];
            applyFilter(selFilter);
        }
    };
    // Clear internal component state
    const handleClearAll = () => {
        setFilters({});
    };
    // Reset component's internal and outer bother states
    const handleResetAllFilters = () => {
        // Reset component state
        handleClearAll();
        // Reset outer state
        onApply({});
    };
    return (<>
      {hasFilterApplied && (<FilterResultBox>
          {Object.keys(appliedFilters).map((key, index) => {
                const currValue = getObjVal(key, appliedFilters);
                if (Array.isArray(currValue === null || currValue === void 0 ? void 0 : currValue.value)) {
                    return currValue === null || currValue === void 0 ? void 0 : currValue.value.map((subVal, subIndex) => {
                        return (<FilterButton id={`${commonPageId}-sort-btn`} key={`${subVal === null || subVal === void 0 ? void 0 : subVal.value}-${subIndex}`} color="primary" size="small" className="rounded" endIcon={(<XOutlineIcon />)} onClick={handleRemoveFilter({ key, addData: { value: subVal === null || subVal === void 0 ? void 0 : subVal.value }, iData: subVal === null || subVal === void 0 ? void 0 : subVal.inputData })}>
                    {t((subVal === null || subVal === void 0 ? void 0 : subVal.label) || (subVal === null || subVal === void 0 ? void 0 : subVal.value) || '')}
                  </FilterButton>);
                    });
                }
                return (<FilterButton id={`${commonPageId}-sort-btn`} key={`${key}-${index}`} color="primary" size="small" className="rounded" endIcon={(<XOutlineIcon />)} onClick={handleRemoveFilter({ key, iData: currValue === null || currValue === void 0 ? void 0 : currValue.inputData })}>
                {t((currValue === null || currValue === void 0 ? void 0 : currValue.label) || (currValue === null || currValue === void 0 ? void 0 : currValue.value) || '')}
              </FilterButton>);
            })}
          <FilterButton id={`${commonPageId}-sort-btn`} variant="outlined" color="primary" size="small" className="rounded" onClick={handleResetAllFilters}>
            {t('Clear all')}
          </FilterButton>
        </FilterResultBox>)}
    </>);
};
export default NxCustomFilter;
