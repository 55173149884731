export var AvailableOrderStatus;
(function (AvailableOrderStatus) {
    AvailableOrderStatus["Completed"] = "Completed";
    AvailableOrderStatus["Processing"] = "Processing";
    AvailableOrderStatus["Failed"] = "Failed";
})(AvailableOrderStatus || (AvailableOrderStatus = {}));
export var OrderTnxType;
(function (OrderTnxType) {
    OrderTnxType["CreatorRoyalty"] = "CreatorRoyalty";
})(OrderTnxType || (OrderTnxType = {}));
