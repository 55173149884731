import { gql, useQuery } from '@apollo/client';
export const GET_USER_AND_GROUPED_ACTIVITIES = gql `
    query (
      $userId: Int!,
      $filterUserActivities: Boolean
    ) {
        getGroupedUserActivities(
        userId: $userId
        isUserActivity: false
        filterUserActivities: $filterUserActivities
        ) {
            userActivities {
                activityId
                activityName
            }
            groupedUserActivities {
                name
                activities {
                    id
                    name
                    groupName
                    iconURL
                }
            }
        }
    }
`;
export const useGetUserAndGroupedActivities = ({ userId }) => {
    return useQuery(GET_USER_AND_GROUPED_ACTIVITIES, {
        variables: {
            userId
        },
        notifyOnNetworkStatusChange: true,
    });
};
