var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Box } from '@mui/material';
import React, { createContext, useContext } from 'react';
const FormContext = createContext({});
const useFormContext = () => useContext(FormContext);
const Form = (_a) => {
    var { id, onSubmit, children } = _a, props = __rest(_a, ["id", "onSubmit", "children"]);
    return (<FormContext.Provider value={{
            formId: id,
        }}>
      <Box id={id} component="form" onSubmit={onSubmit} {...props}>
        {children}
      </Box>
    </FormContext.Provider>);
};
export { Form, useFormContext };
