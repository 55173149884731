import PriceFormat from 'common/components/PriceFormat';
import Drawer from 'common/components/Drawer/Drawer';
import { Box, Button, Typography } from '@mui/material';
import { useGetGroupedActivities } from 'graphql-client/activities';
import { useNavigate, useSearchParams, createSearchParams, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NftActivities } from 'common/components/NftActivityModal/NftActivityModal';
import { NftSortBy } from 'types';
import { LogoIcon } from 'ui';
import { useGlobalFilters } from 'helpers/hooks';
import { XOutlineIcon } from 'ui/icons';
import { FilterButton } from 'common/components/Search/FilterSection/FilterSection.styled';
import { FilterBox, FilterResultBox } from './NxFilter.styled';
import { NxFilterChip } from './NxFilterChip';
import { PriceRange } from './PriceRange';
import { PAGE_ROUTES, MIN_NFT_PRICE } from 'helpers/constants';
const commonPageId = 'filter-drawer';
const parseActivities = (activityList, selectedActivityIds) => {
    const groupedUserActivities = [];
    ((activityList === null || activityList === void 0 ? void 0 : activityList.groupedUserActivities) || []).forEach((groupedActivity) => {
        ((groupedActivity === null || groupedActivity === void 0 ? void 0 : groupedActivity.activities) || []).forEach((activity) => {
            const currentSelection = (groupedUserActivities || []).map(e => e.id);
            if ((selectedActivityIds === null || selectedActivityIds === void 0 ? void 0 : selectedActivityIds.includes(((activity === null || activity === void 0 ? void 0 : activity.id) || 0))) && !currentSelection.includes(((activity === null || activity === void 0 ? void 0 : activity.id) || 0))) {
                groupedUserActivities.push({ id: ((activity === null || activity === void 0 ? void 0 : activity.id) || 0), name: ((activity === null || activity === void 0 ? void 0 : activity.name) || '') });
            }
        });
    });
    return groupedUserActivities;
};
export const NxFilter = ({ drawerOpen, onClose }) => {
    var _a;
    const { t } = useTranslation();
    // const router = useRouter();
    const [searchParams] = useSearchParams();
    const queryObj = Object.fromEntries([...searchParams]);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { globalFilters, isDraftMode, filterCount } = useGlobalFilters();
    const [currentTiers, setCurrentTiers] = useState(globalFilters.tiers || []);
    const [currentSort, setCurrentSort] = useState(globalFilters.orderBy || '');
    const [queryActivityIds, setQueryActivityIds] = useState(globalFilters.activityIds || []);
    const [price, setPrice] = useState(globalFilters.price || '');
    const [isApplyDisabled, setIsApplyDisabled] = useState(false);
    const [forSale, setForSale] = useState(globalFilters.isForSale || false);
    const { loading, data: { getGroupedUserActivities: activityList = {} } = {} } = useGetGroupedActivities();
    const handleClearAll = () => {
        setCurrentTiers([]);
        setCurrentSort('');
        setPrice('');
        setQueryActivityIds([]);
        setForSale(false);
    };
    const handleDrawerClose = () => {
        handleCancel();
        onClose();
    };
    const handleTierChange = (tier) => () => {
        const currentTierArray = [...currentTiers];
        const existingTierIndex = currentTierArray.findIndex(t => t === tier);
        if (existingTierIndex !== -1) {
            currentTierArray.splice(existingTierIndex, 1);
        }
        else {
            currentTierArray.push(tier);
        }
        setCurrentTiers(currentTierArray);
    };
    const handleSortByChange = (sortBy) => () => {
        setCurrentSort(sortBy);
    };
    const handleAvailableSaleChange = () => {
        setForSale(!forSale);
    };
    const handleActivityIdsChange = (field, activities) => {
        const activityList = activities.map(activity => activity.id);
        setQueryActivityIds(activityList);
    };
    const handleApplyFilter = () => {
        const newQuery = Object.assign(Object.assign({}, queryObj), { activityIds: queryActivityIds === null || queryActivityIds === void 0 ? void 0 : queryActivityIds.join(','), sortBy: currentSort, price, tier: currentTiers.join(','), isForSale: forSale });
        if (!queryActivityIds.length) {
            delete newQuery['activityIds'];
        }
        if (!currentTiers.length) {
            delete newQuery['tier'];
        }
        if (!currentSort) {
            delete newQuery['sortBy'];
        }
        if (!price) {
            delete newQuery['price'];
        }
        if (!forSale) {
            delete newQuery['isForSale'];
        }
        if (price) {
            const priceRange = price.split('-');
            const minPrice = parseFloat(priceRange[0] || '0');
            const maxPrice = parseFloat(priceRange[1] || '0');
            if (minPrice === MIN_NFT_PRICE && maxPrice === 50000) {
                delete newQuery['price'];
            }
        }
        navigate({
            search: createSearchParams(JSON.parse(JSON.stringify(newQuery))).toString(),
        });
        onClose();
    };
    const handleCancel = () => {
        onClose();
    };
    const handleResetSingleFilter = (queryParam) => () => {
        const newQuery = Object.assign({}, queryObj);
        delete newQuery[queryParam];
        navigate(newQuery);
    };
    const handleRemoveFromArrayFilter = (queryParamName, idToRemove) => () => {
        var _a;
        const paramName = ((queryObj === null || queryObj === void 0 ? void 0 : queryObj.catId) ? 'catId' : queryParamName);
        const arrayFilter = (_a = queryObj[paramName]) === null || _a === void 0 ? void 0 : _a.split(',');
        const ind = arrayFilter === null || arrayFilter === void 0 ? void 0 : arrayFilter.findIndex(t => t === idToRemove);
        if (ind > -1) {
            arrayFilter.splice(ind, 1);
            const arrayQueryParam = arrayFilter.join(',');
            const newQuery = Object.assign(Object.assign({}, queryObj), { [paramName]: arrayQueryParam });
            if (!arrayQueryParam) {
                delete newQuery[paramName];
            }
            navigate(newQuery);
        }
    };
    const handleResetAllFilters = () => {
        handleClearAll();
        const newQuery = Object.assign({}, queryObj);
        delete newQuery['activityIds'];
        delete newQuery['tier'];
        delete newQuery['sortBy'];
        delete newQuery['price'];
        delete newQuery['isForSale'];
        navigate(newQuery);
    };
    useEffect(() => {
        if (price) {
            const priceRange = price.split('-');
            const minPrice = parseFloat(priceRange[0] || '0');
            const maxPrice = parseFloat(priceRange[1] || '0');
            if ((!(minPrice >= MIN_NFT_PRICE && maxPrice <= 50000)) || (minPrice > maxPrice) || minPrice === 0 || maxPrice === 0) {
                setIsApplyDisabled(true);
            }
            else {
                setIsApplyDisabled(false);
            }
        }
    }, [price]);
    useEffect(() => {
        setCurrentTiers(globalFilters.tiers || []);
        setCurrentSort(globalFilters.orderBy || '');
        setPrice(globalFilters.price || '');
        setQueryActivityIds(globalFilters.activityIds || []);
        setForSale(globalFilters.isForSale || false);
    }, [globalFilters]);
    const NftSortBySorted = isDraftMode ? [NftSortBy.Newest, NftSortBy.Oldest] : [NftSortBy.Newest, NftSortBy.Oldest, NftSortBy.MostViewed, NftSortBy.MostLiked, NftSortBy.PriceLowToHigh, NftSortBy.PriceHighToLow];
    const currentActivities = parseActivities(activityList, queryActivityIds);
    // Initially, Default active sort (Newest) must not be selected for Search Route
    const isSearchQuery = (pathname === PAGE_ROUTES.SEARCH && !!queryObj);
    const sortFilterApplied = globalFilters.orderBy;
    const priceFilterApplied = globalFilters.priceRange || null;
    const forSaleFilterApplied = globalFilters.isForSale;
    const tiersFilterApplied = globalFilters.tiers || [];
    const activitiesFilterApplied = parseActivities(activityList, ((_a = globalFilters === null || globalFilters === void 0 ? void 0 : globalFilters.activityIds) === null || _a === void 0 ? void 0 : _a.map(aId => Number(aId))));
    const isPriceFilterApplied = !!(!isDraftMode && priceFilterApplied);
    const isSortFilterApplied = !!(sortFilterApplied && (!isDraftMode || (isDraftMode && (sortFilterApplied === NftSortBy.Oldest || sortFilterApplied === NftSortBy.Newest))));
    const hasFiltersApplied = !!filterCount;
    const isForSaleFilterApplied = !!(!isDraftMode && forSaleFilterApplied);
    return (<>
      <Drawer open={drawerOpen} closeDrawer={handleDrawerClose} color="primary" title="Filter" leftButton="Cancel" rightButton="Apply" rightButtonDisabled={isApplyDisabled} rightButtonAction={handleApplyFilter}>
        {hasFiltersApplied &&
            <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" color="secondary" size="xsmall" sx={{ mt: 3 }} onClick={handleClearAll}>
              {t('Clear all')}
            </Button>
          </Box>}
        <FilterBox>
          <Typography id={`${commonPageId}-activity-text`} variant="h4" fontWeight="500">{t('Sort by')}</Typography>
          {NftSortBySorted.map((sortByType) => {
            // Default sort option based on current sort and search query
            const isDefaultSort = (!isSearchQuery && !currentSort && NftSortBy.Newest === sortByType);
            return (<NxFilterChip key={sortByType} id={`${commonPageId}-sort-chip-${sortByType.toLowerCase()}`} label={t(sortByType)} onClick={handleSortByChange(sortByType)} selected={((currentSort === sortByType) || isDefaultSort)}/>);
        })}
        </FilterBox>

        {!isDraftMode && (<FilterBox>
          <Typography id={`${commonPageId}-market-text`} variant="h4" fontWeight="500">{t('Availability')}</Typography>
          <NxFilterChip key={'Available for sale'} id={`${commonPageId}-market-chip-is-for-sale`} label={t('Available for sale')} onClick={handleAvailableSaleChange} selected={(forSale === true)}/>
        </FilterBox>)}

        <FilterBox>
          <NftActivities loading={loading} activityList={(activityList || {})} formikActivityIds={currentActivities} formikSetActivityValue={handleActivityIdsChange} label={t('Activity')} hideLabelOptional buttonText={t('Select  Activities')}/>
        </FilterBox>
        <FilterBox className="tier-box">
          <Typography id={`${commonPageId}-activity-text`} variant="h4" fontWeight="500">{t('Tier')}</Typography>
          <NxFilterChip id={`${commonPageId}-tier-chip-legendary`} label={<span>nX<LogoIcon className="logo-icon"/>Legendary</span>} onClick={handleTierChange('Legendary')} selected={currentTiers.indexOf('Legendary') !== -1} sx={{ textTransform: 'uppercase' }}/>
          <NxFilterChip id={`${commonPageId}-tier-chip-epic`} label="Epic" onClick={handleTierChange('Epic')} selected={currentTiers.indexOf('Epic') !== -1} sx={{ textTransform: 'uppercase' }}/>
          <NxFilterChip id={`${commonPageId}-tier-chip-elite`} label="Elite" onClick={handleTierChange('Elite')} selected={currentTiers.indexOf('Elite') !== -1} sx={{ textTransform: 'uppercase' }}/>
          <NxFilterChip id={`${commonPageId}-tier-chip-rare`} label="Rare" onClick={handleTierChange('Rare')} selected={currentTiers.indexOf('Rare') !== -1} sx={{ textTransform: 'uppercase' }}/>
          <NxFilterChip id={`${commonPageId}-tier-chip-common`} label="Common" onClick={handleTierChange('Common')} selected={currentTiers.indexOf('Common') !== -1} sx={{ textTransform: 'uppercase' }}/>
        </FilterBox>
        {!isDraftMode && (<PriceRange price={price} setPrice={setPrice} globalFilterPriceRange={globalFilters.priceRange}/>)}
      </Drawer>
      {hasFiltersApplied &&
            <FilterResultBox>
          {activitiesFilterApplied === null || activitiesFilterApplied === void 0 ? void 0 : activitiesFilterApplied.map(activity => <FilterButton id={`${commonPageId}-sort-btn`} key={`filter-applied-activity-${activity.id}`} color="primary" size="small" className="rounded" endIcon={<XOutlineIcon />} onClick={handleRemoveFromArrayFilter('activityIds', String(activity.id))}>
                {activity.name}
              </FilterButton>)}
          {isSortFilterApplied &&
                    <FilterButton id={`${commonPageId}-sort-btn`} color="primary" size="small" className="rounded" endIcon={<XOutlineIcon />} onClick={handleResetSingleFilter('sortBy')}>
              {t(sortFilterApplied)}
            </FilterButton>}
          {isForSaleFilterApplied && (<FilterButton id={`${commonPageId}-sort-btn`} key={'filter-applied-is-for-sale'} color="primary" size="small" className="rounded" endIcon={<XOutlineIcon />} onClick={handleResetSingleFilter('isForSale')}>
              {t('Available for sale')}
            </FilterButton>)}
          {isPriceFilterApplied && (<FilterButton id={`${commonPageId}-price-range-btn`} color="primary" size="small" className="rounded" endIcon={<XOutlineIcon />} onClick={handleResetSingleFilter('price')}>
              <Typography variant="h6" component="span" fontWeight="100">{t('Price: ')}<PriceFormat value={(priceFilterApplied.min || '0.00')}/> - <PriceFormat isOnlySymbol={true} value={(priceFilterApplied.max || '0.00')}/></Typography>
            </FilterButton>)}
          {tiersFilterApplied === null || tiersFilterApplied === void 0 ? void 0 : tiersFilterApplied.map(tier => <FilterButton id={`${commonPageId}-sort-btn`} key={`filter-applied-tier-${tier}`} color="primary" size="small" className="rounded" endIcon={<XOutlineIcon />} onClick={handleRemoveFromArrayFilter('tier', tier)}>
                {tier}
              </FilterButton>)}

          {hasFiltersApplied && (<FilterButton id={`${commonPageId}-sort-btn`} variant="outlined" color="primary" size="small" className="rounded" onClick={handleResetAllFilters}>
              {t('Clear all')}
            </FilterButton>)}
        </FilterResultBox>}
    </>);
};
