var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Box, IconButton, Tooltip, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Avatar } from 'common/components';
import { BookmarkOutlineIcon, FollowingIcon, FollowOutlineIcon, LogoIcon /*, NXLVLSponsorIcon */, } from 'ui/icons';
import { UserCardBox, PublicUserBox, MoreMenuBox } from './UserCard.styled';
import { useTranslation } from 'react-i18next';
import { BookmarkType } from 'types';
import { SOCIAL_APP_LINK, PAGE_ROUTES, API_FAILURE_MESSAGE } from 'helpers/constants';
import { useQRDialog, useAuthDialog } from 'providers';
import { useToast, useAppContext } from '@nxlvl/web-providers';
import { useAddBookmarkPersonMutation, useDeleteBookmarkPersonMutation, } from 'graphql-client/myNFT';
import { useFollowCreator } from 'graphql-client/dashboard';
import { GET_USER_DETAILS_BY_ID } from 'graphql-client/auth';
import { Link, useNavigate } from 'react-router-dom';
const commonPageId = 'user-box';
export const UserCard = ({ isMyNft, userData, query, queryParams }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const { currentUser } = useAppContext();
    const { openAuthDialog } = useAuthDialog();
    const { openQRDialog } = useQRDialog();
    const { toggleFollowCreator } = useFollowCreator();
    const { openToast } = useToast();
    const theme = useTheme();
    const isTab = useMediaQuery(theme.breakpoints.down('lg'));
    const verifiedStatus = ((_a = userData === null || userData === void 0 ? void 0 : userData.dwollaCustomer) === null || _a === void 0 ? void 0 : _a.status) === 'verified' ? 'normal' : null;
    const { addBookmarkPersonMutation } = useAddBookmarkPersonMutation();
    const { deleteBookmarkPersonMutation } = useDeleteBookmarkPersonMutation();
    const navigate = useNavigate();
    const addBookmarkPerson = (userId = 0) => __awaiter(void 0, void 0, void 0, function* () {
        var _e, _f, _g, _h, _j, _k;
        try {
            const res = yield addBookmarkPersonMutation({
                userId: (userData === null || userData === void 0 ? void 0 : userData.id) || 0,
                bookmarkType: BookmarkType.User,
                query,
                queryParams: Object.assign(Object.assign({}, queryParams), { userId }),
            });
            if ((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.createBookmark) === null || _f === void 0 ? void 0 : _f.message) {
                openToast(t((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.createBookmark) === null || _h === void 0 ? void 0 : _h.message), {
                    type: 'success',
                });
            }
            else {
                openToast(t(API_FAILURE_MESSAGE), { type: 'error' });
            }
        }
        catch (e) {
            const error = e;
            openToast(((_k = (_j = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.message) || (error === null || error === void 0 ? void 0 : error.message) || t(API_FAILURE_MESSAGE), {
                type: 'error',
            });
        }
    });
    const deleteBookmarkPerson = () => __awaiter(void 0, void 0, void 0, function* () {
        var _l, _m, _o, _p, _q;
        try {
            const res = yield deleteBookmarkPersonMutation({
                userId: (userData === null || userData === void 0 ? void 0 : userData.id) || 0,
                bookmarkType: BookmarkType.User,
                query,
                queryParams,
            });
            if (((_l = res === null || res === void 0 ? void 0 : res.data) === null || _l === void 0 ? void 0 : _l.deleteBookmark) && userData) {
                openToast(t((_o = (_m = res === null || res === void 0 ? void 0 : res.data) === null || _m === void 0 ? void 0 : _m.deleteBookmark) === null || _o === void 0 ? void 0 : _o.message), {
                    type: 'success',
                });
            }
            else {
                openToast(t(API_FAILURE_MESSAGE), { type: 'error' });
            }
        }
        catch (e) {
            const error = e;
            openToast(((_q = (_p = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _p === void 0 ? void 0 : _p[0]) === null || _q === void 0 ? void 0 : _q.message) || (error === null || error === void 0 ? void 0 : error.message) || t(API_FAILURE_MESSAGE), {
                type: 'error',
            });
        }
    });
    const handleBookmarkPersonClick = () => {
        if (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) {
            (userData === null || userData === void 0 ? void 0 : userData.isPeopleBookmarked) ? deleteBookmarkPerson() : addBookmarkPerson(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id);
        }
        else {
            openAuthDialog()
                .then((res) => {
                var _a;
                if (res.success && !(userData === null || userData === void 0 ? void 0 : userData.isPeopleBookmarked) && ((_a = res === null || res === void 0 ? void 0 : res.user) === null || _a === void 0 ? void 0 : _a.id) !== (userData === null || userData === void 0 ? void 0 : userData.id)) {
                    setTimeout(() => {
                        var _a;
                        addBookmarkPerson((_a = res === null || res === void 0 ? void 0 : res.user) === null || _a === void 0 ? void 0 : _a.id);
                    }, 500);
                }
            })
                .catch((e) => {
                openToast((e === null || e === void 0 ? void 0 : e.message) || t(API_FAILURE_MESSAGE), { type: 'error' });
            });
        }
    };
    const followCreator = (userId = 0) => __awaiter(void 0, void 0, void 0, function* () {
        var _r, _s;
        if ((userData === null || userData === void 0 ? void 0 : userData.id) && userId) {
            try {
                const res = yield toggleFollowCreator({
                    followingId: userData === null || userData === void 0 ? void 0 : userData.id,
                    userId,
                    isFollowing: !!(userData === null || userData === void 0 ? void 0 : userData.isFollowing),
                    query: GET_USER_DETAILS_BY_ID,
                    queryParams: { id: (userData === null || userData === void 0 ? void 0 : userData.id) || 0, userId },
                });
                if (!(res === null || res === void 0 ? void 0 : res.data)) {
                    openToast(t(API_FAILURE_MESSAGE), { type: 'error' });
                }
            }
            catch (e) {
                const error = e;
                openToast(((_s = (_r = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _r === void 0 ? void 0 : _r[0]) === null || _s === void 0 ? void 0 : _s.message) || (error === null || error === void 0 ? void 0 : error.message) || t(API_FAILURE_MESSAGE), {
                    type: 'error',
                });
            }
        }
    });
    const handleFollowclick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) {
            followCreator(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id);
        }
        else {
            openAuthDialog()
                .then((res) => {
                var _a;
                if (res.success && !(userData === null || userData === void 0 ? void 0 : userData.isFollowing) && ((_a = res === null || res === void 0 ? void 0 : res.user) === null || _a === void 0 ? void 0 : _a.id) !== (userData === null || userData === void 0 ? void 0 : userData.id)) {
                    setTimeout(() => {
                        var _a;
                        followCreator((_a = res === null || res === void 0 ? void 0 : res.user) === null || _a === void 0 ? void 0 : _a.id);
                    }, 500);
                }
            })
                .catch((e) => {
                openToast((e === null || e === void 0 ? void 0 : e.message) || t('Oops! Something went wrong!'), { type: 'error' });
            });
        }
    });
    const redirectOnProfile = (userId) => {
        if (userId) {
            if (userId === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)) {
                navigate(PAGE_ROUTES.MY_NFT__LISTED);
            }
            else {
                navigate(`${PAGE_ROUTES.NFT}/${userId}/listed`);
            }
        }
    };
    const renderMoreMenu = () => {
        return (<MoreMenuBox>
        {/*
            ------------ This will be used in future ------------
            <Tooltip title={<>{t('More')}</>} placement="right-end" >
              <IconButton className="more-btn" id={`${commonPageId}-more-btn`}>
                <DotsVerticalFillIcon className="stroke-color dots-btn-icon" />
              </IconButton>
            </Tooltip> */}
        {(userData === null || userData === void 0 ? void 0 : userData.id) !== (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) && (<>
            <Tooltip title={<>{t((userData === null || userData === void 0 ? void 0 : userData.isFollowing) ? 'Following' : 'Follow')}</>} placement='right-end'>
              <IconButton onClick={handleFollowclick} className='more-btn' id={`${commonPageId}-follow-btn`}>
                {(userData === null || userData === void 0 ? void 0 : userData.isFollowing) ? (<FollowingIcon className='stroke-color follow-btn-icon'/>) : (<FollowOutlineIcon className='stroke-color follow-btn-icon'/>)}
              </IconButton>
            </Tooltip>
            <Tooltip onClick={handleBookmarkPersonClick} title={<>
                  {t((userData === null || userData === void 0 ? void 0 : userData.isPeopleBookmarked) ? 'Remove from Bookmark list' : 'Bookmark person')}
                </>} placement='right-end'>
              <IconButton className={`more-btn ${(userData === null || userData === void 0 ? void 0 : userData.isPeopleBookmarked) ? 'active' : ''}`} id={`${commonPageId}-bookmark-btn`}>
                <BookmarkOutlineIcon className='stroke-color book-btn-icon'/>
              </IconButton>
            </Tooltip>
          </>)}
      </MoreMenuBox>);
    };
    return (<UserCardBox>
      {!isMyNft && renderMoreMenu()}
      <PublicUserBox>
        {!isTab && (<Box className='bg-nx-logo'>
            <LogoIcon className='nx-logo-icon' id={`${commonPageId}-logo-icon`}/>
          </Box>)}
        <Box className='nxUser-box-wrapper'>
          <Box className='nx-user-box' onClick={() => redirectOnProfile((userData === null || userData === void 0 ? void 0 : userData.id) || 0)}>
            <Avatar src={(userData === null || userData === void 0 ? void 0 : userData.profileImage) || ''} name={userData ? (userData === null || userData === void 0 ? void 0 : userData.firstname) + ' ' + (userData === null || userData === void 0 ? void 0 : userData.lastname) : ''} userName={userData === null || userData === void 0 ? void 0 : userData.username} id={`${commonPageId}-avatar`} verifiedUser={verifiedStatus} size='medium' className='cursor-pointer'/>
          </Box>
        </Box>
        <Box display='flex' alignItems='flex-start' justifyContent='space-between'>
          <Box>
            <Typography variant='body2' className='nx-avatar-school-name' id={`${commonPageId}-school-name`}>
              {((_d = (_c = (_b = userData === null || userData === void 0 ? void 0 : userData.userInstitutions) === null || _b === void 0 ? void 0 : _b.College) === null || _c === void 0 ? void 0 : _c.institution) === null || _d === void 0 ? void 0 : _d.name) || ''}
            </Typography>
            <Typography variant='body2' className='nx-avatar-interest' id={`${commonPageId}-user-interest`}>
              {((userData === null || userData === void 0 ? void 0 : userData.preferences) || []).map((activity, i) => {
            if (((userData === null || userData === void 0 ? void 0 : userData.preferences) || []).length - 1 === i)
                return activity === null || activity === void 0 ? void 0 : activity.name;
            return (activity === null || activity === void 0 ? void 0 : activity.name) + ' | ';
        })}
            </Typography>
            {isTab ? (<span className='my-profile-link' id={`${commonPageId}-profile-link`}>
                <Link to={`${SOCIAL_APP_LINK}${userData === null || userData === void 0 ? void 0 : userData.username}`}>{t('View Profile')}</Link>
              </span>) : (<span className='my-profile-link' id={`${commonPageId}-profile-link`} onClick={() => {
                openQRDialog('', userData === null || userData === void 0 ? void 0 : userData.username);
            }}>
                {t('View Profile')}
              </span>)}
          </Box>
          {/*
          ******** Will need it in future *********
          <Box>
            <span className="sponsor-text" id={`${commonPageId}-sponsor`}>NXLVL <br />{t('Sponsor')}</span>
            <NXLVLSponsorIcon id={`${commonPageId}-sponsor-icon`} />
          </Box>
        */}
        </Box>
      </PublicUserBox>
    </UserCardBox>);
};
