import { Box, Button, styled } from '@mui/material';
import { XOutlineIcon } from 'ui/icons';
import { useCategoryFilter } from 'helpers/hooks';
import React from 'react';
const CategoryFilterBox = styled(Box)({
    marginBottom: 24,
});
export const CategoryFilter = () => {
    const { handleFilterChange, selectedCategory } = useCategoryFilter('use-category');
    const removeFilter = (type) => () => {
        handleFilterChange(type);
    };
    if (!selectedCategory)
        return null;
    return (<CategoryFilterBox>
      <Button color="primary" size="small" className="rounded" endIcon={<XOutlineIcon onClick={removeFilter('catId')}/>}>
        {selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.name}
      </Button>
    </CategoryFilterBox>);
};
