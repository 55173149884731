import { Box, Button, Card, styled } from '@mui/material';
export const PreviewListBox = styled(Card)(({ theme, isPreviewScreen }) => ({
    padding: isPreviewScreen ? 0 : theme.spacing(2),
    backgroundColor: isPreviewScreen ? 'transparent' : theme.colors.blueDark,
    '.regular-border': {
        borderColor: `${theme.colors.white} !important`,
        borderWidth: '0.125rem !important',
    },
    '.active': {
        '.regular-border': {
            borderColor: `${theme.colors.blue} !important`,
        }
    },
    '.star-icon': {
        position: 'absolute',
        top: '0.25rem',
        right: '0.25rem',
        color: theme.colors.blue,
        fontSize: '1.125rem',
    },
    '.video-play-icon': {
        position: 'absolute',
        top: '0.25rem',
        left: '0.25rem',
        color: theme.colors.black,
        fontSize: '1.125rem',
        'path': {
            fill: theme.colors.white,
        }
    },
    '&.preview-img-wrap': {
        position: 'relative'
    },
    [theme.breakpoints.down('lg')]: {
        borderRadius: 0,
        overflowX: 'scroll',
        '.preview-img-wrap-list': {
            display: 'flex',
            alignItems: 'top',
            width: '26.563rem',
        }
    }
}));
export const PreviewEditBtn = styled(Button)(({ theme }) => ({
    margin: `${theme.spacing(-1.875)} ${theme.spacing(-2)} ${theme.spacing(2)} ${theme.spacing(-2)}`,
    width: '6.063rem',
    [theme.breakpoints.down('lg')]: {
        margin: `${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(2)}`,
        width: '5rem',
    }
}));
export const NftAssetPreviewBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    maxWidth: '100% !important',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        minHeight: 'auto',
        margin: '0 auto',
    },
    'span': {
        position: 'relative !important',
        '.vertical-slide-img': {
            position: 'relative !important',
            maxHeight: 'calc(100vh - 11rem) !important',
            objectFit: 'contain',
            minWidth: '16rem!important',
            [theme.breakpoints.down('md')]: {
                height: 'auto !important',
                maxWidth: '100% !important',
            },
        }
    },
}));
