import { gql, useLazyQuery } from '@apollo/client';
export const GET_NFT_EDITION_QUERY = gql `
    query getNFTEdition(
      $nftEditionStatus: NFTEditionStatus
      $activityIds: [Int]
      $sortBy: NFTSortBy
      $tiers: [NFTEditionTier]
      $userId: Int
      $take: Int
      $cursor: Int
      $pageNo: Int
      $availableForSale: MarketplaceType
      $priceRange: PriceRange
    ) {
      getNFTEdition(
        getNFTEditionsArgs: {
          nftEditionStatus: $nftEditionStatus,
          activityIds: $activityIds,
          sortBy: $sortBy,
          tiers: $tiers,
          userId: $userId,
          take: $take,
          cursor: $cursor,
          pageNo: $pageNo,
          availableForSale: $availableForSale,
          priceRange: $priceRange
        }
      ){
        __typename
        pageEdges{
          node{
            id
            associatedTiers(nftEditionStatus: $nftEditionStatus, userId : $userId)
            tier
            status
            createdAt
            isRelisted
            isBookmarked
            seriesNumber
            nftId
            price
            left
            size
            tier
            autoGenerated
            nft{
              id
              description
              name
              isNFTBookmarked
              isLiked
              likeCount
              imageUrl
              videoData
              viewCount
              user {
                id
                firstname
                lastname
                username
                profileImage
                dwollaCustomer {
                  status
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          totalCount
          pageNo
        }
      }
    }
`;
export const useNFTEditionLazyQuery = () => {
    return useLazyQuery(GET_NFT_EDITION_QUERY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
    });
};
