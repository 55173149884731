import { gql, useMutation } from '@apollo/client';
import { GET_SIDEBAR_COUNT } from 'graphql-client/myNFT/useSidebarCount';
import { NftEditionStatus } from 'types';
export const DELETE_NFT_BOOKMARK = gql `
    mutation nftBookmarkDelete(
        $bookmarkArgs:bookmarkArgument!
    ) {
        deleteBookmark(bookmarkArgs:$bookmarkArgs){
          message
        }
    }
`;
export const useDeleteNftBookmarkMutation = () => {
    const [deleteBookmarkMutation, options] = useMutation(DELETE_NFT_BOOKMARK, {
        notifyOnNetworkStatusChange: true,
    });
    const deleteNftBookmarkMutation = ({ nftId, nftEditionId, bookmarkType, query, queryParams }) => deleteBookmarkMutation({
        variables: { bookmarkArgs: { nftId, nftEditionId, bookmarkType } },
        refetchQueries: () => [{
                query: GET_SIDEBAR_COUNT,
                variables: { userId: null }
            }],
        update: (cache) => {
            var _a;
            const data = cache.readQuery({ query, variables: queryParams });
            const queryNames = ((query === null || query === void 0 ? void 0 : query.definitions) || []).map((queryData) => { var _a; return (_a = queryData === null || queryData === void 0 ? void 0 : queryData.name) === null || _a === void 0 ? void 0 : _a.value; });
            const queryName = ((queryNames === null || queryNames === void 0 ? void 0 : queryNames[0]) || '');
            const newData = JSON.parse(JSON.stringify(data));
            const cursor = newData === null || newData === void 0 ? void 0 : newData[queryName];
            const dataArray = (cursor === null || cursor === void 0 ? void 0 : cursor.__typename) === 'SearchNFTCursor' ? (_a = cursor === null || cursor === void 0 ? void 0 : cursor.pageEdges) === null || _a === void 0 ? void 0 : _a.node : ((cursor === null || cursor === void 0 ? void 0 : cursor.__typename) === 'DashboardCursor' || (cursor === null || cursor === void 0 ? void 0 : cursor.__typename) === 'NFTEditionCursor') ? cursor === null || cursor === void 0 ? void 0 : cursor.pageEdges : null;
            if (dataArray && queryName === 'getNFTEdition' && (queryParams === null || queryParams === void 0 ? void 0 : queryParams.nftEditionStatus) === NftEditionStatus.Bookmarked) {
                // Remove un-bookmarked item from the list of array in Bookmark Tab (My NFT)
                const fi = dataArray.findIndex((nft) => { var _a; return (nft === null || nft === void 0 ? void 0 : nft.__typename) === 'NFTEditionEdge' && (nft === null || nft === void 0 ? void 0 : nft.node) && ((_a = nft === null || nft === void 0 ? void 0 : nft.node) === null || _a === void 0 ? void 0 : _a.id) === nftEditionId; });
                if (fi > -1)
                    dataArray.splice(fi, 1);
            }
            else {
                (dataArray || []).forEach((nft) => {
                    var _a;
                    if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'DashboardEdge' && nft.node && nft.node.id === nftId) {
                        nft.node.isNFTBookmarked = false;
                    }
                    else if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'NFTEditionEdge' && ((_a = nft.node) === null || _a === void 0 ? void 0 : _a.nft) && nft.node.nft.id === nftId) {
                        nft.node.nft.isNFTBookmarked = false;
                    }
                    else if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'NFTEditionEdge' && nft.node && nft.node.id === nftEditionId) {
                        nft.node.isBookmarked = false;
                    }
                    else if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'ElasticNFT' && nft.id === nftId) {
                        nft.isNFTBookmarked = false;
                    }
                });
            }
            cache.writeQuery({ query, variables: queryParams, data: newData });
        }
    });
    return { deleteNftBookmarkMutation, options };
};
