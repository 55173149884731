import React from 'react';
import { Dialog } from 'common/components';
import { useTranslation } from 'react-i18next';
import { Box, DialogContent, Divider, Typography } from '@mui/material';
const commonPageId = 'confirm-delete-bank-dialog';
export const ConfirmMessageDialog = ({ isOpen, onClose, onConfirm, message, rightButtonText, rightButtonColor }) => {
    const { t } = useTranslation();
    return (<Dialog className={`${commonPageId} dialog-md`} open={isOpen} onClose={() => onClose()} maxWidth="md" variant="secondary" title="" leftButtonText={t('Cancel')} rightButtonText={rightButtonText} rightButtonColor={rightButtonColor} leftButtonAction={() => onClose()} rightButtonAction={() => onConfirm()}>
      <DialogContent sx={{ marginY: 4, textAlign: 'center' }}>
        <Box paddingX={6}>
          <Typography variant="h6" sx={{ color: (theme) => theme.colors.white }}>{message}</Typography>
        </Box>
      </DialogContent>
      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Divider />
      </Box>
    </Dialog>);
};
