import { colors } from 'ui/foundations';
const MuiFormHelperText = {
    styleOverrides: {
        root: {
            marginLeft: 0,
            marginRight: 0,
            '&.Mui-error': {
                color: colors.error500
            }
        },
    },
    variants: [],
    defaultProps: {}
};
export default MuiFormHelperText;
