var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { styled, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import { CURRENCY_PREFIX_FOR_PRICE, CURRENCY_PREFIX_SYMBOL } from 'helpers/constants';
const NumberFormatWrapper = styled(Typography)(({ theme }) => ({
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
    '.price-value.bold': {
        fontWeight: theme.typography.fontWeightBold
    }
}));
const PriceFormat = (props) => {
    const { isOnlySymbol, isPriceBold, onValueChange } = props, rest = __rest(props, ["isOnlySymbol", "isPriceBold", "onValueChange"]);
    const prefix = (isOnlySymbol ? CURRENCY_PREFIX_SYMBOL : CURRENCY_PREFIX_FOR_PRICE);
    return (<NumberFormat {...rest} displayType="text" thousandSeparator fixedDecimalScale decimalScale={2} prefix="" renderText={(value) => (<NumberFormatWrapper variant="caption" className={props === null || props === void 0 ? void 0 : props.className}>
          <span className="price-prefix">{prefix}</span>
          <span className={`price-value${(isPriceBold ? ' bold' : '')}`}>{value}</span>
        </NumberFormatWrapper>)} onValueChange={(_a, ...params) => {
            var { formattedValue } = _a, rest = __rest(_a, ["formattedValue"]);
            if (onValueChange) {
                // eslint-disable-next-line
                onValueChange(Object.assign(Object.assign({}, rest), { formattedValue: `${prefix}${formattedValue}` }), ...params);
            }
        }}/>);
};
export default PriceFormat;
