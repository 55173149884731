import React from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
const WithLoadingWrapper = ({ loading, children, }) => {
    if (loading) {
        return (<Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>);
    }
    return children;
};
export default WithLoadingWrapper;
