import { gql, useLazyQuery, useQuery } from '@apollo/client';
export const GET_FIREBASE_USER_STATISTIC = gql `
  query getUserStatistic(
    $userId: Int!
  ) {
    getUserStatistic(userId: $userId) {
      id
      userId                                           
      firebaseNotificationCount
    }
  }
`;
export const useGetUserStatistic = ({ userId }) => {
    return useQuery(GET_FIREBASE_USER_STATISTIC, {
        variables: { userId },
        notifyOnNetworkStatusChange: true,
    });
};
export const useGetUserStatisticLazyQuery = () => {
    const [getFirebaseNotificationStatistic, { data, error }] = useLazyQuery(GET_FIREBASE_USER_STATISTIC, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });
    return { getFirebaseNotificationStatistic, notificationStateData: data, error };
};
