import React from 'react';
import { Loader, NxCustomImage } from 'common/components';
import { STATIC_IMG_URL } from 'helpers/constants';
import { SponsoredCardBox } from './SponsoredCard.styled';
const SponsoredCardLoader = () => {
    return (<SponsoredCardBox>
      <div className="loader-btn-div">
        <Loader loading width="100%" height={56} variant="rectangular"/>
      </div>
    </SponsoredCardBox>);
};
export const SponsoredCard = () => {
    return (<SponsoredCardBox>
      <NxCustomImage className="add-img" src={`${STATIC_IMG_URL}/sponsor.webp`} alt="Background" id="sponsred-card-id"/>
    </SponsoredCardBox>);
    return <SponsoredCardLoader />;
};
