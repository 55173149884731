var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Box, Avatar as MuiAvatar, Typography, Tooltip } from '@mui/material';
import React from 'react';
import { avatarInitials } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { gaEvent, GTagActionTypes, GTagCategoryTypes } from 'lib/gtag';
import { VerifiedMarkIcon, VerifiedGoldenMarkIcon, NxlvlOutlinedIcon } from 'ui/icons';
import { AvatarBox, NotificationBadge, UserBadge } from './Avatar.styled';
import { Link } from 'react-router-dom';
import { getMyNftUrl } from 'helpers/utils';
export const Avatar = (_a) => {
    var { name = '', userId, nameLink, hideName, institutionName = false, dwollaAvatar, singleLetter = false, userName, size, verifiedUser, notificationBadge = false, currentUserId, customClassName = '', pageRoute, enableClicked = true, isLinked = true } = _a, props = __rest(_a, ["name", "userId", "nameLink", "hideName", "institutionName", "dwollaAvatar", "singleLetter", "userName", "size", "verifiedUser", "notificationBadge", "currentUserId", "customClassName", "pageRoute", "enableClicked", "isLinked"]);
    const { t } = useTranslation();
    const handleUserNameClick = () => {
        gaEvent({
            action: GTagActionTypes.Creator_Name,
            category: GTagCategoryTypes.NFT_PDP,
            label: name + ` (${userId})`
        });
    };
    const clickedOnAvatar = (e) => {
        if (enableClicked) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const renderUserAvatar = () => {
        return (<AvatarBox className={`size-${size} ${customClassName}`}>
        <Box className="nx-avatar-box" display="flex">
          <UserBadge className={`${verifiedUser}-badge`}>
            {(!dwollaAvatar) ?
                (<MuiAvatar {...avatarInitials(name, singleLetter)} className="nx-avatar-img" {...props}/>)
                : (<NxlvlOutlinedIcon className="dwolla-avatar"/>)}
            {(verifiedUser) && ((verifiedUser === 'golden') ?
                <Tooltip title={<>{t('Verified')}</>}>
                  <div>
                    <VerifiedGoldenMarkIcon className={`badge-icon size-${size}`}/>
                  </div>
                </Tooltip> :
                <Tooltip title={<>{t('Verified')}</>}>
                  <div>
                    <VerifiedMarkIcon className={`badge-icon size-${size}`}/>
                  </div>
                </Tooltip>)}
            {(notificationBadge) && <NotificationBadge className={`size-${size}`}/>}
          </UserBadge>
          <Box className="nx-user-info">
            {userName && <Typography variant="body1" className="nx-avatar-id">{userName}</Typography>}
            {!hideName &&
                <Typography variant="body1" className={`nx-avatar-name ${customClassName}`}>
                {nameLink ? <Link to={nameLink}><a onClick={handleUserNameClick}>{name}</a></Link> : name}
              </Typography>}
            {institutionName && <Typography variant="body2" className="nx-avatar-school-name">Bloomington High School</Typography>}
          </Box>
        </Box>
      </AvatarBox>);
    };
    if (isLinked) {
        return (<Box onClick={clickedOnAvatar}>
        <Link to={getMyNftUrl((userId || 0), (currentUserId || 0), pageRoute)} target="_blank" rel="noreferrer">
          {renderUserAvatar()}
        </Link>
      </Box>);
    }
    else {
        return (<>
        {renderUserAvatar()}
      </>);
    }
};
