import { Box, Button, styled } from '@mui/material';
export const DrawerHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}`,
    '.MuiSvgIcon-root': {
        color: theme.colors.white,
    },
    '.left-button-text': {
        display: 'flex',
        alignItems: 'center',
        '.MuiSvgIcon-root': {
            marginRight: 8,
        },
    },
    '.drawer-header-icon': {
        padding: 0
    }
}));
export const HeaderActionButton = styled(Button)(() => ({
    fontSize: '0.875rem !important',
}));
export const DrawerContainer = styled(Box)(({ theme }) => ({
    padding: `0 ${theme.spacing(2)} `,
    '.error-text': {
        color: `${theme.colors.error500}!important`
    },
}));
