export var NotificationSubtype;
(function (NotificationSubtype) {
    NotificationSubtype["nftTransferFailed"] = "nftTransferFailed";
    NotificationSubtype["nftPurchased"] = "nftPurchased";
    NotificationSubtype["paymentDebited"] = "paymentDebited";
    NotificationSubtype["nftSold"] = "nftSold";
    NotificationSubtype["paymentCredited"] = "paymentCredited";
    NotificationSubtype["nftMinted"] = "nftMinted";
    NotificationSubtype["nftListed"] = "nftListed";
    NotificationSubtype["nftListFailed"] = "nftListFailed";
    NotificationSubtype["dwollaPaymentFailed"] = "dwollaPaymentFailed";
    NotificationSubtype["walletVerified"] = "walletVerified";
    NotificationSubtype["walletSuspended"] = "walletSuspended";
    NotificationSubtype["walletDeactivated"] = "walletDeactivated";
    NotificationSubtype["walletReActivated"] = "walletReActivated";
    NotificationSubtype["walletMoreDetailsRequired"] = "walletMoreDetailsRequired";
    NotificationSubtype["walletDocumentFailed"] = "walletDocumentFailed";
    NotificationSubtype["bankAdded"] = "bankAdded";
    NotificationSubtype["bankVerified"] = "bankVerified";
    NotificationSubtype["bankUnverified"] = "bankUnverified";
    NotificationSubtype["fundAddedCompleted"] = "fundAddedCompleted";
    NotificationSubtype["fundWithdrawCompleted"] = "fundWithdrawCompleted";
    NotificationSubtype["fundAddedFailed"] = "fundAddedFailed";
    NotificationSubtype["fundWithdrawFailed"] = "fundWithdrawFailed";
    NotificationSubtype["royaltyReceived"] = "royaltyReceived";
})(NotificationSubtype || (NotificationSubtype = {}));
