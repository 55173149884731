import React, { useRef, useEffect, useState } from 'react';
export const DndFilesUpload = ({ children, onDropFiles }) => {
    const dragRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    useEffect(() => {
        var _a, _b, _c, _d;
        (_a = dragRef === null || dragRef === void 0 ? void 0 : dragRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('dragover', handleDrag);
        (_b = dragRef === null || dragRef === void 0 ? void 0 : dragRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener('drop', handleDrop);
        (_c = dragRef === null || dragRef === void 0 ? void 0 : dragRef.current) === null || _c === void 0 ? void 0 : _c.addEventListener('dragenter', handleDrag);
        (_d = dragRef === null || dragRef === void 0 ? void 0 : dragRef.current) === null || _d === void 0 ? void 0 : _d.addEventListener('dragleave', handleDrag);
        return () => {
            var _a, _b, _c, _d;
            (_a = dragRef === null || dragRef === void 0 ? void 0 : dragRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('dragover', handleDrag);
            (_b = dragRef === null || dragRef === void 0 ? void 0 : dragRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('drop', handleDrop);
            (_c = dragRef === null || dragRef === void 0 ? void 0 : dragRef.current) === null || _c === void 0 ? void 0 : _c.removeEventListener('dragenter', handleDrag);
            (_d = dragRef === null || dragRef === void 0 ? void 0 : dragRef.current) === null || _d === void 0 ? void 0 : _d.removeEventListener('dragleave', handleDrag);
        };
    }, []);
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if ((e.type === 'dragenter' || e.type === 'dragover')) {
            setIsDragging(true);
        }
        else if (e.type === 'dragleave') {
            setIsDragging(false);
        }
    };
    const handleDrop = (e) => {
        var _a, _b;
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        if (e.dataTransfer) {
            onDropFiles(e.dataTransfer.files);
            (_b = (_a = e.dataTransfer) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.clear();
        }
    };
    return (<div ref={dragRef} className={(isDragging ? 'dragging-files' : '')}>
      {children}
    </div>);
};
