var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Box, Button, CardContent, Divider, IconButton, styled, Tooltip, Typography, } from '@mui/material';
import { useAuthDialog } from 'providers';
import { useAppContext, useToast } from '@nxlvl/web-providers';
import { useAddNftBookmarkMutation, useAddNftLikeMutation, useDeleteNftBookmarkMutation, useDeleteNftLikeMutation, } from 'graphql-client/dashboard';
import { shortNumberFormat } from 'helpers/utils';
import { BookmarkOutlineIcon, HeartOutlineIcon } from 'ui/icons';
import { NftCardContentLoader } from './NftCardLoader';
import { NftCardTierBox, Tier } from './NftCardTierBox';
import React from 'react';
import { BookmarkType, NftStatus } from 'types';
import { PAGE_ROUTES, API_FAILURE_MESSAGE } from 'helpers/constants';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'common/components';
import PriceFormat from 'common/components/PriceFormat';
import { NftTitle, TypographyWhite60 } from 'styles/global.styled';
const NftItemCardContent = styled(CardContent)(({ theme }) => ({
    padding: `${theme.spacing(1)} 0!important`,
    '&.size-large': {
        width: '100%',
    },
}));
const TypographyWhite100 = styled(Typography)(() => ({
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
    letterSpacing: 0.12,
}));
const TypographyAmount = styled(Typography)(() => ({
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    letterSpacing: 0.12,
}));
const FavoriteBox = styled(Box)(({ theme, active, activeFill }) => ({
    display: 'flex',
    alignItems: 'center',
    '.MuiIconButton-root': {
        padding: 0,
    },
    '.MuiSvgIcon-root': {
        fontSize: '1.5rem',
        color: active && activeFill ? theme.dynamicTheme.mainSection.link.color : theme.colors.white,
        fill: active && activeFill ? theme.dynamicTheme.mainSection.link.color : 'none',
    },
    '.MuiTypography-root': {
        marginLeft: theme.spacing(1),
        color: 'rgba(255, 255, 255, .6)',
        fontSize: '0.75rem',
    },
    '.MuiAvatar-root': {
        width: '1.5rem',
        height: '1.5rem',
        fontSize: '0.75rem',
    },
}));
const FooterBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&.active-sale-btn': {
        minHeight: '2rem',
    },
    '.nft-card-user': {
        marginLeft: `${theme.spacing(0.25)}`,
        marginRight: `${theme.spacing(0.25)}`,
        cursor: 'default',
    },
    '.nx-avatar-box': {
        display: 'block',
    },
}));
export const NftCardContent = ({ id, data, size = 'small', query, queryParams, isLoading, variant, showListForSaleButton = false, showActionsOnBookmarkTab = false, edition, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { t } = useTranslation();
    const { currentUser } = useAppContext();
    const { openAuthDialog } = useAuthDialog();
    // const router = useRouter();
    const navigate = useNavigate();
    const { openToast } = useToast();
    const { addNftLikeMutation, options: likeNftOption } = useAddNftLikeMutation();
    const { deleteNftLikeMutation, options: unLikeNftOption } = useDeleteNftLikeMutation();
    const { addNftBookmarkMutation } = useAddNftBookmarkMutation();
    const { deleteNftBookmarkMutation } = useDeleteNftBookmarkMutation();
    const details = {
        userName: ((_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.firstname) + ' ' + ((_b = data === null || data === void 0 ? void 0 : data.user) === null || _b === void 0 ? void 0 : _b.lastname),
        title: data === null || data === void 0 ? void 0 : data.name,
        description: data === null || data === void 0 ? void 0 : data.description,
        tier: edition === null || edition === void 0 ? void 0 : edition.tier,
    };
    const isNFTStacked = variant === 'stacked';
    const isLegendary = details.tier === Tier.Legendary;
    // *************** Serializable: NOT IN MVP ******************
    // const isNumbered = (details.tier === Tier.Epic || details.tier === Tier.Elite);
    const currentUserOwnsNft = ((_c = data === null || data === void 0 ? void 0 : data.user) === null || _c === void 0 ? void 0 : _c.id) === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id); // TODO: how to check if current user owns the NFT
    const verifiedStatus = ((_e = (_d = data === null || data === void 0 ? void 0 : data.user) === null || _d === void 0 ? void 0 : _d.dwollaCustomer) === null || _e === void 0 ? void 0 : _e.status) === 'verified' ? 'normal' : null;
    const liked = (data === null || data === void 0 ? void 0 : data.isLiked) || false;
    const bookmarked = (edition === null || edition === void 0 ? void 0 : edition.isBookmarked) || false;
    const isNFTBookmarked = ((_f = edition === null || edition === void 0 ? void 0 : edition.nft) === null || _f === void 0 ? void 0 : _f.isNFTBookmarked) || false;
    const finalBookmark = isNFTStacked ? isNFTBookmarked : bookmarked;
    const isDraft = (edition === null || edition === void 0 ? void 0 : edition.status) === NftStatus.Draft;
    const isAllowActions = !isDraft || (isNFTStacked && (edition === null || edition === void 0 ? void 0 : edition.autoGenerated)) || showActionsOnBookmarkTab;
    const isNftLiked = !(likeNftOption === null || likeNftOption === void 0 ? void 0 : likeNftOption.loading) && !(unLikeNftOption === null || unLikeNftOption === void 0 ? void 0 : unLikeNftOption.loading);
    const handleLikeNft = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.stopPropagation();
        const like = () => __awaiter(void 0, void 0, void 0, function* () {
            if (query && isNftLiked) {
                if (!liked) {
                    yield addNftLikeMutation({ nftId: Number(data === null || data === void 0 ? void 0 : data.id), query, queryParams });
                }
                else {
                    yield deleteNftLikeMutation({ nftId: Number(data === null || data === void 0 ? void 0 : data.id), query, queryParams });
                }
            }
        });
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)) {
            openAuthDialog().then((res) => {
                res.success && like();
            });
        }
        else {
            like();
        }
    });
    const handleBookmarkNft = (e) => {
        e.stopPropagation();
        const bookmark = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if (query) {
                const bookmarkParams = {
                    query,
                    queryParams,
                    bookmarkType: BookmarkType.Edition,
                };
                if (isNFTStacked) {
                    bookmarkParams.nftId = (data === null || data === void 0 ? void 0 : data.id) || 0;
                    bookmarkParams.bookmarkType = BookmarkType.Nft;
                }
                else {
                    bookmarkParams.nftEditionId = (edition === null || edition === void 0 ? void 0 : edition.id) || 0;
                    bookmarkParams.bookmarkType = BookmarkType.Edition;
                }
                if (!finalBookmark) {
                    const addBookmarkRes = yield addNftBookmarkMutation(bookmarkParams);
                    if ((_b = (_a = addBookmarkRes === null || addBookmarkRes === void 0 ? void 0 : addBookmarkRes.data) === null || _a === void 0 ? void 0 : _a.createBookmark) === null || _b === void 0 ? void 0 : _b.message) {
                        openToast(((_d = (_c = addBookmarkRes === null || addBookmarkRes === void 0 ? void 0 : addBookmarkRes.data) === null || _c === void 0 ? void 0 : _c.createBookmark) === null || _d === void 0 ? void 0 : _d.message) || '', { type: 'success' });
                    }
                    else {
                        openToast(API_FAILURE_MESSAGE || '', { type: 'error' });
                    }
                }
                else {
                    const deleteBookMarkRes = yield deleteNftBookmarkMutation(bookmarkParams);
                    if ((_f = (_e = deleteBookMarkRes === null || deleteBookMarkRes === void 0 ? void 0 : deleteBookMarkRes.data) === null || _e === void 0 ? void 0 : _e.deleteBookmark) === null || _f === void 0 ? void 0 : _f.message) {
                        openToast(((_h = (_g = deleteBookMarkRes === null || deleteBookMarkRes === void 0 ? void 0 : deleteBookMarkRes.data) === null || _g === void 0 ? void 0 : _g.deleteBookmark) === null || _h === void 0 ? void 0 : _h.message) || '', { type: 'success' });
                    }
                    else {
                        openToast(API_FAILURE_MESSAGE || '', { type: 'error' });
                    }
                }
            }
        });
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)) {
            openAuthDialog().then((res) => {
                res.success && bookmark();
            });
        }
        else {
            bookmark();
        }
    };
    const handleListForSale = (e) => {
        e.stopPropagation();
        navigate(`/relist-nft/${edition === null || edition === void 0 ? void 0 : edition.nftId}?legendary=true`);
    };
    const redirectOnProfile = (e) => {
        var _a, _b;
        e.preventDefault();
        e.stopPropagation();
        if (((_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.id) === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)) {
            navigate(PAGE_ROUTES.MY_NFT__LISTED);
        }
        else {
            navigate(`${PAGE_ROUTES.NFT}/${((_b = data === null || data === void 0 ? void 0 : data.user) === null || _b === void 0 ? void 0 : _b.id) || 0}/listed`);
        }
    };
    const redirectOnPDP = (e, tier) => {
        e.preventDefault();
        e.stopPropagation();
        if (tier) {
            navigate(`${PAGE_ROUTES.PDP}/${edition === null || edition === void 0 ? void 0 : edition.nftId}/${tier === null || tier === void 0 ? void 0 : tier.toLowerCase()}`);
        }
    };
    if (isLoading) {
        return <NftCardContentLoader size={size}/>;
    }
    return (<NftItemCardContent className={`size-${size}`}>
      <TypographyWhite60 noWrap>{details === null || details === void 0 ? void 0 : details.userName}</TypographyWhite60>
      <NftTitle>{details === null || details === void 0 ? void 0 : details.title}</NftTitle>
      <Divider className='thin'/>
      {variant !== 'stacked' ? (<Box onClick={(e) => redirectOnPDP(e, edition === null || edition === void 0 ? void 0 : edition.tier)}>
          <NftCardTierBox tier={((edition === null || edition === void 0 ? void 0 : edition.tier) || null)} series={(edition === null || edition === void 0 ? void 0 : edition.seriesNumber) || 1}/>
        </Box>) : (<Box display='flex'>
          {((edition === null || edition === void 0 ? void 0 : edition.associatedTiers) || []).map((tier, i) => {
                return (<Box key={`${i}-${tier}`} onClick={(e) => redirectOnPDP(e, tier)}>
                <NftCardTierBox showContent={false} tier={tier} series={(edition === null || edition === void 0 ? void 0 : edition.seriesNumber) || null}/>
              </Box>);
            })}
        </Box>)}

      <Divider className='thin'/>

      {variant !== 'stacked' && (<>
          {isLegendary ? (<TypographyWhite100>1 of 1</TypographyWhite100>) : (<Box sx={{ whiteSpace: 'nowrap', lineHeight: 1 }}>
              <TypographyWhite100 display='inline'>
                {!(edition === null || edition === void 0 ? void 0 : edition.left) ? 'Sold out' : `${edition === null || edition === void 0 ? void 0 : edition.left} left`}
              </TypographyWhite100>
              <TypographyWhite60 display='inline'>
                /{edition === null || edition === void 0 ? void 0 : edition.size}
                {/*
                *************** Serializable: NOT IN MVP ******************
                  {isNumbered && 'numbered'}
                */}
              </TypographyWhite60>
            </Box>)}
        </>)}

      <FooterBox className={`size-${size} ${showListForSaleButton ? 'active-sale-btn' : ''}`}>
        {isAllowActions ? (<>
            {variant !== 'stacked' && (<TypographyAmount>
                <PriceFormat value={edition === null || edition === void 0 ? void 0 : edition.price}/>
              </TypographyAmount>)}
            <Box display='flex' justifyContent='space-between'>
              <FavoriteBox active={liked} activeFill>
                <Tooltip onClick={(e) => handleLikeNft(e)} title={<>{!(data === null || data === void 0 ? void 0 : data.isLiked) ? 'Like NFT' : 'Unlike NFT'}</>}>
                  <IconButton id={`${id}-favorite-button`}>
                    <HeartOutlineIcon />
                  </IconButton>
                </Tooltip>

                <Typography variant='body2' fontWeight='bold' paddingLeft={0} paddingRight={0} className='nft-favorite-text'>
                  {shortNumberFormat((data === null || data === void 0 ? void 0 : data.likeCount) || 0)}
                </Typography>
              </FavoriteBox>

              <FavoriteBox active={finalBookmark} activeFill>
                {((_g = data === null || data === void 0 ? void 0 : data.user) === null || _g === void 0 ? void 0 : _g.id) && (<Tooltip title={<>{details === null || details === void 0 ? void 0 : details.userName}</>}>
                    <IconButton size='small' color='inherit' className='nft-card-user' onClick={redirectOnProfile} disableRipple>
                      <Box>
                        <Avatar id={`${id}-user-avatar`} name={(details === null || details === void 0 ? void 0 : details.userName) || ''} hideName alt={(details === null || details === void 0 ? void 0 : details.userName) || ''} src={((_h = data === null || data === void 0 ? void 0 : data.user) === null || _h === void 0 ? void 0 : _h.profileImage) || ''} verifiedUser={verifiedStatus} className='cursor-pointer'/>
                      </Box>
                    </IconButton>
                  </Tooltip>)}
                <Tooltip onClick={(e) => handleBookmarkNft(e)} title={<>{!((_j = edition === null || edition === void 0 ? void 0 : edition.nft) === null || _j === void 0 ? void 0 : _j.isNFTBookmarked) ? 'Bookmark NFT' : 'Remove Bookmark'}</>}>
                  <IconButton id={`${id}-bookmark-icon`} size='small' color='inherit'>
                    <BookmarkOutlineIcon />
                  </IconButton>
                </Tooltip>
              </FavoriteBox>
            </Box>
          </>) : (<>
            {showListForSaleButton && (<Button color='primary' size='xsmall' fullWidth disabled={!currentUserOwnsNft} onClick={handleListForSale}>
                {currentUserOwnsNft ? <>{t('List for sale')}</> : <>{t('Not yet Listed')}</>}
              </Button>)}
          </>)}
      </FooterBox>
    </NftItemCardContent>);
};
