import { gql, useMutation } from '@apollo/client';
import { GET_SIDEBAR_COUNT } from 'graphql-client/myNFT/useSidebarCount';
// eslint-disable-next-line camelcase
export const Add_NFT_BOOKMARK = gql `
    mutation addbookmark (
        $bookmarkArgs:bookmarkArgument!
    ){
        createBookmark(bookmarkArgs: $bookmarkArgs){
          message
        }
    }
`;
export const useAddNftBookmarkMutation = () => {
    const [addBookmarkMutation, options] = useMutation(Add_NFT_BOOKMARK, {
        notifyOnNetworkStatusChange: true,
    });
    const addNftBookmarkMutation = ({ nftId, nftEditionId, bookmarkType, query, queryParams }) => addBookmarkMutation({
        variables: { bookmarkArgs: { nftId, nftEditionId, bookmarkType } },
        refetchQueries: () => [{
                query: GET_SIDEBAR_COUNT,
                variables: { userId: null }
            }],
        update: (cache) => {
            var _a;
            const data = cache.readQuery({ query, variables: queryParams });
            const queryNames = ((query === null || query === void 0 ? void 0 : query.definitions) || []).map((queryData) => { var _a; return (_a = queryData === null || queryData === void 0 ? void 0 : queryData.name) === null || _a === void 0 ? void 0 : _a.value; });
            const newData = JSON.parse(JSON.stringify(data));
            const cursor = newData === null || newData === void 0 ? void 0 : newData[`${queryNames[0]}`];
            const dataArray = (cursor === null || cursor === void 0 ? void 0 : cursor.__typename) === 'SearchNFTCursor' ? (_a = cursor.pageEdges) === null || _a === void 0 ? void 0 : _a.node : ((cursor === null || cursor === void 0 ? void 0 : cursor.__typename) === 'DashboardCursor' || (cursor === null || cursor === void 0 ? void 0 : cursor.__typename) === 'NFTEditionCursor') ? cursor === null || cursor === void 0 ? void 0 : cursor.pageEdges : null;
            (dataArray || []).forEach((nft) => {
                var _a;
                if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'DashboardEdge' && nft.node && nft.node.id === nftId) {
                    nft.node.isNFTBookmarked = true;
                }
                else if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'NFTEditionEdge' && ((_a = nft.node) === null || _a === void 0 ? void 0 : _a.nft) && nft.node.nft.id === nftId) {
                    nft.node.nft.isNFTBookmarked = true;
                }
                else if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'NFTEditionEdge' && nft.node && nft.node.id === nftEditionId) {
                    nft.node.isBookmarked = true;
                }
                else if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'ElasticNFT' && nft.id === nftId) {
                    nft.isNFTBookmarked = true;
                }
            });
            cache.writeQuery({ query, variables: queryParams, data: newData });
            // options.
            // update bookmark counts
            // const bookMarkCountQuery = cache.readQuery({
            //   query: GET_SIDEBAR_COUNT,
            //   variables: { userId: null }
            // });
            // if (bookMarkCountQuery) {
            //   //:TODO
            // }
        }
    });
    return { addNftBookmarkMutation, options };
};
