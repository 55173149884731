import { Box, Card, Divider, styled } from '@mui/material';
export const MyNFTMenuCardBox = styled(Card)({
    position: 'relative',
    backgroundImage: 'linear-gradient(360deg, #141826 48%, #345BFF 97%)',
    backgroundColor: 'transparent',
});
export const SidebarDivider = styled(Divider)({
    position: 'relative',
    zIndex: '2',
});
export const BackgroundImageBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    [theme.breakpoints.down('lg')]: {
        height: '25%',
    },
    [theme.breakpoints.down('sm')]: {
        height: '15%',
    },
    '&:before': {
        position: 'absolute',
        content: '""',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        backgroundImage: `linear-gradient(180deg, rgba(20, 24, 38, 0) -10%, ${theme.colors.blueDark} 75%);`,
        zIndex: '1',
    },
    '.bg-img': {
        objectFit: 'cover',
    },
}));
export const MyNFTMenuCardLoaderBox = styled(Card)(({ theme }) => ({
    width: '100%',
    paddingTop: `${theme.spacing(12)}`,
    '.loader-profile': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    }
}));
