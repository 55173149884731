var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Grid } from '@mui/material';
import React from 'react';
export const FormGridItem = (_a) => {
    var { half, third, fourth, sixth } = _a, props = __rest(_a, ["half", "third", "fourth", "sixth"]);
    let sm = props.sm;
    if (half)
        sm = 6;
    if (third)
        sm = 4;
    if (fourth)
        sm = 3;
    if (sixth)
        sm = 2;
    return (<Grid item xs={12} {...props} sm={sm}>
      {props.children}
    </Grid>);
};
