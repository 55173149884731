var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiFormControl from '@mui/material/FormControl';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiFormGroup from '@mui/material/FormGroup';
import MuiFormHelperText from '@mui/material/FormHelperText';
import MuiFormLabel from '@mui/material/FormLabel';
import { useFormContext } from '.';
export const Checkbox = (_a) => {
    var { formik, label, option, required = false, height, sx } = _a, props = __rest(_a, ["formik", "label", "option", "required", "height", "sx"]);
    const { formId } = useFormContext();
    const id = `${formId ? (formId + '-') : ''}${props.name}`;
    const hasError = formik.touched[props.name] && Boolean(formik.errors[props.name]);
    return (<>
      <MuiFormControl component="fieldset" error={hasError} className={`checkbox-control-${props.variant || 'standard'}`} sx={sx}>
        {label && <MuiFormLabel component="legend" required={required} className="check-box-label-head">{label}</MuiFormLabel>}
        <MuiFormGroup sx={{ height: height }} className="check-box-wrapper">
          {!Array.isArray(option) &&
            <MuiFormControlLabel className={formik.values[props.name] ? 'checked' : ''} key={id} control={<MuiCheckbox id={id} checked={formik.values[props.name]} value={formik.values[props.name]} onChange={formik.handleChange} {...props} className={`${hasError ? 'error-checkbox' : ''}`}/>} label={option}/>}
          {Array.isArray(option) &&
            option.map(op => {
                const checked = formik.values[props.name] && formik.values[props.name].indexOf(op.value) > -1;
                return <MuiFormControlLabel key={op.name} value={op.value} className={`check-box-form-control ${checked ? 'active' : ''} `} control={<MuiCheckbox id={id} checked={checked} value={op.value} onChange={(e, value) => {
                            let arr = formik.values[props.name] || [];
                            if (value) {
                                arr.push(op.value);
                            }
                            else {
                                // const index = arr.findIndex(value => value === op.value);
                                arr = arr.filter(item => item !== op.value);
                            }
                            formik.setFieldValue(props.name, arr);
                        }} {...props}/>} label={op.name}/>;
            })}
        </MuiFormGroup>
        {!Array.isArray(option) && <MuiFormHelperText sx={{}}>{formik.errors[props.name] || ' '}</MuiFormHelperText>}
      </MuiFormControl>
    </>);
};
