import { Box, styled } from '@mui/material';
export const NxMainHead = styled(Box)(({ theme }) => ({
    '.dashboard-main-head': {
        fontSize: '1.3rem',
        marginRight: `${theme.spacing(1.5)}`,
    },
    '.dashboard-see-all': {
        fontSize: '.8rem',
        fontWeight: 'bold',
    },
    '.nx-filter-btn': {
        marginRight: `${theme.spacing(1.5)}`,
        '.nx-filter-icon path': {
            fill: theme.colors.white,
        }
    },
}));
export const NxMainBody = styled(Box)(({ theme }) => ({
    margin: `${theme.spacing(2)} 0 ${theme.spacing(3)} 0`,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
        zIndex: '1'
    }
}));
