import { gql, useMutation } from '@apollo/client';
const ADD_FOLLOWERS = gql `
  mutation ($followingId: Int!, $userId: Int!) {
    addFollowers(followingId: $followingId, userId: $userId)
  }
`;
const REMOVE_FOLLOWER = gql `
  mutation ($followingId: Int!, $userId: Int!) {
    removeFollowers(followingId: $followingId, userId: $userId)
  }
`;
export const useFollowCreator = () => {
    const [addFollowers, followOptions] = useMutation(ADD_FOLLOWERS, {
        notifyOnNetworkStatusChange: true,
    });
    const [removeFollowers, unfollowOptions] = useMutation(REMOVE_FOLLOWER, {
        notifyOnNetworkStatusChange: true,
    });
    const apiLoading = (followOptions.loading || unfollowOptions.loading);
    // Follow creator and update the cache
    const followCreator = ({ followingId, userId, query, queryParams }) => addFollowers({
        variables: { followingId, userId: (userId || 0) },
        update: (cache) => {
            var _a, _b, _c, _d, _e;
            const data = cache.readQuery({ query, variables: queryParams });
            const newData = JSON.parse(JSON.stringify(data));
            if (newData) {
                if (newData === null || newData === void 0 ? void 0 : newData.getFeaturedCreators) {
                    const dataArray = (((_a = newData === null || newData === void 0 ? void 0 : newData.getFeaturedCreators) === null || _a === void 0 ? void 0 : _a.pageEdges) || []);
                    dataArray.forEach((fCreatorEdge) => {
                        var _a, _b;
                        if ((fCreatorEdge === null || fCreatorEdge === void 0 ? void 0 : fCreatorEdge.node) && ((_b = (_a = fCreatorEdge === null || fCreatorEdge === void 0 ? void 0 : fCreatorEdge.node) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id) === followingId) {
                            fCreatorEdge.node.user.isFollowing = true;
                        }
                    });
                }
                else if (newData === null || newData === void 0 ? void 0 : newData.getTopTraders) {
                    const dataArray = (((_b = newData === null || newData === void 0 ? void 0 : newData.getTopTraders) === null || _b === void 0 ? void 0 : _b.pageEdges) || []);
                    dataArray.forEach((tCreatorEdge) => {
                        var _a, _b;
                        if ((tCreatorEdge === null || tCreatorEdge === void 0 ? void 0 : tCreatorEdge.node) && ((_b = (_a = tCreatorEdge === null || tCreatorEdge === void 0 ? void 0 : tCreatorEdge.node) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id) === followingId) {
                            tCreatorEdge.node.user.isFollowing = true;
                        }
                    });
                }
                else if (newData === null || newData === void 0 ? void 0 : newData.getBookmarkedUsers) {
                    const dataArray = (((_c = newData === null || newData === void 0 ? void 0 : newData.getBookmarkedUsers) === null || _c === void 0 ? void 0 : _c.pageEdges) || []);
                    dataArray.forEach((bCreatorEdge) => {
                        var _a, _b;
                        if ((bCreatorEdge === null || bCreatorEdge === void 0 ? void 0 : bCreatorEdge.node) && ((_b = (_a = bCreatorEdge === null || bCreatorEdge === void 0 ? void 0 : bCreatorEdge.node) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id) === followingId) {
                            bCreatorEdge.node.user.isFollowing = true;
                        }
                    });
                }
                else if (newData === null || newData === void 0 ? void 0 : newData.searchCreator) {
                    const dataArray = (((_e = (_d = newData === null || newData === void 0 ? void 0 : newData.searchCreator) === null || _d === void 0 ? void 0 : _d.pageEdges) === null || _e === void 0 ? void 0 : _e.node) || []);
                    dataArray.forEach((creator) => {
                        if ((creator === null || creator === void 0 ? void 0 : creator.user) && (creator === null || creator === void 0 ? void 0 : creator.id) && (creator === null || creator === void 0 ? void 0 : creator.id) === followingId) {
                            creator.user.isFollowing = true;
                        }
                    });
                }
                else if (newData === null || newData === void 0 ? void 0 : newData.getUserDetailsById) {
                    newData.getUserDetailsById.isFollowing = true;
                }
                cache.writeQuery({ query, variables: queryParams, data: newData });
            }
        }
    });
    // Un follow creator and update the cache
    const unFollowCreator = ({ followingId, userId, query, queryParams }) => removeFollowers({
        variables: { followingId, userId: (userId || 0) },
        update: (cache) => {
            var _a, _b, _c, _d, _e;
            const data = cache.readQuery({ query, variables: queryParams });
            const newData = JSON.parse(JSON.stringify(data));
            if (newData) {
                if (newData === null || newData === void 0 ? void 0 : newData.getFeaturedCreators) {
                    const dataArray = (((_a = newData === null || newData === void 0 ? void 0 : newData.getFeaturedCreators) === null || _a === void 0 ? void 0 : _a.pageEdges) || []);
                    dataArray.forEach((fCreatorEdge) => {
                        var _a, _b;
                        if ((fCreatorEdge === null || fCreatorEdge === void 0 ? void 0 : fCreatorEdge.node) && ((_b = (_a = fCreatorEdge === null || fCreatorEdge === void 0 ? void 0 : fCreatorEdge.node) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id) === followingId) {
                            fCreatorEdge.node.user.isFollowing = false;
                        }
                    });
                }
                else if (newData === null || newData === void 0 ? void 0 : newData.getTopTraders) {
                    const dataArray = (((_b = newData === null || newData === void 0 ? void 0 : newData.getTopTraders) === null || _b === void 0 ? void 0 : _b.pageEdges) || []);
                    dataArray.forEach((tCreatorEdge) => {
                        var _a, _b;
                        if ((tCreatorEdge === null || tCreatorEdge === void 0 ? void 0 : tCreatorEdge.node) && ((_b = (_a = tCreatorEdge === null || tCreatorEdge === void 0 ? void 0 : tCreatorEdge.node) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id) === followingId) {
                            tCreatorEdge.node.user.isFollowing = false;
                        }
                    });
                }
                else if (newData === null || newData === void 0 ? void 0 : newData.getBookmarkedUsers) {
                    const dataArray = (((_c = newData === null || newData === void 0 ? void 0 : newData.getBookmarkedUsers) === null || _c === void 0 ? void 0 : _c.pageEdges) || []);
                    dataArray.forEach((bCreatorEdge) => {
                        var _a, _b;
                        if ((bCreatorEdge === null || bCreatorEdge === void 0 ? void 0 : bCreatorEdge.node) && ((_b = (_a = bCreatorEdge === null || bCreatorEdge === void 0 ? void 0 : bCreatorEdge.node) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id) === followingId) {
                            bCreatorEdge.node.user.isFollowing = false;
                        }
                    });
                }
                else if (newData === null || newData === void 0 ? void 0 : newData.searchCreator) {
                    const dataArray = (((_e = (_d = newData === null || newData === void 0 ? void 0 : newData.searchCreator) === null || _d === void 0 ? void 0 : _d.pageEdges) === null || _e === void 0 ? void 0 : _e.node) || []);
                    dataArray.forEach((creator) => {
                        if ((creator === null || creator === void 0 ? void 0 : creator.user) && (creator === null || creator === void 0 ? void 0 : creator.id) && (creator === null || creator === void 0 ? void 0 : creator.id) === followingId) {
                            creator.user.isFollowing = false;
                        }
                    });
                }
                else if (newData === null || newData === void 0 ? void 0 : newData.getUserDetailsById) {
                    newData.getUserDetailsById.isFollowing = false;
                }
                cache.writeQuery({ query, variables: queryParams, data: newData });
            }
        }
    });
    // Check authorization and perform follow/unfollow according to the conditions
    const toggleFollowCreator = (params) => {
        if (params === null || params === void 0 ? void 0 : params.isFollowing) {
            return unFollowCreator(params);
        }
        else {
            return followCreator(params);
        }
    };
    return { toggleFollowCreator, followOptions, unfollowOptions, apiLoading };
};
