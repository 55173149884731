import { gql, useMutation } from '@apollo/client';
import { getBrowserName } from 'helpers/utils';
const FORGOT_PASSWORD = gql `
mutation forgotPassword(
  $forgotPasswordInput:ForgotPasswordInput!
) {
  forgotPassword(
    forgotPasswordInput: $forgotPasswordInput
  )
}
`;
export const useForgotPasswordMutation = () => {
    const [forgotPasswordMutation, options] = useMutation(FORGOT_PASSWORD, {
        notifyOnNetworkStatusChange: true,
    });
    const forgotPassword = (variables) => {
        return forgotPasswordMutation({
            variables: {
                forgotPasswordInput: Object.assign(Object.assign({}, variables), { platform: getBrowserName(), isMarketplace: true })
            }
        });
    };
    return { forgotPassword, options };
};
