import { PAGE_ROUTES } from 'helpers/constants';
export const getMyNftUrl = (id, currentId, pageRoute) => {
    if (pageRoute) {
        return pageRoute;
    }
    else if (id) {
        if (id === currentId) {
            return PAGE_ROUTES.MY_NFT__LISTED;
        }
        else {
            return `${PAGE_ROUTES.NFT}/${(id || 0)}/listed`;
        }
    }
    else {
        return '';
    }
};
