import { DwollaTnxStatus } from 'types/graphql.models';
export const getTransactionStatusTextByStatus = (status) => {
    switch (status) {
        case DwollaTnxStatus.Completed:
            return 'completed';
        case DwollaTnxStatus.Failed:
            return 'failed';
        default:
            return 'processing';
    }
};
