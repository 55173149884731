var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { CustomScrollbar, Loader } from 'common/components';
import { useQuery } from '@apollo/client';
import { Badge, Box, Card, Collapse, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, styled, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useToast } from '@nxlvl/web-providers';
import { GET_CATEGORIES } from 'graphql-client/sidebar';
import WithLoadingWrapper from 'helpers/hoc/withLoader';
import { useCategoryFilter } from 'helpers/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AllNftsIcon, ArrowLeftOutlineIcon, ChevronDownOutlineIcon, ChevronRightFillIcon } from 'ui/icons';
import { CategorySubMenu } from '../CategorySubMenu';
import { CustomSvgIcon } from '../CustomSvgIcon';
import { CategoriesLoaderBox, SideBarMenuCardBox } from './SideBarMenuCard.styled';
import { useLocation } from 'react-router-dom';
const commonPageId = 'sidebar-menu';
const ExpandMore = styled((props) => {
    const { expand } = props, otherProps = __rest(props, ["expand"]);
    return (<Box textAlign="center" className={expand ? 'expanded' : ''}>
      <IconButton {...otherProps}/>
    </Box>);
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
const CategoriesLoader = () => {
    return (<CategoriesLoaderBox>
      <Stack spacing={4}>
        {([1, 2]).map((i) => (<div key={i}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs="auto">
                <Loader loading width={32} height={32} variant="circular"/>
              </Grid>
              <Grid item xs={5}>
                <Loader loading height={12} variant="rectangular"/>
              </Grid>
            </Grid>
          </div>))}
      </Stack>
    </CategoriesLoaderBox>);
};
const SideBarMenuCard = ({ fullHeight, onSubCategorySelected, onCategoryFilterChange }) => {
    const { t } = useTranslation();
    const [selectedCat, setCategory] = useState(null);
    const [expanded, setExpanded] = useState(!!fullHeight);
    const { openToast } = useToast();
    const location = useLocation();
    const theme = useTheme();
    const isTab = useMediaQuery(theme.breakpoints.down('lg'));
    const { filter, handleFilterChange, parentCategory } = useCategoryFilter('use-category');
    const { loading, error, data: { getCategory = [] } = {} } = useQuery(GET_CATEGORIES, {});
    const { defaultCategories, moreCategories } = useMemo(() => {
        var _a;
        // All categories
        const categories = ((_a = (getCategory || [])) === null || _a === void 0 ? void 0 : _a.filter((category) => !['Other', 'Persons'].includes((category === null || category === void 0 ? void 0 : category.name) || ''))) || [];
        // Default categories
        const defCat = fullHeight ? categories : categories.slice(0, 7);
        // Categories that will be display when down arrow will be clicked
        const mCat = fullHeight ? [] : categories.slice(7);
        return { defaultCategories: defCat, moreCategories: mCat };
    }, [fullHeight, getCategory]);
    useEffect(() => {
        error && openToast(error === null || error === void 0 ? void 0 : error.message, { type: 'error' });
        // eslint-disable-next-line
    }, [error]);
    useEffect(() => {
        setCategory(null);
    }, [location.pathname]);
    const handleSidebarCategories = (category) => {
        setCategory(category);
        if (onSubCategorySelected) {
            onSubCategorySelected(category);
        }
    };
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    if (loading) {
        return <CategoriesLoader />;
    }
    const handleCategoryClick = (catId) => () => {
        changeCategoryFilter(catId);
    };
    const changeCategoryFilter = (catId) => {
        handleFilterChange('catId', catId);
        onCategoryFilterChange && onCategoryFilterChange(catId);
    };
    return (<SideBarMenuCardBox>
      <Card className="left-sidebar-card" elevation={fullHeight ? 0 : 1}>
        <List className={`nx-category-list ${selectedCat !== null ? 'nx-category-list-open' : ''}`}>
          {selectedCat !== null ? (<ListItem id={`${commonPageId}-button-back-to-category`} button className="nx-category-list-item" onClick={() => handleSidebarCategories(null)}>
              <Tooltip title={<>{t('Back')}</>}>
                <ListItemIcon className="nx-menu-icon">
                  <ArrowLeftOutlineIcon className="stroke-color"/>
                </ListItemIcon>
              </Tooltip>
              <ListItemIcon className="nx-menu-icon">
                <CustomSvgIcon className="svg-stroke-color" src={((selectedCat === null || selectedCat === void 0 ? void 0 : selectedCat.iconURL) || '')}/>
              </ListItemIcon>
              <ListItemText className="nx-menu-text">
                {selectedCat === null || selectedCat === void 0 ? void 0 : selectedCat.name}
              </ListItemText>
            </ListItem>) : (<></>)}
        </List>
        {selectedCat !== null ? (<CategorySubMenu categoryName={((selectedCat === null || selectedCat === void 0 ? void 0 : selectedCat.name) || '')} subCategories={selectedCat === null || selectedCat === void 0 ? void 0 : selectedCat.activities} maxHeight={fullHeight ? 'auto' : undefined} onCategorySelected={changeCategoryFilter}/>) : (<WithLoadingWrapper loading={loading}>
            <>
              <CustomScrollbar autoHeight autoHeightMax={isTab ? 'calc(100vh - 12.5rem)' : ''}>
                <List className={`nx-main-menu ${expanded ? 'sidebar-category-list' : ''}`}>
                  <ListItem id={`${commonPageId}-category-all-categories`} className={`nx-main-sub-menu-item nx-main-menu-item ${!(filter === null || filter === void 0 ? void 0 : filter.catId) ? 'active' : ''}`} button onClick={handleCategoryClick(null)}>
                    <Box display="flex" alignItems="center">
                      <ListItemIcon className="nx-menu-icon svg-stroke-color"><AllNftsIcon /></ListItemIcon>
                      <ListItemText className="nx-menu-text">{t('All categories')}</ListItemText>
                    </Box>
                  </ListItem>
                  {(defaultCategories || []).map((category, i) => {
                const active = (category === null || category === void 0 ? void 0 : category.id) == (filter === null || filter === void 0 ? void 0 : filter.catId);
                const subCatActive = (category === null || category === void 0 ? void 0 : category.id) == (parentCategory === null || parentCategory === void 0 ? void 0 : parentCategory.id);
                return ((category === null || category === void 0 ? void 0 : category.activities) || []).length > 0 ? (<ListItem id={`${commonPageId}-category-${category === null || category === void 0 ? void 0 : category.id}`} className="nx-main-sub-menu-item nx-main-menu-item" key={i} button onClick={() => handleSidebarCategories(category)}>
                        <Box display="flex" alignItems="center">
                          <CustomSvgIcon className="nx-menu-icon svg-stroke-color" src={(category.iconURL || '')}/>
                          <ListItemText className="nx-menu-text">{category.name}</ListItemText>
                          {subCatActive && <Badge variant="dot" badgeContent=""/>}
                        </Box>
                        <ListItemIcon className="nx-main-sub-menu-item-icon-box">
                          <ChevronRightFillIcon className="nx-main-sub-menu-item-icon stroke-color"/>
                        </ListItemIcon>
                      </ListItem>) : (<ListItem id={`${commonPageId}-category-${category === null || category === void 0 ? void 0 : category.id}`} key={i} button className={`nx-main-menu-item ${active ? 'active' : ''}`} onClick={handleCategoryClick(active ? null : category === null || category === void 0 ? void 0 : category.id)}>
                        <CustomSvgIcon className="nx-menu-icon svg-stroke-color" src={((category === null || category === void 0 ? void 0 : category.iconURL) || '')}/>
                        <ListItemText className="nx-menu-text">{category === null || category === void 0 ? void 0 : category.name}</ListItemText>
                      </ListItem>);
            })}

                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {(moreCategories || []).map((category, i) => {
                const active = (category === null || category === void 0 ? void 0 : category.id) == (filter === null || filter === void 0 ? void 0 : filter.catId);
                const subCatActive = (category === null || category === void 0 ? void 0 : category.id) == (parentCategory === null || parentCategory === void 0 ? void 0 : parentCategory.id);
                return ((category === null || category === void 0 ? void 0 : category.activities) || []).length > 0 ? (<ListItem id={`${commonPageId}-category-${category === null || category === void 0 ? void 0 : category.id}`} className="nx-main-sub-menu-item nx-main-menu-item" key={i} button onClick={() => handleSidebarCategories(category)}>
                          <Box display="flex">
                            <CustomSvgIcon className="nx-menu-icon svg-stroke-color" src={((category === null || category === void 0 ? void 0 : category.iconURL) || '')}/>
                            <ListItemText className="nx-menu-text">{category.name}</ListItemText>
                            {subCatActive && <Badge variant="dot" badgeContent=""/>}
                          </Box>
                          <ListItemIcon className="nx-main-sub-menu-item-icon-box">
                            <ChevronRightFillIcon className="nx-main-sub-menu-item-icon stroke-color"/>
                          </ListItemIcon>
                        </ListItem>) : (<ListItem id={`${commonPageId}-category-${category === null || category === void 0 ? void 0 : category.id}`} key={i} button className={`nx-main-menu-item ${active ? 'active' : ''}`} onClick={handleCategoryClick(active ? null : category === null || category === void 0 ? void 0 : category.id)}>
                          <CustomSvgIcon className="nx-menu-icon svg-stroke-color" src={((category === null || category === void 0 ? void 0 : category.iconURL) || '')}/>
                          <ListItemText className="nx-menu-text">{category === null || category === void 0 ? void 0 : category.name}</ListItemText>
                        </ListItem>);
            })}
                  </Collapse>
                </List>
              </CustomScrollbar>
              {!fullHeight && (moreCategories === null || moreCategories === void 0 ? void 0 : moreCategories.length) > 0 &&
                <>
                  <Divider className="nx-drawer-hr"/>
                  <Box className="nx-down-arrow">
                    <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more" className="nx-expand-more">
                      <ChevronDownOutlineIcon className="nx-down-arrow-icon"/>
                    </ExpandMore>
                  </Box>
                </>}
            </>
          </WithLoadingWrapper>)}
      </Card>
    </SideBarMenuCardBox>);
};
export default SideBarMenuCard;
