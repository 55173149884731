import { gql, useMutation } from '@apollo/client';
const DWOLLA_WITHDRAW_FUND = gql `
  mutation withdrawFundFromWallet(
	  	$fundingSourceId: String!,
      $amount: Float!,
	) {
    withdrawFundFromWallet(
  		WithdrawFundFromWalletArguments: {
    		fundingSourceId: $fundingSourceId, amount: $amount
      }
    	) {
      message,
			status
    }
}`;
export const useWithdrawFundDwolla = () => {
    const [withDrawDwollaMutation] = useMutation(DWOLLA_WITHDRAW_FUND, {
        notifyOnNetworkStatusChange: true,
    });
    const withDrawFundDwollaWallet = (variables) => {
        return withDrawDwollaMutation({
            variables
        });
    };
    return { withDrawFundDwollaWallet };
};
