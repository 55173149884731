import React, { useEffect } from 'react';
import 'lib/i18n/config';
import './App.scss';
import './styles/globals.scss';
// import { dynamicTheme as defaultTheme } from 'ui/foundations';
import { MuiThemeProvider, AppWrapper, ToastProvider, FullSizeLoaderProvider, } from '@nxlvl/web-providers';
import { dynamicTheme as defaultTheme } from '@nxlvl/web-ui';
import { useApollo } from 'graphql-client/apolloClient';
import { ApolloProvider } from '@apollo/client';
import { Layout } from 'common/Layout/Layout';
import { GoogleAnalyticScript, MixPanelScript } from 'helpers/scripts';
import { Outlet } from 'react-router-dom';
import { attachCustomDataGleap, initGleap, removeCustomDataGleap } from 'lib/gleap';
import { appVersion } from 'helpers/constants';
function App() {
    const apolloClient = useApollo();
    useEffect(() => {
        // window.fbAsyncInit = function () {
        //   window.FB.init({
        //     appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
        //     cookie: true, // Enable cookies to allow the server to access the session.
        //     xfbml: true, // Parse social plugins on this webpage.
        //     version: 'v13.0' // Use this Graph API version for this call.
        //   });
        // };
        initGleap({
            apiKey: process.env.REACT_APP_GLEAP_API_KEY,
            appVersion,
            nodeEnv: process.env.NODE_ENV,
            publicEnv: process.env.PUBLIC_ENV,
        });
    }, []);
    return (<ApolloProvider client={apolloClient}>
      {/* Theme can change at run time (with help of either context or store) */}
      <MuiThemeProvider dynamicTheme={defaultTheme}>
        {/* Theme can change at run time (with help of either context or store) */}
        <FullSizeLoaderProvider>
          <ToastProvider>
            <AppWrapper isPrivateRoute={false} attachCustomGleapData={attachCustomDataGleap} removeCustomGleapData={removeCustomDataGleap}>
              <Layout>
                <GoogleAnalyticScript />
                <MixPanelScript />
                <Outlet />
              </Layout>
            </AppWrapper>
            {/* <RouterProvider router={router}></RouterProvider> */}
          </ToastProvider>
        </FullSizeLoaderProvider>
      </MuiThemeProvider>
    </ApolloProvider>);
}
export default App;
