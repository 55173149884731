import { Box, Card, styled } from '@mui/material';
export const SideBarMenuCardBox = styled(Box)(({ theme }) => ({
    '.nx-category-list': {
        display: 'none',
    },
    '.nx-category-list-open': {
        display: 'block',
    },
    '.nx-category-list-item': {
        padding: '0',
    },
    '.nx-main-sub-menu-item': {
        justifyContent: 'space-between',
        '.nx-main-sub-menu-item-icon-box': {
            marginRight: `${theme.spacing(0)}`,
            minWidth: 'inherit',
            '.nx-main-sub-menu-item-icon path': {
                fill: theme.dynamicTheme.sidebarNavigation.link.color,
            },
        },
        '& .nx-menu-text + .MuiBadge-root': {
            marginLeft: theme.spacing(1.25),
            '& .MuiBadge-dot': {
                top: '50%',
                backgroundColor: theme.dynamicTheme.mainSection.link.color,
            }
        }
    },
    [theme.breakpoints.down('lg')]: {
        '.left-sidebar-card': {
            margin: '0',
            height: 'calc(100vh - 12.25rem)',
            borderBottom: `0.063rem solid ${theme.dynamicTheme.mainSection.backgroundColor}`,
            borderRadius: '0',
            padding: `0 ${theme.spacing(2)} 0}`,
        }
    },
}));
export const SidebarSubMenu = styled(Box)(({ theme }) => ({
    '& .nx-sub-menu-list': {
        flexFlow: 'row wrap',
        display: 'flex',
        overflowX: 'hidden',
        margin: `${theme.spacing(3)} 0`,
        alignItems: 'start',
        padding: `0 ${theme.spacing(1)}`,
        '.nx-sub-menu-list-item': {
            cursor: 'pointer',
            maxWidth: '50%',
            justifyContent: 'center',
            margin: `0 0 ${theme.spacing(2)}`,
            textAlign: 'center',
            padding: `${theme.spacing(0)}`,
            borderRadius: '.75rem',
            '&.active, &.active:hover': {
                background: theme.dynamicTheme.sidebarNavigation.link.colorActive,
            },
            '.nx-sub-menu': {
                padding: `${theme.spacing(1)}`,
            },
            '.nx-sub-menu-text span': {
                color: theme.dynamicTheme.sidebarNavigation.link.color,
                fontSize: '.8rem',
                fontWeight: '500',
                textTransform: 'uppercase',
                wordBreak: 'break-word',
            },
        },
    },
    '.nx-side-search': {
        '.nx-side-search-inp > div': {
            marginTop: `${theme.spacing(2)}`,
        },
        input: {
            color: theme.colors.blueDark,
            opacity: '1',
            fontWeight: '600',
        },
        'input[type="text"]::placeholder': {
            color: theme.colors.blueDark,
            opacity: '1',
            fontWeight: '600',
        },
        '.nx-search-icon-box': {
            backgroundColor: theme.colors.blue,
            marginRight: `${theme.spacing(0)}`,
            borderRadius: '0.375rem',
            padding: `${theme.spacing(.625)}`,
            '.nx-search-icon path': {
                stroke: theme.dynamicTheme.sidebarNavigation.link.color,
            },
        },
    },
}));
export const CategoriesLoaderBox = styled(Card)(() => ({
    padding: '2.5rem',
    width: '100%',
    height: '11rem',
}));
