import { styled, Box, Drawer, List, Divider } from '@mui/material';
export const NxDrawerLeft = styled(Drawer)(({ theme }) => ({
    '.MuiPaper-root': {
        padding: `${theme.spacing(2.5)} 0`,
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '18.75rem',
            backgroundColor: theme.dynamicTheme.sidebarNavigation.backgroundColor,
        },
        [theme.breakpoints.down('lg')]: {
            backgroundColor: theme.dynamicTheme.sidebarNavigation.backgroundColor,
        },
    },
    '.drawer-header': {
        padding: `0 ${theme.spacing(3)}`,
    },
    '.menu-text': {
        display: 'none'
    },
    '.tablet-header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '.drawer-logo': {
            display: 'none'
        },
        '.menu-text': {
            display: 'block'
        },
        '.drawer-hamburger-icon': {
            margin: theme.spacing(0)
        }
    },
    '.close-drawer-icon path': {
        fill: theme.dynamicTheme.sidebarNavigation.text.color,
    },
    '.drawer-hamburger-icon': {
        marginRight: `${theme.spacing(2)}`,
        padding: theme.spacing(0),
    },
    '.drawer-logo': {
        width: 'auto',
    },
    '.drawer-body': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    '.drawer-footer': {
        padding: `0 ${theme.spacing(3)}`,
    },
    '.text-copyright': {
        fontSize: '1rem',
        marginTop: `${theme.spacing(4)}`,
    },
    '.sidebar-people': {
        width: '100%',
        '.MuiPaper-root': {
            width: '100%',
            boxShadow: 'none',
            margin: theme.spacing(0),
            padding: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
        }
    },
    '.sidebar-avatar': {
        width: '100%',
        '.nx-avatar-box': {
            padding: `${theme.spacing(0)} ${theme.spacing(3)}`,
            marginBottom: theme.spacing(2)
        }
    }
}));
export const NxDrawerList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.up('lg')]: {
        display: 'block',
    },
    '.MuiList-root': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexWrap: 'wrap',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
    },
    '.MuiListItem-root': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(3.5),
        paddingRight: theme.spacing(3.5),
        width: '50%',
        '&.full-width': {
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '100%',
            '&:hover, &.active': {
                backgroundColor: theme.dynamicTheme.sidebarNavigation.link.colorHover,
            },
        },
        '.MuiBox-root': {
            '.MuiListItemIcon-root': {
                minWidth: '1.5rem',
                marginRight: `${theme.spacing(1.5)}`,
                justifyContent: 'center',
                alignItems: 'center',
                '.MuiSvgIcon-root': {
                    height: '1.3rem',
                    color: theme.colors.white
                },
                '&:last-child': {
                    marginRight: `${theme.spacing(0)}`,
                }
            },
        },
    },
}));
export const NxDrawerDivider = styled(Divider)(({ theme }) => ({
    borderColor: theme.dynamicTheme.sidebarNavigation.borderColor,
    width: '90%',
    margin: `${theme.spacing(1)} auto`,
}));
export const NxDrawerLeftLoaderBox = styled(Box)(({ theme }) => ({
    width: '100%',
    '.menu-space': {
        padding: `0 ${theme.spacing(3)}`
    }
}));
