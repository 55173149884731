var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Box, styled, TextField as MuiTextField, Typography } from '@mui/material';
import React from 'react';
import { ExclamationCircleOutlineIcon } from 'ui/icons';
import { useFormContext } from '.';
const LabelBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.character-limit': {
        fontSize: '0.625rem'
    }
}));
const TextFieldLabel = ({ fieldId, label, characterLimit, value }) => {
    const isShown = Boolean(label || characterLimit);
    return (isShown ?
        <LabelBox>
        <span>{label}</span>
        {characterLimit && <Typography variant="body2" id={`${fieldId}-character-limit`} className="character-limit">{`${value.length}/${characterLimit}`}</Typography>}
      </LabelBox> :
        null);
};
export const TextField = (_a) => {
    var _b, _c, _d;
    var { formik, successIcon, label, characterLimit, disableOnChange = false } = _a, props = __rest(_a, ["formik", "successIcon", "label", "characterLimit", "disableOnChange"]);
    const { formId } = useFormContext();
    const id = `${formId ? (formId + '-') : ''}${props.name}`;
    const isTouched = !!formik.touched[props.name];
    const hasError = isTouched && Boolean(formik.errors[props.name]);
    let InputProps = Object.assign({}, props.InputProps);
    let inputProps = Object.assign({}, props.inputProps);
    if (hasError && !((_b = props.InputProps) === null || _b === void 0 ? void 0 : _b.endAdornment)) {
        InputProps = Object.assign(Object.assign({}, props.InputProps), { endAdornment: <ExclamationCircleOutlineIcon /> });
    }
    if (!hasError && isTouched && successIcon) {
        InputProps = Object.assign(Object.assign({}, props.InputProps), { endAdornment: successIcon });
    }
    if (characterLimit) {
        inputProps = Object.assign(Object.assign({}, props.inputProps), { maxLength: characterLimit });
    }
    return formik && <MuiTextField id={id} {...props} label={label ? <TextFieldLabel fieldId={id} label={label} characterLimit={characterLimit} value={formik.values[props.name]}/> : label} InputLabelProps={{
            sx: { width: '100%' }
        }} value={formik.values[props.name]} onChange={!disableOnChange && formik.handleChange} onBlur={(e) => {
            formik.setFieldTouched(props.name);
            props.onBlur && props.onBlur(e);
        }} error={hasError} helperText={hasError ? ((_c = formik.errors[props.name]) !== null && _c !== void 0 ? _c : ' ') : ((_d = props.helperText) !== null && _d !== void 0 ? _d : ' ')} InputProps={InputProps} inputProps={inputProps}/>;
};
