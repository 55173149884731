var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MuiFormControl from '@mui/material/FormControl';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiFormHelperText from '@mui/material/FormHelperText';
import MuiFormLabel from '@mui/material/FormLabel';
import MuiRadio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';
import { useFormContext } from '.';
import React from 'react';
export const RadioGroup = (_a) => {
    var { formik, label, options, required = false } = _a, props = __rest(_a, ["formik", "label", "options", "required"]);
    const { formId } = useFormContext();
    const id = `${formId ? (formId + '-') : ''}${props.name}`;
    const hasError = formik.touched[props.name] && Boolean(formik.errors[props.name]);
    return (<>
      <MuiFormControl component="fieldset" error={hasError}>
        {label && <MuiFormLabel component="legend" required={required}>{label}</MuiFormLabel>}
        <MuiRadioGroup id={id} value={formik.values[props.name]} onChange={formik.handleChange} {...props} row>
          {options.map((option) => (<MuiFormControlLabel key={typeof (option) === 'string' ? option : option.value} control={<MuiRadio id={`${id}-${typeof (option) === 'string' ? option : option.value}`}/>} label={typeof (option) === 'string' ? option : option.label} value={typeof (option) === 'string' ? option : option.value}/>))}
        </MuiRadioGroup>
        <MuiFormHelperText>{formik.errors[props.name] || ' '}</MuiFormHelperText>
      </MuiFormControl>
    </>);
};
