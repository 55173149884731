import { gql, useLazyQuery, useQuery } from '@apollo/client';
export const GET_USER_BY_ID = gql `
  query getUserById($id: Int!) {
    getUserById(id: $id) {
      id
      firstname
      lastname
      email
      birthDate
      profileImage
      marketplaceFullAccess
      dwollaCustomer {
        status
        url
      }
      username
      createdAt
      provider
      preferences {
        id
        name
      }
    }
  }
`;
export const useGeUserByIdQuery = ({ id }) => {
    return useQuery(GET_USER_BY_ID, {
        variables: { id },
        notifyOnNetworkStatusChange: true,
    });
};
export const useGeUserByIdLazyQuery = () => {
    const [getUserById] = useLazyQuery(GET_USER_BY_ID, { fetchPolicy: 'network-only' });
    return { getUserById };
};
