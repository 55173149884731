import { NftCard, Loader } from 'common/components';
import { Grid, Typography } from '@mui/material';
import { useToast } from '@nxlvl/web-providers';
import { useDashboardNftQuery } from 'graphql-client/dashboard';
import { getPdpUrl } from 'helpers/utils';
import { useNavigate, Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NxMainBody, NxMainHead } from '../DashboardScreen.styled';
import { SectionType } from 'types';
import { PAGE_ROUTES } from 'helpers/constants';
const commonPageId = 'dashboard-latest-drops';
const LatestDrops = ({ catId }) => {
    const { t } = useTranslation();
    const { openToast } = useToast();
    // const router = useRouter();
    const navigate = useNavigate();
    const queryParams = {
        cursor: 0,
        pageNo: 0,
        take: 3,
        activity: catId ? [Number(catId)] : [],
        type: SectionType.LatestDrop
    };
    const { loading, error, query, data: { getDashboardNFTs = {} } = {} } = useDashboardNftQuery(queryParams);
    const latestNfts = (getDashboardNFTs === null || getDashboardNFTs === void 0 ? void 0 : getDashboardNFTs.pageEdges) || (loading ? [null, null, null] : []);
    useEffect(() => {
        error && openToast(error === null || error === void 0 ? void 0 : error.message, { type: 'error' });
        // eslint-disable-next-line
    }, [error]);
    const onNftSelected = (nft) => () => {
        navigate(getPdpUrl(nft === null || nft === void 0 ? void 0 : nft.node));
    };
    if (!loading && latestNfts.length === 0) {
        return null;
    }
    return (<>
      <NxMainHead display="flex" alignItems="center" justifyContent="space-between">
        <Loader loading={loading}>
          <Typography variant="h5" className="dashboard-main-head">{t('Latest drops')}</Typography>
        </Loader>
        <Loader loading={loading}>
          <Link to={catId ? `${PAGE_ROUTES.DASHBOARD_LATEST_DROP}?activityIds=${catId}` : PAGE_ROUTES.DASHBOARD_LATEST_DROP}>
            <a id={`${commonPageId}-see-all`} className="dashboard-see-all">
              {t('See all')}
            </a>
          </Link>
        </Loader>
      </NxMainHead>
      <NxMainBody>
        <Grid container spacing={2}>
          {latestNfts.map((latestNft, i) => {
            var _a;
            return (<Grid key={i} item xs={12} md={4} sm={6}>
                <NftCard id={`${commonPageId}-card-${(_a = latestNft === null || latestNft === void 0 ? void 0 : latestNft.node) === null || _a === void 0 ? void 0 : _a.id}`} isLoading={loading} data={latestNft === null || latestNft === void 0 ? void 0 : latestNft.node} query={query} queryParams={{ dashboardArgs: queryParams }} size="large" onSelect={onNftSelected(latestNft)}/>
              </Grid>);
        })}
        </Grid>
      </NxMainBody>
    </>);
};
export default LatestDrops;
