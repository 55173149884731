var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Box, Drawer as MuiDrawer, IconButton, Tooltip, Typography } from '@mui/material';
import { DrawerContainer, DrawerHeader, HeaderActionButton } from './Drawer.styled';
import React from 'react';
import { XOutlineIcon } from 'ui/icons';
import { useTranslation } from 'react-i18next';
import { CustomScrollbar } from '../CustomScrollbar/CustomScrollbar';
const Drawer = (_a) => {
    var { children, open, closeDrawer, closeDrawerWithAlert, color, title, leftButton, rightButton, rightButtonDisabled, rightButtonAction, hideTitle } = _a, rest = __rest(_a, ["children", "open", "closeDrawer", "closeDrawerWithAlert", "color", "title", "leftButton", "rightButton", "rightButtonDisabled", "rightButtonAction", "hideTitle"]);
    const { t } = useTranslation();
    const renderTitle = () => {
        if (hideTitle) {
            return null;
        }
        if (title) {
            return (<DrawerHeader>
          <HeaderActionButton id={`${leftButton}-button`} variant="text" onClick={closeDrawer} className="filter-cancel">{leftButton}</HeaderActionButton>
          <Typography variant="h6" fontWeight="500">{title}</Typography>
          <HeaderActionButton disabled={rightButtonDisabled} id={`${rightButton}-button`} variant="text" onClick={rightButtonAction} className="filter-apply">{rightButton}</HeaderActionButton>
        </DrawerHeader>);
        }
        return (<DrawerHeader>
        <Box display="flex" alignItems="center">
          <Typography variant="h4" fontWeight="500" className="left-button-text"> {leftButton}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title={<>{t('Close')}</>}>
            <IconButton className="drawer-header-icon" onClick={closeDrawer}><XOutlineIcon /></IconButton>
          </Tooltip>
        </Box>
      </DrawerHeader>);
    };
    return (<MuiDrawer open={open} onClose={(e, reason) => closeDrawerWithAlert ? closeDrawerWithAlert(reason) : closeDrawer()} anchor="right" PaperProps={{ className: `drawer-${color}` }} {...rest}>

      {renderTitle()}
      {/* ============== This will be un comment when FE will be work on dynamic part ==============  */}
      {/* <DrawerLoader /> */}
      <CustomScrollbar autoHeight className="drawer-scroll-height" autoHeightMax="calc(100vh - 5rem)">
        <DrawerContainer>
          {children}
        </DrawerContainer>
      </CustomScrollbar>
    </MuiDrawer>);
};
export default Drawer;
