import { Box, Button, Link } from '@mui/material';
import { FiltersOutlineIcon } from 'ui/icons';
import { useTranslation } from 'react-i18next';
import { useGlobalFilters } from 'helpers/hooks';
import React from 'react';
const NxShowFilterButton = ({ commonPageId, onClick, seeAllLink }) => {
    const { t } = useTranslation();
    const { filterCount } = useGlobalFilters();
    return (<Box>
      <Button variant="contained" size="small" endIcon={<FiltersOutlineIcon className="nx-filter-icon"/>} id={`${commonPageId}-filter-btn`} onClick={onClick}>{filterCount > 1 ? `Filter (${filterCount})` : 'Filter'}</Button>
      {!!seeAllLink && (<Link href={seeAllLink}>
          <a id={`${commonPageId}-see-all`} className="dashboard-see-all">
            {t('See all')}
          </a>
        </Link>)}
    </Box>);
};
export default NxShowFilterButton;
