import { Box, styled } from '@mui/material';
export const AvatarBox = styled(Box)(({ theme }) => ({
    '.nx-avatar-box': {
        whiteSpace: 'nowrap',
        alignItems: 'center',
    },
    '.nx-user-info': {
        marginLeft: theme.spacing(1)
    },
    '.nx-avatar-name': {
        // color: theme.colors.blue, // for future reference
        lineHeight: '1.5',
        fontSize: '1rem',
        '&.bold-text': {
            fontWeight: 700,
        }
    },
    '.nx-avatar-school-name': {
        color: theme.colors.neutral450,
        lineHeight: '1.64',
        letterSpacing: '0.004rem',
        fontSize: '0.875rem',
    },
    '&.size-xsmall': {
        '.MuiAvatar-root, .dwolla-avatar': {
            width: '1.5rem',
            height: '1.5rem',
            fontSize: '0.75rem',
        },
        '.nx-avatar-name': {
            fontSize: '0.75rem',
        }
    },
    '&.size-small': {
        '.MuiAvatar-root, .dwolla-avatar': {
            width: '2rem',
            height: '2rem',
            fontSize: '0.875rem',
        },
        '.nx-avatar-name': {
            fontSize: '0.875rem',
        }
    },
    '&.size-medium': {
        '.MuiAvatar-root, .dwolla-avatar': {
            width: '3rem',
            height: '3rem',
            fontSize: '1rem',
        },
    },
    '&.size-large': {
        '.MuiAvatar-root, .dwolla-avatar': {
            width: '4rem',
            height: '4rem',
            fontSize: '3rem',
            fontWeight: '600',
        },
    },
}));
export const UserBadge = styled(Box)(({ theme }) => ({
    width: 'max-content',
    padding: '1px',
    borderRadius: '50%',
    position: 'relative',
    '.nx-avatar-img': {
        margin: '0!important',
    },
    '.badge-icon': {
        position: 'absolute',
        right: 0,
        bottom: -4,
        width: 13,
        height: 'auto',
        '&.size-xsmall': {
            bottom: -2,
            width: 11,
        },
        '&.size-small': {
            bottom: -3,
        },
        '&.size-medium': {
            bottom: 0,
            width: 15,
        },
        '&.size-large': {
            bottom: 1,
            width: 17,
        },
        [theme.breakpoints.down('sm')]: {
            width: 10,
            bottom: -1,
        }
    },
    '&.normal-badge': {
        backgroundColor: theme.colors.blue,
    },
    '&.golden-badge': {
        background: theme.colors.tierLegendary,
    }
}));
export const NotificationBadge = styled(Box)(({ theme }) => ({
    position: 'absolute',
    right: 4,
    top: -2,
    width: 12,
    height: 12,
    backgroundColor: theme.colors.error500,
    borderRadius: '50%',
    border: `0.094rem solid ${theme.colors.neutral925}`,
    '&.size-xsmall': {
        width: 8,
        height: 8,
        right: 2,
    },
    '&.size-small': {
        width: 10,
        height: 10,
        right: 2,
    },
    '&.size-large': {
        width: 14,
        height: 14,
        right: 6,
    },
}));
