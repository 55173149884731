import { Box, Card, styled } from '@mui/material';
export const DiscoverCardBox = styled(Box)(({ theme }) => ({
    '.nx-user-head': {
        marginBottom: `${theme.spacing(2)}`,
    },
    '.nx-discover-btn ': {
        marginBottom: `${theme.spacing(2)}`,
        height: '3rem',
        '&:last-child': {
            marginBottom: `${theme.spacing(0)}`,
        }
    },
    '.nx-discover-btn-icon ': {
        fontSize: '1.5rem !important',
    },
    '.create-nft-btn-icon': {
        width: '2rem',
    },
    '.nx-logo-icon': {
        width: '0.938rem',
        transform: 'translateY(-0.063rem)',
    }
}));
export const DiscoverCardLoaderBox = styled(Card)({
    width: '100%',
    height: '19rem'
});
