import { gql, useQuery } from '@apollo/client';
export const GET_SIDEBAR_COUNT = gql `
  query nftStatistics($userId: Int) {
      getNFTStatistics(userId:$userId) {
          userId
          listedCount
          createdCount
          soldCount
          purchaseCount
          bookmarkCount
          draftCount
      }
  }
`;
export const useSidebarCountQuery = ({ userId }) => {
    return Object.assign(Object.assign({}, useQuery(GET_SIDEBAR_COUNT, {
        variables: { userId },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    })), { query: GET_SIDEBAR_COUNT });
};
