import React from 'react';
import { useAppContext } from 'providers';
import { BuildBrandCard, DiscoverCard, NxlvlCard, SponsoredCard } from './components';
import { SideBarWrap } from './SideBar.styled';
import { PAGE_ROUTES } from 'helpers/constants';
import { useLocation } from 'react-router-dom';
const PeopleBookmarkCard = React.lazy(() => import('./components/PeopleBookmarkCard'));
const TransitionHistoryMenuCard = React.lazy(() => import('./components/TransitionHistoryMenuCard'));
// Load components in client side only
const PeopleCard = React.lazy(() => import('./components/PeopleCard'));
const SideBarMenuCard = React.lazy(() => import('./components/SideBarMenuCard/SideBarMenuCard'));
const TopTradersCard = React.lazy(() => import('./components/TopTradersCard/TopTradersCard'));
const SideBar = () => {
    const { isLoggedIn } = useAppContext();
    const location = useLocation();
    const isTransactions = (isLoggedIn && location.pathname.startsWith(PAGE_ROUTES.TRANSACTIONS));
    const isShowBookmarkToggle = (isLoggedIn &&
        (location.pathname.startsWith(PAGE_ROUTES.USERS__BOOKMARKED_PEOPLE) || location.pathname.startsWith(PAGE_ROUTES.USERS__BOOKMARKED_INSTITUTE)));
    const hideCategoryFilter = (isTransactions || isShowBookmarkToggle);
    return (<SideBarWrap>
      <DiscoverCard hideMyNftBtn={!isLoggedIn}/>

      {isShowBookmarkToggle && (<PeopleBookmarkCard />)}

      {isTransactions && (<TransitionHistoryMenuCard />)}

      {!hideCategoryFilter && (<SideBarMenuCard />)}

      {/* ***************** NOT IN MVP ****************** */}
      {/* {isLoggedIn && <InstitutionsCard />} */}

      <BuildBrandCard labels={['Discover', 'Collect', 'Trade']}/>

      {isLoggedIn && <PeopleCard />}
      <SponsoredCard />
      <NxlvlCard />
      <TopTradersCard />
    </SideBarWrap>);
};
export default SideBar;
