import { Box, CardContent, Divider, IconButton, styled, Tooltip } from '@mui/material';
import { formatDate } from 'helpers/utils';
import React from 'react';
import { PenFillIcon, TrashOutlineIcon } from 'ui/icons';
import { NftCardContentLoader } from './NftCardLoader';
import { useTranslation } from 'react-i18next';
import { NftTitle, TypographyWhite60 } from 'styles/global.styled';
const NftCardContent = styled(CardContent)(({ theme }) => ({
    padding: `${theme.spacing(1)} 0!important`,
    '&.size-large': {
        width: '100%'
    }
}));
const FooterBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    '&.size-large': {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between'
    },
    '.MuiIconButton-root': {
        padding: 0,
    },
    '.MuiSvgIcon-root': {
        fontSize: '1.125rem',
        color: theme.colors.white
    },
}));
export const NftCardContentDraft = ({ id, edition, size = 'small', isLoading, onEdit, onDelete }) => {
    var _a;
    const { t } = useTranslation();
    const handleEdit = (e) => {
        e.stopPropagation();
        onEdit && onEdit();
    };
    const handleDelete = (e) => {
        e.stopPropagation();
        onDelete && onDelete();
    };
    if (isLoading) {
        return <NftCardContentLoader size={size}/>;
    }
    return (<NftCardContent className={`size-${size}`}>
      <TypographyWhite60 noWrap>{formatDate(edition === null || edition === void 0 ? void 0 : edition.createdAt)}</TypographyWhite60>
      <NftTitle>{(_a = edition === null || edition === void 0 ? void 0 : edition.nft) === null || _a === void 0 ? void 0 : _a.name}</NftTitle>
      <Divider className="thin"/>

      <FooterBox className={`size-${size}`}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Tooltip title={<>{t('Edit')}</>}>
              <IconButton id={`${id}-edit-button`} onClick={handleEdit}>
                <PenFillIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <IconButton id={`${id}-delete-button`} onClick={handleDelete}>
              <TrashOutlineIcon />
            </IconButton>
          </Box>
        </Box>
      </FooterBox>
    </NftCardContent>);
};
