import { Box } from '@mui/material';
import { Waypoint } from 'react-waypoint';
import { LoaderCircular } from 'common/components';
import React from 'react';
const WithWayPointScroll = ({ loading, children, hasNextPage, onEnter }) => {
    if (hasNextPage) {
        return (<>
        {children}
        <Waypoint onEnter={loading ? undefined : onEnter}/>
        {loading && (<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <LoaderCircular />
          </Box>)}
      </>);
    }
    return children;
};
export default WithWayPointScroll;
