import { Box, Card, styled, keyframes } from '@mui/material';
export const FavoriteBox = styled(Box)(({ theme }) => ({
    '.nft-favorite-btn': {
        paddingLeft: 0,
        paddingRight: 0,
    },
    '.nft-favorite-icon': {
        fontSize: '1.3rem',
    },
    '.nft-favorite-text': {
        fontSize: '.75rem',
        paddingLeft: theme.spacing(.5),
    },
    '&.active': {
        '.nft-favorite-icon path': {
            fill: theme.dynamicTheme.mainSection.link.color,
            stroke: theme.dynamicTheme.mainSection.link.color,
        },
        '.nft-favorite-text': {
            color: theme.dynamicTheme.mainSection.link.color,
        }
    },
}));
export const NftCardBox = styled(Card)(({ theme }) => ({
    backgroundColor: 'transparent',
    marginBottom: 0,
    cursor: 'pointer',
    padding: 0,
    '.nft-card-img-box': {
        height: '37.125rem',
        borderRadius: '.75rem',
    },
    '.nft-card-img, .nft-card-video': {
        width: '100%',
        borderRadius: '.75rem',
        height: '100%',
        objectFit: 'cover',
    },
    '.nft-card-content': {
        position: 'relative',
        padding: `${theme.spacing(.5)} 0 !important`,
        '.nft-bookmark-box': {
            position: 'absolute',
            top: '-3rem',
            left: 0,
            width: '100%',
            padding: `0 ${theme.spacing(1)}`,
            '.nft-card-user': {
                padding: `${theme.spacing(0)}`,
            },
            '.nft-book-icon-box': {
                backgroundColor: theme.dynamicTheme.mainSection.bookmarkIconBackground.backgroundColor,
                '.nft-book-icon path': {
                    stroke: theme.dynamicTheme.mainSection.bookmarkIconBackground.color,
                },
                '.nft-book-icon.active path': {
                    stroke: 'blue',
                },
            },
            '.nft-book-icon-box.bookmarked': {
                backgroundColor: theme.dynamicTheme.mainSection.link.color,
                '.nft-book-icon path': {
                    stroke: theme.dynamicTheme.mainSection.link.colorActive,
                },
            },
            '.nft-card-user, .nft-book-icon-box': {
                height: '2.5rem',
                width: '2.5rem',
                borderRadius: '50%',
                [theme.breakpoints.down('sm')]: {
                    height: '2rem',
                    width: '2rem',
                }
            }
        },
        '.nft-on-image-box': {
            position: 'absolute',
            top: '-6rem',
            left: 0,
            width: '100%',
            padding: `0 ${theme.spacing(1)}`,
        },
    },
    '.nft-small-card-box': {
        '.nft-card-img-box': {
            height: '17.5rem'
        },
        '.nft-favorite-btn': {
            padding: 0,
        },
        '.nft-news-body': {
            fontSize: '.875rem',
            height: '3.6rem',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
        },
        '.nft-price-box': {
            marginTop: `${theme.spacing(.5)}`,
        },
        '.nft-card-divider': {
            margin: `${theme.spacing(.6)} 0`,
        },
    },
}));
export const NftCardContentBox = styled(Box)(({ theme }) => ({
    '.nft-news-head': {
        lineHeight: '1.3',
        marginBottom: `${theme.spacing(.625)}`,
        height: '2rem',
        overflow: 'hidden',
    },
    '.nft-news-body': {
        fontSize: '1rem',
        height: '3rem',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical'
    },
    '.nft-series': {
        '.nft-series-head': {
            fontSize: '.75rem',
            fontWeight: '500',
            textTransform: 'uppercase',
        },
        '.nft-series-text': {
            fontSize: '.7rem',
            textTransform: 'capitalize'
        }
    },
    '.nft-price-box': {
        marginTop: `${theme.spacing(0.5)}`,
        '.nft-price-text': {
            fontWeight: '500',
            fontSize: '.875rem',
        },
        '.nft-price': {
            fontWeight: '600',
            fontSize: '1rem',
        },
    },
    '.nft-card-divider': {
        margin: `${theme.spacing(1)} 0`,
        opacity: '30%',
        borderColor: theme.dynamicTheme.mainSection.borderColor,
    },
}));
const rotate360 = keyframes `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const NftCardBoxLoader = styled('div')(({ theme }) => ({
    height: 'inherit',
    '.card-loader': {
        background: theme.dynamicTheme.mainSection.backgroundColor,
        borderRadius: '0.625rem',
        border: `2px solid ${theme.dynamicTheme.mainSection.alertBackgroundColor}`,
        width: '334px',
        height: '594px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '1rem',
        [theme.breakpoints.down('xl')]: {
            width: '100%',
        },
        '&.card-loader--fullwidth': {
            width: '100%',
            height: '100%',
        },
        '&.card-loader--medium': {
            width: '15.38rem',
            height: '27.38rem'
        },
        '&.card-loader--small': {
            width: 'auto',
            height: '17.5rem'
        },
        '&.card-loader--xsmall': {
            width: 'auto',
            height: '12.188rem'
        },
        'svg': {
            animation: `${rotate360} 1s linear infinite`
        },
    }
}));
