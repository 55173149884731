import { Box, Button, styled } from '@mui/material';
export const FilterDetailsBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    '.title-align-center': {
        alignSelf: 'center',
    },
    '.filter-main-head': {
        color: theme.dynamicTheme.mainSection.text1.color,
        padding: '.25rem 0',
        '.nx-search-value': {
            color: theme.dynamicTheme.mainSection.link.color,
        },
    },
}));
export const FilterButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));
