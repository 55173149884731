import { gql, useMutation } from '@apollo/client';
const VERIFY_RESET_TOKEN = gql `
mutation verifyResetToken(
  $token:String!
) {
  verifyResetToken (
    token: $token
  ){
    id
  }
}`;
export const useVerifyResetToken = () => {
    const [verifyResetTokenMutation] = useMutation(VERIFY_RESET_TOKEN, {
        notifyOnNetworkStatusChange: true,
    });
    const verifyResetToken = (token) => {
        return verifyResetTokenMutation({
            variables: {
                token
            }
        });
    };
    return { verifyResetToken };
};
