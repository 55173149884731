var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApolloClient, concat, fromPromise, HttpLink, InMemoryCache, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import Cookie from 'js-cookie';
import { REFRESH_TOKEN } from './auth/refreshToken.graphql';
import { getJwtExpirationDate } from 'helpers/utils/getJwtExpirationDate';
import { useToast } from '@nxlvl/web-providers';
let apolloClient;
const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPH_QL_URL || '', // Server URL (must be absolute)
});
const getNewToken = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const currentRefreshToken = Cookie.get('refresh-token');
    const response = yield apolloClient.mutate({
        mutation: REFRESH_TOKEN,
        variables: { refreshToken: currentRefreshToken },
    });
    const responseData = ((_a = response.data) === null || _a === void 0 ? void 0 : _a.refreshToken) || {};
    return responseData;
});
let pendingAccessTokenPromise = null;
const clearAuthAndRedirect = () => {
    Cookie.remove('token');
    Cookie.remove('user');
    Cookie.remove('refresh-token');
    location.href = '/login';
};
const errorLink = (openToast) => onError(
// remove below line and use operation and forward or remove them
// eslint-disable-next-line @typescript-eslint/no-unused-vars
({ graphQLErrors, networkError, operation, forward }) => {
    var _a;
    if (graphQLErrors && graphQLErrors.length && typeof window !== 'undefined') {
        if (graphQLErrors[0].extensions.statusCode === 401 ||
            ((_a = graphQLErrors[0].extensions.exception) === null || _a === void 0 ? void 0 : _a.code) === 401) {
            if (Cookie.get('refresh-token')) {
                // try to refresh the token
                if (!pendingAccessTokenPromise) {
                    pendingAccessTokenPromise = getNewToken()
                        .catch((e) => {
                        if (openToast)
                            openToast(`[Refresh Token Error]: ${(e === null || e === void 0 ? void 0 : e.message) || ''}`, { type: 'error' });
                        clearAuthAndRedirect();
                        return {};
                    })
                        .finally(() => (pendingAccessTokenPromise = null));
                }
                return fromPromise(pendingAccessTokenPromise)
                    .filter((auth) => !!(auth === null || auth === void 0 ? void 0 : auth.accessToken) && !!(auth === null || auth === void 0 ? void 0 : auth.refreshToken) && !!(auth === null || auth === void 0 ? void 0 : auth.userId))
                    .flatMap((auth) => {
                    if (auth.accessToken !== Cookie.get('token')) {
                        const tokenExpDate = getJwtExpirationDate(auth === null || auth === void 0 ? void 0 : auth.accessToken);
                        Cookie.set('token', auth.accessToken || '', { expires: tokenExpDate });
                        const refreshTokenExpDate = getJwtExpirationDate(auth === null || auth === void 0 ? void 0 : auth.refreshToken);
                        Cookie.set('refresh-token', auth.refreshToken || '', {
                            expires: refreshTokenExpDate,
                        });
                        Cookie.set('userId', `${auth.userId}`, {
                            expires: refreshTokenExpDate || tokenExpDate,
                        });
                    }
                    const oldHeaders = operation.getContext().headers;
                    // modify the operation context with a new token
                    operation.setContext({
                        headers: Object.assign(Object.assign({}, oldHeaders), { authorization: `Bearer ${auth.accessToken}` }),
                    });
                    // retry the request, returning the new observable
                    return forward(operation);
                });
            }
            else {
                clearAuthAndRedirect();
            }
        }
        else if (openToast) {
            openToast(graphQLErrors[0].message, { type: 'error' });
        }
    }
    if (networkError) {
        console.log('[Network error]');
        if (openToast)
            openToast(`[Network error]: ${networkError || ''}`, { type: 'error' });
    }
});
const setAuthorizationLink = setContext((_, { headers }) => {
    const token = Cookie.get('token');
    const authHeaders = token ? { Authorization: `Bearer ${token}` } : {};
    return {
        headers: headers ? Object.assign(Object.assign({}, headers), authHeaders) : authHeaders,
    };
});
const createApolloClient = (openToast) => {
    return new ApolloClient({
        link: errorLink(openToast).concat(concat(setAuthorizationLink, httpLink)),
        cache: new InMemoryCache({
            typePolicies: {
                DwollaCustomer: {
                    merge: true,
                },
            },
        }),
        credentials: 'include',
    });
};
export const initializeApollo = (openToast) => {
    const client = createApolloClient(openToast);
    if (!apolloClient)
        apolloClient = client;
    return client;
};
export const useApollo = () => {
    const { openToast } = useToast();
    return initializeApollo(openToast);
};
