import { Box, styled } from '@mui/material';
export const SubHeaderWrap = styled(Box)(({ theme }) => ({
    margin: `0 0 ${theme.spacing(1)}`,
    '& .nx-btn-create-nft': {
        '.nx-create-nft-icon': {
            fontSize: '1.5rem!important',
        },
    },
    '& .nx-search-text-field': {
        '& input': {
            fontSize: '1rem ',
        },
        'input[type="text"]::placeholder': {
            opacity: '1',
            fontWeight: '600',
        },
        '.nx-search-text-input': {
            '> div': {
                fontWeight: 'bold',
                margin: `${theme.spacing(0)}`,
                borderRadius: '0.625rem',
                paddingRight: theme.spacing(1),
                marginBottom: `${theme.spacing(2)}`,
            },
            '.MuiFormHelperText-root': {
                display: 'none',
            }
        },
    },
    '& .nx-search-icon-box': {
        backgroundColor: theme.dynamicTheme.mainSection.chipPrimary.backgroundColor,
        marginRight: `${theme.spacing(0)}`,
        borderRadius: '.625rem',
        '&:hover': {
            backgroundColor: theme.dynamicTheme.mainSection.chipPrimary.backgroundColorHover,
        },
        '.nx-search-icon': {
            transform: 'scale(1.2)',
        },
    },
    '.nx-tabs': {
        margin: `0 ${theme.spacing(-0.375)}`,
        '.nx-chips': {
            flex: '1 1 auto',
            margin: `0 ${theme.spacing(.5)}`,
        },
    },
    '.market-text-section': {
        position: 'relative',
        margin: `${theme.spacing(2)} 0`,
        '.banner-title': {
            marginBottom: theme.spacing(1),
        }
    }
}));
export const SearchDropdownLoaderBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    '&.responsive-search': {
        marginTop: theme.spacing(7.5),
    },
    '.search-panel': {
        border: '0.125rem solid',
        borderColor: theme.colors.neutral800,
        borderRadius: '0.5rem',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(1)
    },
    '.text-space': {
        marginBottom: theme.spacing(1.1),
    }
}));
