var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Skeleton } from '@mui/material';
export const Loader = (_a) => {
    var { loading } = _a, props = __rest(_a, ["loading"]);
    return ((loading) ?
        (props === null || props === void 0 ? void 0 : props.children) ?
            <Skeleton animation="wave" {...props}>
          {props === null || props === void 0 ? void 0 : props.children}
        </Skeleton>
            :
                <Skeleton animation="wave" {...props}/>
        :
            <>{props === null || props === void 0 ? void 0 : props.children}</>);
};
