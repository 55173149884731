// import { AdvertiseCard } from '@/components/common/AdvertiseCard/AdvertiseCard';
import { NxlvlCard } from 'common/Layout/SideBar';
import { useToast } from '@nxlvl/web-providers';
import { Grid, styled } from '@mui/material';
import { Box } from '@mui/system';
import { NftCard, FilterSection, Loader, NXConnectAdvertiseCard, AdCardListing } from 'common/components';
import { useDashboardNftQuery } from 'graphql-client/dashboard';
import { useGlobalFilters } from 'helpers/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { NxMainBody, NxMainHead } from '../../DashboardScreen.styled';
import { EmptyNftListState } from './EmptyNftListState';
import WithWayPointScroll from 'helpers/hoc/withWayPointScroll';
const SectionResultBox = styled(Box)(({ theme }) => ({
    '.explore-header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        '.dashboard-main-head': {
            width: '7.5rem',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                marginRight: theme.spacing(0),
                marginBottom: theme.spacing(1)
            },
        },
        '.nx-filter-head-box': {
            display: 'flex',
            flex: '1',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
    },
    '.filter-text': {
        fontSize: '0.875rem',
        marginRight: theme.spacing(1),
    },
    '.filter-select': {
        color: theme.dynamicTheme.mainSection.text.color,
        fontSize: '0.875rem',
        fontWeight: 'bold',
        'select': {
            paddingRight: `${theme.spacing(.5)}!important`
        },
    },
    '.filter-select-option': {
        background: `${theme.dynamicTheme.mainSection.backgroundColor}!important`,
    },
    '.sort-icon path': {
        fill: theme.dynamicTheme.mainSection.text.color,
    }
}));
export const ViewAllDashboardSection = ({ sectionType, sectionTitle }) => {
    var _a, _b, _c, _d, _e, _f;
    const { openToast } = useToast();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const queryObj = Object.fromEntries([...searchParams]);
    const searchString = (queryObj === null || queryObj === void 0 ? void 0 : queryObj.query) || '';
    const { globalFilters } = useGlobalFilters();
    const commonPageId = `dashboard-section-view-all-${sectionType}`;
    const queryParams = {
        cursor: 0,
        pageNo: 0,
        take: 10,
        activity: globalFilters.activityIds,
        tier: (_a = globalFilters.tiers) === null || _a === void 0 ? void 0 : _a.map(t => t[0].toUpperCase() + t.substring(1)),
        sortBy: globalFilters.orderBy,
        availableForSale: globalFilters.availableForSale,
        priceRange: globalFilters.priceRange,
        type: sectionType
    };
    const { loading, called, error, query, data, fetchMore } = useDashboardNftQuery(queryParams);
    const searchResultNfts = (((_b = data === null || data === void 0 ? void 0 : data.getDashboardNFTs) === null || _b === void 0 ? void 0 : _b.pageEdges) || []);
    const isLoading = (loading && searchResultNfts.length === 0);
    useEffect(() => {
        error && openToast(error === null || error === void 0 ? void 0 : error.message, { type: 'error' });
        // eslint-disable-next-line
    }, [error]);
    const onNftSelected = (nftId) => () => {
        navigate(`/pdp/${nftId}`);
    };
    const handleFetchMore = () => {
        var _a, _b, _c, _d;
        const listData = (((_a = data === null || data === void 0 ? void 0 : data.getDashboardNFTs) === null || _a === void 0 ? void 0 : _a.pageEdges) || []);
        const last = (listData === null || listData === void 0 ? void 0 : listData.length) - 1;
        fetchMore({
            variables: {
                dashboardArgs: Object.assign(Object.assign({}, queryParams), { cursor: (_c = (_b = listData[last]) === null || _b === void 0 ? void 0 : _b.node) === null || _c === void 0 ? void 0 : _c.id, pageNo: (((_d = data === null || data === void 0 ? void 0 : data.getDashboardNFTs.pageInfo) === null || _d === void 0 ? void 0 : _d.pageNo) || 0) + 1 })
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                const prevContents = prev === null || prev === void 0 ? void 0 : prev.getDashboardNFTs;
                const contents = (fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.getDashboardNFTs) || {};
                return {
                    getDashboardNFTs: Object.assign({}, prevContents, {
                        pageEdges: [...((prevContents === null || prevContents === void 0 ? void 0 : prevContents.pageEdges) || []), ...((contents === null || contents === void 0 ? void 0 : contents.pageEdges) || [])],
                        pageInfo: Object.assign({}, contents.pageInfo)
                    })
                };
            }
        });
    };
    const hasNoData = !loading && called && ((searchResultNfts === null || searchResultNfts === void 0 ? void 0 : searchResultNfts.length) || 0) === 0;
    const hasNextPage = !!((_d = (_c = data === null || data === void 0 ? void 0 : data.getDashboardNFTs) === null || _c === void 0 ? void 0 : _c.pageInfo) === null || _d === void 0 ? void 0 : _d.hasNextPage);
    return (<SectionResultBox>
      <FilterSection searchString={searchString} totalCount={(_f = (_e = data === null || data === void 0 ? void 0 : data.getDashboardNFTs) === null || _e === void 0 ? void 0 : _e.pageInfo) === null || _f === void 0 ? void 0 : _f.totalCount} title={sectionTitle}/>
      <NxMainHead>
        <Loader loading={loading}>
          <Box className="explore-header">
            <Box className="nx-filter-head-box">
              <Box className="nx-filter-btn-box">

              </Box>
            </Box>
          </Box>
        </Loader>
      </NxMainHead>
      {hasNoData ? (<EmptyNftListState />) : (<NxMainBody>
          <WithWayPointScroll loading={loading} hasNextPage={hasNextPage} onEnter={handleFetchMore}>
            <Grid container spacing={2}>
              {(searchResultNfts || []).map((nft, index) => {
                var _a, _b, _c;
                return (<AdCardListing key={`search-nft-card-${(_a = nft === null || nft === void 0 ? void 0 : nft.node) === null || _a === void 0 ? void 0 : _a.id}-${index}`} index={index} adCardComponent={(<NXConnectAdvertiseCard variant="vertical"/>)} adBannerComponent={(<NxlvlCard />)}>
                    <NftCard id={`${commonPageId}-card-${(_b = nft === null || nft === void 0 ? void 0 : nft.node) === null || _b === void 0 ? void 0 : _b.id}`} isLoading={isLoading} size="small" data={nft === null || nft === void 0 ? void 0 : nft.node} variant="stacked" query={query} queryParams={{ dashboardArgs: queryParams }} onSelect={onNftSelected((_c = nft === null || nft === void 0 ? void 0 : nft.node) === null || _c === void 0 ? void 0 : _c.id)}/>
                  </AdCardListing>);
            })}
            </Grid>
          </WithWayPointScroll>
        </NxMainBody>)}
    </SectionResultBox>);
};
