import { gql, useQuery } from '@apollo/client';
export const GET_GROUPED_USER_ACTIVITIES = gql `
    query getGroupedUserActivities{
        getGroupedUserActivities(isUserActivity: false) {
            groupedUserActivities {
                name
                activities {
                  id
                  name
                }
            }
        }
    }
`;
export const useGetGroupedActivities = () => {
    return useQuery(GET_GROUPED_USER_ACTIVITIES, {
        notifyOnNetworkStatusChange: true,
    });
};
