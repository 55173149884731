import { gql, useMutation } from '@apollo/client';
import { GET_FIREBASE_NOTIFICATION } from './useGetFirebaseNotificationQuery';
export const MARK_FIREBASE_NOTIFICATION_VIEWED = gql `
  mutation markAllFirebaseNotificationAsViewed {
    markAllFirebaseNotificationAsViewed
  }
`;
export const useMarkAllFirebaseNotificationAsViewed = () => {
    const [markReadFirebaseNotificationViewed, options] = useMutation(MARK_FIREBASE_NOTIFICATION_VIEWED, {
        notifyOnNetworkStatusChange: true,
    });
    const markFirebaseNotificationViewMutation = (variables) => markReadFirebaseNotificationViewed({
        refetchQueries: () => [{
                query: GET_FIREBASE_NOTIFICATION,
                variables: variables
            }],
    });
    return { markFirebaseNotificationViewMutation, options };
};
