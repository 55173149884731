import { gql, useMutation } from '@apollo/client';
export const Add_NFT_LIKE = gql `
    mutation nftLike (
        $nftLikeArgs: nftLikeArgument!,
    ){
        createNFTLike(nftLikeArgs: $nftLikeArgs)
    }
`;
export const useAddNftLikeMutation = () => {
    const [addLikeMutation, options] = useMutation(Add_NFT_LIKE, {
        notifyOnNetworkStatusChange: true,
    });
    const addNftLikeMutation = ({ nftId, query, queryParams }) => addLikeMutation({
        variables: { nftLikeArgs: { nftId } },
        update: (cache) => {
            var _a;
            const data = cache.readQuery({ query, variables: queryParams });
            const queryNames = ((query === null || query === void 0 ? void 0 : query.definitions) || []).map((queryData) => { var _a; return (_a = queryData === null || queryData === void 0 ? void 0 : queryData.name) === null || _a === void 0 ? void 0 : _a.value; });
            const newData = JSON.parse(JSON.stringify(data));
            const cursor = newData === null || newData === void 0 ? void 0 : newData[`${queryNames[0]}`];
            const dataArray = cursor.__typename === 'SearchNFTCursor' ? (_a = cursor.pageEdges) === null || _a === void 0 ? void 0 : _a.node : (cursor.__typename === 'DashboardCursor' || cursor.__typename === 'NFTEditionCursor') ? cursor.pageEdges : null;
            (dataArray || []).forEach((nft) => {
                var _a;
                if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'DashboardEdge' && nft.node && nft.node.id === nftId) {
                    nft.node.likeCount = (nft.node.likeCount || 0) + 1;
                    nft.node.isLiked = true;
                }
                else if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'NFTEditionEdge' && ((_a = nft === null || nft === void 0 ? void 0 : nft.node) === null || _a === void 0 ? void 0 : _a.nft) && nft.node.nft.id === nftId) {
                    nft.node.nft.likeCount = (nft.node.nft.likeCount || 0) + 1;
                    nft.node.nft.isLiked = true;
                }
                else if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'ElasticNFT' && nft.id === nftId) {
                    nft.isLiked = true;
                    nft.likeCount = (nft.likeCount || 0) + 1;
                }
            });
            cache.writeQuery({ query, variables: queryParams, data: newData });
        }
    });
    return { addNftLikeMutation, options };
};
