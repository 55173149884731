import { Box, IconButton, List, ListItem, ListItemText, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { SidebarSubMenu } from './SideBarMenuCard/SideBarMenuCard.styled';
import { SearchOutlineIcon } from 'ui/icons';
import { CustomSvgIcon } from './CustomSvgIcon';
import { CustomScrollbar } from 'common/components';
import { useCategoryFilter } from 'helpers/hooks';
import { gaEvent, GTagActionTypes, GTagCategoryTypes } from 'lib/gtag';
import { useTranslation } from 'react-i18next';
export const CategorySubMenu = ({ subCategories, maxHeight, categoryName = '', onCategorySelected }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { filter } = useCategoryFilter();
    const isTab = useMediaQuery(theme.breakpoints.down('lg'));
    // Refs
    const searchInputRef = useRef(null);
    const timeoutIDRef = useRef();
    // States
    const [searchedCats, setSearchCats] = useState([]);
    useEffect(() => {
        if (subCategories === null || subCategories === void 0 ? void 0 : subCategories.length) {
            setSearchCats(subCategories);
        }
    }, [subCategories === null || subCategories === void 0 ? void 0 : subCategories.length]);
    const handleSearch = ({ target: { value } }) => {
        clearTimeout(timeoutIDRef.current);
        timeoutIDRef.current = window.setTimeout(() => {
            if (value.trim() !== '') {
                const searchCategories = (subCategories || []).filter((o) => ((o === null || o === void 0 ? void 0 : o.name) || '').toLowerCase().includes(String(value.toLowerCase())));
                setSearchCats(searchCategories);
            }
            else if ((searchedCats === null || searchedCats === void 0 ? void 0 : searchedCats.length) !== (subCategories === null || subCategories === void 0 ? void 0 : subCategories.length)) {
                setSearchCats(subCategories);
            }
        }, 500);
    };
    const handleSubCatClick = (cat) => () => {
        gaEvent({ action: GTagActionTypes.Activities, category: GTagCategoryTypes.Side_Panel, label: cat === null || cat === void 0 ? void 0 : cat.name });
        onCategorySelected((cat === null || cat === void 0 ? void 0 : cat.id) || null);
    };
    const handleKeyDown = (e) => {
        var _a, _b;
        if ((e === null || e === void 0 ? void 0 : e.key) === 'Enter') {
            (_b = (_a = searchInputRef === null || searchInputRef === void 0 ? void 0 : searchInputRef.current) === null || _a === void 0 ? void 0 : _a.blur) === null || _b === void 0 ? void 0 : _b.call(_a);
        }
    };
    return (<SidebarSubMenu maxHeight={maxHeight}>
      <Box className="nx-side-search">
        <TextField inputRef={searchInputRef} margin="none" onChange={handleSearch} onKeyDown={handleKeyDown} placeholder={`Search ${categoryName}`} InputProps={{
            endAdornment: (<Tooltip title={<>{t('Search')}</>}>
                <IconButton className="nx-search-icon-box" edge="end">
                  <SearchOutlineIcon className="nx-search-icon"/>
                </IconButton>
              </Tooltip>),
        }} className="nx-side-search-inp"/>
      </Box>
      <CustomScrollbar autoHeight autoHeightMax={isTab ? 'calc(100vh - 17rem)' : 380}>
        <List className="nx-sub-menu-list">
          {searchedCats === null || searchedCats === void 0 ? void 0 : searchedCats.map((subCategory, i) => {
            const active = Number(filter === null || filter === void 0 ? void 0 : filter.catId) === (subCategory === null || subCategory === void 0 ? void 0 : subCategory.id);
            return (<ListItem key={i} className={`nx-sub-menu-list-item ${active ? 'active' : ''}`} onClick={handleSubCatClick(active ? null : subCategory)}>
                <Box className="nx-sub-menu">
                  <CustomSvgIcon className="nx-sub-menu-icon svg-stroke-color" src={((subCategory === null || subCategory === void 0 ? void 0 : subCategory.iconURL) || '')}/>
                  <ListItemText className="nx-sub-menu-text">
                    {subCategory === null || subCategory === void 0 ? void 0 : subCategory.name}
                  </ListItemText>
                </Box>
              </ListItem>);
        })}
        </List>
      </CustomScrollbar>
    </SidebarSubMenu>);
};
