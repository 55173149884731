import { Box, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { SearchOutlineIcon } from 'ui/icons';
import { useTranslation } from 'react-i18next';
import { SubHeaderWrap } from './SubHeader.styled';
{ /* This loader use for content loader */ }
// SearchDropdownLoaderBox, ResponsiveDropdownLoaderBox
import { Form, TextField, qTypes, SearchDropdown } from 'common/components';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { checkHashtag } from 'helpers/utils';
import { gaEvent, GTagActionTypes, GTagCategoryTypes } from 'lib/gtag';
import { useSearchParams } from 'react-router-dom';
import { useNavigateParams } from 'helpers/hooks';
import React from 'react';
{ /* This loader use for content loader */ }
// import { Loader } from '../../common';
const commonPageId = 'sub-header';
{ /* This content loader for search dropdown desktop view */ }
// const SearchDropdownLoader = () => {
//   return (
//     <SearchDropdownLoaderBox>
//       <Grid container spacing={2} >
//         <Grid item xs={12} xl={8} lg={7}>
//           <Stack spacing={2}>
//             <Box className="search-panel">
//               <Loader loading variant="rectangular" height={35} width={35} />
//             </Box>
//           </Stack>
//         </Grid>
//         <Grid item xs={12} xl={4} lg={5}>
//           <Box className="nx-tabs" display="flex" justifyContent="space-between">
//             <Loader loading variant="rectangular" width={52} height={50} />
//             <Loader loading variant="rectangular" width={65} height={50} />
//             <Loader loading variant="rectangular" width={108} height={50} />
//             <Loader loading variant="rectangular" width={78} height={50} />
//           </Box>
//         </Grid>
//       </Grid>
//     </SearchDropdownLoaderBox>
//   );
// }
{ /* This content loader for search dropdown responsive view */ }
// const ResponsiveDropdownLoader = () => {
//   return (
//     <SearchDropdownLoaderBox className="responsive-search">
//       <Grid container spacing={2} >
//         <Grid item xs={12} xl={4} lg={5}>
//           <Box className="nx-tabs">
//             <Loader loading variant="rectangular" width={100} height={20} className="text-space" />
//             <Loader loading variant="rectangular" width={300} height={20} className="text-space" />
//             <Loader loading variant="rectangular" width={300} height={20} className="text-space" />
//           </Box>
//         </Grid>
//         <Grid item xs={12} xl={8} lg={7}>
//           <Box className="search-panel">
//             <Loader loading variant="rectangular" height={35} width={35} />
//           </Box>
//         </Grid>
//       </Grid>
//     </SearchDropdownLoaderBox>
//   );
// }
const validationSchema = Yup.object({
    search: Yup.string(),
});
export const SubHeader = () => {
    const { t } = useTranslation();
    const navigate = useNavigateParams();
    const [searchParams] = useSearchParams();
    const queryObj = Object.fromEntries([...searchParams]);
    const searchString = (queryObj['query'] || '');
    const searchType = (queryObj['sType'] || 'NFTs');
    const isValidHashtag = checkHashtag(searchString);
    const formik = useFormik({
        initialValues: {
            search: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            gaEvent({ action: GTagActionTypes.Search_NFT, category: GTagCategoryTypes.Search });
            const newQuery = Object.assign(Object.assign({}, queryObj), { query: (values.search || '').trim() });
            navigate('/search', newQuery);
        },
    });
    useEffect(() => {
        formik.setFieldValue('search', searchString || '');
        if (isValidHashtag && searchType !== 'NFTs') {
            gaEvent({ action: GTagActionTypes.Search_NFT, category: GTagCategoryTypes.Search });
            const newQuery = Object.assign(Object.assign({}, queryObj), { sType: 'NFTs' });
            navigate('/search', newQuery, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);
    const handleSearchTypeClick = (sType) => {
        gaEvent({ action: GTagActionTypes.Search_NFT, category: GTagCategoryTypes.Search });
        if (sType === 'Users') {
            // Remove NFT related filters for Users tab
            // TODO: Add NFT filter keys here when new filters are added for NFT section
            const nftFilters = ['sortBy', 'tier'];
            const qObj = Object.keys(queryObj)
                .filter((key) => nftFilters.indexOf(key) === -1)
                .reduce((obj, key) => {
                return Object.assign(obj, { [key]: queryObj[key] });
            }, {});
            const newQuery = Object.assign(Object.assign({}, qObj), { sType: sType });
            navigate('/search', newQuery);
        }
        else {
            const newQuery = Object.assign(Object.assign({}, queryObj), { sType: sType });
            navigate('/search', newQuery);
        }
    };
    return (<SubHeaderWrap>
      <Form id={`${commonPageId}-search-form`} onSubmit={formik.handleSubmit}>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Box className="market-text-section">
            <Typography id={`${commonPageId}-Market`} variant="h3" className="banner-title">{t('Market')}</Typography>
            <Typography id={`${commonPageId}-Market-text`} variant="h3" className="">{t('Discover, Collect & Sell your favorite Talent, Moments and rare NFTs')}</Typography>
          </Box>
          <SearchDropdown name="search" formik={formik} searchType={searchType} onTypeChange={handleSearchTypeClick} isValidHashtag={isValidHashtag}/>
        </Box>
        <Grid container spacing={2} sx={{ display: { xs: 'none', lg: 'flex' } }}>
          <Grid item xs={12} xl={8} lg={7}>
            <Box className="nx-search-text-field">
              <TextField id={`${commonPageId}-input-global-search`} formik={formik} variant="standard" name="search" margin="none" placeholder={t('Search NFTs, Institutions and Users')} InputProps={{
            endAdornment: (<Tooltip title={<>{t('Search')}</>}>
                      <IconButton className="nx-search-icon-box" edge="end" type="submit">
                        <SearchOutlineIcon className="nx-search-icon stroke-color"/>
                      </IconButton>
                    </Tooltip>)
        }} className="nx-search-text-input"/>
            </Box>
          </Grid>
          <Grid item xs={12} xl={4} lg={5}>
            <Box className="nx-tabs" display="flex" justifyContent="space-between">
              {qTypes.map((qType) => (<Chip disabled={(isValidHashtag && qType !== 'NFTs')} key={qType} id={`${commonPageId}-tab-all`} label={qType} className="nx-chips" color={searchType === qType ? 'primary' : 'default'} onClick={() => handleSearchTypeClick(qType)}/>))}
            </Box>
          </Grid>
        </Grid>
      </Form>
    </SubHeaderWrap>);
};
