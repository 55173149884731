import { gql, useMutation } from '@apollo/client';
export const DELETE_NFT_LIKE = gql `
    mutation deleteLike  (
        $nftDeleteArgs: nftDeleteArgument!,
    ){
        deleteNFTLike(nftDeleteArgs: $nftDeleteArgs)
    }
`;
export const useDeleteNftLikeMutation = () => {
    const [deleteLikeMutation, options] = useMutation(DELETE_NFT_LIKE, {
        notifyOnNetworkStatusChange: true,
    });
    const deleteNftLikeMutation = ({ nftId, query, queryParams }) => deleteLikeMutation({
        variables: { nftDeleteArgs: { nftId } },
        update: (cache) => {
            var _a;
            const data = cache.readQuery({ query, variables: queryParams });
            const queryNames = ((query === null || query === void 0 ? void 0 : query.definitions) || []).map((queryData) => { var _a; return (_a = queryData === null || queryData === void 0 ? void 0 : queryData.name) === null || _a === void 0 ? void 0 : _a.value; });
            const newData = JSON.parse(JSON.stringify(data));
            const cursor = newData === null || newData === void 0 ? void 0 : newData[`${queryNames[0]}`];
            const dataArray = cursor.__typename === 'SearchNFTCursor' ? (_a = cursor.pageEdges) === null || _a === void 0 ? void 0 : _a.node : (cursor.__typename === 'DashboardCursor' || cursor.__typename === 'NFTEditionCursor') ? cursor.pageEdges : null;
            (dataArray || []).forEach((nft) => {
                var _a;
                if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'DashboardEdge' && nft.node && nft.node.id === nftId) {
                    nft.node.isLiked = false;
                    nft.node.likeCount = (nft.node.likeCount || 0) - 1;
                }
                else if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'NFTEditionEdge' && ((_a = nft === null || nft === void 0 ? void 0 : nft.node) === null || _a === void 0 ? void 0 : _a.nft) && nft.node.nft.id === nftId) {
                    nft.node.nft.isLiked = false;
                    nft.node.nft.likeCount = (nft.node.nft.likeCount || 0) - 1;
                }
                else if ((nft === null || nft === void 0 ? void 0 : nft.__typename) === 'ElasticNFT' && nft.id === nftId) {
                    nft.isLiked = false;
                    nft.likeCount = (nft.likeCount || 0) - 1;
                }
            });
            cache.writeQuery({ query, variables: queryParams, data: newData });
        }
    });
    return { deleteNftLikeMutation, options };
};
