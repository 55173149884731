import { Box, styled } from '@mui/material';
export const DropdownBox = styled(Box)(({ theme }) => ({
    '.nx-dropdown-icon': {
        marginLeft: theme.spacing(.5),
        position: 'absolute',
        right: '0',
        top: 'calc(50% - 0.5em)',
        pointerEvents: 'none',
        width: 20,
        'path': {
            fill: theme.dynamicTheme.mainSection.text.color,
        }
    },
    '&.disable-text': {
        '.MuiSelect-select.MuiSelect-standard': {
            color: theme.dynamicTheme.mainSection.disableText.color,
        },
        '.nx-dropdown-icon': {
            'path': {
                fill: theme.dynamicTheme.mainSection.disableText.color,
                stroke: theme.dynamicTheme.mainSection.disableText.color,
            }
        }
    },
}));
