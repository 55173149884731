import { PAGE_ROUTES } from 'helpers/constants';
export const getPdpUrl = (nftEdition, nftEditionVariant, disableClick, isDraftMode) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    if (!disableClick && nftEdition) {
        if ((nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.__typename) === 'DashboardNFT') {
            return `${PAGE_ROUTES.PDP}/${(nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.id) || ''}`;
        }
        else if ((nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.__typename) === 'NFTEdition') {
            if (nftEditionVariant === 'stacked') {
                return `${PAGE_ROUTES.PDP}/${(nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.nftId) || ''}`;
            }
            return `${PAGE_ROUTES.PDP}/${(nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.nftId) || ''}/${((_a = nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.tier) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) || ''}/${(nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.seriesNumber) || ''}`;
        }
        else if ((nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.__typename) === 'NFTEditionEdge') {
            if (nftEditionVariant === 'stacked') {
                return `${PAGE_ROUTES.PDP}/${((_b = nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.node) === null || _b === void 0 ? void 0 : _b.nftId) || ''}/${((_c = nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.node) === null || _c === void 0 ? void 0 : _c.associatedTiers) ? (_e = (_d = nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.node) === null || _d === void 0 ? void 0 : _d.associatedTiers[0]) === null || _e === void 0 ? void 0 : _e.toLocaleLowerCase() : ''}`;
            }
            // my-nft draft section
            if (isDraftMode) {
                if ((_f = nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.node) === null || _f === void 0 ? void 0 : _f.isRelisted) {
                    return `${PAGE_ROUTES.RELIST_NFT}/${(_h = (_g = nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.node) === null || _g === void 0 ? void 0 : _g.nft) === null || _h === void 0 ? void 0 : _h.id}?edition=${(_j = nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.node) === null || _j === void 0 ? void 0 : _j.id}`;
                }
                else {
                    return `${PAGE_ROUTES.EDIT_NFT}/${(_k = nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.node) === null || _k === void 0 ? void 0 : _k.id}`;
                }
            }
            return `${PAGE_ROUTES.PDP}/${((_l = nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.node) === null || _l === void 0 ? void 0 : _l.nftId) || ''}/${((_o = (_m = nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.node) === null || _m === void 0 ? void 0 : _m.tier) === null || _o === void 0 ? void 0 : _o.toLocaleLowerCase()) || ''}/${((_p = nftEdition === null || nftEdition === void 0 ? void 0 : nftEdition.node) === null || _p === void 0 ? void 0 : _p.seriesNumber) || ''}`;
        }
        return '';
    }
    else {
        return '';
    }
};
