import { Box, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { GET_BOOKMARK_PEOPLE } from 'graphql-client/sidebar';
import { useToast, useAppContext } from '@nxlvl/web-providers';
import WithLoadingWrapper from 'helpers/hoc/withLoader';
import { BookmarkOutlineIcon } from 'ui/icons';
import { Loader, Avatar } from 'common/components';
import { PAGE_ROUTES } from 'helpers/constants';
import { LeftSideBarCardHead } from '../SideBar.styled';
import { TopTraderLoaderBox, TopTradersCardBox } from './TopTradersCard/TopTradersCard.styled';
import { NxDrawerDivider } from 'common/Layout/NxDrawer/NxDrawer.styled';
import { Link, useLocation, useNavigate } from 'react-router-dom';
const commonPageId = 'sidebar-bookmarked-users';
const PeopleCardLoader = () => {
    return (<TopTraderLoaderBox>
      <Stack spacing={1.5}>
        <div>
          <Grid container alignItems="center" spacing={1.5}>
            <Grid item>
              <Loader loading width={40} height={40} variant="circular"/>
            </Grid>
            <Grid item xs={4}>
              <Loader loading variant="rectangular"/>
            </Grid>
            <Grid item xs display="flex" justifyContent="right">
              <Loader loading width="25%" variant="rectangular" height={9}/>
            </Grid>
          </Grid>
        </div>
        <div>
          <Stack spacing={2.2}>
            {([1, 2, 3]).map((i) => (<div key={i}>
                <Grid container alignItems="center" spacing={1.5}>
                  <Grid item>
                    <Loader loading width={48} height={48} variant="circular"/>
                  </Grid>
                  <Grid item xs>
                    <Stack spacing={1}>
                      <Loader width="45%" height={10} loading variant="rectangular"/>
                      <Loader width="35%" height={8} loading variant="rectangular"/>
                    </Stack>
                  </Grid>
                </Grid>
              </div>))}
          </Stack>
        </div>
      </Stack>
    </TopTraderLoaderBox>);
};
const PeopleCard = ({ onNavClick, showDivider }) => {
    const { t } = useTranslation();
    const { openToast } = useToast();
    const { currentUser } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = {
        userId: ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) || 0),
        cursor: 0,
        take: 3,
    };
    const { loading, error, data: { getBookmarkedUsers = [] } = {} } = useQuery(GET_BOOKMARK_PEOPLE, {
        variables: queryParams,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
    });
    const bookmarkUsers = ((getBookmarkedUsers === null || getBookmarkedUsers === void 0 ? void 0 : getBookmarkedUsers.pageEdges) || []);
    const isBookmarkPage = location.pathname.includes(PAGE_ROUTES.USERS__BOOKMARKED_PEOPLE);
    const redirectOnProfile = (bookUser) => {
        var _a, _b, _c, _d;
        if (((_b = (_a = bookUser === null || bookUser === void 0 ? void 0 : bookUser.node) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id) === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)) {
            navigate(PAGE_ROUTES.MY_NFT__LISTED);
        }
        else {
            navigate(`${PAGE_ROUTES.NFT}/${(((_d = (_c = bookUser === null || bookUser === void 0 ? void 0 : bookUser.node) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.id) || 0)}/listed`);
        }
    };
    useEffect(() => {
        error && openToast(error === null || error === void 0 ? void 0 : error.message, { type: 'error' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);
    if (isBookmarkPage || !(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) || (bookmarkUsers.length === 0 && loading === false)) {
        return null;
    }
    if (loading) {
        return <PeopleCardLoader />;
    }
    return (<>
      {(showDivider) &&
            <NxDrawerDivider />}
      <TopTradersCardBox>
        <Card className="left-sidebar-card">
          <LeftSideBarCardHead display="flex" justifyContent="space-between">
            <Box className="left-sidebar-card-head" display="flex" alignItems="center" justifyContent="center">
              <IconButton size="small" color="inherit" className="nft-book-icon-box">
                <BookmarkOutlineIcon className="nft-book-icon"/>
              </IconButton>
              <Typography variant="h4" className="nx-Institutions-heading">{t('People')}</Typography>
            </Box>
            <Link to={PAGE_ROUTES.USERS__BOOKMARKED_PEOPLE}>
              <a id={`${commonPageId}-button-see-all`} className="nx-trade-link" onClick={onNavClick}>
                {t('See all')}
              </a>
            </Link>
          </LeftSideBarCardHead>
          <WithLoadingWrapper loading={loading}>
            <Box>
              {bookmarkUsers.map((bookUser, i) => {
            var _a, _b;
            const user = (_a = bookUser === null || bookUser === void 0 ? void 0 : bookUser.node) === null || _a === void 0 ? void 0 : _a.user;
            const userName = (((user === null || user === void 0 ? void 0 : user.firstname) || '') + ' ' + ((user === null || user === void 0 ? void 0 : user.lastname) || ''));
            const verifiedStatus = (((_b = user === null || user === void 0 ? void 0 : user.dwollaCustomer) === null || _b === void 0 ? void 0 : _b.status) === 'verified' ? 'normal' : null);
            return (<Box key={i} className="nx-traders-list-item" display="flex" alignItems="center" onClick={() => redirectOnProfile(bookUser)}>
                    <Avatar id={`${commonPageId}-user-avatar`} name={userName} hideName alt={userName} src={((user === null || user === void 0 ? void 0 : user.profileImage) || '')} verifiedUser={verifiedStatus} size="medium"/>
                    <Box className="nx-trade-info">
                      <Typography variant="h6" fontWeight="500" className="nx-trader-name">
                        <Link to={`/nft/${user === null || user === void 0 ? void 0 : user.id}/listed`}>
                          <a id={`${commonPageId}-user-name-${user === null || user === void 0 ? void 0 : user.id}`} onClick={onNavClick}>{userName}</a>
                        </Link>
                      </Typography>
                      <Typography variant="body2" className="nx-trader-id">{((user === null || user === void 0 ? void 0 : user.username) ? `@${user === null || user === void 0 ? void 0 : user.username}` : '')}</Typography>
                    </Box>
                  </Box>);
        })}
            </Box>
          </WithLoadingWrapper>
        </Card>
      </TopTradersCardBox>
    </>);
};
export default PeopleCard;
