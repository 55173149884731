export var DwollaTypes;
(function (DwollaTypes) {
    DwollaTypes[DwollaTypes["SWITCH_DEFAULT_DRAWER_CONTENT_STATE"] = 0] = "SWITCH_DEFAULT_DRAWER_CONTENT_STATE";
    DwollaTypes[DwollaTypes["SWITCH_WALLET_DRAWER_OPEN_STATE"] = 1] = "SWITCH_WALLET_DRAWER_OPEN_STATE";
    DwollaTypes[DwollaTypes["SWITCH_CURRENT_DRAWER_CONTENT_STATE"] = 2] = "SWITCH_CURRENT_DRAWER_CONTENT_STATE";
    DwollaTypes[DwollaTypes["SWITCH_DWOLLA_DIALOG_OPEN_STATE"] = 3] = "SWITCH_DWOLLA_DIALOG_OPEN_STATE";
    DwollaTypes[DwollaTypes["SWITCH_CURRENT_DWOLLA_DIALOG_CONTENT_STATE"] = 4] = "SWITCH_CURRENT_DWOLLA_DIALOG_CONTENT_STATE";
    DwollaTypes[DwollaTypes["UPDATE_ADD_FUND_AMOUNT"] = 5] = "UPDATE_ADD_FUND_AMOUNT";
    DwollaTypes[DwollaTypes["UPDATE_BANK_LIST"] = 6] = "UPDATE_BANK_LIST";
    DwollaTypes[DwollaTypes["UPDATE_DWOLLA_CUSTOMER_DATA"] = 7] = "UPDATE_DWOLLA_CUSTOMER_DATA";
    DwollaTypes[DwollaTypes["UPDATE_DIALOG_PREV_STATE"] = 8] = "UPDATE_DIALOG_PREV_STATE";
    DwollaTypes[DwollaTypes["UPDATE_DWOLLA_CREATE_WALLET_STEP"] = 9] = "UPDATE_DWOLLA_CREATE_WALLET_STEP";
    DwollaTypes[DwollaTypes["GET_DWOLLA_CUSTOMER_BALANCE"] = 10] = "GET_DWOLLA_CUSTOMER_BALANCE";
    DwollaTypes[DwollaTypes["UPDATE_WITHDRAW_FUND_AMOUNT"] = 11] = "UPDATE_WITHDRAW_FUND_AMOUNT";
    DwollaTypes[DwollaTypes["UPDATE_RECENTLY_ADDED_BANK"] = 12] = "UPDATE_RECENTLY_ADDED_BANK";
    DwollaTypes[DwollaTypes["UPDATE_DUE_AMOUNT"] = 13] = "UPDATE_DUE_AMOUNT";
})(DwollaTypes || (DwollaTypes = {}));
export var DwollaSetupWalletSteps;
(function (DwollaSetupWalletSteps) {
    DwollaSetupWalletSteps["WELCOME"] = "WELCOME";
    DwollaSetupWalletSteps["CREATE_WALLET_FORM"] = "CREATE_WALLET_FORM";
    DwollaSetupWalletSteps["CREATE_WALLET_VERIFICATION_IN_PROGRESS"] = "CREATE_WALLET_VERIFICATION_IN_PROGRESS";
    DwollaSetupWalletSteps["CREATE_WALLET_SUCCESS"] = "CREATE_WALLET_SUCCESS";
})(DwollaSetupWalletSteps || (DwollaSetupWalletSteps = {}));
export var DwollaCurrentStateTypes;
(function (DwollaCurrentStateTypes) {
    DwollaCurrentStateTypes["FETCHING_CUSTOMER_DATA"] = "FETCHING_CUSTOMER_DATA";
    DwollaCurrentStateTypes["CREATE_WALLET"] = "CREATE_WALLET";
    DwollaCurrentStateTypes["ADD_FUND"] = "ADD_FUND";
    DwollaCurrentStateTypes["UPGRADE_CUSTOMER"] = "UPGRADE_CUSTOMER";
    DwollaCurrentStateTypes["UPLOAD_DOCUMENT"] = "UPLOAD_DOCUMENT";
    DwollaCurrentStateTypes["ADD_BANK_SUCCESS"] = "ADD_BANK_SUCCESS";
    DwollaCurrentStateTypes["ADD_FUND_SELECT_BANK"] = "ADD_FUND_SELECT_BANK";
    DwollaCurrentStateTypes["WALLET_OVERVIEW"] = "WALLET_OVERVIEW";
    DwollaCurrentStateTypes["ADD_FUND_SUCCESS"] = "ADD_FUND_SUCCESS";
    DwollaCurrentStateTypes["SUSPENDED_USER"] = "SUSPENDED_USER";
    DwollaCurrentStateTypes["WITHDRAW_FUND"] = "WITHDRAW_FUND";
    DwollaCurrentStateTypes["WITHDRAW_FUND_SELECT_BANK"] = "WITHDRAW_FUND_SELECT_BANK";
    DwollaCurrentStateTypes["WITHDRAW_FUND_SUCCESS"] = "WITHDRAW_FUND_SUCCESS";
    DwollaCurrentStateTypes["MANAGE_FUNDING_SOURCE"] = "MANAGE_FUNDING_SOURCE";
})(DwollaCurrentStateTypes || (DwollaCurrentStateTypes = {}));
export var DwollaFundingSourceErrorCodes;
(function (DwollaFundingSourceErrorCodes) {
    DwollaFundingSourceErrorCodes["DuplicateResource"] = "DuplicateResource";
    DwollaFundingSourceErrorCodes["MaxNumberOfResources"] = "MaxNumberOfResources";
    DwollaFundingSourceErrorCodes["ValidationError"] = "ValidationError";
    DwollaFundingSourceErrorCodes["InvalidResourceState"] = "InvalidResourceState";
})(DwollaFundingSourceErrorCodes || (DwollaFundingSourceErrorCodes = {}));
