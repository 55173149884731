import { gql, useMutation } from '@apollo/client';
export const ACTIVE_WALLET = gql `
  mutation activateWallet (
    $ActivateWalletArguments: ActivateWalletArguments!
  ) {
    activateWallet (
      ActivateWalletArguments: $ActivateWalletArguments
    ) {
      id
      userId
      url
      status
      user {
        firstname
      }
   }
  }
`;
export const useActivateWalletMutation = () => {
    const [activateWalletMutation] = useMutation(ACTIVE_WALLET, {
        notifyOnNetworkStatusChange: true,
    });
    return { activateWalletMutation };
};
