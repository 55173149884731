import { AvailableOrderStatus } from 'types/myorder.types';
export const getStatusTextByStatus = (status) => {
    switch (status) {
        case AvailableOrderStatus.Completed:
            return 'Success';
        case AvailableOrderStatus.Failed:
            return 'Failed';
        default:
            return 'Processing';
    }
};
