import { gql, useQuery } from '@apollo/client';
export const GET_DASHBOARD_NFT = gql `
    query getDashboardNFTs($dashboardArgs: DashboardArgs!) {
      getDashboardNFTs(dashboardArgs: $dashboardArgs) {
        pageEdges{
          node {
            id
            name
            imageUrl
            videoData
            isLiked
            likeCount
            isNFTBookmarked
            associatedTiers(nftStatus: [Listed, Sold])
            userId
            user {
              id
              firstname
              lastname
              profileImage
              dwollaCustomer {
                status
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          pageNo
          totalCount
        }
      }
    }
`;
export const useDashboardNftQuery = (dashboardArgs) => {
    return Object.assign(Object.assign({}, useQuery(GET_DASHBOARD_NFT, {
        variables: { dashboardArgs },
        notifyOnNetworkStatusChange: true,
    })), { query: GET_DASHBOARD_NFT });
};
