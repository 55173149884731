import { AvailableOrderStatus } from 'types/myorder.types';
export const getClassNameByStatus = (status) => {
    switch (status) {
        case AvailableOrderStatus.Completed:
            return 'status-completed';
        case AvailableOrderStatus.Failed:
            return 'status-failed';
        default:
            return 'status-processing';
    }
};
