var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useTranslation } from 'react-i18next';
import { Typography, Button, Box, Stack, DialogContent, DialogContentText, IconButton, TextField, Divider, useMediaQuery, useTheme } from '@mui/material';
import { SearchOutlineIcon, XOutlineIcon, CheckOutlineIcon } from 'ui/icons';
import React, { useEffect, useState } from 'react';
import { CustomScrollbar } from 'common/components';
import { NFTActivityButton, NFTActivityWrapper, NFTButtonListingBox, NftActivityDialog, NFTSearchActivity, NFTHelperText } from './NftActivityModal.styled';
const commonPageId = 'create-nft-NFTForm';
export const NftActivityListing = ({ activities, commonPageId, onClickActivity, readOnly = false }) => {
    if (activities.length)
        return (<Stack flexDirection="row" flexWrap="wrap">
        {(activities || []).map((item) => {
                return (<NFTActivityButton key={item === null || item === void 0 ? void 0 : item.id} color="secondary" size="xsmall" endIcon={!readOnly ?
                        <XOutlineIcon id={`${commonPageId}-remove-activity-button-${item === null || item === void 0 ? void 0 : item.id}`} onClick={() => { onClickActivity && onClickActivity(item); }}/>
                        : null} isReadable={readOnly} disableRipple>
              {item === null || item === void 0 ? void 0 : item.name}
            </NFTActivityButton>);
            })}
      </Stack>);
    return null;
};
export const NftActivities = ({ activityList, formikSetActivityValue, formikActivityIds, buttonText, label, hideLabelOptional }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const theme = useTheme();
    const [searchVal, setSearchVal] = useState('');
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [filteredLists, setFilteredLists] = useState({ userActivities: [], groupedUserActivities: [] });
    const [potentialSelections, setPotentialSelections] = useState([]);
    const emptyState = (((_a = filteredLists === null || filteredLists === void 0 ? void 0 : filteredLists.userActivities) === null || _a === void 0 ? void 0 : _a.length) === 0 && ((_b = filteredLists === null || filteredLists === void 0 ? void 0 : filteredLists.groupedUserActivities) === null || _b === void 0 ? void 0 : _b.length) === 0);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        var _a, _b;
        const filterData = (data = []) => {
            var _a, _b;
            return (_b = (_a = data === null || data === void 0 ? void 0 : data.map((act) => ({
                name: (act === null || act === void 0 ? void 0 : act.activityName) || (act === null || act === void 0 ? void 0 : act.name) || '',
                id: (act === null || act === void 0 ? void 0 : act.activityId) || (act === null || act === void 0 ? void 0 : act.id) || 0,
            }))) === null || _a === void 0 ? void 0 : _a.filter(({ id }) => !potentialSelections.some(({ id: pId }) => pId === id))) === null || _b === void 0 ? void 0 : _b.filter(({ name }) => name.toUpperCase().includes(searchVal.toUpperCase()));
        };
        const userActivities = filterData(((activityList === null || activityList === void 0 ? void 0 : activityList.userActivities) || []));
        const groupedUserActivities = (_b = (_a = (((activityList === null || activityList === void 0 ? void 0 : activityList.groupedUserActivities) || []))) === null || _a === void 0 ? void 0 : _a.map(({ name, activities }) => ({ name, activities: filterData(activities) }))) === null || _b === void 0 ? void 0 : _b.filter(({ activities }) => (activities === null || activities === void 0 ? void 0 : activities.length) > 0);
        setFilteredLists((prev) => {
            var _a, _b, _c;
            if ((((_a = prev.userActivities) === null || _a === void 0 ? void 0 : _a.length) !== (userActivities === null || userActivities === void 0 ? void 0 : userActivities.length)) ||
                (((_b = JSON.stringify(prev === null || prev === void 0 ? void 0 : prev.groupedUserActivities)) === null || _b === void 0 ? void 0 : _b.length) !== ((_c = JSON.stringify(groupedUserActivities)) === null || _c === void 0 ? void 0 : _c.length))) {
                return { userActivities, groupedUserActivities };
            }
            return prev;
        });
        if (error) {
            setError('');
        }
    }, [activityList, searchVal, potentialSelections]);
    const handleClickOpen = () => {
        setPotentialSelections([...formikActivityIds]);
        setOpen(true);
    };
    const handleClose = () => {
        setSearchVal('');
        setFilteredLists({ userActivities: [], groupedUserActivities: [] });
        setOpen(false);
    };
    const handleSaveClose = () => {
        formikSetActivityValue('activityIds', potentialSelections);
        handleClose();
    };
    const handleRemoveActivity = (item) => {
        const newActivities = formikActivityIds.filter((act) => (act === null || act === void 0 ? void 0 : act.id) !== (item === null || item === void 0 ? void 0 : item.id));
        formikSetActivityValue('activityIds', newActivities);
    };
    const setGroupedActivityRows = (data, selected = false) => {
        let currentRow = 0;
        let numRows = 1;
        if ((data === null || data === void 0 ? void 0 : data.length) > 5 && (data === null || data === void 0 ? void 0 : data.length) < 11) {
            numRows = 2;
        }
        else if ((data === null || data === void 0 ? void 0 : data.length) >= 11) {
            numRows = 3;
        }
        const rows = data.reduce((acc, item, i) => {
            const buttonProps = {
                id: `${commonPageId}-activity-tag-${item === null || item === void 0 ? void 0 : item.id}`,
                type: 'button',
                key: `${item === null || item === void 0 ? void 0 : item.id}-${i}`,
                className: selected ? 'active' : '',
                startIcon: selected ? <CheckOutlineIcon /> : null,
                color: (selected ? 'primary' : 'secondary'),
                size: 'xsmall',
                onClick: () => {
                    if (selected) {
                        if (typeof item === 'string')
                            return;
                        setPotentialSelections((prev) => prev.filter((act) => (act === null || act === void 0 ? void 0 : act.id) !== (item === null || item === void 0 ? void 0 : item.id)));
                    }
                    else {
                        if ((potentialSelections || []).length >= 5) {
                            setError(`${t('Maximum limit (5) to select nXactivity has reached!')}`);
                        }
                        else {
                            if (typeof item !== 'string')
                                setPotentialSelections((prev) => [item, ...prev]);
                        }
                    }
                }
            };
            const chip = (<NFTActivityButton {...buttonProps}>{item === null || item === void 0 ? void 0 : item.name}</NFTActivityButton>);
            acc[currentRow] = [...(acc[currentRow] || []), chip];
            if ((acc[currentRow] || []).length > 2)
                currentRow = currentRow === numRows - 1 ? 0 : currentRow + 1;
            return acc;
        }, []);
        return rows;
    };
    return (<NFTActivityWrapper>
      <Box className="form-box">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" fontWeight="500">{label !== null && label !== void 0 ? label : 'Related activities'}</Typography>
          {!hideLabelOptional && <Typography variant="body2" id={`${commonPageId}-optional-text`} className="optional-text">Optional</Typography>}
        </Box>
        <Button id={`${commonPageId}-button`} type="button" fullWidth color="primary" size="medium" className="activity-btn" onClick={handleClickOpen}>
          {buttonText !== null && buttonText !== void 0 ? buttonText : t('Add activity')}
        </Button>
        <NftActivityListing commonPageId={commonPageId} activities={formikActivityIds} onClickActivity={(item) => handleRemoveActivity(item)}/>
      </Box>
      <NftActivityDialog className={`${commonPageId} dialog-md`} open={open} onClose={handleClose} maxWidth="lg" variant="primary" title="Activities" leftButtonText="Cancel" rightButtonText="Save" leftButtonAction={handleClose} rightButtonAction={handleSaveClose} hideCloseButton={true}>
        <NFTSearchActivity>
          <TextField id={`${commonPageId}-activity-search`} margin="none" value={searchVal} placeholder="Search Activities" InputProps={{
            startAdornment: (<IconButton className="nx-search-icon-box" edge="end">
                  <SearchOutlineIcon className="nx-search-icon"/>
                </IconButton>)
        }} onChange={(e) => { var _a; return setSearchVal((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value); }} className="nx-search-text-input"/>
        </NFTSearchActivity>
        <Box mt={2}>
          <CustomScrollbar className="modal-content-scroll" autoHeight autoHeightMax={isMobile ? 'calc(100vh - 170px)' : 650} renderTrackVertical={(_a) => {
            var props = __rest(_a, []);
            return (<Box {...props} className="nx-scrollbar-track"/>);
        }}>
            <DialogContent className="nx-activity-dialog-content">
              <DialogContentText id="alert-dialog-description" component="div">
                {!!potentialSelections.length && (<>
                    <Box className="nft-listed-button">
                      <Typography variant="h6" className="dialog-head" fontWeight="500">Selected activities</Typography>
                      <CustomScrollbar autoHeight renderTrackHorizontal={(_a) => {
                var props = __rest(_a, []);
                return (<Box {...props} className="nx-horizontal-scrollbar-track"/>);
            }}>
                        <div className="activity-row-chips">
                          {(setGroupedActivityRows(potentialSelections, true) || []).map((row, i) => {
                return (<NFTButtonListingBox key={`your-interests-${i}`}>
                                {row}
                              </NFTButtonListingBox>);
            })}
                        </div>
                      </CustomScrollbar>
                    </Box>
                    <Divider />
                  </>)}
                {error && <NFTHelperText error>{error}</NFTHelperText>}
                {emptyState && (<Box display="flex" justifyContent="center">
                    <Typography variant="h6" className="dialog-head" fontWeight="500">{t('No activity matches this search criteria')}</Typography>
                  </Box>)}
                {!!((_c = filteredLists === null || filteredLists === void 0 ? void 0 : filteredLists.userActivities) === null || _c === void 0 ? void 0 : _c.length) && (<>
                    <Box className="nft-listed-button">
                      <Typography variant="h6" className="dialog-head" fontWeight="500">Your activities and interests</Typography>
                      <CustomScrollbar autoHeight renderTrackHorizontal={(_a) => {
                var props = __rest(_a, []);
                return (<Box {...props} className="nx-horizontal-scrollbar-track"/>);
            }}>
                        <div className="activity-row-chips">
                          {(setGroupedActivityRows(filteredLists === null || filteredLists === void 0 ? void 0 : filteredLists.userActivities) || []).map((row, i) => {
                return (<NFTButtonListingBox key={`your-interests-${i}`}>
                                {row}
                              </NFTButtonListingBox>);
            })}
                        </div>
                      </CustomScrollbar>
                    </Box>
                    <Divider />
                  </>)}
                {((filteredLists === null || filteredLists === void 0 ? void 0 : filteredLists.groupedUserActivities) || []).map((groupedActivity) => {
            const renderingRows = (setGroupedActivityRows(groupedActivity === null || groupedActivity === void 0 ? void 0 : groupedActivity.activities) || []);
            return (<Box key={`groupedActivity-${groupedActivity.name}`}>
                      {/* ============== This will be un comment when FE will be work on dynamic part ==============  */}
                      {/* <ActivityTypesLoader /> */}
                      <Typography key={groupedActivity === null || groupedActivity === void 0 ? void 0 : groupedActivity.name} variant="h6" className="dialog-head" fontWeight="500">{groupedActivity === null || groupedActivity === void 0 ? void 0 : groupedActivity.name}</Typography>
                      <CustomScrollbar autoHeight renderTrackHorizontal={(_a) => {
                    var props = __rest(_a, []);
                    return (<Box {...props} className="nx-horizontal-scrollbar-track"/>);
                }}>
                        <div className="activity-row-chips">
                          {(renderingRows).map((row, i) => {
                    return (<NFTButtonListingBox key={`${groupedActivity === null || groupedActivity === void 0 ? void 0 : groupedActivity.name}-${i}`}>
                                {row}
                              </NFTButtonListingBox>);
                })}
                        </div>
                      </CustomScrollbar>
                      <Divider />
                    </Box>);
        })}
              </DialogContentText>
            </DialogContent>
          </CustomScrollbar>
        </Box>
      </NftActivityDialog>
    </NFTActivityWrapper>);
};
