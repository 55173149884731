import { Box, styled, Typography } from '@mui/material';
export const NftSplitUserWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.cancel-button': {
        padding: '0',
    }
}));
export const RoyaltyTag = styled(Typography, { shouldForwardProp: (prop) => prop !== 'reSaleDataTag' })(({ theme, reSaleDataTag }) => ({
    backgroundColor: reSaleDataTag ? theme.colors.blueDark : theme.colors.blue,
    fontSize: '0.625rem',
    padding: reSaleDataTag ? `${theme.spacing(0.3125)} ${theme.spacing(1)}` : `${theme.spacing(.5)} ${theme.spacing(1)}`,
    borderRadius: '6.25rem ',
    minWidth: reSaleDataTag ? '30px' : '50px',
    textAlign: 'center',
}));
