export var GTagActionTypes;
(function (GTagActionTypes) {
    GTagActionTypes["Creator_Name"] = "Creator Name";
    GTagActionTypes["Sign_Up_button"] = "Sign-Up button";
    GTagActionTypes["Social_Logins"] = "Social Logins";
    GTagActionTypes["Login_button"] = "Login button";
    GTagActionTypes["List_NFT"] = "List NFT";
    GTagActionTypes["Successful_Listing_NFT_Page"] = "Successful Listing NFT Page";
    GTagActionTypes["List_Legendary_NFT"] = "List (Legendary NFT)";
    GTagActionTypes["Wallet_Icon_Header"] = "Wallet on Header";
    GTagActionTypes["MyNFT"] = "MyNFT";
    GTagActionTypes["Wallet_Activation"] = "Wallet Activation";
    GTagActionTypes["Activities"] = "Activities";
    GTagActionTypes["Search_NFT"] = "Search NFT";
    GTagActionTypes["Wallet"] = "Wallet";
    GTagActionTypes["Setup_Wallet"] = "Setup my Wallet";
    GTagActionTypes["Cancel_Purchase"] = "Cancel Purchase";
    GTagActionTypes["Confirm_Purchase"] = "Confirm Purchase";
    GTagActionTypes["Tier"] = "Tier";
    GTagActionTypes["Buy"] = "Buy";
    GTagActionTypes["Share_NFT"] = "Share NFT";
    GTagActionTypes["Like_NFT"] = "Like NFT";
    GTagActionTypes["Bookmark_NFT"] = "Bookmark NFT";
})(GTagActionTypes || (GTagActionTypes = {}));
export var GTagCategoryTypes;
(function (GTagCategoryTypes) {
    GTagCategoryTypes["Onboarding"] = "Onboarding";
    GTagCategoryTypes["NFT_Creation"] = "NFT Creation";
    GTagCategoryTypes["Wallet_Drawer"] = "Wallet Drawer";
    GTagCategoryTypes["NFT_PDP"] = "NFT PDP";
    GTagCategoryTypes["Buy_NFT"] = "Buy NFT";
    GTagCategoryTypes["Search"] = "Search";
    GTagCategoryTypes["Header"] = "Header";
    GTagCategoryTypes["Side_Panel"] = "Side Panel";
    GTagCategoryTypes["Share_NFT_Facebook"] = "Share NFT Facebook";
    GTagCategoryTypes["Share_NFT_Twitter"] = "Share NFT Twitter";
    GTagCategoryTypes["Share_NFT_NXLVL"] = "Share NFT nXlvl";
})(GTagCategoryTypes || (GTagCategoryTypes = {}));
export const GA_TRACKING_ID = (process.env.REACT_APP_GA_TRACKING_ID || '');
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gaPageview = (url) => {
    try {
        window.gtag('config', GA_TRACKING_ID, {
            // eslint-disable-next-line camelcase
            page_path: url,
        });
    }
    catch (err) {
        console.log('gaEvent - err:', err);
    }
};
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gaEvent = ({ action, category, label, value }) => {
    try {
        window.gtag('event', action, {
            // eslint-disable-next-line camelcase
            event_category: category,
            // eslint-disable-next-line camelcase
            event_label: label,
            value,
        });
    }
    catch (err) {
        console.log('gaEvent - err:', err);
    }
};
