import { gql, useQuery } from '@apollo/client';
export const GET_ALL_TOP_TRADERS = gql `
  query getTopTraders($cursor: Int!, $take: Int!, $userId: Int!) {
    getTopTraders(cursor: $cursor, take: $take) {
      pageEdges {
        node {
          id
          user {
            id
            username
            firstname
            lastname
            profileImage
            backgroundImage
            backgroundVideoData
            isFollowing(userId: $userId)
            dwollaCustomer {
              status
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`;
export const useAllTopTradersQuery = (variables) => {
    return Object.assign(Object.assign({}, useQuery(GET_ALL_TOP_TRADERS, {
        variables: variables,
        notifyOnNetworkStatusChange: true,
    })), { query: GET_ALL_TOP_TRADERS });
};
