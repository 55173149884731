import { Box, styled } from '@mui/material';
export const Center3dSliderBox = styled(Box)(({ theme }) => ({
    padding: `${theme.spacing(0)} ${theme.spacing(11)}`,
    margin: `${theme.spacing(0)} auto`,
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0),
        '.nft-card-img-box': {
            height: '17.5rem!important',
        },
        '.nft-card-content-box': {
            display: 'none!important',
        },
    },
    '.card': {
        position: 'relative',
        width: '100%',
        '.card-button': {
            position: 'absolute',
            top: '43%',
            boxShadow: 'none',
            cursor: 'pointer',
            width: '2rem',
            height: '2rem',
            color: 'black',
            backgroundColor: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&.right': {
                right: '0'
            },
            '&.disable': {
                display: 'none',
            },
            [theme.breakpoints.down('md')]: {
                display: 'none',
            }
        },
    },
    '.inner-card': {
        transition: 'all 300ms ease',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    '.nft-card-content-box': {
        display: 'none',
    },
    '.react-stacked-center-carousel-slide-0': {
        '.inner-card': {
            cursor: 'default',
            '&:hover': {
                transform: 'none',
            },
        },
        '.nft-card-content-box': {
            display: 'block',
        },
        '.nft-on-image-box': {
            display: 'none',
        },
        '.nft-news-body': {
            height: 'auto',
        }
    },
}));
