import withSuspense from 'helpers/hoc/withSuspense';
import React from 'react';
import { Route } from 'react-router-dom';
import { DashboardExploreScreen } from 'screens/Dashobard/DashboardExploreAllPage/DashboardExploreScreen';
import { DashboardSectionScreen } from '../screens/Dashobard/DashboardSectionsPage/DashboardSectionsScreen';
// import { Trending } from '../screens/Dashobard/components/TrendingSection/Trending';
const Home = withSuspense(React.lazy(() => import('../screens/Home')));
const Dashboard = withSuspense(React.lazy(() => import('../screens/Dashobard')));
const CreateNFT = withSuspense(React.lazy(() => import('../screens/createnft')));
const MyNFTCategoryScreen = withSuspense(React.lazy(() => import('../screens/mynft')));
const PdpScreen = withSuspense(React.lazy(() => import('../screens/pdp')));
const ForSaleScreen = withSuspense(React.lazy(() => import('../screens/forsale')));
const MyNotificationScreen = withSuspense(React.lazy(() => import('../screens/mynotification')));
const FaqScreen = withSuspense(React.lazy(() => import('../screens/FAQs')));
const PrivacyPolicyScreen = withSuspense(React.lazy(() => import('../screens/PrivacyPolicy')));
const TosScreen = withSuspense(React.lazy(() => import('../screens/TOS')));
const NotFoundScreen = withSuspense(React.lazy(() => import('../screens/notfound')));
const StyleGuideScreen = withSuspense(React.lazy(() => import('../screens/styleguide')));
const MyOrdersScreen = withSuspense(React.lazy(() => import('../screens/myorders')));
const UsersScreen = withSuspense(React.lazy(() => import('../screens/users')));
const TransactionsScreen = withSuspense(React.lazy(() => import('../screens/transactions')));
const SearchScreen = withSuspense(React.lazy(() => import('../screens/Search')));
import App from '../App';
const Router = () => {
    return (
    // <Routes>
    <Route path={'/'} element={<App />}>
      {/* <Route index element={<Trending />} /> */}
      <Route path='/dashboard' element={<Dashboard />}/>
      <Route path='/dashboard/:section' element={<DashboardSectionScreen />}/>
      <Route path='/dashboard/explore-all' element={<DashboardExploreScreen />}/>
      <Route path='/pdp/*' element={<PdpScreen />}/>
      <Route path='/for-sale/:nftEditionId' element={<ForSaleScreen />}/>
      <Route path='/create-nft' element={
        // <ProtectedRoute>
        <CreateNFT />
        // </ProtectedRoute>
        }/>
      <Route path='/edit-nft/:nftEditionId' element={
        // <ProtectedRoute>
        <CreateNFT />
        // </ProtectedRoute>
        }/>
      <Route path='/relist-nft/:nftId' element={
        // <ProtectedRoute>
        <CreateNFT />
        // </ProtectedRoute>
        }/>
      <Route path='/my-nft/' element={<MyNFTCategoryScreen />}/>
      <Route path='/my-nft/:category' element={<MyNFTCategoryScreen />}/>
      <Route path='/nft/:userId?/:category' element={<MyNFTCategoryScreen />}/>
      <Route path='/my-notification/' element={<MyNotificationScreen />}/>
      <Route path='/faq' element={<FaqScreen />}/>
      <Route path='/privacypolicy' element={<PrivacyPolicyScreen />}/>
      <Route path='/tos' element={<TosScreen />}/>
      <Route path='/404' element={<NotFoundScreen />}/>
      <Route path='/styleguide' element={<StyleGuideScreen />}/>
      <Route path='/my-orders' element={<MyOrdersScreen />}/>
      <Route path='/users/:creatorType' element={<UsersScreen />}/>
      <Route path='/transactions' element={<TransactionsScreen />}/>
      <Route path='/search' element={<SearchScreen />}/>
      <Route path='/*' element={<NotFoundScreen />}/>
    </Route>
    // </Routes>
    );
};
// const router = createBrowserRouter([
//   {
//     element: (
//       <div>
//         <ul>
//           <li>
//             <Link to={'/'}>Marketplace</Link>
//           </li>
//           <li>
//             <Link to={'/nil'}>NIL</Link>
//           </li>
//           <li>
//             <Link to={'/create-nft'}>Create NFT</Link>
//           </li>
//         </ul>
//         <RouterOutlet />
//       </div>
//     ),
//     children: [
//       {
//         path: '/',
//         element: <Home />,
//       },
//       {
//         path: '/nil',
//         element: <Dashboard />,
//       },
//       {
//         path: '/create-nft',
//         element: (
//           <ProtectedRoute>
//             <CreateNFT />
//           </ProtectedRoute>
//         ),
//       },
//       {
//         path: '/dashboard/:section',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <Dashboard />
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/confirm-email/:token/:username',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Confirm email page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/edit-nft/:nftEditionId',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>edit NFT page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/for-sale/:nftEditionId',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <ForSaleScreen />
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/my-nft/',
//         element: ( <>My NFT page</>)
//       },
//       {
//         path: '/my-nft/:category',
//         element: ( <>My NFT category page</>)
//       },
//       {
//         path: '/my-notification',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Nofitications page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/my-orders',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>My orders page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/nft/:userId?/:category',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>NFt user category</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/nft-success/:nftEditionId',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>NFt user category</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/pdp/*',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <PdpScreen />
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/relist-nft/:nftId',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>relist-nft</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/reset-password/:token',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Resetpassword page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/transactions',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Transactions page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/users/:creatorType',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>User page by creators type</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/404',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Not found page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/faq',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>FAQs page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/forgot-password',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Forgot password page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/Institution',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Institution page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/login',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Login page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/pdp-sale-ns',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>PDP sale ns page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/pdp-sale-se',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>PDP sale se page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/Privacy-policy',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Privacy policy page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/search',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Search</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/signup',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Signup page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/styleguide',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Styleguide page</>
//           </React.Suspense>
//         ),
//       },
//       {
//         path: '/tos',
//         element: (
//           <React.Suspense fallback={<>Loading...</>}>
//             <>Tos page</>
//           </React.Suspense>
//         ),
//       },
//     ],
//   },
// ]);
export default Router;
