var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Checkbox, Form, FormGrid, FormGridItem, TextField, SSNMaskCustom, FullSSNMaskCustom, PostalCodeMaskCustom, CustomTooltip } from 'common/components';
import { Collapse, Alert, Button, Box, MenuItem, styled, Divider, Tooltip } from '@mui/material';
import { STATIC_IMG_URL, US_STATES } from 'helpers/constants';
import { CalendarOutlineIcon, ChevronLeftOutlineIcon, XOutlineIcon, ChevronRightOutlineIcon, ChevronDownOutlineIcon, ExclamationCircleOutlineIcon, FAQIcon } from 'ui/icons';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { DateTimeFormat, formatDate } from 'helpers/utils';
import { differenceInYears } from 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DwollaCustomerOperation, DwollaCustomerStatus } from 'types/graphql.models';
import { useActivateWalletMutation } from 'graphql-client/dwolla/useActivateWallet';
import React, { useState } from 'react';
import * as DWOLLA_ACTIONS from 'providers/Dwolla/DwollaActions';
import { useDwollaContext } from 'providers';
import { useAppContext } from 'providers';
import { DwollaSetupWalletSteps } from 'providers/Dwolla/dwolla-types';
import { gaEvent, GTagActionTypes, GTagCategoryTypes } from 'lib/gtag';
import { TooltipBox } from '../Dwolla.styled';
const WalletSetupFormBox = styled(Box)(({ theme }) => ({
    'fieldset': {
        border: 'none',
        padding: 0,
        margin: 0
    },
    '.MuiInputBase-input': {
        height: 18,
        fontWeight: 600,
    },
    '.MuiFormControl-root': {
        marginBottom: 0
    },
    '.MuiSvgIcon-root': {
        fontSize: 18,
    },
    '.select-dropdown': {
        '.MuiSelect-select': {
            padding: `${theme.spacing(1.3)} ${theme.spacing(2)}!important`,
            color: `${theme.colors.black}!important`,
        }
    },
    '.MuiInputLabel-root': {
        minHeight: 22,
        color: `${theme.colors.white} !important`
    },
    '.MuiFormHelperText-root.Mui-error': {
        fontWeight: 600,
    },
    '.check-box-wrapper': {
        'label.MuiFormControlLabel-root': {
            alignItems: 'flex-start',
            marginTop: 10,
            marginBottom: 10,
            'span.MuiCheckbox-root': {
                paddingTop: 0,
            },
            '.MuiSvgIcon-root': {
                fontSize: 24,
            },
            '.dwolla-customer-text': {
                fontSize: 12,
                'a': {
                    display: 'inline-block',
                    marginLeft: 4,
                    marginRight: 4,
                }
            }
        },
    },
    '.MuiFormHelperText-root:not(.Mui-error)': {
        display: 'none',
    },
    'hr.MuiDivider-root': {
        margin: `${theme.spacing(1.25)} 0 0`,
    }
}));
const NxDatePicker = styled(Box)(({ theme }) => ({
    'svg.MuiSvgIcon-root': {
        color: `${theme.dynamicTheme.mainSection.bookmarkIconBackground.color}!important`,
    }
}));
export const WalletSetupForm = ({ isEditMode = false, customerData }) => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState('');
    const { activateWalletMutation } = useActivateWalletMutation();
    const { updateDwollaCustomerData, currentUser } = useAppContext();
    const { dispatch } = useDwollaContext();
    const SSNMaskInput = isEditMode ? FullSSNMaskCustom : SSNMaskCustom;
    let initialValues = {
        operation: isEditMode ? DwollaCustomerOperation.Update : DwollaCustomerOperation.Insert,
        firstName: ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstname) || ''),
        lastName: ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastname) || ''),
        email: ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.email) || ''),
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        dateOfBirth: isEditMode ? '' : ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.birthDate) || ''),
        ssn: '',
        terms: false,
    };
    if (isEditMode) {
        initialValues = Object.assign(Object.assign(Object.assign({}, initialValues), customerData), { dateOfBirth: '', ssn: '' });
    }
    const ssnLabel = isEditMode ? t('SSN') : t('Last 4 Digits of SSN');
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            firstName: Yup.string().trim().label(t('First name'))
                .required()
                .matches(/^[aA-zZ0-9\s]+$/, t('Must contain no special characters'))
                .max(50, t('Must be less than or equal to 50 characters')),
            lastName: Yup.string().trim().label(t('Last name'))
                .required()
                .matches(/^[aA-zZ0-9\s]+$/, t('Must contain no special characters'))
                .max(50, t('Must be less than or equal to 50 characters')),
            email: Yup.string().trim().label(t('Email')).email().required(),
            dateOfBirth: Yup.date().label(t('Date of Birth')).required().typeError(t('Invalid date of birth'))
                .test('dateOfBirth', t('Must be between 18 to 125 years of age'), (value) => {
                return !!value && differenceInYears(new Date(), value) >= 18 && differenceInYears(new Date(), value) <= 125;
            }),
            address1: Yup.string().trim().label(t('Address 1'))
                .required()
                .matches(/^[aA-zZ0-9\s]+$/, t('Must contain no special characters'))
                .max(50, t('Must be less than or equal to 50 characters')),
            address2: Yup.string().trim().label(t('Address 2 (Optional)'))
                .matches(/^[aA-zZ0-9\s]+$/, t('Must contain no special characters'))
                .max(50, t('Must be less than or equal to 50 characters')),
            city: Yup.string().trim().label(t('City')).required()
                .matches(/^[aA-zZ\s]+$/, t('Must contain no special characters and numbers'))
                .max(50, t('Must be less than or equal to 50 characters')),
            state: Yup.string().trim().label(t('State')).required(),
            postalCode: Yup.string().trim().label(t('Zip Code')).required()
                .test('postalCode', t('Invalid zip code'), (value) => {
                return !!value && (value.length === 5 || value.length === 10);
            }),
            ssn: Yup.string().trim().label(ssnLabel).required()
                .test('ssn', `Invalid ${ssnLabel.toLocaleLowerCase()}`, (value) => {
                return !!value && (value.length === 4 || value.length === 11);
            }),
            terms: Yup.boolean().test('terms', t('Please accept the terms'), (value) => !!value)
        }),
        onSubmit: ({ firstName, lastName, email, address1, address2, city, state, postalCode, dateOfBirth, ssn, operation }, { setSubmitting }) => {
            submitCustomerData({
                firstName,
                lastName,
                email,
                address1,
                address2,
                city,
                state,
                postalCode,
                dateOfBirth,
                ssn,
                operation
            }, setSubmitting);
        },
    });
    const submitCustomerData = (customerData, setSubmitting) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setSubmitting(true);
        const param = Object.assign(Object.assign({}, customerData), { dateOfBirth: formatDate(new Date(customerData.dateOfBirth), { format: DateTimeFormat.ISO_DATE }), ssn: customerData.ssn.replaceAll('-', '') });
        try {
            const { data } = yield activateWalletMutation({
                variables: { ActivateWalletArguments: param },
            });
            if ((_a = data === null || data === void 0 ? void 0 : data.activateWallet) === null || _a === void 0 ? void 0 : _a.url) {
                gaEvent({ action: GTagActionTypes.Wallet_Activation, category: GTagCategoryTypes.Wallet_Drawer });
                const activateWallet = data.activateWallet;
                dispatch(DWOLLA_ACTIONS.setDialogPrevState([]));
                if (activateWallet.status === DwollaCustomerStatus.Unverified) {
                    dispatch(DWOLLA_ACTIONS.setSetupWalletStep(DwollaSetupWalletSteps.CREATE_WALLET_VERIFICATION_IN_PROGRESS));
                }
                updateDwollaCustomerData(activateWallet);
            }
        }
        catch (e) {
            const error = e;
            if (error.graphQLErrors) {
                setErrorMessage(error.graphQLErrors[0].message);
            }
            else {
                setErrorMessage(error.message);
            }
        }
        setSubmitting(false);
    });
    const handleBirthDateField = (value) => {
        formik.setFieldValue('dateOfBirth', value);
    };
    return (<WalletSetupFormBox>

      <Collapse in={!!errorMessage}>
        <Box sx={{ paddingLeft: 2.5, paddingRight: 2.5 }}>
          <Alert severity="error" icon={<ExclamationCircleOutlineIcon />} action={<Button color="inherit" size="small" variant="text" onClick={() => setErrorMessage('')}>
                <XOutlineIcon />
              </Button>}>
            {errorMessage}
          </Alert>
        </Box>
      </Collapse>

      <Form id="add-bank-form" onSubmit={formik.handleSubmit}>
        <FormGrid>
          <FormGridItem pt={0}>
            <TextField id="firstName" name="firstName" label={t('First name') || ''} variant="standard" formik={formik} placeholder=""/>
          </FormGridItem>
          <FormGridItem pt={0}>
            <TextField id="lastName" name="lastName" label={t('Last name') || ''} variant="standard" formik={formik} placeholder=""/>
          </FormGridItem>
          <FormGridItem pt={0}>
            <TextField id="email" name="email" label={t('Email') || ''} variant="standard" formik={formik} disabled placeholder=""/>
          </FormGridItem>
          <FormGridItem>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <NxDatePicker>
                <DatePicker disableFuture openTo="year" label={t('Date Of Birth')} views={['year', 'month', 'day']} inputFormat="MM/dd/yyyy" value={formik.values['dateOfBirth']} onChange={(value) => { handleBirthDateField(value); }} components={{
            OpenPickerIcon: CalendarOutlineIcon,
            LeftArrowIcon: ChevronLeftOutlineIcon,
            RightArrowIcon: ChevronRightOutlineIcon,
        }} PopperProps={{ className: 'nx-datepicker-dialog-props' }} className="nx-date-picker" renderInput={(params) => <TextField autoComplete="birth-date" name="dateOfBirth" formik={formik} id="dateOfBirth" disableOnChange {...params} label={t('Date Of Birth')} className="half-width"/>}/>
              </NxDatePicker>
            </LocalizationProvider>
          </FormGridItem>
          <FormGridItem pt={0}>
            <Divider />
          </FormGridItem>
          <FormGridItem pt={0}>
            <TextField id="address1" name="address1" label={t('Address 1') || ''} variant="standard" formik={formik} placeholder="Street number, street name"/>
          </FormGridItem>
          <FormGridItem pt={0}>
            <TextField id="address2" name="address2" label={t('Address 2 (Optional)') || ''} variant="standard" formik={formik} placeholder="Apartment, floor, suite etc."/>
          </FormGridItem>
          <FormGridItem pt={0}>
            <TextField id="city" name="city" label={t('City') || ''} variant="standard" formik={formik} placeholder=""/>
          </FormGridItem>
          <FormGridItem half>
            <TextField id="state" name="state" label={t('State') || ''} variant="standard" placeholder="2 letter code eg. NY" formik={formik} select InputProps={{
            endAdornment: <ChevronDownOutlineIcon />
        }} className="select-dropdown">
              <MenuItem value="">
                {t('State...')}
              </MenuItem>
              {US_STATES.map((state) => <MenuItem value={state} key={state}>{state}</MenuItem>)}
            </TextField>
          </FormGridItem>
        </FormGrid>
        <FormGrid>
          <FormGridItem half>
            <TextField id="postalCode" name="postalCode" label={t('Zip Code') || ''} variant="standard" formik={formik} placeholder="00000 OR 00000-0000" InputProps={{
            inputComponent: PostalCodeMaskCustom
        }}/>
          </FormGridItem>
          <FormGridItem pt={0}>
            <Divider />
          </FormGridItem>
          <FormGridItem>
            <TooltipBox>
              <TextField id="ssn" name="ssn" label={ssnLabel} variant="standard" formik={formik} placeholder={isEditMode ? '000-00-0000' : '0000'} InputProps={{
            inputComponent: SSNMaskInput
        }}/>
              <Tooltip title={<CustomTooltip title="What is SSN?" description="A Social Security Number(SSN) is a nine digit number that is for U.S. citizens and to people living in the U.S." imageSrc={`${STATIC_IMG_URL}/ssn.webp`}/>} enterTouchDelay={0} leaveTouchDelay={10000} placement="top-end">
                <Box className="faq-icon"><FAQIcon /></Box>
              </Tooltip>
            </TooltipBox>
          </FormGridItem>
          <FormGridItem pt={0}>
            <Checkbox formik={formik} name="terms" id="terms" option={<span className="dwolla-customer-text">
                By checking this box you agree to
                <a className="dwolla-link" target="_blank" rel="noopener noreferrer" href="https://www.nxlvl.app/privacy/">Our Terms of Service</a>
                and
                <a className="dwolla-link" target="_blank" rel="noopener noreferrer" href="https://www.nxlvl.app/privacy/">Privacy Policy</a>
                as well as our Vendor
                <a className="dwolla-link" href="https://www.dwolla.com/legal/tos/" target="_blank" rel="noopener noreferrer">Dwolla’s Terms of Service</a>
                and
                <a className="dwolla-link" href="https://www.dwolla.com/legal/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </span>} className="accept-terms-checkbox"/>
          </FormGridItem>
          <FormGridItem pt={0}>
            <Button disabled={(formik.isSubmitting || !formik.isValid || !formik.dirty)} type="submit" color="primary" fullWidth size="large" sx={{ marginTop: 2, marginBottom: 6 }}>
              {t('Agree and Continue')}
            </Button>
          </FormGridItem>
        </FormGrid>
      </Form>
    </WalletSetupFormBox>);
};
