import { gql, useMutation } from '@apollo/client';
// { bookmarkType: User, userId: 4 }
export const ADD_BOOKMARK_PERSON = gql `
    mutation bookmarkpeople(
      $bookmarkArgs:bookmarkArgument!
    ) {
      createBookmark(bookmarkArgs: $bookmarkArgs){
        message
      }
    }
`;
export const useAddBookmarkPersonMutation = () => {
    const [addBookmarkMutation, options] = useMutation(ADD_BOOKMARK_PERSON, {
        notifyOnNetworkStatusChange: true,
    });
    const addBookmarkPersonMutation = ({ userId, bookmarkType, query, queryParams }) => addBookmarkMutation({
        variables: { bookmarkArgs: { userId, bookmarkType } },
        update: (cache) => {
            var _a;
            const data = cache.readQuery({ query, variables: queryParams });
            const newData = JSON.parse(JSON.stringify(data));
            if (((_a = newData === null || newData === void 0 ? void 0 : newData.getUserDetailsById) === null || _a === void 0 ? void 0 : _a.isPeopleBookmarked) === false) {
                newData.getUserDetailsById.isPeopleBookmarked = true;
                cache.writeQuery({ query, variables: queryParams, data: newData });
            }
        }
    });
    return { addBookmarkPersonMutation, options };
};
