import { gql, useQuery } from '@apollo/client';
export const GET_FIREBASE_NOTIFICATION = gql `
  query getFirebaseNotifications(
    $userId: Int!
    $take: Int!
    $cursor: Int!
  ) {
    getFirebaseNotifications(
      notificationArgs: { userId: $userId, take: $take, cursor: $cursor }
    ) {
      pageEdges {
        node {
          id
          isActionRequired
          extraParameters
          title
          type
          isRead
          isViewed
          receiverId
          senderId
          nftId
          nftEditionId
          nftEdition {
            availableTiers
            associatedTiers
            price
          }
          nft {
            name
            imageUrl
            videoData
          }
          receiver {
            firstname
            lastname
            profileImage
         }
         sender {
            firstname
            lastname
            profileImage
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`;
export const useGetFirebaseNotificationQuery = (variables) => {
    return Object.assign(Object.assign({}, useQuery(GET_FIREBASE_NOTIFICATION, {
        variables: variables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
    })), { query: GET_FIREBASE_NOTIFICATION });
};
