import { format, parseJSON } from 'date-fns';
export var DateTimeFormat;
(function (DateTimeFormat) {
    DateTimeFormat["STANDARD"] = "MMM dd, hh:mm aa";
    DateTimeFormat["STANDARD_DATE"] = "MMM dd";
    DateTimeFormat["STANDARD_TIME"] = "hh:mm aa";
    DateTimeFormat["ISO_DATE"] = "yyyy-MM-dd";
    DateTimeFormat["MIXPANEL"] = "EEEE, LLLL d, yyyy hh:mm aa";
})(DateTimeFormat || (DateTimeFormat = {}));
export const formatDate = (date, options = {}) => {
    const mergedOptions = Object.assign({ format: DateTimeFormat.STANDARD, timeZone: 'UTC', showHyphensInNonResult: true, nonResultValue: '' }, options);
    const value = typeof date === 'string' ? parseJSON(date) : date;
    if (value) {
        return format(value, mergedOptions.format);
    }
    if (!mergedOptions.showHyphensInNonResult)
        return mergedOptions.nonResultValue;
    if (mergedOptions.format === DateTimeFormat.STANDARD)
        return '--- --, --:-- --';
    if (mergedOptions.format === DateTimeFormat.STANDARD_DATE)
        return '--- --';
    if (mergedOptions.format === DateTimeFormat.STANDARD_TIME)
        return '--:-- --';
    return '---';
};
