import { Loader, NftCard } from 'common/components';
import { Box, Grid, Typography } from '@mui/material';
import { useToast } from '@nxlvl/web-providers';
import { useDashboardNftQuery } from 'graphql-client/dashboard';
import { getPdpUrl } from 'helpers/utils';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NxMainBody, NxMainHead } from '../../DashboardScreen.styled';
import { SectionType } from 'types';
import { TopSalesBox } from './TopSales.styled';
import { PAGE_ROUTES } from 'helpers/constants';
const commonPageId = 'dashboard-top-sales';
const TopSales = ({ catId }) => {
    const { t } = useTranslation();
    const { openToast } = useToast();
    // const router = useRouter();
    const navigate = useNavigate();
    const queryParams = {
        cursor: 0,
        pageNo: 0,
        take: 3,
        activity: catId ? [Number(catId)] : [],
        type: SectionType.TopSales
    };
    const { loading, error, query, data: { getDashboardNFTs = {} } = {} } = useDashboardNftQuery(queryParams);
    useEffect(() => {
        error && openToast(error === null || error === void 0 ? void 0 : error.message, { type: 'error' });
        // eslint-disable-next-line
    }, [error]);
    const topSalesNfts = (getDashboardNFTs === null || getDashboardNFTs === void 0 ? void 0 : getDashboardNFTs.pageEdges) || (loading ? [null, null, null] : []);
    const onNftSelected = (nft) => () => {
        navigate(getPdpUrl(nft === null || nft === void 0 ? void 0 : nft.node));
    };
    if (!loading && topSalesNfts.length === 0) {
        return null;
    }
    return (<TopSalesBox>
      <NxMainHead display="flex" alignItems="center" justifyContent="space-between">
        <Loader loading={loading}>
          <Typography variant="h5" className="dashboard-main-head">{t('Top sales')}</Typography>
        </Loader>
        <Loader loading={loading}>
          <Link to={catId ? `${PAGE_ROUTES.DASHBOARD_TOP_SALES}?activityIds=${catId}` : PAGE_ROUTES.DASHBOARD_TOP_SALES}>
            <a id={`${commonPageId}-see-all`} className="dashboard-see-all">
              {t('See all')}
            </a>
          </Link>
        </Loader>
      </NxMainHead>
      <NxMainBody>
        <Box className="top-sales-list">
          <Grid container spacing={2} className="dashboard-see-all top-sales-row">
            {topSalesNfts.map((nft, index) => {
            var _a, _b;
            return (<Grid key={((_a = nft === null || nft === void 0 ? void 0 : nft.node) === null || _a === void 0 ? void 0 : _a.id) || `top-sales-nft-${index}`} item xs={12} md={4} sm={6} lg={4}>
                  <NftCard id={`${commonPageId}-card-${(_b = nft === null || nft === void 0 ? void 0 : nft.node) === null || _b === void 0 ? void 0 : _b.id}`} isLoading={loading} data={nft === null || nft === void 0 ? void 0 : nft.node} query={query} queryParams={{ dashboardArgs: queryParams }} size="large" onSelect={onNftSelected(nft)}/>
                </Grid>);
        })}
          </Grid>
        </Box>
      </NxMainBody>
    </TopSalesBox>);
};
export default TopSales;
