import React, { useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { ImageCropBox } from './ImageCrop.styles';
import { styled } from '@mui/material';
import { NFT_IMG_RATIO } from 'helpers/constants';
const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(makeAspectCrop({
        unit: '%',
        height: 99,
    }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
};
const CroppingImage = styled('img')(() => ({
    width: '100%',
    '&.landscape-img': {
    // width: '100%',
    // height: 'auto !important',
    },
    '&.portrait-img': {
    // width: 'auto !important',
    }
}));
export const ImageCrop = ({ id, imgSrc, aspect = NFT_IMG_RATIO, imgRef, percentCrop, isLandscape, onCompletedCrop }) => {
    const [crop, setCrop] = useState();
    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        if (percentCrop) {
            setCrop(percentCrop);
        }
        else {
            setCrop(centerAspectCrop(width, height, aspect));
        }
    };
    if (!imgSrc) {
        return null;
    }
    return (<ImageCropBox id={id}>
      {Boolean(imgSrc) && (<ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={(_, percentCrop) => onCompletedCrop(percentCrop)} aspect={aspect} keepSelection>
          <CroppingImage id={`${id}-img`} ref={imgRef} alt="Crop thumbnail" src={imgSrc} onLoad={onImageLoad} crossOrigin="anonymous" className={`nft-crop-img ${isLandscape ? 'landscape-img' : 'portrait-img'}`}/>
        </ReactCrop>)}
    </ImageCropBox>);
};
