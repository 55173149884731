import { Box, styled } from '@mui/material';
export const SearchDropdownBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'stretch',
}));
export const SearchFieldBox = styled(Box)(({ theme }) => ({
    width: '80%',
    [theme.breakpoints.down('sm')]: {
        width: '65%',
    },
    'input[type="text"]::placeholder': {
        opacity: '1',
        fontWeight: '600',
    },
    '.nx-search-icon-box': {
        backgroundColor: theme.dynamicTheme.mainSection.chipPrimary.backgroundColor,
        marginRight: `${theme.spacing(0)}`,
        marginLeft: `${theme.spacing(1)}`,
        borderRadius: '.625rem',
        '.nx-search-icon': {
            transform: 'scale(1.3)',
        },
    },
    '.nx-search-text-input > div': {
        fontWeight: 'bold',
        margin: `${theme.spacing(0)}`,
        borderBottomRightRadius: '0',
        borderTopRightRadius: '0',
        minHeight: '3.5rem',
    },
}));
export const DropdownListBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.dynamicTheme.mainSection.chipPrimary.backgroundColor,
    borderRadius: '0 .5rem .5rem 0',
    width: '25%',
    height: 56,
    padding: `0 ${theme.spacing(0.75)}`,
    position: 'relative',
    '.MuiSelect-standard.MuiSelect-select': {
        minHeight: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 !important',
    },
    '.nx-search-dropdown-icon': {
        // this for NXM-3398
        position: 'absolute',
        right: 0,
        top: 'calc(50% - 0.5em)',
        pointerEvents: 'none',
    },
    [theme.breakpoints.down('sm')]: {
        width: '40%',
    }
}));
