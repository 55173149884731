import React from 'react';
import { Box, keyframes, styled } from '@mui/material';
import { LoaderIcon } from 'ui/icons';
const rotate360 = keyframes `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const LoaderBox = styled(Box)(({ theme }) => ({
    'svg': {
        animation: `${rotate360} 1s linear infinite`
    },
    '&.size-small': {
        'svg': {
            width: '2.5rem',
            height: '2.5rem',
        },
    },
    '&.size-medium': {
        marginBottom: theme.spacing(1),
        'svg': {
            width: '3.5rem',
            height: '3.5rem',
            display: 'block'
        },
    },
    '&.size-xlarge': {
        'svg': {
            width: '4.5rem',
            height: '4.5rem',
        },
    },
}));
export const LoaderCircular = ({ size = 'medium' }) => {
    return (<LoaderBox className={`size-${size}`}>
      <LoaderIcon />
    </LoaderBox>);
};
