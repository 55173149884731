import { gql, useMutation } from '@apollo/client';
export const MARK_FIREBASE_NOTIFICATION_AS_READ = gql `
  mutation markFirebaseNotificationAsRead (
    $notificationId: Int!
    ){
    markFirebaseNotificationAsRead(notificationId:  $notificationId)
  }
`;
export const useMarkFirebaseNotificationAsRead = () => {
    const [markAllReadFirebaseNotification] = useMutation(MARK_FIREBASE_NOTIFICATION_AS_READ, {
        notifyOnNetworkStatusChange: true,
    });
    const markReadFirebaseNotificationMutation = ({ variables, query, notificationId }) => markAllReadFirebaseNotification({
        variables: { notificationId: notificationId },
        update: (cache) => {
            var _a, _b;
            const data = cache.readQuery({ query, variables: variables });
            const newData = JSON.parse(JSON.stringify(data));
            (_b = (_a = newData === null || newData === void 0 ? void 0 : newData.getFirebaseNotifications) === null || _a === void 0 ? void 0 : _a.pageEdges) === null || _b === void 0 ? void 0 : _b.map((notification) => {
                var _a;
                if ((notification === null || notification === void 0 ? void 0 : notification.node) && notificationId == ((_a = notification === null || notification === void 0 ? void 0 : notification.node) === null || _a === void 0 ? void 0 : _a.id))
                    notification.node.isRead = true;
            });
            cache.writeQuery({ query, variables: variables, data: newData });
        }
    });
    return { markReadFirebaseNotificationMutation };
};
