import { Box, styled } from '@mui/material';
export const TopSalesBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        '.top-sales-row': {
            flexWrap: 'nowrap',
            overflowX: 'scroll',
        },
        '.top-sales-list': {
            marginRight: theme.spacing(-2),
        },
        '.top-sales-row > div': {
            display: 'flex',
            flexShrink: '0',
            maxWidth: '75%',
            '&:last-child': {
                marginRight: theme.spacing(2),
            },
        },
        '.nft-card-img-box': {
            height: '26.875rem!important',
        }
    }
}));
