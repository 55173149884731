import { Dialog } from 'common/components';
import { Box, styled, DialogContent, Typography } from '@mui/material';
import { useQRDialog } from './QRDialogProvider';
import { useTranslation } from 'react-i18next';
// import { useToast } from '../components/common';
import QRCode from 'react-qr-code';
import { SOCIAL_APP_LINK } from 'helpers/constants';
import React from 'react';
const commonPageId = 'qr-dialog';
const QRCodeBox = styled(Box)(({ theme }) => ({
    marginBottom: 40,
    marginTop: 40,
    padding: 10,
    backgroundColor: theme.colors.white,
    display: 'inline-flex'
}));
const QRCodeContent = styled(Box)(({ theme }) => ({
    '.qr-code-text': {
        color: theme.colors.neutral500,
    }
}));
export const QRDialog = () => {
    const { title = '', isOpen, closeQRDialog } = useQRDialog();
    const { t } = useTranslation();
    // const { openToast } = useToast();
    const handleClose = () => {
        closeQRDialog();
    };
    if (!isOpen)
        return null;
    return (<Dialog className={`${commonPageId} dialog-md`} open={true} onClose={handleClose} maxWidth="md" variant="primary" hideDialogAction={true}>
      <DialogContent sx={{ marginY: 4, textAlign: 'center' }}>
        <QRCodeContent>
          <Typography variant="h6" fontWeight="500" className="qr-code-text">{title}</Typography>
          <QRCodeBox>
            <QRCode value={SOCIAL_APP_LINK} size={160}/>
          </QRCodeBox>
          <Box paddingX={6}>
            <Typography variant="h4" fontWeight="500" marginBottom={1}>{t('Download the free app')}</Typography>
            <Typography variant="h6" fontWeight="500" className="qr-code-text">{t('Point your phone’s camera at the code above to visit the App store')}</Typography>
          </Box>
        </QRCodeContent>
      </DialogContent>
    </Dialog>);
};
