import { styled } from '@mui/material';
import React, { useState } from 'react';
import Drawer from 'common/components/Drawer/Drawer';
import { t } from 'i18next';
import SideBarMenuCard from './SideBarMenuCard/SideBarMenuCard';
const SidebarDrawer = styled(Drawer)({
    '.nx-menu-text span': {
        fontSize: '1rem'
    }
});
export const SidebarMobileDrawer = ({ open, onClose }) => {
    const [selectedCat, setCategory] = useState(null);
    const showHeader = !selectedCat;
    return (<SidebarDrawer open={open} closeDrawer={onClose} anchor="left" color="primary" leftButton={showHeader ? t('Menu') : ''}>
      <SideBarMenuCard fullHeight onSubCategorySelected={cat => setCategory(cat)}/>
    </SidebarDrawer>);
};
