// Naming format: [MODULE_NAME]__[SUB_MODULE_1]__[SUB_MODULE_2]__...[SUB_MODULE_N]
export const PAGE_ROUTES = {
    // Auth
    LOGIN: '/login',
    SIGNUP: '/signup',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    DASHBOARD: '/dashboard',
    DASHBOARD_FOR_YOU: '/dashboard/for-you',
    DASHBOARD_LATEST_DROP: '/dashboard/latest-drop',
    DASHBOARD_EXPLORE_ALL: '/dashboard/explore-all',
    DASHBOARD_TOP_PICKS: '/dashboard/top-pick',
    DASHBOARD_TOP_SALES: '/dashboard/top-sales',
    DASHBOARD_TRENDING: '/dashboard/trending',
    // PDP
    PDP: '/pdp',
    PDP__PARAM: '/pdp/[...param]',
    // TRANSACTIONS
    TRANSACTIONS: '/transactions',
    TRANSACTIONS__WALLET: '/transactions/wallet',
    TRANSACTIONS__CC: '/transactions/credit-card',
    // USERS
    USERS__BOOKMARKED_PEOPLE: '/users/bookmarked-people',
    USERS__BOOKMARKED_INSTITUTE: '/users/bookmarked-institute',
    USERS__TOP_TRADERS: '/users/top-traders',
    // SEARCH
    SEARCH: '/search',
    // MY NFT
    MY_NFT: '/my-nft',
    MY_NFT__DRAFT: '/my-nft/draft',
    MY_NFT__CREATED: '/my-nft/created',
    MY_NFT__LISTED: '/my-nft/listed',
    MY_NFT__PURCHASED: '/my-nft/purchased',
    MY_NFT__SOLD: '/my-nft/sold',
    MY_NFT__BOOKMARKED: '/my-nft/bookmarked',
    // MY ORDERS
    MY_ORDERS: '/my-orders',
    // MY NOTIFICATION
    MY_NOTIFICATION: '/my-notification',
    // NFT
    NFT: '/nft',
    NFT_SUCCESS: '/nft-success',
    // CREATE/EDIT/RELIST NFT
    CREATE_NFT: '/create-nft',
    EDIT_NFT: '/edit-nft',
    RELIST_NFT: '/relist-nft',
    // FOR SALE (Buy from Secondary marketplace)
    FOR_SALE: '/for-sale',
    // TOS
    TOS: '/tos'
};
