import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { WalletIcon } from 'ui/icons';
import { PAGE_ROUTES } from 'helpers/constants';
import { LeftSideBarCard } from '../SideBar.styled';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
const commonPageId = 'transition-history-menu-card';
const TransitionHistoryMenuCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isCreditCardTransactions = location.pathname.startsWith(PAGE_ROUTES.TRANSACTIONS__CC);
    const navigateToPage = (pathToNavigate) => {
        navigate(pathToNavigate);
    };
    return (<LeftSideBarCard>
      <List className="nx-main-menu">
        <ListItem id={`${commonPageId}-button-wallet`} button className={`nx-main-menu-item ${isCreditCardTransactions ? '' : 'active'}`} onClick={() => navigateToPage(PAGE_ROUTES.TRANSACTIONS__WALLET)}>
          <Box display="flex">
            <ListItemIcon className="nx-menu-icon"><WalletIcon /></ListItemIcon>
            <ListItemText className="nx-menu-text">{t('Wallet')}</ListItemText>
          </Box>
        </ListItem>
        {/* NXM-2640 Hide "Credit card" options, We will use this in future. */}
        {/* <ListItem
          id={`${commonPageId}-button-credit-card`}
          button
          disabled
          className={`nx-main-menu-item ${isCreditCardTransactions ? 'active' : ''}`}

        >
          <Box display="flex">
            <ListItemIcon className="nx-menu-icon"><CreditCardIcon /></ListItemIcon>
            <ListItemText className="nx-menu-text">{t('Credit Card')}</ListItemText>
          </Box>
        </ListItem> */}
      </List>
    </LeftSideBarCard>);
};
export default TransitionHistoryMenuCard;
