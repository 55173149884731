import { gql, useMutation } from '@apollo/client';
const CREATE_APPLE_USER = gql `
  mutation createTempAppleUser(
    $id: String!, 
    $user: JSON!
  ) {
    createTempAppleUser(
        id: $id, 
        user: $user
    )
  }
`;
export const useCreateTempAppleUser = () => {
    const [createTempAppleUserMutation] = useMutation(CREATE_APPLE_USER, {
        notifyOnNetworkStatusChange: true,
    });
    const createAppleUser = (variables) => {
        return createTempAppleUserMutation({
            variables
        });
    };
    return { createAppleUser };
};
