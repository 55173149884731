import { DwollaTnxStatus, DwollaTnxType } from 'types/graphql.models';
import { ExclamationCircleOutlineIcon, CartIcon, SoldIcon, FollowOutlineIcon, BankIcon } from 'ui/icons';
import { OrderTnxType } from 'types';
export const getIconByTransactionStatusAndType = (status, type) => {
    if (status === DwollaTnxStatus.Failed) {
        return (<ExclamationCircleOutlineIcon className="status-icon status-failed"/>);
    }
    switch (type) {
        case DwollaTnxType.FundAdded:
        case OrderTnxType.CreatorRoyalty:
            return (<FollowOutlineIcon className="status-icon"/>);
        case DwollaTnxType.Purchased:
            return (<CartIcon className="status-icon"/>);
        case DwollaTnxType.Sold:
            return (<SoldIcon className="status-icon"/>);
        case DwollaTnxType.FundWithdrawn:
            return (<BankIcon className="status-icon"/>);
    }
};
