var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Alert, Box, Button, DialogActions, DialogContent, Divider, IconButton, InputAdornment, Link, styled, Typography, useTheme, } from '@mui/material';
import { useLoginMutation } from 'graphql-client/auth';
import { useGeUserByIdLazyQuery } from 'graphql-client/auth/useGeUserByIdQuery';
import Cookie from 'js-cookie';
import { SocialProvider } from 'types';
import { 
/* AppleIcon, */ ExclamationCircleOutlineIcon, EyeFillIcon, EyeOffOutlineIcon, FacebookWithBackgroundIcon, 
/* GoogleColorIcon, */ InstagramWithBackgroundIcon, MeOutlineIcon, SuccessCircleOutlineIcon, } from 'ui/icons';
import { useAuthDialog } from 'providers';
import { useLoader, useToast, useAppContext } from '@nxlvl/web-providers';
import { useTranslation } from 'react-i18next';
// import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Checkbox, Form, FormGrid, FormGridItem, TextField, Dialog /* , AppleLoginSignUp */, } from 'common/components';
import React, { useState } from 'react';
import { getJwtExpirationDate } from 'helpers/utils';
import { ForgotPassword } from './ForgotPassword';
import { MixpanelEvents, mpTrackEvent } from 'lib/mixpanel';
import { useNavigate } from 'react-router-dom';
export const getUserDataCookie = (obj) => ({
    id: obj.id,
    username: obj.username,
    firstname: obj.firstname,
    lastname: obj.lastname,
    marketplaceFullAccess: obj.marketplaceFullAccess,
    email: obj.email,
    birthDate: obj.birthDate,
    profileImage: obj.profileImage,
    dwollaCustomer: obj.dwollaCustomer,
});
const commonPageId = 'auth-dialog';
const AuthButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.colors.neutral050,
    color: theme.colors.black,
    justifyContent: 'start',
    marginBottom: theme.spacing(1),
    '&:hover': {
        backgroundColor: theme.colors.neutral100,
        color: theme.colors.black,
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
    },
    '.MuiButton-startIcon': {
        width: '4rem',
        [theme.breakpoints.down('sm')]: {
            width: '3rem',
        },
    },
    '.auth-btn-text': {
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: '.875rem',
        },
    },
    '&.multi-soc-link': {
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            svg: {
                margin: `0 ${theme.spacing(1)} 0 0`,
            },
        },
    },
}));
export const LoginSignUpNavText = styled(Typography)(({ theme }) => ({
    fontSize: '.75rem',
    marginTop: theme.spacing(2),
}));
export const LinkClick = styled(Link)(({ theme }) => ({
    '&:hover': {
        color: `${theme.colors.blue} !important`,
    },
}));
export const RememberBox = styled(Box)(() => ({
    '.MuiFormControlLabel-label': {
        fontSize: '0.875rem !important',
    },
    '.forget-password': {
        fontSize: '0.875rem',
        textAlign: 'left',
        fontWeight: '500',
        paddingTop: '0.625rem',
    },
}));
export const AuthDialog = () => {
    const { isOpen, options, closeAuthDialog, rejectAuthDialog } = useAuthDialog();
    const { refreshContext } = useAppContext();
    const { openToast } = useToast();
    const { openLoader, closeLoader } = useLoader();
    const { loginMutation } = useLoginMutation();
    const { getUserById } = useGeUserByIdLazyQuery();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [currentView, setCurrentView] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const theme = useTheme();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().label(t('Username')).email().required(),
            password: Yup.string().label(t('Password')).required(),
        }),
        onSubmit: (values) => {
            initiateLogin({ email: values.email, password: btoa(values.password) });
        },
    });
    const currEmail = formik.getFieldProps('email').value || '';
    const getModalTitle = () => {
        if (currentView === 'email')
            return t('Log in');
        if (currentView === 'forgot-password')
            return t('Forgot Password?');
        if (currentView === 'forgot-success')
            return t('Check your email');
        return t('Log in to nXlvl');
    };
    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };
    const handleClose = (user) => {
        setErrorMessage('');
        setCurrentView('');
        closeAuthDialog(user);
    };
    const handleCancel = () => __awaiter(void 0, void 0, void 0, function* () {
        (options === null || options === void 0 ? void 0 : options.redirectToDashboard) && (yield navigate('/'));
        setErrorMessage('');
        rejectAuthDialog();
        setCurrentView('');
    });
    const initiateLogin = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            openLoader();
            const { data } = yield loginMutation({
                variables: { loginInput: Object.assign({}, values) },
            });
            const tokenExpDate = getJwtExpirationDate(data.login.accessToken);
            Cookie.set('token', data.login.accessToken, { expires: tokenExpDate });
            const refreshTokenExpDate = getJwtExpirationDate(data.login.refreshToken);
            Cookie.set('refresh-token', data.login.refreshToken, { expires: refreshTokenExpDate });
            Cookie.set('userId', data.login.userId, { expires: refreshTokenExpDate || tokenExpDate });
            const { data: userData } = yield getUserById({
                variables: { id: data.login.userId },
            });
            if (userData) {
                mpTrackEvent(MixpanelEvents.Login, (userData === null || userData === void 0 ? void 0 : userData.getUserById) || {});
                Cookie.set('user', btoa(JSON.stringify(getUserDataCookie(userData.getUserById || {}))), {
                    expires: refreshTokenExpDate || tokenExpDate,
                });
            }
            refreshContext();
            handleClose(userData === null || userData === void 0 ? void 0 : userData.getUserById);
            closeLoader();
        }
        catch (e) {
            closeLoader();
            const error = e;
            if (error.graphQLErrors) {
                setErrorMessage(error.graphQLErrors[0].message);
            }
            else {
                setErrorMessage(error.message);
            }
        }
    });
    const handleSignUp = () => {
        handleCancel();
        navigate('/signup');
    };
    // const handleGoogleSuccess = (
    //   success: GoogleLoginResponse | GoogleLoginResponseOffline
    // ) => {
    //   if ('profileObj' in success) {
    //     const args = {
    //       email: success?.profileObj?.email,
    //       provider: SocialProvider.Google,
    //       socialAuthId: success?.googleId,
    //     };
    //     initiateLogin(args);
    //   }
    // };
    // const handleGoogleError = (res: { error: string; details: string }) => {
    //   if ('details' in res && 'error' in res) {
    //     openToast(`${res.error}: ${res.details}`, { type: 'error' });
    //   } else {
    //     openToast(t('Login via Google is not successful.'), { type: 'error' });
    //   }
    // };
    const handleFbLogin = () => {
        window.FB.login((response) => {
            const authResponse = response === null || response === void 0 ? void 0 : response.authResponse;
            if (response.status === 'connected' && authResponse) {
                initiateLogin({
                    provider: SocialProvider.Facebook,
                    socialAuthId: authResponse === null || authResponse === void 0 ? void 0 : authResponse.userID,
                });
            }
            else {
                openToast(t('Login via Facebook is not successful.'), { type: 'error' });
            }
        }, { scope: 'public_profile' });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const handleAppleLogin = async (appleLoginResponse: any) => {
    //   if (appleLoginResponse && !appleLoginResponse?.error) {
    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     const decoded: any = jwt_decode(appleLoginResponse?.authorization?.id_token || '');
    //     const email = (decoded && decoded?.email) ? decoded?.email : '';
    //     initiateLogin({
    //       email: email,
    //       provider: SocialProvider.Apple,
    //       socialAuthId: (appleLoginResponse?.authorization?.id_token || '')
    //     });
    //   } else {
    //     openToast(t('Login via Apple is not successful.'), { type: 'error' });
    //   }
    // }
    const renderEmailLogin = () => {
        return (<Form id={`${commonPageId}-login-form`} onSubmit={formik.handleSubmit} mt={2} className='form-white-bg' sx={{
                '.MuiInputLabel-root': {
                    color: `${theme.colors.black} !important`,
                    '&.Mui-focused': {
                        color: `${theme.colors.black} !important`,
                    },
                },
                '.MuiInput-root': {
                    backgroundColor: theme.colors.white,
                },
            }}>
        <FormGrid>
          <FormGridItem>
            <TextField label='Username' name='email' autoComplete='email' size='large' autoFocus formik={formik} successIcon={<InputAdornment position='end'>
                  <IconButton edge='end'>
                    <SuccessCircleOutlineIcon />
                  </IconButton>
                </InputAdornment>}/>
          </FormGridItem>
          <FormGridItem>
            <TextField name='password' autoComplete='current-password' type={showPassword ? 'text' : 'password'} label={t('Password')} size='large' formik={formik} InputProps={{
                endAdornment: (<InputAdornment position='end'>
                    <IconButton onClick={handleShowPassword} edge='end'>
                      {showPassword ? <EyeOffOutlineIcon /> : <EyeFillIcon />}
                    </IconButton>
                  </InputAdornment>),
            }}/>
          </FormGridItem>
          <FormGridItem>
            <Button id={`${commonPageId}-sign-in-button`} type='submit' fullWidth color='primary' size='medium' sx={{ mt: 3, mb: 2 }} className='btn-login-submit'>
              {t('Submit')}
            </Button>
          </FormGridItem>
          <FormGridItem>
            <RememberBox display='flex' justifyContent='space-between'>
              <Checkbox formik={formik} name='rememberMe' option={'Remember me'} className='remember-checkbox'/>
              <LinkClick id={`${commonPageId}-forgot-password`} onClick={() => setCurrentView('forgot-password')} className='forget-password'>
                {t('Forgot password')}?
              </LinkClick>
            </RememberBox>
          </FormGridItem>
        </FormGrid>
      </Form>);
    };
    const renderInitialButtons = () => {
        return (<Box margin={0}>
        <AuthButton onClick={() => setCurrentView('email')} variant='outlined' color='secondary' fullWidth startIcon={<MeOutlineIcon />}>
          <span className='auth-btn-text'>Use email</span>
        </AuthButton>
        {/* <GoogleLogin
              clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || ''}
              render={(renderProps) => (
                <AuthButton
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  startIcon={<GoogleColorIcon />}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <span className="auth-btn-text">Continue with Google</span>
                </AuthButton>
              )}
              buttonText="Login"
              onSuccess={handleGoogleSuccess}
              onFailure={handleGoogleError}
              cookiePolicy={'single_host_origin'}
            /> */}
        {/* <AppleLoginSignUp
              handleAppleClicked={handleAppleLogin}
              render={(renderProps) => (
                <AuthButton
                  variant="outlined"
                  onClick={renderProps.onClick}
                  color="secondary" fullWidth
                  startIcon={
                    <AppleIcon sx={{ color: (theme) => theme.colors.black }} />}
                >
                  <span className="auth-btn-text">Continue with Apple</span>
                </AuthButton>
              )}
            /> */}
        <AuthButton className='multi-soc-link' onClick={handleFbLogin} variant='outlined' color='secondary' fullWidth startIcon={<>
              <FacebookWithBackgroundIcon sx={{ mr: 1 }}/> <InstagramWithBackgroundIcon />
            </>}>
          <span className='auth-btn-text'>Continue with Facebook or Instagram</span>
        </AuthButton>
      </Box>);
    };
    const renderContent = () => {
        if (currentView === 'email')
            return renderEmailLogin();
        if (currentView === 'forgot-password')
            return (<ForgotPassword currEmail={currEmail} setCurrentView={setCurrentView} setErrorMessage={setErrorMessage}/>);
        if (currentView === 'forgot-success')
            return <ForgotPassword isSuccess/>;
        return renderInitialButtons();
    };
    if (!isOpen)
        return null;
    const hideSignupLink = ['forgot-success'].indexOf(currentView) > -1;
    const modalTitle = getModalTitle();
    return (<Dialog className='login-dialog dialog-md' title={modalTitle} open={true} onClose={handleCancel} maxWidth='md' variant='secondary' hideDialogAction={true}>
      <DialogContent>
        {errorMessage && (<Alert severity='error' icon={<ExclamationCircleOutlineIcon />} sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>)}
        {renderContent()}
      </DialogContent>
      {!hideSignupLink && (<DialogActions>
          <Box sx={{ width: '100%' }}>
            <Divider />
            <LoginSignUpNavText>
              Not a member?{' '}
              <Button variant='text' disableRipple onClick={handleSignUp}>
                Sign up
              </Button>
            </LoginSignUpNavText>
          </Box>
        </DialogActions>)}
    </Dialog>);
};
