var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DEFAULT_IMAGE } from 'helpers/constants';
import React, { useState, useEffect } from 'react';
import { Box, styled } from '@mui/material';
// interface NxCustomImageProps extends ImageProps {
//   id?: string;
//   onError?: () => void;
//   alt: string;
// }
const NxCustomImageBox = styled(Box)({
    position: 'relative',
    height: '100%',
    '.nx-nft-img': {
        borderRadius: '0.5rem',
        // position: 'absolute',
        inset: '0px',
        boxSizing: 'border-box',
        padding: '0px',
        border: 'none',
        margin: 'auto',
        // display: 'block',
        // width: '0px',
        // height: '0px',
        minWidth: '100%',
        maxWidth: '100%',
        minHeight: '100%',
        maxHeight: '100%'
    }
});
export const NxCustomImage = (_a) => {
    var { src, alt, onError, className = '' } = _a, props = __rest(_a, ["src", "alt", "onError", "className"]);
    const [imgSrc, setImgSrc] = useState(src);
    useEffect(() => {
        setImgSrc(src);
    }, [src]);
    const errorFunction = () => {
        if (onError) {
            onError();
        }
        else {
            setImgSrc(DEFAULT_IMAGE);
        }
    };
    return (<NxCustomImageBox>
      <img src={(imgSrc || DEFAULT_IMAGE)} alt={alt} onError={errorFunction} layout="fill" {...props} className={`nx-nft-img ${className}`}/>
    </NxCustomImageBox>);
};
