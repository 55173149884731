import React from 'react';
import { BuildBrandCard, DiscoverCard, MyNFTMenuCard, SponsoredCard } from 'common/Layout';
import { SideBarWrap } from '../SideBar.styled';
const MyNftSidebar = () => {
    return (<SideBarWrap>
      <DiscoverCard hideMyNftBtn={true}/>
      <MyNFTMenuCard />
      <BuildBrandCard labels={['Discover', 'Collect', 'Trade']}/>
      <SponsoredCard />
    </SideBarWrap>);
};
export default MyNftSidebar;
