import { NftCard, Loader } from 'common/components';
import { Grid, Typography } from '@mui/material';
import { useToast } from '@nxlvl/web-providers';
import { useDashboardNftQuery } from 'graphql-client/dashboard';
import { getPdpUrl } from 'helpers/utils';
import { useNavigate, Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NxMainBody, NxMainHead } from '../DashboardScreen.styled';
import { SectionType } from 'types';
import { PAGE_ROUTES } from 'helpers/constants';
const commonPageId = 'dashboard-for-you';
export const ForYou = ({ catId }) => {
    const { t } = useTranslation();
    const { openToast } = useToast();
    // const router = useRouter();
    const navigate = useNavigate();
    const queryParams = {
        cursor: 0,
        pageNo: 0,
        take: 6,
        activity: catId ? [Number(catId)] : [],
        type: SectionType.ForYou
    };
    const { loading, error, query, data: { getDashboardNFTs = {} } = {} } = useDashboardNftQuery(queryParams);
    useEffect(() => {
        error && openToast(error === null || error === void 0 ? void 0 : error.message, { type: 'error' });
        // eslint-disable-next-line
    }, [error]);
    const forYouNfts = ((getDashboardNFTs === null || getDashboardNFTs === void 0 ? void 0 : getDashboardNFTs.pageEdges) || (loading ? [null, null, null, null, null, null] : []));
    const onNftSelected = (nft) => () => {
        navigate(getPdpUrl(nft === null || nft === void 0 ? void 0 : nft.node));
    };
    if (!loading && forYouNfts.length === 0) {
        return null;
    }
    return (<>
      <NxMainHead display="flex" alignItems="center" justifyContent="space-between">
        <Loader loading={loading}>
          <Typography variant="h5" className="dashboard-main-head">{t('For you')}</Typography>
        </Loader>
        <Loader loading={loading}>
          <Link to={catId ? `${PAGE_ROUTES.DASHBOARD_FOR_YOU}?activityIds=${catId}` : PAGE_ROUTES.DASHBOARD_FOR_YOU}>
            <a id={`${commonPageId}-see-all`} className="dashboard-see-all">
              {t('See all')}
            </a>
          </Link>
        </Loader>
      </NxMainHead>
      <NxMainBody>
        <Grid container spacing={2}>
          {forYouNfts.map((nftData, i) => {
            var _a;
            return (<Grid key={i} item xs={6} sm={3} lg={2}>
              <NftCard id={`for-you-card-${(_a = nftData === null || nftData === void 0 ? void 0 : nftData.node) === null || _a === void 0 ? void 0 : _a.id}`} size="small" data={nftData === null || nftData === void 0 ? void 0 : nftData.node} query={query} queryParams={{ dashboardArgs: queryParams }} isLoading={loading} onSelect={onNftSelected(nftData)}/>
            </Grid>);
        })}

          {/* *********** Will need it in future ************** */}

          {/*
            <Grid item xs={12} lg={2}>
                <AdvertiseCard addDetails={true} />
            </Grid>
        */}
        </Grid>
      </NxMainBody>
    </>);
};
