import { colors, themeDefault } from 'ui/foundations';
const MuiDialog = {
    styleOverrides: {
        root: {
            '.MuiTypography-root': {
                color: colors.black,
                '&.MuiDialogTitle-root': {
                    fontSize: '1.125rem',
                },
            },
            '.MuiDialogTitle-root': {
                padding: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '&.notification-modal-title': {
                    justifyContent: 'flex-start',
                    fontSize: '1rem',
                    paddingBottom: 4,
                },
                //******* This z-index we are adding for the close button in PDP maximize slide. *******// 
                '.MuiButtonBase-root': {
                    zIndex: '10'
                }
            },
            '.MuiDialogContent-root': {
                padding: themeDefault.spacing(2),
            },
            '.MuiDialogActions-root': {
                padding: themeDefault.spacing(2),
                justifyContent: 'flex-start'
            },
            '.external-share': {
                '.MuiIconButton-root': {
                    padding: '0',
                },
                '.MuiSvgIcon-root': {
                    'path': {
                        stroke: colors.blue,
                        fill: colors.blue,
                    }
                },
                '.dialog-head': {
                    fontSize: '1rem',
                },
                '.dialog-sub-head': {
                    fontSize: '1.125rem',
                    fontWeight: '600',
                    lineHeight: '1.625rem'
                },
                '.dialog-text': {
                    fontSize: '0.875rem',
                    color: colors.neutral450,
                    fontWeight: '500',
                    lineHeight: '1.625rem'
                },
            },
        },
        paper: {
            width: '100%',
            '&.notification-modal': {
                '.MuiDialogContent-root': {
                    padding: '0 1rem 0 2.813rem',
                },
                '.MuiTypography-root': {
                    color: colors.white,
                    fontWeight: '500',
                },
                'svg': {
                    width: '1.5rem',
                    height: '1.5rem',
                    marginRight: 4,
                },
                '.MuiDialogActions-root': {
                    padding: '1rem'
                }
            }
        },
        paperWidthSm: {
            [themeDefault.breakpoints.up('sm')]: {
                maxWidth: '20.5rem!important'
            }
        },
        paperWidthMd: {
            [themeDefault.breakpoints.up('sm')]: {
                maxWidth: '31rem!important',
            }
        },
        paperWidthLg: {
            maxWidth: '37.5rem',
        },
        paperWidthXl: {
            maxWidth: '100% !important',
            width: '100%',
            height: '100vh',
            margin: 0,
            borderRadius: 0,
        }
    },
    variants: [
        {
            props: {
                color: 'primary',
            },
            style: {
                '.MuiTypography-root': {
                    color: colors.white,
                },
                '.MuiDialog-container > .MuiPaper-root': {
                    backgroundColor: colors.blueDark,
                    [themeDefault.breakpoints.down('sm')]: {
                        height: '100%',
                        maxHeight: 'unset',
                        maxWidth: '100%!important',
                        margin: '0px',
                        backgroundColor: `${colors.black}!important`,
                        paddingTop: 20,
                    },
                },
                '.MuiIconButton-root': {
                    color: colors.white
                },
            }
        },
        {
            props: {
                color: 'secondary',
            },
            style: {
                '.MuiDialog-container > .MuiPaper-root': {
                    backgroundColor: colors.white
                },
                '.MuiDivider-root': {
                    borderColor: colors.neutral075,
                    margin: '0',
                },
            }
        },
        {
            props: {
                color: 'error',
            },
            style: {
                '.MuiDialog-container > .MuiPaper-root': {
                    backgroundColor: colors.error500
                },
                '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                    backgroundColor: colors.errorButton,
                },
            }
        },
        {
            props: {
                color: 'success',
            },
            style: {
                '.MuiDialog-container > .MuiPaper-root': {
                    backgroundColor: colors.success500
                },
                '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                    backgroundColor: colors.successButton,
                },
            }
        },
        {
            props: {
                color: 'warning',
            },
            style: {
                '.MuiDialog-container > .MuiPaper-root': {
                    backgroundColor: colors.warning500
                },
                '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                    backgroundColor: colors.warningButton,
                    color: `${colors.black}!important`,
                },
                '.MuiTypography-root': {
                    color: `${colors.black}!important`,
                },
                'svg.notification-modal-icon': {
                    path: {
                        fill: `${colors.black}!important`
                    }
                }
            }
        },
        {
            props: {
                color: 'default',
            },
            style: {
                '.MuiDialog-container > .MuiPaper-root': {
                    backgroundColor: colors.black,
                    '.MuiButtonBase-root': {
                        color: colors.white
                    }
                },
                '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                    backgroundColor: colors.infoButton,
                },
            }
        }
    ],
    defaultProps: {}
};
export default MuiDialog;
