import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useRegisterFirebaseToken } from 'graphql-client/auth/useRegisterFirebaseToken';
import MultipleNotificationContent from './MultipleNotificationContent';
// import { getToken, onMessageListener } from '../../../firebaseInit';
import { useAppContext } from '../../Application/AppProvider';
import { LogoIcon } from 'ui/icons';
import { useGetUserStatisticLazyQuery, useGetFirebaseNotificationCountLazyQuery } from 'graphql-client/notifications';
import { useSnackbar } from 'notistack';
const initialNotification = {
    title: '',
    body: '',
    nftEditionId: 0,
    nftId: 0,
    variant: 'success',
    showActionButtons: false,
    type: '',
    subType: ''
};
const MultipleNotificationContext = createContext({});
export const useNotificationContext = () => {
    return useContext(MultipleNotificationContext);
};
export const MultipleNotificationProvider = ({ children }) => {
    const { currentUser, isLoggedIn } = useAppContext();
    const { getFirebaseNotificationStatistic, notificationStateData } = useGetUserStatisticLazyQuery();
    const { getFirebaseNotificationCount, notificationUnReadCount } = useGetFirebaseNotificationCountLazyQuery();
    const { registerFirebaseToken } = useRegisterFirebaseToken();
    const { enqueueSnackbar } = useSnackbar();
    const [notification, setNotification] = useState(Object.assign({}, initialNotification));
    const [isNotificationBadge, setIsNotificationBadge] = useState(false);
    const [notificationReadCount, setNotificationReadCount] = useState(false);
    useEffect(() => {
        var _a;
        if ((_a = notificationStateData === null || notificationStateData === void 0 ? void 0 : notificationStateData.getUserStatistic) === null || _a === void 0 ? void 0 : _a.firebaseNotificationCount) {
            setIsNotificationBadge(true);
        }
        if (notificationUnReadCount === null || notificationUnReadCount === void 0 ? void 0 : notificationUnReadCount.getFirebaseNotificationCount) {
            setNotificationReadCount(true);
        }
    }, [notificationStateData, notificationUnReadCount]);
    const getFirebaseNotificationStastic = () => {
        getFirebaseNotificationStatistic({ variables: { userId: ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) || 0) } });
    };
    useEffect(() => {
        if (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) {
            getFirebaseNotificationStastic();
            getFirebaseNotificationCount();
        }
    }, [currentUser]);
    const setListedNftNotification = useCallback((payload) => {
        var _a, _b, _c, _d, _e, _f;
        return ({
            title: (((_a = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _a === void 0 ? void 0 : _a.nftName) ? `'${(_b = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _b === void 0 ? void 0 : _b.nftName}' has been listed` : 'Title text Not Available'),
            body: ((_c = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _c === void 0 ? void 0 : _c.legendaryNFTCreated) ? <span>We’ve also created a nX{<LogoIcon className="nxlvl-logo-icon"/>}LEGENDARY for you. You can list it or hold onto it for later</span> : 'You can list more now or later',
            nftEditionId: (((_d = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _d === void 0 ? void 0 : _d.nftEditionId) || 0),
            nftId: (((_e = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _e === void 0 ? void 0 : _e.nftId) || 0),
            variant: 'success',
            showActionButtons: true,
            type: payload === null || payload === void 0 ? void 0 : payload.type,
            subType: (((_f = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _f === void 0 ? void 0 : _f.subType) || '')
        });
    }, []);
    const setCommonNotification = useCallback((payload) => {
        var _a, _b, _c;
        return ({
            title: ((payload === null || payload === void 0 ? void 0 : payload.title) || 'Notification Title'),
            body: ((payload === null || payload === void 0 ? void 0 : payload.body) || 'Notification Body'),
            nftEditionId: (((_a = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _a === void 0 ? void 0 : _a.nftEditionId) || 0),
            nftId: (((_b = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _b === void 0 ? void 0 : _b.nftId) || 0),
            variant: 'success',
            showActionButtons: false,
            type: payload === null || payload === void 0 ? void 0 : payload.type,
            subType: (((_c = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _c === void 0 ? void 0 : _c.subType) || ''),
        });
    }, []);
    const setErrorNotification = useCallback((payload) => {
        var _a, _b, _c;
        return ({
            title: 'Error',
            body: payload === null || payload === void 0 ? void 0 : payload.error,
            nftEditionId: (((_a = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _a === void 0 ? void 0 : _a.nftEditionId) || 0),
            nftId: (((_b = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _b === void 0 ? void 0 : _b.nftId) || 0),
            variant: 'error',
            showActionButtons: false,
            type: payload === null || payload === void 0 ? void 0 : payload.type,
            subType: (((_c = payload === null || payload === void 0 ? void 0 : payload.extraParameters) === null || _c === void 0 ? void 0 : _c.subType) || ''),
        });
    }, []);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleNotificationValues = (payload) => {
        var _a, _b;
        if (!isLoggedIn) {
            return;
        }
        let notificationData = null;
        getFirebaseNotificationStastic();
        if ((_a = payload === null || payload === void 0 ? void 0 : payload.notification) === null || _a === void 0 ? void 0 : _a.error) {
            notificationData = setErrorNotification(payload === null || payload === void 0 ? void 0 : payload.notification);
        }
        else {
            if (((_b = payload === null || payload === void 0 ? void 0 : payload.notification) === null || _b === void 0 ? void 0 : _b.type) === 'ListedNFT') {
                notificationData = setListedNftNotification(payload === null || payload === void 0 ? void 0 : payload.notification);
            }
            else {
                notificationData = setCommonNotification(payload === null || payload === void 0 ? void 0 : payload.notification);
            }
        }
        setNotification(notificationData);
        handleClickVariant(notificationData);
    };
    const handleClickVariant = (notificationData) => {
        enqueueSnackbar('', {
            persist: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            content: (key) => {
                return (<MultipleNotificationContent id={key} {...notificationData}/>);
            }
        });
    };
    // const configureFirebase = async () => {
    //   console.log('configureFirebase');
    //
    //   try {
    //     const fcmData = Cookies.get('fcmData');
    //     let deviceId = '';
    //     let fcmToken = ''
    //     if (fcmData) {
    //       const fbMessageData = JSON.parse(atob(fcmData));
    //       if (fbMessageData?.deviceId) {
    //         deviceId = fbMessageData?.deviceId;
    //         fcmToken = fbMessageData?.token;
    //       }
    //     }
    //     const token = await getToken();
    //     if (token) {
    //       if (token !== fcmToken) {
    //         const firebaseRegistered = await registerFirebaseToken({ variables: { userId: currentUser?.id, token, deviceId } });
    //
    //         if (firebaseRegistered?.data?.registerFirebaseToken) {
    //           if (!deviceId) {
    //             Cookies.set('fcmData', btoa(JSON.stringify(firebaseRegistered?.data?.registerFirebaseToken)))
    //           }
    //           onMessageListener(handleNotificationValues);
    //         }
    //       } else {
    //         console.log('added listener on token');
    //         onMessageListener(handleNotificationValues);
    //       }
    //     }
    //   } catch (error) {
    //     console.log('An error occurred while retrieving token. ');
    //   }
    // }
    useEffect(() => {
        if (isLoggedIn) {
            // configureFirebase();
        }
    }, [isLoggedIn]);
    return (<MultipleNotificationContext.Provider value={{ notification, isNotificationBadge, setIsNotificationBadge, notificationReadCount, setNotificationReadCount }}>
      {children}
    </MultipleNotificationContext.Provider>);
};
