import { gql, useMutation } from '@apollo/client';
import { GET_SIDEBAR_COUNT } from './useSidebarCount';
export const DELETE_NFT_DRAFT = gql `
    mutation deleteNFTEditionDraft (
        $id: Int!,
    ){
      deleteNFTEditionDraft(id: $id) {
        message
      }
    }
`;
export const useDeleteNFTMutation = () => {
    const [deleteNFTEditionDraft] = useMutation(DELETE_NFT_DRAFT, {
        notifyOnNetworkStatusChange: true,
    });
    const deleteNFTMutation = (variables) => deleteNFTEditionDraft({
        variables: variables,
        refetchQueries: () => [{
                query: GET_SIDEBAR_COUNT,
                variables: { userId: null }
            }],
    });
    return { deleteNFTMutation };
};
