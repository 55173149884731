import { Box, InputAdornment, Slider, Typography } from '@mui/material';
import { Form, FormGrid, FormGridItem, PriceMaskInput, TextField } from 'common/components/Form';
import { PriceRangeBox, PriceRangeTextBoxWrapper } from './NxFilter.styled';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DollarIcon } from 'ui/icons';
import PriceFormat from 'common/components/PriceFormat';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
const validationSchema = Yup.object({
    minAmount: Yup.string(),
    maxAmount: Yup.string()
});
export const PriceRange = ({ price, setPrice, globalFilterPriceRange }) => {
    var _a, _b;
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
            minAmount: ((_a = globalFilterPriceRange === null || globalFilterPriceRange === void 0 ? void 0 : globalFilterPriceRange.min) === null || _a === void 0 ? void 0 : _a.toString()) || '3',
            maxAmount: ((_b = globalFilterPriceRange === null || globalFilterPriceRange === void 0 ? void 0 : globalFilterPriceRange.max) === null || _b === void 0 ? void 0 : _b.toString()) || '50000'
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            // onSubmit() not required as we handle filter apply on from drawer.
        },
    });
    useEffect(() => {
        if (!price) {
            formik.setFieldValue('minAmount', '3');
            formik.setFieldValue('maxAmount', '50000');
        }
    }, [price]);
    const handleChange = (event, newValue) => {
        const rangeValue = newValue;
        if (rangeValue === null || rangeValue === void 0 ? void 0 : rangeValue.length) {
            formik.setFieldValue('minAmount', rangeValue[0].toString());
            formik.setFieldValue('maxAmount', rangeValue[1].toString());
        }
    };
    const minAmount = isNaN(formik.getFieldProps('minAmount').value) ? 0 : formik.getFieldProps('minAmount').value;
    const maxAmount = isNaN(formik.getFieldProps('maxAmount').value) ? 0 : formik.getFieldProps('maxAmount').value;
    const renderErrorMessage = () => {
        setPrice(`${minAmount}-${maxAmount}`);
        if (!formik.dirty) {
            return null;
        }
        if (!(parseFloat(minAmount) >= 3 && parseFloat(maxAmount) <= 50000)) {
            return (<Typography variant="h6" mt={1} className="error-text">{t('Please enter amount between USD $3.00 - $50,000.00')}</Typography>);
        }
        else if (parseFloat(minAmount) > parseFloat(maxAmount)) {
            return (<Typography variant="h6" mt={1} className="error-text">{t('Minimum amount is grater then maximum amount.')}</Typography>);
        }
        return null;
    };
    return (<PriceRangeBox>
      <Box mb={1}>
        <Typography variant="h5" fontWeight="500">Price range</Typography>
        <Typography variant="h6" fontWeight="500"><PriceFormat value={(minAmount || '0.00')}/> - <PriceFormat isOnlySymbol={true} value={(maxAmount || '0.00')}/></Typography>
      </Box>
      <Box textAlign="center">
        <Slider value={[parseFloat(minAmount), parseFloat(maxAmount)]} onChange={handleChange} min={3} max={50000} step={1} className="price-slider"/>
      </Box>
      <PriceRangeTextBoxWrapper>
        <Form id="price-range-form">
          <FormGrid>
            <FormGridItem xs={6}>
              <TextField formik={formik} name="minAmount" className="min-price-range" placeholder="0.00" InputProps={{
            startAdornment: <><InputAdornment position="start"><Typography className="price-range-text-value">Min <DollarIcon className="dollar-icon"/></Typography></InputAdornment></>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: PriceMaskInput,
        }}/>
            </FormGridItem>
            <FormGridItem xs={6}>
              <TextField formik={formik} name="maxAmount" className="max-price-range" placeholder="0.00" InputProps={{
            startAdornment: <><InputAdornment position="start"><Typography className="price-range-text-value">Max <DollarIcon className="dollar-icon"/></Typography></InputAdornment></>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: PriceMaskInput,
        }}/>
            </FormGridItem>
          </FormGrid>
          {renderErrorMessage()}
        </Form>
      </PriceRangeTextBoxWrapper>
    </PriceRangeBox>);
};
