import Gleap from 'gleap';
export const initGleap = ({ apiKey, appVersion, nodeEnv, publicEnv, }) => {
    const isProd = nodeEnv === 'production';
    if (apiKey && isProd) {
        Gleap.initialize(apiKey);
        Gleap.setEnvironment(publicEnv === 'production' ? 'prod' : 'staging');
        attachCustomDataGleap({
            buildEnvironment: publicEnv === 'uat' ? 'staging' : publicEnv || '',
            buildVersion: appVersion,
        });
    }
};
// Send custom data (object) to Gleap
export const attachCustomDataGleap = (data) => {
    Gleap.attachCustomData(data);
};
// Remove custom data from Geap for specified Key
export const removeCustomDataGleap = (key) => {
    Gleap.removeCustomData(key);
};
export const openGleapForm = () => {
    Gleap.open();
};
