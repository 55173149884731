import { Box, styled } from '@mui/material';
export const SwitchButton = styled(Box)(() => ({
    '.MuiSwitch-root': {
        width: '3.25rem',
        height: '2rem',
        marginRight: 0,
        borderRadius: '3.125rem',
        '.MuiSwitch-thumb': {
            width: '1.75rem',
            height: '1.75rem',
        },
        '.Mui-checked': {
            transform: 'translateX(1.25rem)',
        }
    }
}));
