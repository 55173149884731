import React from 'react';
import { colors } from 'ui/foundations';
import { EmptyCircleOutlineIcon, SuccessCircleOutlineIcon } from '../icons';
const MuiRadio = {
    styleOverrides: {
        root: {
            color: colors.blue
        },
    },
    variants: [],
    defaultProps: {
        icon: <EmptyCircleOutlineIcon fontSize="small"/>,
        checkedIcon: <SuccessCircleOutlineIcon fontSize="small"/>,
    }
};
export default MuiRadio;
