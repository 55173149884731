import { colors, themeDefault } from 'ui/foundations';
const MuiSnackbar = {
    styleOverrides: {
        root: {
            '&.MuiSnackbar-root': {
                top: 58,
                width: 350,
                backgroundColor: colors.blueDark,
                padding: 12,
                borderRadius: 10,
                display: 'block',
                [themeDefault.breakpoints.down('sm')]: {
                    width: 'calc(100% - 1rem)',
                },
            },
            '.MuiButton-root': {
                margin: 4,
            },
        },
    },
    variants: [
        {
            props: {
                color: 'default',
            },
            style: {
                '&.MuiSnackbar-root': {
                    backgroundColor: colors.blue
                },
                '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                    backgroundColor: colors.infoButton,
                },
            }
        },
        {
            props: {
                color: 'error',
            },
            style: {
                '&.MuiSnackbar-root': {
                    backgroundColor: colors.error500
                },
                '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                    backgroundColor: colors.errorButton,
                },
            }
        },
        {
            props: {
                color: 'success',
            },
            style: {
                '&.MuiSnackbar-root': {
                    backgroundColor: colors.success500
                },
                '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                    backgroundColor: colors.successButton,
                },
            }
        },
        {
            props: {
                color: 'warning',
            },
            style: {
                '&.MuiSnackbar-root': {
                    backgroundColor: colors.warning500
                },
                '.MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
                    backgroundColor: colors.warningButton,
                    color: `${colors.black}!important`,
                },
                '.MuiTypography-root': {
                    color: `${colors.black}!important`,
                },
                'svg.MuiSvgIcon-root': {
                    color: `${colors.black}!important`,
                    path: {
                        stroke: `${colors.black}!important`
                    }
                }
            }
        },
    ],
    defaultProps: {}
};
export default MuiSnackbar;
