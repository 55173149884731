import { NxlvlCard } from 'common/Layout/SideBar';
import { NXConnectAdvertiseCard, FilterSection, Loader, AdCardListing, NftCard } from 'common/components';
import { useToast } from '@nxlvl/web-providers';
import { Grid, styled } from '@mui/material';
import { Box } from '@mui/system';
import { SEARCH_NFT, useSearchNFTQuery } from 'graphql-client/search';
import { useGlobalFilters } from 'helpers/hooks';
import { mapElasticNftToDashboardNft } from 'helpers/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { NxMainBody, NxMainHead } from '../../DashboardScreen.styled';
import { EmptyNftListState } from './EmptyNftListState';
import WithWayPointScroll from 'helpers/hoc/withWayPointScroll';
const commonPageId = 'dashboard-section-view-all-explore';
const SearchResultBox = styled(Box)(({ theme }) => ({
    '.explore-header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        '.dashboard-main-head': {
            width: '7.5rem',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                marginRight: theme.spacing(0),
                marginBottom: theme.spacing(1)
            },
        },
        '.nx-filter-head-box': {
            display: 'flex',
            flex: '1',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
    },
    '.filter-text': {
        fontSize: '0.875rem',
        marginRight: theme.spacing(1),
    },
    '.filter-select': {
        color: theme.dynamicTheme.mainSection.text.color,
        fontSize: '0.875rem',
        fontWeight: 'bold',
        'select': {
            paddingRight: `${theme.spacing(.5)}!important`
        },
    },
    '.filter-select-option': {
        background: `${theme.dynamicTheme.mainSection.backgroundColor}!important`,
    },
    '.sort-icon path': {
        fill: theme.dynamicTheme.mainSection.text.color,
    }
}));
export const ViewAllExplore = () => {
    var _a, _b, _c, _d, _e, _f;
    const { openToast } = useToast();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const queryObj = Object.fromEntries([...searchParams]);
    const searchString = (queryObj === null || queryObj === void 0 ? void 0 : queryObj.query) || '';
    const { globalFilters } = useGlobalFilters();
    const queryParams = {
        searchString: globalFilters.searchString,
        pageNo: 0,
        take: 10,
        activity: globalFilters.activityIds,
        editions: globalFilters.tiers,
        availableForSale: globalFilters.availableForSale,
        priceRange: globalFilters.priceRange,
        sortBy: globalFilters.orderBy
    };
    const { loading, called, error, data, fetchMore } = useSearchNFTQuery(queryParams);
    useEffect(() => {
        error && openToast(error === null || error === void 0 ? void 0 : error.message, { type: 'error' });
        // eslint-disable-next-line
    }, [error]);
    const searchResultNfts = ((_b = (_a = data === null || data === void 0 ? void 0 : data.searchNFT) === null || _a === void 0 ? void 0 : _a.pageEdges) === null || _b === void 0 ? void 0 : _b.node) || [];
    const onNftSelected = (nftId) => () => {
        navigate(`/pdp/${nftId}`);
    };
    const handleFetchMore = () => {
        var _a, _b;
        const newPageNo = (((_b = (_a = data === null || data === void 0 ? void 0 : data.searchNFT) === null || _a === void 0 ? void 0 : _a.pageInfo) === null || _b === void 0 ? void 0 : _b.pageNo) || 0) + 1;
        fetchMore({
            variables: {
                searchNFTArgs: Object.assign(Object.assign({}, queryParams), { pageNo: newPageNo })
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                var _a, _b;
                const prevData = prev === null || prev === void 0 ? void 0 : prev.searchNFT;
                const data = (fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.searchNFT) || {};
                const result = {
                    searchNFT: Object.assign({}, prevData, {
                        pageEdges: Object.assign(Object.assign({}, prevData === null || prevData === void 0 ? void 0 : prevData.pageEdges), { node: [...(((_a = prevData === null || prevData === void 0 ? void 0 : prevData.pageEdges) === null || _a === void 0 ? void 0 : _a.node) || []), ...(((_b = data === null || data === void 0 ? void 0 : data.pageEdges) === null || _b === void 0 ? void 0 : _b.node) || [])] }),
                        pageInfo: Object.assign({}, data.pageInfo)
                    })
                };
                return result;
            }
        });
    };
    const isLoading = (loading && ((searchResultNfts === null || searchResultNfts === void 0 ? void 0 : searchResultNfts.length) || 0) === 0);
    const hasNoData = !loading && called && ((searchResultNfts === null || searchResultNfts === void 0 ? void 0 : searchResultNfts.length) || 0) === 0;
    const hasNextPage = !!((_d = (_c = data === null || data === void 0 ? void 0 : data.searchNFT) === null || _c === void 0 ? void 0 : _c.pageInfo) === null || _d === void 0 ? void 0 : _d.hasNextPage);
    return (<SearchResultBox>
      <FilterSection searchString={searchString} totalCount={(_f = (_e = data === null || data === void 0 ? void 0 : data.searchNFT) === null || _e === void 0 ? void 0 : _e.pageInfo) === null || _f === void 0 ? void 0 : _f.totalCount} title="Explore All"/>
      <NxMainHead>
        <Loader loading={loading}>
          <Box className="explore-header">
            <Box className="nx-filter-head-box">
              <Box className="nx-filter-btn-box">

              </Box>
            </Box>
          </Box>
        </Loader>
      </NxMainHead>
      {hasNoData ? (<EmptyNftListState />) : (<NxMainBody>
          <WithWayPointScroll loading={loading} hasNextPage={hasNextPage} onEnter={handleFetchMore}>
            <Grid container spacing={2}>
              {(searchResultNfts || []).map((nft, index) => {
                return (<AdCardListing key={`search-nft-card-${nft === null || nft === void 0 ? void 0 : nft.id}-${index}`} index={index} adCardComponent={(<NXConnectAdvertiseCard variant="vertical"/>)} adBannerComponent={(<NxlvlCard />)}>
                    <NftCard id={`${commonPageId}-card-${nft === null || nft === void 0 ? void 0 : nft.id}`} isLoading={isLoading} size="small" data={mapElasticNftToDashboardNft(nft)} variant="stacked" query={SEARCH_NFT} queryParams={{ searchNFTArgs: queryParams }} onSelect={onNftSelected(nft === null || nft === void 0 ? void 0 : nft.id)}/>
                  </AdCardListing>);
            })}
            </Grid>
          </WithWayPointScroll>
        </NxMainBody>)}
    </SearchResultBox>);
};
